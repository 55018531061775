import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Search } from 'src/toneIcons';
import { useReportCreateMutation } from 'src/trpcHooks/useReportMutation';
import { useDebouncedCallback } from 'use-debounce';
import useRouterQuery from '../hooks/useRouterQuery';
import { Button } from './Button/Button';
import { Input } from './Input/Input';
import { Paginator } from './Paginator/Paginator';

interface FiltersProps {
  addText?: string;
  addIcon?: JSX.Element;
  hideSearch?: boolean;
  length?: number;
}

const DEFAULT_LIMIT = 25;

export const PageFilters: React.FC<FiltersProps> = ({ addText, hideSearch, addIcon, length }) => {
  const [query, setQuery] = useRouterQuery();
  const { control } = useForm<{ q: string }>({
    mode: 'all',
    defaultValues: {
      q: query.q,
    },
  });
  const debounceFn = useDebouncedCallback(
    (n: string, v: string) => setQuery((o) => ({ ...o, [n]: v })),
    400,
  );
  const reportCreateMutation = useReportCreateMutation();
  const navigate = useNavigate();

  const onCreate = async () => {
    const reportId = await reportCreateMutation.mutateAsync({});
    navigate(`/drafts/${reportId.toString()}`);
  };
  return (
    <>
      {!hideSearch && (
        <Controller
          control={control}
          name="q"
          render={({ field }) => (
            <Input
              size="small"
              icon={<Search />}
              placeholder="Search"
              {...field}
              onChange={(e) => {
                field.onChange(e);
                debounceFn(field.name, e.currentTarget.value);
              }}
            />
          )}
        />
      )}
      <Paginator
        limit={query.limit}
        page={query.page ?? 0}
        defaultLimit={DEFAULT_LIMIT}
        length={length}
      />
      {(addText || addIcon) && (
        <Button size="small" icon={addIcon} onClick={onCreate}>
          {addText}
        </Button>
      )}
    </>
  );
};
