import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from '../../libs/Tooltip';
import { ReportCategory, ServerBrandDataCounts } from '../../utils/trpc';
import { getRangeColor, getRangeIcon, getRangeString, getScore } from '../../utils2';
import styles from './ScoreCircle.module.scss';

interface ScoreBoxProps {
  counts?: ServerBrandDataCounts;
  concept: ReportCategory;
}
export const ScoreCircle: React.FC<ScoreBoxProps> = ({ counts, concept }) => {
  const value = getScore(counts?.categoryScore);

  if (counts == null || Number.isNaN(value) || value == null) {
    return <span className={styles.nan}>N/A</span>;
  }

  const tooltipContent = (
    <TooltipItem
      value={value}
      rank={counts?.categoryScoreRank?.value}
      outOf={counts?.categoryScoreRank?.outOf}
      catType={concept?.name}
      rangeText={getRangeString(value)}
    />
  );
  const Icon = getRangeIcon(value);

  const style = {
    '--circle': getRangeColor(value),
  } as CSSProperties;
  return (
    <Link style={style} to={`${counts.brandId}?category=${concept._id.toHexString()}`}>
      <Tooltip
        background="#fff"
        label={tooltipContent}
        autoPlace={false}
        boxShadow="0px 2px 4px rgba(0,0,0,0.2)">
        <div className={styles.circle}>{Icon != null && <Icon />}</div>
      </Tooltip>
    </Link>
  );
};

interface TooltipItemProps extends React.HTMLProps<HTMLDivElement> {
  value: number;
  rank: number;
  outOf: number;
  catType?: string;
  rangeText?: string;
}

export const TooltipItem: React.FC<TooltipItemProps> = ({
  value,
  rank,
  outOf,
  catType,
  rangeText,
}) => (
  <>
    <div className={styles.tooltipSentimentScore}>Sentiment score: {value.toFixed(0)}/100</div>
    <div className={styles.tooltipSentimentText}>
      Rank: {rank}/{outOf}
    </div>
    <div className={styles.tooltipSentimentText}>
      <span>Reviews mentioning <strong>{catType}</strong> had </span>
      <strong className={styles.tooltipSentimentValue} style={{ color: getRangeColor(value) }}>
        {rangeText}
      </strong>
      <span> ratings</span>
    </div>
  </>
);
