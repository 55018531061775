import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import {
  reportCategoriesV2ListRQKey,
  reportPhrasesListRQKey,
  RQUtils,
  suggestedClustersListRQKey,
} from '../../utils/reactQuery.utils';
import {
  CompactReportPhrase,
  CompactReportPhrasesForReport,
  ReportCategoryV2,
  SuggestedCluster,
  trpc,
} from '../../utils/trpc';
import { DeletablePill } from '../pills/pills';
import styles from './conceptV2.module.scss';

interface ConceptV2CompProps {
  reportId: string;
  concept: ReportCategoryV2;
  reportPhrasesById: Record<string, CompactReportPhrase>;
  onEdit?: (concept: ReportCategoryV2) => void;
}

export const ConceptV2Comp: React.FC<ConceptV2CompProps> = ({
  reportId,
  concept,
  reportPhrasesById,
  onEdit,
}) => {
  const queryClient = useQueryClient();

  const deleteConceptMut = trpc.reportCategoriesV2.delete.useMutation({
    onSuccess: (deletedRes) => {
      const { conceptId, updatedClusters, updatedPhrases } = deletedRes;
      queryClient.setQueryData<ReportCategoryV2[]>(reportCategoriesV2ListRQKey(reportId), (old) => {
        return RQUtils.delArrQuery(old, conceptId);
      });

      // update suggested clusters
      queryClient.setQueryData<SuggestedCluster[]>(suggestedClustersListRQKey(reportId), (old) => {
        return RQUtils.bulkUpdateArrQuery(old, updatedClusters);
      });
      // update phrases
      queryClient.setQueryData<CompactReportPhrasesForReport>(
        reportPhrasesListRQKey(reportId),
        (old) => {
          return RQUtils.bulkUpdateDictQuery(old, updatedPhrases);
        },
      );
    },
  });
  const deleteConcept = (concept: ReportCategoryV2) => {
    if (deleteConceptMut.isLoading == true) {
      return;
    }
    deleteConceptMut.mutate({ reportId, conceptId: concept.id });
  };

  // initial condition for splitting phrase ids
  const initialPartitions = {
    active: [] as CompactReportPhrase[],
    unknown: [] as string[],
  };
  // split phrases ids into different type
  const partitioned = concept.phraseIds.reduce((acc, pId) => {
    const phrase = reportPhrasesById[pId];
    if (phrase == null) {
      acc.unknown.push(pId);
      return acc;
    }
    acc.active.push(phrase);
    return acc;
  }, initialPartitions);

  const deleteConceptText = deleteConceptMut.isLoading ? '...' : 'delete';

  // const phrases: ClusteredPhrase[] = data?.phrases;
  const phraseIds = concept.phraseIds;
  return (
    <div className={styles.suggestConceptWrap} id={`${concept.id}_concept`}>
      <div className={styles.suggestConcept}>
        <div className={styles.buttons}>
          {onEdit && <button onClick={() => onEdit(concept)}>edit</button>}
          <button className={styles.conceptDelete} onClick={() => deleteConcept(concept)}>
            {deleteConceptText}
          </button>
        </div>
        <div className={styles.conceptName}>{concept.name ?? `Concept ${concept.id}`}</div>
        <>
          <div className={styles.suggestConceptWords}>
            {partitioned.active.map((phrase, i) => (
              <DeleteConceptPhrasePill
                reportId={reportId}
                conceptId={concept.id}
                phrase={phrase}
                key={`${concept.id}-${phrase.id}-${i}`}
              />
            ))}
          </div>
        </>
      </div>
    </div>
  );
};

interface ConceptPillActionProps {
  reportId: string;
  conceptId: string;
  phrase: CompactReportPhrase;
}
const DeleteConceptPhrasePill: React.FC<ConceptPillActionProps> = ({
  reportId,
  conceptId,
  phrase,
}) => {
  const queryClient = useQueryClient();

  const deleteConceptPhraseMut = trpc.reportCategoriesV2.deletePhrase.useMutation({
    onSuccess: (updatedConcept) => {
      queryClient.setQueryData<ReportCategoryV2[]>(reportCategoriesV2ListRQKey(reportId), (old) => {
        return RQUtils.updateArrQuery(old, updatedConcept);
      });
    },
  });
  const deleteConceptPhrase = () => {
    if (deleteConceptPhraseMut.isLoading == true) {
      return;
    }
    deleteConceptPhraseMut.mutate({ reportId, conceptId, phraseId: phrase.id });
  };

  return (
    <DeletablePill
      text={phraseText(phrase)}
      status={deleteConceptPhraseMut.status}
      onDelete={deleteConceptPhrase}
    />
  );
};
function phraseText(phrase: CompactReportPhrase) {
  const reviewSentiment = (phrase.ss?.s || 0).toFixed(1);
  const sentenceSentiment = (phrase.rs?.s || 0).toFixed(1);

  return `${phrase.p} (${phrase.c}) (${sentenceSentiment}) (${reviewSentiment})`;
}

export default ConceptV2Comp;
