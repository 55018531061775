import clsx from 'clsx';
import React from 'react';
import styles from './BrandSource.module.scss';

interface BrandSourceProps extends React.HTMLProps<HTMLDivElement> {
  name?: string;
  icon: JSX.Element;
}
export const BrandSource = React.forwardRef<HTMLDivElement, BrandSourceProps>(
  ({ className, name, icon, style, onClick }, ref) => {
    const classList = clsx(className, styles.brandSource, onClick ? styles.hoverable : undefined);
    return (
      <div key={name} className={classList} ref={ref} style={style} onClick={onClick}>
        <div className={styles.brandSourceIcon}>{icon}</div>
        {name != null && <div className={styles.brandSourceName}>{name}</div>}
      </div>
    );
  },
);

export default BrandSource;
