import clsx from 'clsx';
import React from 'react';
import { Plus, XMarkCircle } from '../StrokeIcons';
import styles from './Badge.module.scss';

type BadgeType = 'primary';

interface BadgeProps extends React.HTMLProps<HTMLDivElement> {
  icon?: JSX.Element;
  dot?: boolean;
  onDelete?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  type?: BadgeType;
}

export const Badge: React.FC<BadgeProps> = ({
  children,
  className,
  dot,
  icon,
  type,
  onClick,
  onDelete,
}) => {
  const classList = clsx(
    styles.container,
    className,
    type ? styles[type] : undefined,
    onClick ? styles.hoverable : undefined,
    onDelete ? styles.deletable : undefined,
  );
  return (
    <div className={classList} onClick={onClick}>
      {dot && <div className={styles.dot} />}
      {icon && <div className={styles.icon}>{icon}</div>}
      {children}
      {onDelete && (
        <div className={styles.delete} onClick={onDelete}>
          <XMarkCircle />
        </div>
      )}
    </div>
  );
};

export const SmallBadge: React.FC<BadgeProps> = ({ className, ...props }) => (
  <Badge className={clsx(className, styles.small)} {...props} />
);

export const AddBadge: React.FC<BadgeProps> = ({
  children,
  dot,
  className,
  onClick,
  icon = <Plus />,
}) => {
  const classList = clsx(
    styles.container,
    styles.add,
    onClick ? styles.hoverable : undefined,
    className,
  );
  return (
    <div className={classList} onClick={onClick}>
      {dot && <div className={styles.dot} />}
      {icon && <div className={styles.icon}>{icon}</div>}
      {children}
    </div>
  );
};

export const SmallAddBadge: React.FC<BadgeProps> = ({ className, ...props }) => (
  <AddBadge className={clsx(styles.small, className)} {...props} />
);
