import { initializeApp } from 'firebase/app';
import { User as ClientUser, getAuth, onAuthStateChanged } from 'firebase/auth';
import React from 'react';
import { AmplitudeContext, useSetUserId } from '../libs/react-amplitude';
import { SvrUser, trpc } from '../utils/trpc';

interface AuthType {
  user?: ClientUser | null;
  me?: SvrUser | null;
}

export const AuthContext = React.createContext<AuthType>({});

const firebaseConfig = {
  apiKey: 'AIzaSyBucqYtzOS2y5nILCm-buPpf82WC99JbIc',
  authDomain: 'harmonizeai.firebaseapp.com',
  databaseURL: 'https://harmonizeai.firebaseio.com',
  projectId: 'harmonizeai',
  storageBucket: 'harmonizeai.appspot.com',
};

initializeApp(firebaseConfig);

export const AuthProvider: React.FC<React.HTMLProps<HTMLDivElement>> = ({ children }) => {
  const [user, setUser] = React.useState<ClientUser | null>();
  useSetUserId(user?.uid, user);

  const { data, isError } = trpc.user.me.useQuery(undefined, {
    enabled: Boolean(user),
  });

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), setUser);
    return () => {
      unsubscribe();
    };
  }, []);

  const { amplitudeInstance } = React.useContext(AmplitudeContext);
  const amplitudeInstanceDeviceId = amplitudeInstance.options.deviceId;

  return (
    <AuthContext.Provider
      value={{
        user,
        me: isError ? null : data,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => React.useContext(AuthContext);
