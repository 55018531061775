import clsx from 'clsx';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  useAddWords,
  useReportCategoryRemoveWord,
} from 'src/trpcHooks/useReportCategoriesMutation';
import useRouterQuery from '../../hooks/useRouterQuery';
import { AspectType, ReportCategory, trpc } from '../../utils/trpc';
import { AddBadge, Badge } from '../Badge/Badge';
import { CreateButton } from '../Button/CustomButton';
import { ContentDivider } from '../ContentDivider/ContentDivider';
import { ContentSection } from '../ContentSection/ContentSection';
import { Form } from '../Form/Form';
import { Input } from '../Input/Input';
import { Flex, Right } from '../Layout/Layout';
import styles from './ConceptDetailPage.module.scss';
import { SvgIconButton } from '../SvgIconButton/SvgIconButton';
import { Pencil } from '../StrokeIcons';
import EditableLabel from 'src/components/editableLabel/editableLabel';
import { useUpdateConceptAspectName } from 'src/trpcHooks/reportCategories/useUpdateConceptAspectName';

interface ConceptProps {
  reportId: string;
  concept: ReportCategory;
  aspect: AspectType;
  name?: string | null;
}

interface FormData {
  words: string;
}

export const Concept: React.FC<ConceptProps> = ({ concept, name, reportId, aspect }) => {
  const conceptId = concept._id.toHexString();
  const deleteWordFromReportConceptMut = useReportCategoryRemoveWord();
  const conceptUpdateMut = useUpdateConceptAspectName(aspect);

  const [query, setQuery] = useRouterQuery();
  const { control, handleSubmit, getValues, watch, setValue } = useForm<FormData>({
    mode: 'all',
    defaultValues: {
      words: '',
    },
  });
  const addWordsMutation = useAddWords();

  const toggleSearch = (q: string) => {
    setQuery((s) => ({
      ...s,
      q: query.q === q ? undefined : q,
      page: undefined,
    }));
  };

  const onDeleteTag = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    word: string,
    aspect: AspectType | null,
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (deleteWordFromReportConceptMut.isLoading) {
      return;
    }
    deleteWordFromReportConceptMut.mutate({
      reportId,
      categoryId: conceptId,
      word,
      aspect,
    });
  };

  const onSubmit = async (data: FormData) => {
    const words = data.words.split(',').map((word) => word.trim());
    await addWordsMutation.mutate({
      aspect,
      words,
      reportId,
      categoryId: conceptId,
    });

    setValue('words', '');
  };

  const wordsConcepts = React.useMemo(() => {
    const newWords = concept[aspect]?.words?.filter((el) => el.includes(getValues('words')));
    if (!newWords?.length) {
      return concept[aspect]?.words;
    }
    return newWords;
  }, [watch('words'), concept[aspect]?.words]);

  const onChangeLabel = async (value: string) => {
    await conceptUpdateMut.mutate({
      reportId,
      categoryId: conceptId,
      newVals: { [aspect]: { name: value } },
    });
  };

  return (
    <ContentSection>
      <ContentDivider className={styles.editableLabel}>
        <>
          <EditableLabel
            className={styles.editableLabel}
            value={name || aspect}
            onChangeValue={onChangeLabel}
            deleteTooltip="remove this brand from report"
          />
        </>
      </ContentDivider>
      <Form width="restricted" onSubmit={handleSubmit(onSubmit)}>
        <ContentSection>
          <Controller
            control={control}
            name="words"
            render={({ field }) => (
              <Input
                title="Words"
                placeholder="add words"
                disabled={addWordsMutation.isLoading}
                autoFocus
                {...field}
              />
            )}
          />
          <Right>
            <CreateButton isLoading={addWordsMutation.isLoading}> Update</CreateButton>
          </Right>
        </ContentSection>
      </Form>
      <Flex>
        {wordsConcepts?.map((word, i) => (
          <Badge
            key={`${word + i}`}
            className={clsx(
              wordsConcepts.length === 1 && watch('words') === wordsConcepts[0]
                ? styles.wordSelect
                : '',
            )}
            type={query && word === query.q ? 'primary' : undefined}
            onClick={() => toggleSearch(word)}
            onDelete={(e) => onDeleteTag(e, word, aspect)}>
            {word}
          </Badge>
        ))}

        {watch('words') &&
          !(wordsConcepts?.length === 1 && watch('words') === wordsConcepts[0]) && (
            <AddBadge onClick={() => onSubmit({ words: getValues('words') })}>
              {getValues('words')}
            </AddBadge>
          )}
      </Flex>
    </ContentSection>
  );
};
