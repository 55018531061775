import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ServerBrand, trpc } from '../../utils/trpc';
import { Select } from '../Input/Select';
import { sourcesById } from '../../sources';
import Icon from 'src/components/icon/icon';
import styles from './ReportProviderPicker.module.scss';

const DEFAULT_VALUE = '';

interface ReportProviderPickerProps {
  reportId: string;
  brand: ServerBrand;
  appId?: string;
}

interface OptionsType {
  value: string;
  imgBrand?: string;
  label: string;
  icon?: JSX.Element;
}

export const ReportProviderPicker: React.FC<ReportProviderPickerProps> = ({
  reportId,
  brand,
  appId,
}) => {
  const navigate = useNavigate();
  const brandId = brand._id.toHexString();

  const report = trpc.report.id.useQuery(reportId);

  const appsForBrand = React.useMemo(() => {
    return report.data?.apps?.filter((a) => a.brandId?.equals(brand._id));
  }, [report.data]);

  const options: OptionsType[] = [
    { value: DEFAULT_VALUE, label: 'All Sources' },
    ...(appsForBrand?.map((app) => {
      const providerId = app.appProviderOverride || app?.appProvider;
      const provider = providerId ? sourcesById?.[providerId] : undefined;

      return {
        value: app.metaId.toHexString(),
        imgBrand: app.iconUrl || undefined,
        label: app.title || '',
        icon: provider?.icon,
      };
    }) || []),
  ];

  const onChangeSource = (data: { label: string; value: string }) => {
    if (data.value === DEFAULT_VALUE) {
      navigate({
        pathname: `/reports/${reportId}/brands/${brandId}`,
        search: window.location.search,
      });
    } else {
      navigate({
        pathname: `/reports/${reportId}/brands/${brandId}/${data.value}`,
        search: window.location.search,
      });
    }
  };

  return (
    <Select<OptionsType>
      size="small"
      placeholder="All Sources"
      value={appId || DEFAULT_VALUE}
      options={options}
      optionRow={(option) => (
        <>
          {option.icon && <span className={styles.optionIcon}>{option.icon}</span>}
          {option.imgBrand && <Icon src={option.imgBrand} />} <span>{option.label}</span>
        </>
      )}
      onChange={onChangeSource}
    />
  );
};
