import React from 'react';
import clsx from 'clsx';
import { DeleteIcon } from '../icons/icons';
import styles from './sourceFileUpload.module.scss';
import { sourcesById } from '../../sources';
import { Button } from '../../PoseidonComponents/Button/Button';
import { Input } from 'src/PoseidonComponents/Input/Input';

interface CSVColumnExplanationProps {
  colName: string;
  colTypes: ('text' | 'date text' | 'number')[];
  required: boolean;
  description: string;
  validFormats?: string[];
}

export const CSVColumnExplanation: React.FC<CSVColumnExplanationProps> = ({
  colName,
  colTypes,
  required,
  description,
  validFormats,
}) => {
  const requiredStr = required == true ? 'REQUIRED' : 'optional';
  const typesStr = [...colTypes, requiredStr].join(', ');

  return (
    <div className={styles.csvColumn}>
      <div className={styles.csvColumndata}>
        <div className={styles.csvColumnName}>
          <strong>{colName}</strong>: ({typesStr})
        </div>
        <div className={styles.csvColumnDescription}>{description}</div>
      </div>
      {validFormats != null && validFormats.length > 0 && (
        <CSVColumnFormat formatVals={validFormats} />
      )}
    </div>
  );
};

interface CSVColumnFormatProps {
  formatVals: string[];
}
export const CSVColumnFormat: React.FC<CSVColumnFormatProps> = ({ formatVals }) => {
  return (
    <div className={styles.csvColumnFormats}>
      <div className={styles.csvColumnFormatsTitle}>valid formats:</div>
      {formatVals.map((v, idx) => (
        <div key={`col-fomat-${idx}`} className={styles.csvColumnFormatsValue}>
          <strong>{idx + 1}</strong> {v}
        </div>
      ))}
    </div>
  );
};

interface SourceFileUploadProps {
  brandId: string;
  brandName: string | undefined;
  onClose: () => void;
  provider: string;
  createSourceFromFile: (brandId: string, sourceName: string, file: File, provider: string) => void;
  csvErrors?: any;
}

export const SourceFileUpload: React.FC<SourceFileUploadProps> = ({
  brandName,
  onClose,
  provider,
  createSourceFromFile,
  csvErrors,
  brandId,
}) => {
  const [sourceName, setSourceName] = React.useState(brandName);
  const [fileToUpload, setFileToUpload] = React.useState<File>();
  const [hoveringFileDrop, setHoveringFileDrop] = React.useState(false);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSourceName(e.target.value);
  };

  const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    if (e.dataTransfer.effectAllowed === 'move') return;
    e.preventDefault();
    e.stopPropagation();
    setHoveringFileDrop(true);
  };
  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    if (e.dataTransfer.effectAllowed === 'move') return;
    e.preventDefault();
    e.stopPropagation();
    setHoveringFileDrop(false);
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    if (e.dataTransfer.effectAllowed === 'move') return;
    e.preventDefault();
    e.stopPropagation();
    setHoveringFileDrop(false);

    const files = Array.from(e.dataTransfer.files).filter((f) => f.type === 'text/csv');
    setFileToUpload(files[0]);
  };
  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const createSource = () => {
    if (sourceName && fileToUpload) {
      createSourceFromFile(brandId, sourceName, fileToUpload, provider);
    }
  };

  const brandSource = sourcesById[provider];

  return (
    <div className={styles.container}>
      <DefaultColumns />
      <Input placeholder="Name your provider" value={sourceName || ''} onChange={onChange} />

      {fileToUpload && (
        <div className={styles.files}>
          <div className={styles.file}>
            {brandSource?.icon}
            <div>{fileToUpload.name}</div>
            <div className={styles.spacer} />
            <div className={styles.deleteIcon} onClick={() => setFileToUpload(undefined)}>
              <DeleteIcon />
            </div>
          </div>
        </div>
      )}
      <div
        className={clsx(styles.filedrop, hoveringFileDrop ? styles.hovering : null)}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        onDragOver={onDragOver}>
        <div className={styles.filedropContent}>Drag and Drop file</div>
      </div>
      {csvErrors?.body && (
        <div className={styles.errors}>
          <div>{csvErrors?.body?.message}</div>
          {csvErrors?.body?.csvErrors?.errorMessages && (
            <div>
              {csvErrors?.body?.csvErrors?.errorMessages?.map((e: any, i: number) => (
                <div key={i} className={styles.errorMessage}>
                  {e}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      <div className={styles.buttons}>
        <Button disabled={!brandName || !fileToUpload} onClick={createSource}>
          Create source
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </div>
    </div>
  );
};

export default SourceFileUpload;

const DefaultColumns = () => {
  const dateFormats = [
    'milliseconds since unix epoch (Jan 1st 1970 00:00:00.000 UTC)',
    'seconds since unix epoch (Jan 1st 1970 00:00:00.000 UTC)',
    '"2011-10-10" (date-only format) interpreted as UTC',
    '"2011-10-10T14:48:00" (date-time format) interpreted as local time',
    '"2011-10-10T14:48:00.000+09:00" (date-time format with milliseconds and time zone)',
  ];

  return (
    <div>
      <h3>CSV column format:</h3>
      <p className={styles.specialnote}>
        Note: When you are uploading a CSV, you will generally <strong>either have</strong> an NPS®
        (net promoter score) or a raw score, not both, as in the example below
      </p>

      <div className={styles.csvTable}>
        <div className={styles.header}>id</div>
        <div className={styles.header}>score</div>
        <div className={styles.header}>nps</div>
        <div className={styles.header}>text</div>
        <div className={styles.header}>title</div>
        <div className={styles.header}>userName</div>
        <div className={styles.header}>date</div>

        <div className={styles.celldata}>1</div>
        <div className={styles.celldata}>4</div>
        <div className={styles.celldata}></div>
        <div className={styles.celldata}>this is the verbatim text</div>
        <div className={styles.celldata}>title</div>
        <div className={styles.celldata}>JoanDoe</div>
        <div className={styles.celldata}>2022-10-10</div>
      </div>

      <CSVColumnExplanation
        colName="id"
        colTypes={['text']}
        required={false}
        description="unique identifier for the verbatim (review or text chunk), if not provided a unique identifier is created by taking a hash of the provided text"
      />
      <CSVColumnExplanation
        colName="score"
        colTypes={['text']}
        required={false}
        description="0-5 raw score value for the verbatim (e.g. 0-5 stars if this is a review)"
      />
      <CSVColumnExplanation
        colName="nps"
        colTypes={['text']}
        required={false}
        description="0-10 value associated with the verbatim (e.g. 0-10 for NPS® or net promoter score)"
      />
      <CSVColumnExplanation
        colName="text"
        colTypes={['text']}
        required={true}
        description="verbatim text"
      />
      <CSVColumnExplanation
        colName="title"
        colTypes={['text']}
        required={false}
        description="title of the verbatim"
      />
      <CSVColumnExplanation
        colName="userName"
        colTypes={['text']}
        required={false}
        description="author of verbatim as username or full name"
      />
      <CSVColumnExplanation
        colName="date"
        colTypes={['text']}
        required={true}
        description="date when the verbatim was authored"
        validFormats={dateFormats}
      />
    </div>
  );
};
