import clsx from 'clsx';
import React, { type ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { Chevron } from 'src/PoseidonComponents/StrokeIcons';
import styles from './Breadcrumbs.module.scss';

interface BreadcrumbsProps extends React.HTMLProps<HTMLDivElement> {
  showStartingChevron?: boolean;
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ children, showStartingChevron }) => {
  return (
    <div className={styles.container}>
      {React.Children.map(children, (child, i) => {
        if (React.isValidElement(child)) {
          return (
            <>
              {(showStartingChevron || i > 0) && (
                <span className={styles.chevron}>
                  <Chevron />
                </span>
              )}
              {child}
            </>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export const BreadCrumb: React.FC<React.HTMLProps<HTMLDivElement>> = ({ children }) => {
  return <div className={styles.crumb}>{children}</div>;
};

interface BreadCrumbLinkProps extends LinkProps {
  children: ReactNode;
}

export const BreadCrumbLink: React.FC<BreadCrumbLinkProps> = ({ to, children }) => {
  return (
    <Link to={to} className={clsx(styles.crumb, styles.link)}>
      {children}
    </Link>
  );
};
