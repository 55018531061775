import { AspectType, trpc } from 'src/utils/trpc';
import { isNotNull } from 'src/utils2';

export const useUpdateOrder = () => {
  const utils = trpc.useContext();

  return trpc.reportCategories.updateOrder.useMutation({
    onMutate: ({ categoryIds, reportId }) => {
      utils.reportCategories.list.setData({ reportId }, (data) => {
        if (data == null) return undefined;

        const judgementArray = data.filter((item) => item.catType === 'judgement');
        const domainArray = data.filter((item) => item.catType === 'domain');

        const isDomain = categoryIds.includes(domainArray[0]?._id.toHexString() || '');

        if (isDomain) {
          const orderedData = categoryIds.map((id) =>
            domainArray.find((item) => item._id.toHexString() === id),
          );

          return [...orderedData, ...judgementArray].filter(isNotNull);
        } else {
          const orderedData = categoryIds.map((id) =>
            judgementArray.find((item) => item._id.toHexString() === id),
          );

          return [...domainArray, ...orderedData].filter(isNotNull);
        }
      });
    },
    onSuccess: (_, { reportId }) => {
      utils.report.conceptById.invalidate();
      utils.reportCategories.list.invalidate({ reportId });
    },
  });
};
