import clsx from 'clsx';
import React from 'react';
import { SvrReportReview } from '../../utils/trpc';
import { getDetailRangeColor, getScore, isNotNull } from '../../utils2';
import { HaterIcon, IndifferentIcon, LoverIcon, PassionateIcon } from '../icons/icons';
import styles from './sentimentQuadrant.module.scss';

export const sigmoid = (t: number) => 1 / (1 + Math.pow(Math.E, -t));

const quadrantAxisStyle: React.CSSProperties = {
  stroke: '#ECEFF1',
  strokeDasharray: '2, 2',
  strokeWidth: 1,
  fill: 'transparent',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  vectorEffect: 'non-scaling-stroke',
};

const axisStyle: React.CSSProperties = {
  stroke: 'rgb(217, 217, 217)',
  strokeWidth: 1,
  fill: 'transparent',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  vectorEffect: 'non-scaling-stroke',
};

const textStyle: React.CSSProperties = {
  fontSize: '12px',
  fontWeight: 500,
  fill: 'rgb(176, 176, 176)',
  fontFamily: 'Readex Pro',
  pointerEvents: 'none',
};

interface SentimentQuadrantProps {
  allReviews?: SvrReportReview[];
  hoverReview: (id?: string) => void;
  clickReview: (id?: string) => void;
  selected?: string;
}

export const SentimentQuadrant: React.FC<SentimentQuadrantProps> = React.memo(
  ({ allReviews, hoverReview, clickReview, selected }) => {
    const insetWidth = 500;
    const insetHeight = 350;
    const r = 10;
    const emojiSize = 16;
    const padding = 4;
    const inset = emojiSize + padding * 2;
    const outerWidth = insetWidth + inset * 2;
    const outerHeight = insetHeight + inset * 2;

    const filteredReviews =
      allReviews?.filter((r) => r.sentiment != null && r.magnitude != null) || [];

    const magAvg =
      filteredReviews.reduce((p, r) => {
        if (r.magnitude == null) {
          return p;
        }
        return p + r.magnitude;
      }, 0) / filteredReviews.length;

    const onMouseOver = (id: string) => {
      hoverReview(id);
    };
    const onMouseOut = () => {
      hoverReview(undefined);
    };
    const onClick = (id: string) => {
      clickReview(id);
    };

    return (
      <div className={styles.quadrantContainer}>
        <svg
          viewBox={`0 0 ${outerWidth} ${outerHeight}`}
          className={styles.quadrantChart}
          preserveAspectRatio="xMidYMin meet">
          <g transform={`translate(${inset},${inset})`}>
            <g
              transform={`translate(${(insetWidth - emojiSize) / 2},${
                -emojiSize - padding
              }) scale(${emojiSize / 100})`}>
              <PassionateIcon fill="#dcdcdc" />
            </g>

            <g
              transform={`translate(${insetWidth + padding}, ${
                (insetHeight - emojiSize) / 2
              }) scale(${emojiSize / 100})`}>
              <LoverIcon fill="#dcdcdc" />
            </g>

            <g
              transform={`translate(${(insetWidth - emojiSize) / 2}, ${
                insetHeight + padding
              }) scale(${emojiSize / 100})`}>
              <IndifferentIcon fill="#dcdcdc" />
            </g>
            <g
              transform={`translate(${-emojiSize - padding}, ${
                (insetHeight - emojiSize) / 2
              }) scale(${emojiSize / 100})`}>
              <HaterIcon fill="#dcdcdc" />
            </g>

            <line
              x1={insetWidth / 2}
              x2={insetWidth / 2}
              y1={0}
              y2={insetHeight}
              style={axisStyle}
            />
            <line
              x1={0}
              x2={insetWidth}
              y1={insetHeight / 2}
              y2={insetHeight / 2}
              style={axisStyle}
            />

            {[0.1, 0.2, 0.3, 0.4, 0.6, 0.7, 0.8, 0.9].map((t) => (
              <React.Fragment key={t}>
                <line
                  x1={0}
                  x2={insetWidth}
                  y1={insetHeight * t}
                  y2={insetHeight * t}
                  style={quadrantAxisStyle}
                />
                <line
                  x1={insetWidth * t}
                  x2={insetWidth * t}
                  y1={0}
                  y2={insetHeight}
                  style={quadrantAxisStyle}
                />
              </React.Fragment>
            ))}

            <path
              d={`M${r} 0 h${insetWidth / 2 - r} v${insetHeight / 2} h${-insetWidth / 2} v${
                -insetHeight / 2 + r
              } a${r},${r} 0 0 1 ${r},${-r} Z`}
              style={{ fill: '#c469d6', opacity: 0.08 }}
            />
            <path
              d={`M${insetWidth / 2} 0 h${insetWidth / 2 - r} a${r},${r} 0 0 1 ${r},${r} v${
                insetHeight / 2 - r
              } h${-insetWidth / 2} v${-insetHeight / 2} Z`}
              style={{ fill: '#e1cbe6', opacity: 0.08 }}
            />
            <path
              d={`M${insetWidth / 2} ${insetHeight / 2} h${insetWidth / 2} v${
                insetHeight / 2 - r
              } a${r},${r} 0 0 1 ${-r},${r} h${-insetWidth / 2 + r} v${-insetHeight / 2} Z`}
              style={{ fill: '#68358a', opacity: 0.08 }}
            />
            <path
              d={`M${insetWidth / 2} ${insetHeight / 2} v${insetHeight / 2} h${
                -insetWidth / 2 + r
              } a${r},${r} 0 0 1 ${-r},${-r} v${-insetHeight / 2 + r} h${insetWidth / 2}  Z`}
              style={{ fill: '#c59ad4', opacity: 0.08 }}
            />
            <rect
              x={0}
              y={0}
              width={insetWidth}
              height={insetHeight}
              rx={r}
              style={quadrantAxisStyle}
            />

            {allReviews
              ?.slice(0, 4000)
              .map((r) => {
                if (r.sentiment == null) return null;
                if (r.magnitude == null) return null;

                const magnitudeSigmoid = sigmoid(r.magnitude - magAvg) * 2 - 1;
                const cx = ((r.sentiment + 1) * insetWidth) / 2;
                const cy = ((-magnitudeSigmoid + 1) * insetHeight) / 2;
                const fill = getDetailRangeColor(getScore(r.sentiment));
                const style = { fill };

                const className = clsx(
                  styles.point,
                  selected === r._id.toHexString() ? styles.selected : null,
                );
                return (
                  <circle
                    key={r._id.toHexString()}
                    cx={cx}
                    cy={cy}
                    r={2.5}
                    style={style}
                    className={className}
                    onMouseOver={() => onMouseOver(r._id.toHexString())}
                    onMouseOut={onMouseOut}
                    onClick={() => onClick(r._id.toHexString())}
                  />
                );
              })
              .filter(isNotNull)}

            <text
              x={insetWidth * 0.25}
              y={insetHeight / 4}
              textAnchor="middle"
              dominantBaseline="middle"
              style={textStyle}>
              <tspan>PASSIONATE</tspan>
              <tspan dy="16" x={insetWidth * 0.25}>
                DETRACTORS
              </tspan>
            </text>
            <text
              x={insetWidth * 0.75}
              y={insetHeight / 4}
              textAnchor="middle"
              dominantBaseline="middle"
              style={textStyle}>
              <tspan>PASSIONATE</tspan>
              <tspan dy="16" x={insetWidth * 0.75}>
                PROMOTERS
              </tspan>
            </text>
            <text
              x={insetWidth * 0.75}
              y={insetHeight * 0.75}
              textAnchor="middle"
              dominantBaseline="middle"
              style={textStyle}>
              <tspan>INDIFFERENT</tspan>
              <tspan dy="16" x={insetWidth * 0.75}>
                PROMOTERS
              </tspan>
            </text>
            <text
              x={insetWidth * 0.25}
              y={insetHeight * 0.75}
              textAnchor="middle"
              dominantBaseline="middle"
              style={textStyle}>
              <tspan>INDIFFERENT</tspan>
              <tspan dy="16" x={insetWidth * 0.25}>
                DETRACTORS
              </tspan>
            </text>
          </g>
        </svg>
      </div>
    );
  },
);

export default SentimentQuadrant;
