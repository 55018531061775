import _ from 'lodash';
import queryString from 'query-string';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import superjson from 'superjson';
import z from 'zod';
import { ConceptTypeValues } from '../utils/trpc';
import { isNotNull } from '../utils2';

function strToInt(str: string, ctx: z.RefinementCtx) {
  try {
    const num = parseInt(str, 10);
    return num;
  } catch (e) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'not an integer text',
    });

    // This is a special symbol you can use to
    // return early from the transform function.
    // It has type `never` so it does not affect the
    // inferred return type.
    return z.NEVER;
  }
}

function strToBool(str: string | null | undefined, ctx: z.RefinementCtx) {
  return str !== undefined;
}

export type MachineParam =
  | { rid: string; start: number; end: number; id?: string }
  | { rid: string; start?: number; end?: number; id?: string };
function strToMachineParam(str: string, ctx: z.RefinementCtx) {
  const splitKey = str.split('_');
  const [rId, start, end, id] = splitKey;

  if (rId == null) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'not valid machine parameter',
    });
    return z.NEVER;
  }

  if (start == null && end == null) {
    const p: MachineParam = { rid: rId };
    return p;
  }

  const startInt = start != null ? parseInt(start, 10) : undefined;
  const endInt = end != null ? parseInt(end, 10) : undefined;

  if (startInt != null && !Number.isNaN(startInt) && endInt != null && !Number.isNaN(endInt)) {
    const p: MachineParam = { rid: rId, start: startInt, end: endInt, id };
    return p;
  }

  ctx.addIssue({
    code: z.ZodIssueCode.custom,
    message: 'not valid machine parameter',
  });
  return z.NEVER;
}

export const RatingValues = [
  '0',
  '0.5',
  '1',
  '1.5',
  '2',
  '2.5',
  '3',
  '3.5',
  '4',
  '4.5',
  '5',
] as const;
export type Rating = (typeof RatingValues)[number];
export const StartRatingValues: Rating[] = ['1', '2', '3', '4', '5'];

const SentimentRatingValues = ['1', '2', '3', '4', '5'] as const;
export type SentimentRating = (typeof SentimentRatingValues)[number];

const DraftStepValues = ['brands', 'concepts'] as const;
export type DraftStep = (typeof DraftStepValues)[number];

const SectionValues = ['versionHistory', 'sentences'] as const;

const ConceptAspectValues = ['1', '-1'] as const;
export type ConceptAspect = (typeof ConceptAspectValues)[number];

const SortValues = ['asc', 'desc'] as const;
export type SortValues = typeof SortValues;

export const CatTypeValues = ['judgement', 'domain'] as const;

const strToIntOrUndefined = (str: string) => {
  try {
    const num = parseInt(str, 10);
    return !isNaN(num) ? num : undefined;
  } catch (e) {
    return undefined;
  }
};

const strToBoolOrUndefined = (str?: string) => {
  if (str == null) return undefined;
  return Boolean(str);
};

const searchParamsSchema = z.object({
  rId: z.string().optional(),
  category: z.string().optional(),
  conceptAspect: z.enum(ConceptAspectValues).optional(),
  rating: z.enum(RatingValues).optional(),
  sRating: z.enum(SentimentRatingValues).optional(),
  machine: z.string().transform(strToMachineParam).optional(),
  machineRange: z.string().transform(strToMachineParam).optional(),
  page: z.string().transform(strToInt).optional(),
  pinnedConcept: z.string().optional(),
  addConcept: z.enum(ConceptTypeValues).optional(),
  hideDetail: z.string().nullish().transform(strToBool).optional(),
  section: z.enum(SectionValues).optional(),
  catType: z.enum(CatTypeValues).optional(),
  // user: z.string().optional(),
  step: z.string().optional(),
  reviewCommentId: z.string().optional(),
  responseId: z.string().optional(),
  extras: z.map(z.string(), z.set(z.string())).optional(),
  sortOrder: z.enum(SortValues).optional(),
  sort: z.string().optional(),
  q: z.string().optional(),
  limit: z.string().transform(strToIntOrUndefined).optional(),
  filterInsights: z.string().optional(),
  topicId: z.string().optional(),
  polarSentimentInsights: z.string().optional(),
  imgUrl: z.string().optional(),
});

export type SearchParams = z.infer<typeof searchParamsSchema>;
export type SetSearchParams = SearchParams | ((old: SearchParams) => SearchParams);
export type SetSearchFn = (dataOrFn: SetSearchParams) => void;

export const useRouterQuery = (): [SearchParams, SetSearchFn] => {
  const location = useLocation();
  const navigate = useNavigate();
  const locationPathname = location?.pathname;
  const locationSearch = location?.search;

  const query = React.useMemo(() => {
    if (locationSearch) {
      const parsed = queryString.parse(locationSearch);

      // if parsed has extras, unstringify it
      const withHandledExtras =
        parsed.extras != null && _.isString(parsed.extras)
          ? { ...parsed, extras: superjson.parse(parsed.extras) }
          : parsed;

      const validated = searchParamsSchema.safeParse(withHandledExtras);
      if (validated.success == false) {
        console.error('failed to parse location string', validated.error);
        return {};
      }

      return validated.data;
    } else return {};
  }, [locationSearch]);

  const setQuery = React.useCallback(
    (dataOrFn: SetSearchParams) => {
      if (navigate == null) return;

      const data = typeof dataOrFn === 'function' ? dataOrFn(query) : dataOrFn;
      const dataAdapted: any = data;
      if (data.extras) {
        dataAdapted.extras = superjson.stringify(data.extras);
      }
      if (data.machine) {
        dataAdapted.machine = [
          data.machine.rid,
          data.machine.start,
          data.machine.end,
          data.machine.id,
        ]
          .filter(isNotNull)
          .join('_');
      }

      const newSearch = queryString.stringify(dataAdapted);
      if (newSearch) navigate(`${locationPathname}?${newSearch}`);
      else navigate(locationPathname);
    },
    [locationPathname, query, navigate],
  );

  return [query, setQuery];
};

export default useRouterQuery;
