import React, { CSSProperties, useState } from 'react';
import clsx from 'clsx';
import { createPortal } from 'react-dom';
import usePortal from '../usePortal';
import { useKeyOld } from '../../hooks/useKey';
import styles from './popup.module.scss';

interface PopupPortalProps extends React.HTMLProps<HTMLDivElement> {}

export const PopupPortal: React.FC<PopupPortalProps> = ({ id, children }) => {
  const target = usePortal(id || 'modal-portal');
  if (target == null) return null;
  else return createPortal(children, target);
};

interface PopupViewProps extends React.HTMLProps<HTMLDivElement> {
  popupClassName?: string;
  onClose: any;
}
export const DeletePopupView: React.FC<PopupViewProps> = ({
  children,
  popupClassName,
  onClose,
  onSubmit,
}) => {
  const [disabled, setDisabled] = useState(true);
  const ref = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    if (ref.current) ref.current?.focus();
  }, []);

  const changeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled && e.target.value === 'delete') setDisabled(false);
    else if (!disabled) setDisabled(true);
  };
  const _onSubmit = (e: any) => {
    if (e) e.preventDefault();
    if (!disabled) onSubmit?.(e);
  };

  return (
    <PopupPortal>
      <div className={clsx(styles.container, popupClassName)}>
        <div className={styles.background} onClick={onClose} />
        <form className={styles.content} onSubmit={_onSubmit}>
          <div className={styles.header}>Warning: deleting can't be undone!</div>
          {children}
          <div className={styles.text}>Please type the word 'delete' to confirm delete</div>
          <input
            placeholder="type the word 'delete'"
            className={styles.input}
            onChange={changeInput}
            ref={ref}
          />
          <button className={clsx(styles.deleteButton, disabled ? styles.disabled : null)}>
            I understand what I'm doing
          </button>
        </form>
      </div>
    </PopupPortal>
  );
};

export const AddIconPopupView: React.FC<PopupViewProps> = ({
  children,
  popupClassName,
  onClose,
  onSubmit,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [value, setValue] = useState('');
  const ref = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    if (ref.current) ref.current.focus();
  }, []);

  const changeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled && e.target.value.length > 0) setDisabled(false);
    else if (!disabled && e.target.value.length === 0) setDisabled(true);

    setValue(e.target.value);
  };
  const _onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault();
    const _value: any = value;
    if (!disabled) onSubmit?.(_value);
  };

  return (
    <PopupPortal>
      <div className={clsx(styles.container, popupClassName)}>
        <div className={styles.background} onClick={onClose} />
        <form className={styles.content} onSubmit={_onSubmit}>
          <div className={styles.header}>Add an icon</div>
          {children}
          <input
            placeholder="image url"
            className={styles.input}
            onChange={changeInput}
            value={value}
            ref={ref}
          />
          <button className={clsx(styles.deleteButton, disabled ? styles.disabled : null)}>
            Add
          </button>
        </form>
      </div>
    </PopupPortal>
  );
};

export const Popup: React.FC<PopupViewProps> = ({ children, popupClassName, onClose }) => {
  React.useEffect(() => {
    document.body.classList.add('popupShown');
    return () => {
      document.body.classList.remove('popupShown');
    };
  }, []);
  useKeyOld(['Escape'], () => onClose());
  if (children == null) return null;
  return (
    <PopupPortal>
      <div className={clsx(styles.container, popupClassName)}>
        <div className={styles.background} onClick={onClose} />
        {children}
      </div>
    </PopupPortal>
  );
};

interface CustomPopupViewProps extends React.HTMLProps<HTMLDivElement> {
  tagName?: any;
  content?: any;
  onClose?: any;
  popupClassName?: string;
}

export const DeletePopup: React.FC<CustomPopupViewProps> = ({
  tagName,
  content,
  onClose,
  onSubmit,
  popupClassName,
  ...props
}) => {
  const Tag = tagName || 'div';
  useKeyOld(['Escape'], () => onClose());

  return (
    <>
      <Tag {...props} />
      <DeletePopupView popupClassName={popupClassName} onClose={onClose} onSubmit={onSubmit} />
    </>
  );
};

export const AddIconPopup: React.FC<CustomPopupViewProps> = ({
  tagName,
  content,
  onClose,
  onSubmit,
  popupClassName,
  ...props
}) => {
  const Tag = tagName || 'div';
  useKeyOld(['Escape'], () => onClose());

  return (
    <>
      <Tag {...props} />
      <AddIconPopupView popupClassName={popupClassName} onClose={onClose} onSubmit={onSubmit} />
    </>
  );
};

export default Popup;

interface Props extends React.HTMLProps<HTMLDivElement> {
  popupClassName?: string;
  popupContentClassName?: string;
  icon?: React.ReactNode;
  onSubmit?: React.FormEventHandler;
  style?: CSSProperties;
  onClose: React.EventHandler<React.MouseEvent | React.KeyboardEvent>;
}

export const Popup2: React.FC<Props> = ({
  popupClassName,
  popupContentClassName,
  onClose,
  icon,
  onSubmit,
  children,
  style,
  className,
  ...props
}) => {
  React.useEffect(() => {
    document.body.classList.add('popupShown');
    return () => {
      document.body.classList.remove('popupShown');
    };
  }, []);
  useKeyOld(['Escape'], onClose);

  const Tag: any = onSubmit != null ? 'form' : 'div';

  return (
    <PopupPortal>
      <div className={clsx(styles.container, popupClassName)} style={style}>
        <div className={styles.background} onClick={onClose} />
        <Tag className={clsx(styles.content, className)} {...props} onSubmit={onSubmit}>
          {icon != null && <div className={styles.circle}>{icon}</div>}
          <div className={clsx(styles.body, popupContentClassName)}>{children}</div>
        </Tag>
      </div>
    </PopupPortal>
  );
};
