import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import styles from './DetailPage.module.scss';

export const DetailPage: React.FC<React.HTMLProps<HTMLDivElement>> = ({ children }) => {
  return (
    <AnimatePresence>
      <motion.div
        className={clsx(styles.container )}
        initial={{ opacity: 0.2, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ type: 'spring' }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};
