import { useLocalStorage } from '@rehooks/local-storage';
import React from 'react';
import { Gear, Logout, Warning } from 'src/PoseidonComponents/StrokeIcons';
import { BreadCrumb, Breadcrumbs } from '../../PoseidonComponents/Breadcrumbs/Breadcrumbs';
import { Button, LinkButton } from '../../PoseidonComponents/Button/Button';
import { ContentDivider } from '../../PoseidonComponents/ContentDivider/ContentDivider';
import { ContentSection } from '../../PoseidonComponents/ContentSection/ContentSection';
import { FullHeight } from '../../PoseidonComponents/FullHeight/FullHeight';
import { Gap } from '../../PoseidonComponents/Gap';
import { Left, WithLeftNav } from '../../PoseidonComponents/Layout/Layout';
import { PaddedScrollable } from '../../PoseidonComponents/PaddedScrollable/PaddedScrollable';
import { PageHeader } from '../../PoseidonComponents/PageHeader/PageHeader';
import { TeamsTable } from '../../PoseidonComponents/TeamsTable/TeamsTable';
import { UserAndTeam } from '../../Route';
import { ReportsLeftNav } from '../../components/LeftNav/LeftNav';
import { useMeUpdateMutation } from '../../trpcHooks/useUserMutation';
import { trpc } from '../../utils/trpc';

const TeamsContainer: React.FC<UserAndTeam> = ({ user, team }) => {
  const [navMinimized] = useLocalStorage('navMinimized', false);
  const teamsQuery = trpc.team.all.useQuery();
  const meUpdateMutation = useMeUpdateMutation();

  const style = {
    '--nav-width': !navMinimized ? 'var(--nav-opened-width)' : 'var(--nav-closed-width)',
  } as React.CSSProperties;

  return (
    <FullHeight style={style}>
      <ReportsLeftNav user={user} team={team} />
      <WithLeftNav hasDetail={false}>
        <PageHeader icon={<Gear />} title="Settings">
          <ContentDivider
            end={
              <LinkButton size="small" to="/teams/create">
                Create team
              </LinkButton>
            }>
            <Breadcrumbs showStartingChevron>
              <BreadCrumb>Teams</BreadCrumb>
            </Breadcrumbs>
          </ContentDivider>
        </PageHeader>
        <PaddedScrollable>
          <ContentSection>
            <TeamsTable
              teams={teamsQuery.data}
              user={user}
              team={team}
              userById={undefined}
              isLoading={teamsQuery.isLoading}
            />
          </ContentSection>
          <ContentSection>
            <ContentDivider icon={<Warning />}>Dangerous actions</ContentDivider>
            <Left>
              <LinkButton icon={<Logout />} to="/logout" type="dangerous">
                Log out
              </LinkButton>
              <Gap height={8} />
              {user.isSuperAdmin && (
                <Button
                  onClick={() => {
                    meUpdateMutation.mutate({
                      data: { showSuperAdminData: !user.showSuperAdminData },
                    });
                  }}
                  disabled={meUpdateMutation.isLoading}>
                  {user.showSuperAdminData && 'Hide all superadmin data'}
                  {!user.showSuperAdminData && 'Show all superadmin data'}
                </Button>
              )}
            </Left>
          </ContentSection>
        </PaddedScrollable>
      </WithLeftNav>
    </FullHeight>
  );
};

export default TeamsContainer;
