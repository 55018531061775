import { createTRPCReact, createTRPCProxyClient } from '@trpc/react-query';
import type {
  AppProvider as AppProviderServer,
  AppRouter,
  AspectType as AspectTypeServer,
  ConceptType as ConceptTypeServer,
  RouterInput as RouterInputServer,
  RouterOutput as RouterOutputServer,
} from '../../../harmonize-server/src/_trpc/app';
export const trpc = createTRPCReact<AppRouter>();

export type RouterOutput = RouterOutputServer;
export type RouterInput = RouterInputServer;

export type AspectType = AspectTypeServer;
export type AppProvider = AppProviderServer;
export type ConceptType = ConceptTypeServer;

//new
export type SvrUser = RouterOutput['user']['me'];
export type SvrReport = RouterOutput['report']['id'];
export type SvrTeam = RouterOutput['team']['id'];
export type SvrReportBrand = NonNullable<SvrReport['brands']>[number];
export type SvrReportApp = NonNullable<SvrReport['apps']>[number];
export type SvrLibraryConcept = RouterOutput['concepts']['all'][number];

export type ServerSearchApp = RouterOutput['providerSearch']['search'][number];
export type ServerSearchInput = RouterInput['providerSearch']['search'];
export type ServerBrand = NonNullable<RouterOutput['reportBrand']['id']>;

export type ReviewComments = RouterOutput['reviewComment']['all'][number];
export type ReviewComment = ReviewComments['comments'][number];

export type ServerAddApp = RouterInput['reportApp']['add']['data'];

export type SvrReportReview = RouterOutput['reportReviews']['forApp']['reviews'][number];
export type SvrReportReviewIdWithBrand = RouterOutput['reportReviews']['idWithBrand'];
export type SvrReportReviewClassOverride = NonNullable<
  RouterOutput['reportReviews']['id']['classOverrides']
>[number];
export type SvrReportReviewResponse = NonNullable<SvrReportReview['response']>;
export type SvrReportReviewResponseMeta = NonNullable<SvrReportReview['responseMeta']>;

export type ReportClassifyAddData = RouterInput['reportClassify']['add']['data'];

export type SvrReportReviewRange = NonNullable<SvrReportReview['ranges']>[number];
export type SvrReportReviewRangeCategory = NonNullable<
  SvrReportReview['ranges']
>[number]['cats'][number];

export type ServerDataCounts = RouterOutput['report']['dataCountsV2'];
export type ServerBrandDataCounts = ServerDataCounts['brands'][number];

export type ReportReviewCategory = NonNullable<SvrReportReview['categories']>[number];

export type ReportAppDataCounts = RouterOutput['reportApp']['dataCounts'];

export type SuggestedCluster = RouterOutput['suggestedClusters']['list'][number];
export type UpdateSuggestedCluster = RouterInput['suggestedClusters']['update']['newValues'];

export type ServerAddCollaborator = RouterInput['reportCollaborators']['add']['data'];

export type ReportCategoryV2 = RouterOutput['reportCategoriesV2']['list'][number];
export type UpdateReportCategoryV2 = RouterInput['reportCategoriesV2']['update']['newValues'];

export type ConceptGroupList = RouterOutput['conceptGroups']['list'];
export type ConceptGroup = RouterOutput['conceptGroups']['list']['conceptGroups'][number];
export type ConceptGroupCreateParams = RouterInput['conceptGroups']['create']['toCreate'];
export type ConceptGroupUpdateParams = RouterInput['conceptGroups']['update']['toUpdate'];

export type ReportClassifyByReviewId = RouterOutput['reportClassify']['byReviewId'];

export type ServerTeamUpdate = RouterInput['team']['id'];

export type ReportInsightSummaryReviewById =
  RouterOutput['reportInsightSummary']['getAllByReportId']['reviewById'];

export type ReportInsightSummaryReview = ReportInsightSummaryReviewById[string];

export type InsightSummariesgetAllByReportId =
  RouterOutput['reportInsightSummary']['getAllByReportId'];
export type InsightSummariesGet =
  RouterOutput['reportInsightSummary']['getAllByReportId']['insightSummaries'][number];

// old
export type ReportCategory = RouterOutput['reportCategories']['list'][number];
export type ReportCategoryCreateParams = RouterInput['reportCategories']['create']['toCreate'];
export type ReportCategoryUpdateParams = RouterInput['reportCategories']['update']['newVals'];

// old

export type ConceptCreateParams = RouterInput['concepts']['add']['newValues'];
export type ConceptUpdateParams = RouterInput['concepts']['update']['updateValues'];

export type CompactReportPhrasesForReport = RouterOutput['reportPhrases']['list'];
export type CompactReportPhrase = CompactReportPhrasesForReport[string];

export type ReviewExtraColsRes = RouterOutput['extraCols']['forApp'];
export type ReviewExtraColFromList = RouterOutput['extraCols']['forApp']['extraCols'][number];
export type ReviewExtraColSectionFromList =
  RouterOutput['extraCols']['forReport']['sections'][number];
export type ReviewExtraCol = RouterOutput['extraCols']['forSlug'];

export type SearchableExtraColsRes = RouterOutput['extraCols']['searchableForReport'];
export type SearchableExtraColModel =
  RouterOutput['extraCols']['searchableForReport']['searchableExtraCols'][number];
export type SearchableExtraColKey = RouterOutput['extraCols']['searchableColKeys'][number];

export function conceptTypeNameFromType(type: ConceptType) {
  switch (type) {
    case 'domain':
      return 'Domain';
    case 'judgement':
      return 'Valence';
    default:
      return 'Unknown';
  }
}

export const ConceptTypeValues = ['domain', 'judgement'] as const;
