import React from 'react';
import {useDebouncedCallback} from 'use-debounce';

export const useForm = (initialState, setValueCallback) => {
  
  const [values, setValues] = React.useState(initialState);
  const [errors, setErrors] = React.useState({});
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const debounced = useDebouncedCallback(v => setValueCallback(v), 600)
  
  const handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if(e.target.type === 'file') {
      value = e.target.files[0];
    }
    setValues(values => ({ ...values, [name]: value}))
    if(setValueCallback) setValueCallback({[name]: value})
  }
  const handleDebouncedChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if(e.target.type === 'file') {
      value = e.target.files[0];
    }
    setValues(values => ({ ...values, [name]: value}))
    if(setValueCallback) debounced.callback({[name]: value})
  }
  const registerSubmit = (handleSubmit) => (e) => {
    if(e) { e.preventDefault(); }

    if(handleSubmit) {
      setIsSubmitting(true);
      handleSubmit({values, setIsSubmitting, setErrors})
    }
  }

  const reset = () => {
    setValues(initialState);
    setErrors({});
    setIsSubmitting(false);
  }

  const hasErrors = errors && Object.keys(errors).length > 0;
  return { values, errors, hasErrors, isSubmitting, handleChange, handleDebouncedChange, setValues, registerSubmit, reset }
}

export default useForm