import React from 'react';
import Select, { SingleValue } from 'react-select';
import Icon from '../icon/icon';

import styles from './Select.module.scss';

const Option: React.FC<any> = ({ src, label }) => {
  return (
    <div className={styles.selectContainer}>
      <div>{src && <Icon className={styles.icon} src={src} />}</div>
      <div className={styles.selectLabel}>{label}</div>
    </div>
  );
};

export interface DataProps {
  value: string;
  src: string | undefined;
  label: string | undefined;
}

interface GroupOptions {
  label: string | undefined;
  options: DataProps[];
  value: string;
}

interface Props {
  options: any;
  onChange: (e: SingleValue<DataProps>) => void;
  value?: string;
}

const GoodSelect: React.FC<Props> = ({ options, value, onChange }) => {
  const [selectOption, setSelectOption] = React.useState(options[0]);

  React.useEffect(() => {
    let selectOptionQ = null;
    if (options[0].options) {
      const optionsQ = [...options.map((el: GroupOptions) => el.options)];
      selectOptionQ = optionsQ.flat().filter((el: DataProps) => el?.value === value)[0];
    } else {
      selectOptionQ = options.filter((option: DataProps) => option.value === value)[0];
    }

    setSelectOption(selectOptionQ);
  }, [value]);

  return (
    <Select<DataProps, false, GroupOptions>
      options={options}
      onChange={onChange}
      value={selectOption}
      defaultValue={options[0]}
      formatOptionLabel={Option}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          height: '100%',
          zIndex: '10',
          border: 'none',
          width: '18em',
          fontSize: '0.8em',
          boxShadow: 'none',
          color: 'black',
          borderTopLeftRadius: '0',
          borderBottomLeftRadius: '0',
          borderBottomRightRadius: '0',
          outline: 'none !important',
          cursor: 'pointer',
          ':hover': {
            background: '#9593c3',
            color: 'white',
            div: {
              color: 'white',
            },
          },
        }),
        indicatorSeparator: (baseStyles) => ({
          ...baseStyles,
          display: 'none',
        }),
        option: (baseStyle, { isSelected, isFocused }) => ({
          ...baseStyle,
          margin: '0',
          padding: '0',
          fontSize: '0.8em',

          ':hover': { background: '#9593c3', color: 'white' },
          backgroundColor: isSelected ? '#524F9F' : isFocused ? '#9593c3' : 'transparent',
        }),
      }}
    />
  );
};

export default GoodSelect;
