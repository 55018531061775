import React from 'react'

import styles from './RatingBar2.module.scss'

interface RatingBarProps {
  value?: number
}

export const RatingBar: React.FC<RatingBarProps> = ({value}) => {
  return <div className={styles.container}>
    <div className={styles.background} />
    <div className={styles.bar} style={{width: `${value || 0}%`}} />
  </div>
}

export default RatingBar