import clsx from 'clsx';
import React from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { Tooltip } from '../../libs/Tooltip';
import { PencilIcon, TrashIcon } from '../icons/icons';
import styles from './editableLabel.module.scss';

interface EditableLabelProps extends React.HTMLProps<HTMLDivElement> {
  onInstantChange?: (s: string) => void;
  deleteTooltip?: string;
  value?: string;

  onDelete?: () => void;
  onChangeValue?: (value: string) => void;
}

export const EditableLabel: React.FC<EditableLabelProps> = ({
  className,
  value,
  placeholder,
  onChangeValue,
  onInstantChange,
  onDelete,
  deleteTooltip,
}) => {
  const [editing, setEditing] = React.useState(false);
  const [inputValue, setInputValue] = React.useState(value);

  const debounced = useDebouncedCallback((value) => onChangeValue?.(value), 600);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debounced(e.target.value);
    setInputValue(e.target.value);
    if (onInstantChange) onInstantChange(e.target.value);
  };

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setEditing(false);
  };

  if (editing) {
    return (
      <form className={clsx(className, styles.container)} onSubmit={submitForm}>
        <input
          placeholder={placeholder}
          value={inputValue}
          onChange={onInputChange}
          onBlur={() => setEditing(false)}
          autoFocus
        />
      </form>
    );
  }

  return (
    <div className={clsx(className, styles.container)}>
      <div className={styles.text} onClick={() => setEditing(true)}>
        {value}
      </div>
      <div className={styles.pencil} onClick={() => setEditing(true)}>
        <PencilIcon />
      </div>

      {onDelete != null && (
        <div className={styles.trashIcon}>
          {deleteTooltip && (
            <Tooltip label={deleteTooltip}>
              <div onClick={onDelete}>
                <TrashIcon />
              </div>
            </Tooltip>
          )}
          {!deleteTooltip && <TrashIcon />}
        </div>
      )}
    </div>
  );
};

export default EditableLabel;
