import useLocalStorage from '@rehooks/local-storage';
import React, { MouseEvent } from 'react';
import styles from './datePicker.module.scss';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar } from '@hassanmojab/react-modern-calendar-datepicker';

import type { DayRange } from '@hassanmojab/react-modern-calendar-datepicker';
import { Button } from 'src/PoseidonComponents/Button/Button';
import { CalendarDate } from 'src/PoseidonComponents/StrokeIcons';
import moment from 'moment';

export const DatePicker: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const [date, setDateTypeValue] = useLocalStorage<DayRange>('dateTypeValueV2');
  const [selectedDayRange, setSelectedDayRange] = React.useState<DayRange>({
    from: date?.from || null,
    to: date?.to || null,
  });

  const changeDate = (d: DayRange) => {
    setSelectedDayRange(d);

    setDateTypeValue({
      from: d.from,
      to: d.to,
    });
  };

  const maxDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };

  const onChangeSource = () => {
    setIsOpen(!isOpen);
  };

  const calendarRef = React.useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent<HTMLDocument>) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 27) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside as unknown as EventListener);
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('mousedown', handleClickOutside as unknown as EventListener);
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside as unknown as EventListener);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  const formattedDateFrom = React.useMemo(() => {
    if (selectedDayRange.from) {
      return moment(
        `${selectedDayRange.from.year}-${selectedDayRange.from.month}-${selectedDayRange.from.day}`,
        'YYYY-MM-DD',
      ).format('l');
    }
    return undefined;
  }, [selectedDayRange.from]);
  const formattedDateTo = React.useMemo(() => {
    if (selectedDayRange.to) {
      return moment(
        `${selectedDayRange.to.year}-${selectedDayRange.to.month}-${selectedDayRange.to.day}`,
        'YYYY-MM-DD',
      ).format('l');
    }
    return undefined;
  }, [selectedDayRange.to]);

  return (
    <>
      <Button size="small" onClick={onChangeSource} icon={<CalendarDate />}>
        Date sort:
        {formattedDateFrom && formattedDateTo
          ? ` ${formattedDateFrom} - ${formattedDateTo}`
          : 'All'}
      </Button>
      {isOpen && (
        <div className={styles.calendarContainer} ref={calendarRef}>
          <Calendar
            value={selectedDayRange}
            onChange={(date: DayRange) => changeDate(date)}
            shouldHighlightWeekends
            colorPrimary="hsl(264, 43%, 28%)"
            colorPrimaryLight="hsl(264deg 49.45% 53.56%)"
            maximumDate={maxDate}
            renderFooter={() => (
              <div className={styles.footerContainer}>
                {selectedDayRange && (
                  <Button size="small" onClick={() => changeDate({ from: null, to: null })}>
                    Clear
                  </Button>
                )}
              </div>
            )}
          />
        </div>
      )}
    </>
  );
};

export default DatePicker;
