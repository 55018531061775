import {
  closestCorners,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DndContext,
} from '@dnd-kit/core';

import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import queryString from 'query-string';
import React from 'react';
import { Link } from 'react-router-dom';
import { useUpdateOrder } from 'src/trpcHooks/reportCategories/useUpdateOrder';
import useRouterQuery from '../../hooks/useRouterQuery';
import { ConceptType, conceptTypeNameFromType, ReportCategory } from '../../utils/trpc';
import { getRangeColor2, getScore } from '../../utils2';
import { Droppable } from '../KeyTakeaways/Droppable';
import styles from './conceptsSection.module.scss';
import { AspectButtons } from './Row';
import { RowDnd } from '../sentimentRatingSection/RowDnd/RowDnd';

interface ConceptsSectionProps extends React.HTMLProps<HTMLDivElement> {
  concepts: ReportCategory[];
  catType: ConceptType;
  onAddConceptClicked?: () => void;
  reportId: string;
  categories?: Record<string, { value: number; count: number }>;
}

const DOMAIN = 'domain';

export const ConceptsSection: React.FC<ConceptsSectionProps> = React.memo(
  ({ concepts, catType, reportId, onAddConceptClicked, categories }) => {
    const updateOrder = useUpdateOrder();
    const [query, setQuery] = useRouterQuery();
    const addConceptSearch = queryString.stringify({
      ...query,
      addConcept: catType,
      hideDetail: undefined,
    });

    const onToggleAspect = (aspect: number) => () => {
      setQuery((s: any) => ({
        ...s,
        conceptAspect: parseInt(s.conceptAspect) === aspect ? undefined : aspect,
      }));
    };
    const onToggle = (v: string) => {
      const isPinned = query.pinnedConcept === v;
      setQuery((s: any) => ({
        ...s,
        category: s.category === v ? undefined : v,
        page: undefined,
        conceptAspect: undefined,
        addConcept: undefined,
        pinnedConcept: isPinned && s.category !== v ? undefined : s.pinnedConcept,
      }));
    };

    const conceptName = conceptTypeNameFromType(catType);

    const onPin = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
      e.preventDefault();
      e.stopPropagation();
      setQuery((q) => ({
        ...q,
        category: q.category === id ? undefined : q.category,
        pinnedConcept: q.pinnedConcept === id ? undefined : id,
        addConcept: q.pinnedConcept === id ? q.addConcept : undefined,
      }));
    };

    const valenceActiveIndex = concepts.findIndex((c) => c._id.toHexString() === query.category);

    const indexValency = (i: number) => {
      if (valenceActiveIndex === -1) return i;

      return valenceActiveIndex < i ? i + 1 : i;
    };

    const mouseSensor = useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    });
    const touchSensor = useSensor(TouchSensor);
    const keyboardSensor = useSensor(KeyboardSensor);

    const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

    const onDragEnd = async (event: DragEndEvent) => {
      const activeId = event.active.id;
      const overId = event.over?.id;

      if (overId == null) return;
      if (overId == activeId) return;

      const massById = concepts?.map((el) => el._id.toHexString());

      const activeIndex = massById.findIndex((el) => el === activeId);
      const overIndex = massById.findIndex((el) => el === overId);

      const summaryIds = arrayMove(massById, activeIndex, overIndex);
      await updateOrder.mutateAsync({
        reportId,
        categoryIds: summaryIds,
      });
    };

    return (
      <DndContext sensors={sensors} collisionDetection={closestCorners} onDragEnd={onDragEnd}>
        <SortableContext
          items={concepts?.map((el) => el._id.toHexString())}
          strategy={verticalListSortingStrategy}>
          <Droppable className={styles.concepts} id={catType}>
            {concepts?.map((c, i) => {
              const catId = c._id.toHexString();
              const categoryCount = categories?.[catId];
              const rating = getScore(categoryCount?.value);
              const color = getRangeColor2(rating);
              return (
                <React.Fragment key={catId}>
                  <RowDnd
                    index={c.catType !== DOMAIN ? indexValency(i) : i}
                    name={c.name || ''}
                    color={color}
                    onClick={() => onToggle(catId)}
                    onPin={(e) => onPin(e, catId)}
                    count={categoryCount?.count ?? 0}
                    rating={rating}
                    id={catId}
                    isPinned={query.pinnedConcept == catId}
                    selected={query.category == catId}
                    tooltip={<div>Click to see {c.name} Reviews</div>}
                  />
                  {catId === query.category && c.catType !== DOMAIN && (
                    <AspectButtons
                      concept={c}
                      index={i}
                      conceptAspect={query.conceptAspect}
                      onToggleAspect={onToggleAspect}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </Droppable>
        </SortableContext>
        <Link
          className={styles.newRowName}
          to={`?${addConceptSearch}`}
          onClick={onAddConceptClicked}>
          + Add {conceptName} Concept
        </Link>
      </DndContext>
    );
  },
);

export default ConceptsSection;
