import React from 'react';
import { Link } from 'react-router-dom';
import useRouterQuery from 'src/hooks/useRouterQuery';
import { useReportLogEffect } from 'src/libs/react-amplitude';
import { getSortedBrands2 } from 'src/utils2';
import {
  ReportCategory,
  ReportCategoryV2,
  ServerDataCounts,
  SvrReportBrand,
  SvrReport,
} from '../utils/trpc';
import { Gap } from './Gap';
import { BrandLogo } from './IconWithPlaceholder/IconWithPlaceholder';
import { ScoreBox } from './ScoreBox/ScoreBox';
import { ScoreCircle } from './ScoreCircle/ScoreCircle';
import { Cell, SortableHeader, Table } from './Table/Table';

interface ReportBrandTableProps {
  isLoading?: boolean;
  reportCategories?: ReportCategory[];
  reportCategoriesV2?: ReportCategoryV2[];
  dataCounts?: ServerDataCounts;
  report: SvrReport;
}

export const ReportBrandTable: React.FC<ReportBrandTableProps> = ({
  isLoading,
  reportCategories,
  report,
  dataCounts,
}) => {
  const [query, setQuery] = useRouterQuery();
  useReportLogEffect({ eventType: 'viewTLDR', report }, [report]);

  const searchSort = query.sort;
  const sortOrder = query.sortOrder;
  const brands = report.brands;

  const sortedBrands = React.useMemo(() => {
    return getSortedBrands2(brands, dataCounts, searchSort, sortOrder);
  }, [searchSort, sortOrder, brands, dataCounts]);
  const getSortOrder = (k: string) => (searchSort === k ? sortOrder : undefined);
  const clickHeader = (sort: string) => {
    if (sort === searchSort && sortOrder === 'asc') {
      setQuery((o) => ({ ...o, sort: undefined, sortOrder: undefined }));
    } else if (sort === searchSort && sortOrder === 'desc') {
      setQuery((o) => ({ ...o, sort, sortOrder: 'asc' }));
    } else {
      setQuery((o) => ({ ...o, sort, sortOrder: 'desc' }));
    }
  };
  const numColumns = (reportCategories?.length || 0) + 2;
  return (
    <Table gridTemplateColumns={`max-content repeat(${numColumns}, max-content)`} bordered>
      <SortableHeader sortOrder={getSortOrder('name')} sticky name="name" onClickCell={clickHeader}>
        Brand Name
      </SortableHeader>
      <SortableHeader
        sortOrder={getSortOrder('score')}
        name="score"
        onClickCell={clickHeader}
        tooltip="filter by score">
        Score
      </SortableHeader>
      <SortableHeader
        sortOrder={getSortOrder('reviews')}
        name="reviews"
        onClickCell={clickHeader}
        tooltip="filter by # of reviews">
        # reviews
      </SortableHeader>

      {reportCategories?.map((c) => (
        <SortableHeader
          key={c._id.toHexString()}
          sortOrder={getSortOrder(c._id.toHexString())}
          name={c._id.toHexString()}
          onClickCell={clickHeader}
          tooltip={`filter by ${c.name}`}>
          {c.name || 'No name'}
        </SortableHeader>
      ))}

      {sortedBrands?.map((brand, index) => (
        <ReportBrandRow
          brand={brand}
          dataCounts={dataCounts}
          index={index}
          reportCategories={reportCategories}
          reportId={report._id.toHexString()}
          key={brand._id.toHexString()}
        />
      ))}
    </Table>
  );
};

interface ReportBrandRowProps extends React.HTMLProps<HTMLDivElement> {
  brand: SvrReportBrand;
  index: number;
  reportCategories?: ReportCategory[];
  dataCounts?: ServerDataCounts;
  reportId: string;
}
const ReportBrandRow: React.FC<ReportBrandRowProps> = ({
  brand,
  index,
  reportCategories,
  dataCounts,
  reportId,
}) => {
  const [query] = useRouterQuery();
  const brandId = brand._id.toString();
  const counts = dataCounts?.brandsByCategory._review?.[brandId];

  return (
    <>
      <Cell index={index} sticky active={query.sort == null || query.sort === 'name'}>
        <BrandLogo brand={brand} size={32} shape="circle" />
        <Gap width={4} />
        <Link to={`/reports/${reportId}/brands/${brand._id.toHexString()}`}>{brand.name}</Link>
      </Cell>
      <Cell index={index} align="center" active={query.sort === 'score'}>
        <ScoreBox score={counts?.categoryScore} reportCategories={reportCategories} />
      </Cell>
      <Cell index={index} align="center" active={query.sort === 'reviews'}>
        {counts?.counts?.processed}
      </Cell>
      {reportCategories?.map((concept) => {
        const counts =
          dataCounts?.brandsByCategory?.[concept._id?.toHexString()]?.[brand._id.toHexString()];
        return (
          <Cell
            index={index}
            align="center"
            active={query.sort === concept._id.toHexString()}
            key={concept._id.toHexString()}>
            <ScoreCircle concept={concept} counts={counts} />
          </Cell>
        );
      })}
    </>
  );
};
