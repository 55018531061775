import clsx from 'clsx';
import React, { forwardRef, useImperativeHandle } from 'react';
import type { FieldError } from 'react-hook-form';
import { ArrowUpCircle } from '../StrokeIcons';
import { SvgIconButton } from '../SvgIconButton/SvgIconButton';
import styles from './Input.module.scss';
import { InputTitle } from './Title';

interface TextareaProps extends Omit<React.HTMLProps<HTMLTextAreaElement>, 'title'> {
  error?: FieldError;
  title?: JSX.Element | string;
}
export const Textarea = forwardRef<HTMLTextAreaElement | null, TextareaProps>(
  ({ className, title, value, ...props }, ref) => {
    const inputRef = React.useRef<HTMLTextAreaElement | null>(null);
    useImperativeHandle(ref, () => {
      return inputRef.current as HTMLTextAreaElement;
    });
    useAutosizeTextArea(inputRef.current, value ? value.toString() : '');

    const refMount = React.useCallback((el: HTMLTextAreaElement | null) => {
      if (el) {
        el.style.height = '0px';
        const scrollHeight = el.scrollHeight;
        el.style.height = `${scrollHeight}px`;
      }
      inputRef.current = el;
    }, []);

    return (
      <div className={clsx(className, styles.variables, styles.container)}>
        <InputTitle>{title}</InputTitle>
        <label className={styles.label}>
          <textarea
            className={styles.textarea}
            rows={1}
            ref={refMount}
            {...props}
            value={value || ''}
          />
        </label>
      </div>
    );
  },
);

Textarea.displayName = 'Textarea';

const useAutosizeTextArea = (textAreaRef: HTMLTextAreaElement | null, value: string) => {
  React.useEffect(() => {
    if (textAreaRef) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.style.height = '0px';
      const scrollHeight = textAreaRef.scrollHeight;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      textAreaRef.style.height = `${scrollHeight}px`;
    }
  }, [textAreaRef, value]);
};

export default useAutosizeTextArea;


Textarea.displayName = 'Textarea';

export const TextareaWithSubmit = forwardRef<HTMLTextAreaElement | null, TextareaProps>(
  ({ className, title, value, ...props }, ref) => {
    const inputRef = React.useRef<HTMLTextAreaElement | null>(null);
    useImperativeHandle(ref, () => {
      return inputRef.current as HTMLTextAreaElement;
    });
    useAutosizeTextArea(inputRef.current, value ? value.toString() : '');

    const refMount = React.useCallback((el: HTMLTextAreaElement | null) => {
      if (el) {
        el.style.height = '0px';
        const scrollHeight = el.scrollHeight;
        el.style.height = `${scrollHeight}px`;
      }
      inputRef.current = el;
    }, []);

    return (
      <div className={clsx(className, styles.container, styles.variables, styles.withSubmit)}>
        <InputTitle>{title}</InputTitle>
        <label className={styles.label}>
          <textarea
            className={styles.textarea}
            rows={1}
            ref={refMount}
            {...props}
            value={value || ''}
          />
        </label>
        <SvgIconButton
          size="small"
          icon={<ArrowUpCircle />}
          tooltip="Submit"
          type="submit"
          className={styles.submit}
        />
      </div>
    );
  },
);

TextareaWithSubmit.displayName = 'TextareaWithSubmit';
