import { trpc } from '../utils/trpc';

export const useDeleteAppMutation = () => {
  const utils = trpc.useContext();

  return trpc.reportApp.delete.useMutation({
    onSuccess: (_, { reportId }) => {
      utils.report.all.invalidate();
      utils.report.id.invalidate(reportId);
      utils.conceptGroups.forReport.invalidate({ reportId });
    },
  });
};

export const useReportAppUpdateMutation = () => {
  const utils = trpc.useContext();

  return trpc.reportApp.update.useMutation({
    onSuccess: (_, { metaId, reportId }) => {
      utils.reportApp.id.invalidate({ metaId });
      utils.report.id.invalidate(reportId);
    },
  });
};

export const useAddAppMutation = () => {
  const utils = trpc.useContext();

  return trpc.reportApp.add.useMutation({
    onSuccess: (_, { reportId }) => {
      utils.report.all.invalidate();
      utils.report.id.invalidate(reportId);
      utils.conceptGroups.forReport.invalidate({ reportId });
    },
  });
};

export const useUpdateAppMutation = () => {
  const utils = trpc.useContext();

  return trpc.reportApp.update.useMutation({
    onSuccess: (_, { reportId }) => {
      utils.report.all.invalidate();
      utils.report.id.invalidate(reportId);
    },
  });
};
