import { ObjectId } from 'bson';
import { keyBy } from 'lodash';
import { trpc } from '../utils/trpc';

export const useAddReviews = () => {
  const utils = trpc.useContext();

  return trpc.reportInsightSummary.addReview.useMutation({
    onSuccess: () => {
      utils.reportInsightSummary.getAllByReportId.invalidate();
    },
  });
};

export const useCreate = () => {
  const utils = trpc.useContext();

  return trpc.reportInsightSummary.create.useMutation({
    onSuccess: () => {
      utils.reportInsightSummary.getAllByReportId.invalidate();
    },
  });
};

export const useDelete = () => {
  const utils = trpc.useContext();

  return trpc.reportInsightSummary.delete.useMutation({
    onSuccess: () => {
      utils.reportInsightSummary.getAllByReportId.invalidate();
    },
  });
};

export const useRemoveReview = () => {
  const utils = trpc.useContext();

  return trpc.reportInsightSummary.removeReview.useMutation({
    onSuccess: () => {
      utils.reportInsightSummary.getAllByReportId.invalidate();
    },
  });
};

export const useSortReviewIds = () => {
  const utils = trpc.useContext();

  return trpc.reportInsightSummary.sortReviewIds.useMutation({
    onMutate: ({ reviewIds, reportId, id }) => {
      utils.reportInsightSummary.getAllByReportId.setData({ reportId }, (data) => {
        const insightSummaries = data?.insightSummaries.map((summary) =>
          summary._id.equals(id)
            ? Object.assign({}, summary, { reviewIds: reviewIds.map((id) => new ObjectId(id)) })
            : summary,
        );
        return Object.assign({}, data, { insightSummaries });
      });
    },
    onError: () => {
      utils.reportInsightSummary.getAllByReportId.invalidate();
    },
    onSuccess: (_, input) => {
      utils.reportInsightSummary.getAllByReportId.invalidate({ reportId: input.id });
    },
  });
};

export const useSortSummaries = () => {
  const utils = trpc.useContext();

  return trpc.reportInsightSummary.sortSummaries.useMutation({
    onMutate: ({ reportId, summaryIds }) => {
      utils.reportInsightSummary.getAllByReportId.setData({ reportId }, (data) => {
        if (!data) return;

        const insightSummariesById = keyBy(data.insightSummaries, (el) => el._id.toHexString());
        const insightSummaries = summaryIds.map((summaryId) => insightSummariesById[summaryId]);

        return Object.assign({}, data, { insightSummaries });
      });
    },
    onError: () => {
      utils.reportInsightSummary.getAllByReportId.invalidate();
    },
    onSuccess: () => {
      utils.reportInsightSummary.getAllByReportId.invalidate();
    },
  });
};

export const useUpdate = () => {
  const utils = trpc.useContext();

  return trpc.reportInsightSummary.update.useMutation({
    onSuccess: (_, { reportId }) => {
      utils.reportInsightSummary.getAllByReportId.invalidate();
      utils.report.id.invalidate(reportId);
    },
  });
};

export const useSummarizeMutation = () => {
  const utils = trpc.useContext();

  return trpc.reportInsightSummary.summarizeSection.useMutation({
    onSuccess: () => {
      utils.reportInsightSummary.getAllByReportId.invalidate();
    },
  });
};
