import { useQueryClient } from '@tanstack/react-query';
import {
  RQUtils,
  conceptsRQKey,
  reportCategoriesRQKey,
  reportConceptByIdRQKey,
} from '../utils/reactQuery.utils';
import { SvrLibraryConcept, ReportCategory, trpc } from '../utils/trpc';

export const useReportCategoryUpdate = () => {
  const queryClient = useQueryClient();
  return trpc.reportCategories.update.useMutation({
    onSuccess: (updated, { reportId }) => {
      queryClient.setQueryData<ReportCategory[]>(reportCategoriesRQKey(reportId), (old) => {
        return RQUtils.updateArrQueryV2(old, updated, '_id');
      });
    },
  });
};

export const useReportCategoryDelete = () => {
  const queryClient = useQueryClient();
  return trpc.reportCategories.delete.useMutation({
    onSuccess: (deleted, { reportId }) => {
      queryClient.setQueryData<ReportCategory[]>(reportCategoriesRQKey(reportId), (old) => {
        return RQUtils.delArrQueryV2(old, deleted._id, '_id');
      });
    },
  });
};

export const useReportCategoryRemoveWord = () => {
  const queryClient = useQueryClient();

  return trpc.reportCategories.removeWord.useMutation({
    onSuccess: (updatedReportConcept, { reportId }) => {
      queryClient.setQueryData<ReportCategory[]>(reportCategoriesRQKey(reportId), (old) => {
        return RQUtils.updateArrQueryV2(old, updatedReportConcept, '_id');
      });
    },
  });
};
export const useReportCategoryAddWord = () => {
  const queryClient = useQueryClient();

  return trpc.reportCategories.addWords.useMutation({
    onSuccess: (updatedReportConcept, { reportId, aspect, categoryId, words }) => {
      queryClient.setQueryData<ReportCategory>(
        reportConceptByIdRQKey(reportId, categoryId),
        (old) => {
          if (!aspect) return old;
          return { ...updatedReportConcept, [aspect]: updatedReportConcept?.[aspect]?.words };
        },
      );
    },
  });
};

export const useReportSyncFromConcept = () => {
  const queryClient = useQueryClient();

  return trpc.reportCategoriesConcept.syncFromConcept.useMutation({
    onSuccess: (updatedReportCategory, { reportId }) => {
      queryClient.setQueryData<ReportCategory[]>(reportCategoriesRQKey(reportId), (old) => {
        return RQUtils.updateArrQueryV2(old, updatedReportCategory, '_id');
      });
    },
  });
};

export const useReportConceptCreateFromCategory = () => {
  const queryClient = useQueryClient();

  return trpc.concepts.createFromReportCategory.useMutation({
    onSuccess: (createdConcept) => {
      queryClient.setQueryData<SvrLibraryConcept[]>(conceptsRQKey(), (old) => {
        return RQUtils.updateArrQueryV2(old, createdConcept, '_id');
      });
    },
  });
};

export const useReportSyncFromCategory = () => {
  const queryClient = useQueryClient();

  return trpc.concepts.syncFromReportCategory.useMutation({
    onSuccess: (updateConcept) => {
      queryClient.setQueryData<SvrLibraryConcept[]>(conceptsRQKey(), (old) => {
        return RQUtils.updateArrQueryV2(old, updateConcept, '_id');
      });
    },
  });
};

export const useReportConceptCreateFromConcept = () => {
  const queryClient = useQueryClient();

  return trpc.reportCategoriesConcept.createFromConcept.useMutation({
    onSuccess: (newReportConcept, { reportId }) => {
      queryClient.setQueryData<ReportCategory[]>(reportCategoriesRQKey(reportId), (old) => {
        return RQUtils.updateArrQueryV2<ReportCategory>(old, newReportConcept, '_id');
      });
    },
  });
};

export const useReportConceptCreate = () => {
  const queryClient = useQueryClient();

  return trpc.reportCategories.create.useMutation({
    onSuccess: (newReportConcept, { reportId }) => {
      queryClient.setQueryData<ReportCategory[]>(reportCategoriesRQKey(reportId), (old) => {
        return RQUtils.updateArrQueryV2<ReportCategory>(old, newReportConcept, '_id');
      });
    },
  });
};

export const useConceptDeleteMutation = () => {
  const queryClient = useQueryClient();

  return trpc.concepts.delete.useMutation({
    onSuccess: (deletedConcept) => {
      queryClient.setQueryData<SvrLibraryConcept[]>(conceptsRQKey(), (old) => {
        // cluster element was updated
        return RQUtils.delArrQueryV2(old, deletedConcept._id, '_id');
      });
    },
  });
};
