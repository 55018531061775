import clsx from 'clsx';
import React from 'react';
import { CircleDelete, Plus } from '../../toneIcons';
import styles from './pills.module.scss';

interface TogglePillProps extends React.HTMLProps<HTMLButtonElement> {
  isOn: boolean;
  onText: string;
  onHoverText: string;
  offText: string;
  offHoverText: string;
}

export const TogglePill: React.FC<TogglePillProps> = ({
  isOn,
  onText,
  onHoverText,
  offText,
  offHoverText,
  onClick,
}) => {
  const [hovering, setHovering] = React.useState(false);

  //set the text
  const onMouseover = () => {
    setHovering(true);
  };
  //clear the text
  const onMouseout = () => {
    setHovering(false);
  };

  const text =
    isOn == true
      ? hovering == true
        ? onHoverText
        : onText
      : hovering == true
      ? offHoverText
      : offText;

  const classNames = clsx(
    styles.clickable,
    isOn == true ? styles.toggleContainerOn : styles.toggleContainerOff,
  );

  return (
    <button
      className={classNames}
      disabled={onClick == null}
      onClick={onClick}
      onMouseEnter={onMouseover.bind(this)}
      onMouseLeave={onMouseout.bind(this)}>
      <div className={styles.text}>{text}</div>
    </button>
  );
};

interface TxtDeletablePillProps extends React.HTMLProps<HTMLDivElement> {
  text?: string;
  onDelete?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  status?: 'loading' | 'success' | 'error' | 'idle';
}

export const DeletablePill: React.FC<TxtDeletablePillProps> = ({ text, onDelete, status }) => {
  if (text == null) return null;

  let icon = <CircleDelete />;
  if (status == 'loading') {
    icon = <span>...</span>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.text}>{text}</div>
      {onDelete && (
        <div onClick={onDelete} className={styles.delete}>
          {icon}
        </div>
      )}
    </div>
  );
};

interface TxtAddablePillProps extends React.HTMLProps<HTMLDivElement> {
  text?: string;
  onAdd?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  status?: 'loading' | 'success' | 'error' | 'idle';
}

export const AddablePill: React.FC<TxtAddablePillProps> = ({ text, onAdd, status }) => {
  if (text == null) return null;

  let icon = <Plus />;
  if (status == 'loading') {
    icon = <span>...</span>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.text}>{text}</div>
      {onAdd && (
        <div onClick={onAdd} className={styles.delete}>
          {icon}
        </div>
      )}
    </div>
  );
};

export const BlankPill: React.FC<TxtDeletablePillProps> = ({ text }) => {
  if (text == null) return null;
  return (
    <div className={styles.container}>
      <div className={styles.text}>{text}</div>
    </div>
  );
};
