import React from 'react';
import { Select } from 'src/PoseidonComponents/Input/Select';
import { Button } from '../../PoseidonComponents/Button/Button';
import { SubmitButton } from '../../PoseidonComponents/Button/CustomButton';
import { ContentDivider } from '../../PoseidonComponents/ContentDivider/ContentDivider';
import { ContentSection } from '../../PoseidonComponents/ContentSection/ContentSection';
import { Gap } from '../../PoseidonComponents/Gap';
import { Flex, Left } from '../../PoseidonComponents/Layout/Layout';
import * as Brands from '../../components/icons/brands';
import { useReportsContext } from '../../contexts';
import { HttpError } from '../../contexts/utils';
import { useDeleteIntegrationMutation } from '../../trpcHooks/useDeleteIntegrationMutation';
import { SvrReport } from '../../utils/trpc';
import { isNotNull } from '../../utils2';
import { AndroidIntegrationRow } from '../IntegrationRow/IntegrationRow';
import styles from './integration.module.scss';
import { Input } from 'src/PoseidonComponents/Input/Input';

interface AndroidIntegrationProps {
  report: SvrReport;
}

export const AndroidServiceAccountIntegration: React.FC<AndroidIntegrationProps> = ({ report }) => {
  const [, { addAndroidSvcAccountIntegration }] = useReportsContext();
  const [selectedAppId, setSelectedAppId] = React.useState<string>();
  const [selectedFile, setSelectedFile] = React.useState<string>();
  const [error, setError] = React.useState<string>();
  const deleteIntegrationMutation = useDeleteIntegrationMutation();
  const reportId = report._id.toHexString();

  const apps = report.apps?.filter((app) => app.appProvider === 'google');

  if (apps == null || apps.length === 0) {
    return (
      <ContentSection>
        <ContentDivider size="small" icon={<Brands.Android />}>
          Android
        </ContentDivider>
        <div>Please add Android apps to your report</div>
      </ContentSection>
    );
  }

  const selectedAppleApp = apps?.find((app) => app.metaId.toHexString() === selectedAppId);

  const submitAndroidSvcAccount = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (selectedAppId) {
      setError(undefined);
      try {
        await addAndroidSvcAccountIntegration(
          report._id.toHexString(),
          selectedAppId,
          selectedFile,
        );
        setSelectedAppId(undefined);
        setSelectedFile(undefined);
      } catch (error) {
        if (error instanceof HttpError) {
          setError(error.body.message);
        }
      }
    }
  };

  const onCancel = () => {
    setSelectedAppId(undefined);
    setSelectedFile(undefined);
  };

  const selectFile = (e: any) => {
    setError(undefined);
    setSelectedFile(e.target.files[0]);
  };

  const selectFilters = [
    { value: '', label: `(select Android app)` },
    ...apps.map((app) => {
      if (app.metaId == null || app.title == null) return undefined;
      return { value: app.metaId.toHexString(), label: app.title, icon: <img src={app.iconUrl} /> };
    }),
  ].filter(isNotNull);

  return (
    <ContentSection size="small">
      <ContentDivider size="small" icon={<Brands.Android />}>
        Android
      </ContentDivider>
      {apps.map((app) => {
        if (app.integration == null) return null;
        const metaId = app.metaId.toHexString();
        return (
          <AndroidIntegrationRow
            key={app.metaId.toHexString()}
            reportId={report._id.toHexString()}
            app={app}
            deleteIntegration={() => deleteIntegrationMutation.mutate({ reportId, metaId })}
          />
        );
      })}
      <Left>
        <Select
          placeholder="select Android app"
          value={selectedAppId ?? ''}
          options={selectFilters}
          onChange={(e) => setSelectedAppId(e.value)}
        />
      </Left>
      {selectedAppleApp != null && (
        <form onSubmit={submitAndroidSvcAccount}>
          <div>add a service account</div>
          <Input type="file" placeholder="Add a service account" onChange={selectFile} />
          <div className={styles.error}>{error}</div>
          <Gap height={6} />
          <Flex>
            <Button onClick={onCancel}>Cancel</Button>
            <SubmitButton>Submit</SubmitButton>
          </Flex>
        </form>
      )}
    </ContentSection>
  );
};
