import { keyBy } from 'lodash';
import React from 'react';
import { ContentDivider } from '../../../PoseidonComponents/ContentDivider/ContentDivider';
import { ContentSection } from '../../../PoseidonComponents/ContentSection/ContentSection';
import { WithLeftNav } from '../../../PoseidonComponents/Layout/Layout';
import { PaddedScrollable } from '../../../PoseidonComponents/PaddedScrollable/PaddedScrollable';
import { ReportUserAndTeam } from '../../../Route';
import ConceptV2Comp from '../../../components/conceptV2/conceptV2';
import { EditConceptV2Popup } from '../../../components/editConceptV2Popup/editConceptV2Popup';
import EditSuggestedClusterPopup from '../../../components/editSuggestedClusterPopup/editSuggestedClusterPopup';
import { SuggestedClusterComp } from '../../../components/suggestedCluster/suggestedCluster';
import { ReportCategoryV2, SuggestedCluster, trpc } from '../../../utils/trpc';

export const SuggestedConcepts: React.FC<ReportUserAndTeam> = ({ report }) => {
  const reportId = report._id.toHexString();

  const [editingConcept, setEditingConcept] = React.useState<ReportCategoryV2 | null>(null);
  const [editingCluster, setEditingCluster] = React.useState<SuggestedCluster | null>(null);

  const reportPhrasesByIdRes = trpc.reportPhrases.list.useQuery({ reportId });
  const suggestedClustersRes = trpc.suggestedClusters.list.useQuery({ reportId });

  const conceptsRes = trpc.reportCategoriesV2.list.useQuery({ reportId });
  const conceptsById = conceptsRes.data == null ? {} : keyBy(conceptsRes.data, (c) => c.id);

  const visibleClusters = suggestedClustersRes.data?.filter((c) => c.hidden == false);
  const hiddenClusters = suggestedClustersRes.data?.filter((c) => c.hidden == true);

  const closeEditCluster = (cluster: SuggestedCluster) => {
    setEditingCluster(null);
  };
  const closeEditConcept = (cluster: ReportCategoryV2) => {
    setEditingConcept(null);
  };

  return (
    <WithLeftNav hasDetail={false}>
      <PaddedScrollable>
        <ContentSection>
          <ContentDivider>ACTIVE</ContentDivider>
          {reportPhrasesByIdRes.data != null &&
            conceptsRes.data?.map((concept) => (
              <ConceptV2Comp
                key={concept.id}
                reportId={reportId}
                concept={concept}
                reportPhrasesById={reportPhrasesByIdRes.data}
                onEdit={setEditingConcept}
              />
            ))}
        </ContentSection>
        <ContentSection>
          <ContentDivider>SUGGESTED CLUSTERS</ContentDivider>
          {reportPhrasesByIdRes.data != null &&
            visibleClusters?.map((cluster) => (
              <SuggestedClusterComp
                key={cluster.id}
                reportId={reportId}
                suggestedCluster={cluster}
                reportPhrasesById={reportPhrasesByIdRes.data}
                conceptsById={conceptsById}
                onEdit={setEditingCluster}
              />
            ))}
        </ContentSection>
        <ContentSection>
          <ContentDivider>HIDDEN SUGGESTED CLUSTERS</ContentDivider>
          {reportPhrasesByIdRes.data != null &&
            hiddenClusters?.map((cluster) => (
              <SuggestedClusterComp
                key={cluster.id}
                reportId={reportId}
                suggestedCluster={cluster}
                reportPhrasesById={reportPhrasesByIdRes.data}
                conceptsById={conceptsById}
                onEdit={setEditingCluster}
              />
            ))}
        </ContentSection>
      </PaddedScrollable>

      {editingCluster != null && (
        <EditSuggestedClusterPopup
          reportId={reportId}
          suggestedCluster={editingCluster}
          reportPhrasesById={reportPhrasesByIdRes.data}
          onUpdate={closeEditCluster}
          onClose={closeEditCluster}
        />
      )}

      {editingConcept != null && (
        <EditConceptV2Popup
          reportId={reportId}
          concept={editingConcept}
          reportPhrasesById={reportPhrasesByIdRes.data}
          onClose={closeEditConcept}
        />
      )}
    </WithLeftNav>
  );
};

export default SuggestedConcepts;
