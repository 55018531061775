import clsx from 'clsx';
import React from 'react';
import styles from './review.module.scss';


interface ReviewPlaceholderProps {
  opacity?: number;
  noWrap?: boolean;
}

export const ReviewPlaceholder: React.FC<ReviewPlaceholderProps> = ({ opacity, noWrap }) => (
  <div className={clsx(styles.reviewWrap, noWrap ? styles.noReviewWrap : null)} style={{ opacity }}>
    <div className={clsx(styles.review, styles.placeholderReview)}>
      <div className={styles.header}>
        <div className={styles.title} />
        <div className={styles.spacer} />
        <div className={styles.headerRight}>
          <div className={styles.headerText} />
          <div className={styles.author} />
        </div>
      </div>
      <div className={styles.textContent} />
    </div>
  </div>
);
