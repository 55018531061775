import React, { type CSSProperties } from 'react';
import styles from './Grid.module.scss';

interface GridProps extends React.HTMLProps<HTMLDivElement> {
  gap: string | number;
  columns: number;
  columnMinWidth: number;
}

export const ColumnsGrid: React.FC<GridProps> = ({ children, gap, columns, columnMinWidth }) => {
  const style = {
    '--grid-layout-gap': `${gap}px`,
    '--grid-column-count': columns,
    '--grid-item--min-width': `${columnMinWidth}px`,
  } as CSSProperties;
  return (
    <div className={styles.container} style={style}>
      {children}
    </div>
  );
};


interface RatioGridProps extends React.HTMLProps<HTMLDivElement> {
  gap: string | number;
  ratios: number[]
}

export const RatioGrid: React.FC<RatioGridProps> = ({ children, gap, ratios }) => {
  const style = {
    '--grid-layout-gap': `${gap}px`,
    '--grid-template-columns': ratios.map(r => `${r}fr`).join(' '), 
  } as CSSProperties;
  return (
    <div className={styles.container2} style={style}>
      {children}
    </div>
  );
};
