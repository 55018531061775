import React, { CSSProperties } from 'react';
import ConceptSummaryTooltip from '../../components/conceptSummaryTooltip/conceptSummaryTooltip';
import { Tooltip } from '../../libs/Tooltip';
import { getBubbleColors } from '../../utils2';
import { BubbleData } from './conceptSummaryCard';
import styles from './conceptSummaryCard.module.scss';

interface TraceRowProps {
  data: BubbleData;
}

export const TraceRow: React.FC<TraceRowProps> = ({ data }) => {
  const barStyle = { '--color': getBubbleColors(50) } as CSSProperties;
  const value = data.value >= 1 ? data.value.toFixed(0) : data.value.toFixed(1);

  const posBarStyle = {
    '--color': getBubbleColors(100),
    '--width': `${data.posValue * 100}%`,
  } as CSSProperties;
  const negBarStyle = {
    '--color': getBubbleColors(0),
    '--width': `${data.negValue * 100}%`,
  } as CSSProperties;

  return (
    <>
      <div className={styles.chartTraceRow}>
        <Tooltip
          label={<ConceptSummaryTooltip data={data} />}
          background="#fff"
          autoPlace={false}
          boxShadow="0px 2px 4px rgba(0,0,0,0.2)">
          <span className={styles.legendValue}>{value}%</span>
        </Tooltip>
        <Tooltip
          label={<ConceptSummaryTooltip data={data} />}
          background="#fff"
          autoPlace={false}
          boxShadow="0px 2px 4px rgba(0,0,0,0.2)">
          <span className={styles.legendText}>{data.label}</span>
        </Tooltip>
      </div>

      <div className={styles.bar} style={barStyle}>
        <div className={styles.posBar} style={posBarStyle} />
        <div className={styles.negBar} style={negBarStyle} />
      </div>
    </>
  );
};
