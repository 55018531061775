import { trpc } from 'src/utils/trpc';

export const useGenerateDocxFile = () => {
  const utils = trpc.useContext();

  return trpc.reportInsightSummary.generateDocxFile.useMutation({
    onSuccess: (_, { reportId }: any) => {
      utils.report.invalidate(reportId);
    },
  });
};
