import { keyBy } from 'lodash';
import React from 'react';
import { BrandLogo } from '../../PoseidonComponents/IconWithPlaceholder/IconWithPlaceholder';
import { WaveSpinner } from '../../PoseidonComponents/WaveSpinner';
import GoodSelect from '../../components/Select/Select';
import Card from '../../components/card/card';
import { Tooltip } from '../../libs/Tooltip';
import { ReportCategory, ServerDataCounts, SvrReportBrand, SvrReport } from '../../utils/trpc';
import { getScore } from '../../utils2';
import { useSelectFilters } from '../loversHatersCard/loversHatersCard';
import styles from './heatmapCard.module.scss';

interface HeatmapCardProps {
  report: SvrReport;
  concepts?: ReportCategory[];
  filter?: string;
  handleChangeFilter: (e?: string) => void;
  filteredBrandIds?: string[];
  dataCounts?: ServerDataCounts;
}

export const HeatmapCard: React.FC<HeatmapCardProps> = ({
  report,
  dataCounts,
  concepts,
  filteredBrandIds,
  filter,
  handleChangeFilter,
}) => {
  if (concepts == null) return <LoadingCard />;
  if (dataCounts == null) return <LoadingCard />;

  const selectFiltes = useSelectFilters(report);

  let filters = undefined;
  if (selectFiltes?.length > 1) {
    filters = (
      <GoodSelect
        options={selectFiltes}
        value={filter}
        onChange={(e) => handleChangeFilter?.(e?.value)}
      />
    );
  }

  return (
    <Card
      auto
      full
      header="Sentiment Heatmap"
      description="Displays relative distribution of verbatim sentiment across consumer opinion"
      fileName={`${report?.name}_heatmap.png`}
      filters={filters}>
      <HeatmapChart
        report={report}
        concepts={concepts}
        filteredBrandIds={filteredBrandIds}
        dataCounts={dataCounts}
      />
    </Card>
  );
};

const LoadingCard = () => (
  <Card
    full
    header="Sentiment Heatmap"
    description="Displays relative distribution of verbatim sentiment across consumer opinion">
    <WaveSpinner />
  </Card>
);

export default HeatmapCard;

interface HeatmapChartProps {
  report: SvrReport;
  concepts?: ReportCategory[];
  filteredBrandIds?: string[];
  dataCounts?: ServerDataCounts;
}

const HeatmapChart: React.FC<HeatmapChartProps> = React.memo(
  ({ report, concepts, filteredBrandIds, dataCounts }) => {
    const brandById = React.useMemo(
      () => keyBy(report.brands, (brand) => brand._id.toString()),
      [report],
    );
    const reportBrandIds = report?.brands?.map((b) => b._id.toString());
    const brandsByCategory = dataCounts?.brandsByCategory;
    const filteredBrands = React.useMemo(() => {
      return reportBrandIds?.filter((brandId) => {
        if (filteredBrandIds != null && filteredBrandIds.indexOf(brandId) < 0) return false;
        else return true;
      });
    }, [reportBrandIds, filteredBrandIds]);

    return (
      <div className={styles.container}>
        {concepts?.map((concept) => {
          const brandsScores = brandsByCategory?.[concept._id.toHexString()];
          filteredBrands &&
            filteredBrands.sort((a, b) => {
              const aBrandScore = brandsScores?.[a];
              const bBrandScore = brandsScores?.[b];
              const aRankValue = aBrandScore?.ratingRank?.value;
              const bRankValue = bBrandScore?.ratingRank?.value;
              return bRankValue - aRankValue;
            });
          return (
            <React.Fragment key={concept._id.toHexString()}>
              <div className={styles.name}>{concept.name}</div>
              <div className={styles.row}>
                {filteredBrands?.map((brandId, i) => {
                  const brand = brandById?.[brandId];
                  const brandScore = brandsScores?.[brandId];
                  const score = brandScore ? getScore(brandScore.categoryScore) : undefined;
                  if (score == null || brand == null) return null;
                  const style = { left: `${score}%` };

                  return (
                    <React.Fragment key={brandId}>
                      <div className={styles.circle} style={style} />
                      <Tooltip label={<CircleTooltip score={score} brand={brand} />}>
                        <div style={style} className={styles.icon}>
                          <BrandLogo brand={brand} size={24} shape="circle" />
                        </div>
                      </Tooltip>
                    </React.Fragment>
                  );
                })}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    );
  },
);

interface CircleTooltipProps {
  brand: SvrReportBrand;
  score: number;
}

const CircleTooltip: React.FC<CircleTooltipProps> = ({ brand, score }) => {
  return (
    <div className={styles.tooltipRow}>
      <BrandLogo brand={brand} size={24} shape="circle" />
      <span>{brand.name}</span>
      <span>{score}%</span>
    </div>
  );
};
