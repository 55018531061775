import clsx from 'clsx';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  useAddWords,
  useReportCategoryRemoveWord,
} from 'src/trpcHooks/useReportCategoriesMutation';
import useRouterQuery from '../../hooks/useRouterQuery';
import {
  useReportCategoryDelete,
  useReportCategoryUpdate,
  useReportConceptCreateFromCategory,
  useReportSyncFromCategory,
  useReportSyncFromConcept,
} from '../../trpcHooks/useConceptMutation';
import {
  AspectType,
  ReportCategory,
  ReportCategoryUpdateParams,
  SvrUser,
  trpc,
} from '../../utils/trpc';
import { AddBadge, Badge } from '../Badge/Badge';
import { Button } from '../Button/Button';
import { CreateButton } from '../Button/CustomButton';
import { ContentDivider } from '../ContentDivider/ContentDivider';
import { ContentSection } from '../ContentSection/ContentSection';
import { DetailHeader } from '../DetailHeader/DetailHeader';
import { Form } from '../Form/Form';
import { Gap } from '../Gap';
import { Input } from '../Input/Input';
import { Flex, Right, Spacer } from '../Layout/Layout';
import { PaddedScrollable } from '../PaddedScrollable/PaddedScrollable';
import { TrashCan } from '../StrokeIcons';
import { FullPageWaveSpinnerContainer } from '../WaveSpinnerContainer/WaveSpinnerContainer';
import { Concept } from './Concept';
import styles from './ConceptDetailPage.module.scss';
import EditableLabel from 'src/components/editableLabel/editableLabel';
import { useUpdateConceptName } from 'src/trpcHooks/reportCategories/useUpdateConceptName';

interface ConceptDetailPageProps extends React.HTMLProps<HTMLDivElement> {
  id: string;
  reportId: string;
  user: SvrUser;
}

export const ConceptDetailPage: React.FC<ConceptDetailPageProps> = ({ id, reportId, user }) => {
  const conceptQuery = trpc.report.conceptById.useQuery({ reportId, id });
  const conceptUpdateMut = useUpdateConceptName();

  const onChangeLabel = async (value: string) => {
    await conceptUpdateMut.mutateAsync({
      reportId,
      categoryId: id,
      newVals: { name: value },
    });
  };

  if (!conceptQuery.data?.name) return null;

  return (
    <>
      <DetailHeader queryKey="category">
        <EditableLabel
          value={conceptQuery.data?.name}
          onChangeValue={onChangeLabel}
          deleteTooltip="remove this brand from report"
        />
      </DetailHeader>
      {conceptQuery.data && (
        <ConceptDetailPageWithData concept={conceptQuery.data} reportId={reportId} user={user} />
      )}
      {conceptQuery.isLoading && <FullPageWaveSpinnerContainer />}
    </>
  );
};

export const PinnedConceptDetailPage: React.FC<ConceptDetailPageProps> = ({
  id,
  reportId,
  user,
}) => {
  const conceptQuery = trpc.report.conceptById.useQuery({ reportId, id });

  return (
    <>
      <DetailHeader queryKey="pinnedConcept">{conceptQuery.data?.name}</DetailHeader>
      {conceptQuery.data && (
        <ConceptDetailPageWithData concept={conceptQuery.data} reportId={reportId} user={user} />
      )}
      {conceptQuery.isLoading && <FullPageWaveSpinnerContainer />}
    </>
  );
};

interface ConceptDetailPageWithDataProps {
  reportId: string;
  concept: ReportCategory;
  user: SvrUser;
}

interface FormData {
  words: string;
}

export const ConceptDetailPageWithData: React.FC<ConceptDetailPageWithDataProps> = ({
  concept,
  reportId,
  user,
}) => {
  const addWordsMutation = useAddWords();
  const syncFromLibraryConceptMut = useReportSyncFromConcept();
  const updateReportCategoryMut = useReportCategoryUpdate();
  const deleteReportCategoryMut = useReportCategoryDelete();
  const deleteWordFromReportConceptMut = useReportCategoryRemoveWord();
  const createConceptFromReportCategoryMut = useReportConceptCreateFromCategory();
  const syncFromReportCategoryMut = useReportSyncFromCategory();
  const [query, setQuery] = useRouterQuery();

  const { control, handleSubmit, getValues, watch, setValue } = useForm<FormData>({
    mode: 'all',
    defaultValues: {
      words: '',
    },
  });

  const wordsConcepts = React.useMemo(() => {
    const newWords = concept?.words?.filter((el) => el.includes(getValues('words')));

    if (!newWords?.length) {
      return concept?.words;
    }
    return newWords;
  }, [watch('words'), concept]);

  const createConceptFromReportCategory = () => {
    if (createConceptFromReportCategoryMut.isLoading) {
      return;
    }
    createConceptFromReportCategoryMut.mutate({
      reportId,
      reportCategoryId: concept._id.toHexString(),
    });
  };

  const deleteReportCategory = async () => {
    if (deleteReportCategoryMut.isLoading) {
      return;
    }
    await deleteReportCategoryMut.mutateAsync({ reportId, categoryId: concept._id.toHexString() });
    setQuery((s) => ({
      ...s,
      page: undefined,
      addConcept: undefined,
      category: undefined,
      pinnedConcept: undefined,
    }));
  };

  const toggleSearch = (q: string) => {
    setQuery((s) => ({
      ...s,
      q: query.q === q ? undefined : q,
      page: undefined,
    }));
  };

  const onDeleteTag = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    word: string,
    aspect: AspectType | null,
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (deleteWordFromReportConceptMut.isLoading) {
      return;
    }
    await deleteWordFromReportConceptMut.mutate({
      reportId,
      categoryId: concept._id.toHexString(),
      word,
      aspect,
    });
  };

  const onSubmit = async (data: FormData) => {
    const words = data.words.split(',').map((word) => word.trim());
    await addWordsMutation.mutate({
      reportId,
      aspect: null,
      words,
      categoryId: concept._id.toHexString(),
    });
    setValue('words', '');
  };

  return (
    <PaddedScrollable size="small" fullHeight>
      {concept?.catType === 'domain' && (
        <ContentSection>
          <Form width="restricted" onSubmit={handleSubmit(onSubmit)}>
            <ContentSection>
              <Controller
                control={control}
                name="words"
                render={({ field }) => (
                  <Input
                    title="Words"
                    placeholder="add words"
                    disabled={addWordsMutation.isLoading}
                    autoFocus
                    {...field}
                  />
                )}
              />
              <Right>
                <CreateButton isLoading={addWordsMutation.isLoading}>Update</CreateButton>
              </Right>
            </ContentSection>
          </Form>
          <ContentDivider>Concept related phrases</ContentDivider>
          <Flex>
            {wordsConcepts?.map((word, i) => (
              <Badge
                key={`${word + i}`}
                className={clsx(
                  wordsConcepts.length === 1 && watch('words') === wordsConcepts[0]
                    ? styles.wordSelect
                    : '',
                )}
                type={query && word === query.q ? 'primary' : undefined}
                onClick={() => toggleSearch(word)}
                onDelete={(e) => onDeleteTag(e, word, null)}>
                {word}
              </Badge>
            ))}

            {watch('words') &&
              !(wordsConcepts?.length === 1 && watch('words') === wordsConcepts[0]) && (
                <AddBadge onClick={() => onSubmit({ words: getValues('words') })}>
                  {getValues('words')}
                </AddBadge>
              )}
          </Flex>
        </ContentSection>
      )}
      {concept?.catType === 'judgement' && (
        <>
          <Concept
            reportId={reportId}
            concept={concept}
            aspect={'positive'}
            name={concept.positive?.name}
          />
          <Concept
            reportId={reportId}
            concept={concept}
            aspect={'negative'}
            name={concept.negative?.name}
          />
        </>
      )}
      <Spacer />
      <Gap height={64} />
      <ContentSection>
        <ContentDivider>Actions</ContentDivider>
        {/* {concept && (
            <label >
              <div>Use score in brand comparison</div>
              <input
                checked={concept.countInScore || false}
                type="checkbox"
                onChange={(e) => updateReportCategory({ countInScore: e.target.checked })}
              />
            </label>
          )} */}
        {concept?.blueprintId && user?.isSuperAdmin && (
          <>
            <Button onClick={() => createConceptFromReportCategory()}>Create into Library</Button>
            <Button
              onClick={() =>
                syncFromLibraryConceptMut.mutate({
                  reportId,
                  reportCategoryId: concept._id.toHexString(),
                })
              }
              disabled={syncFromLibraryConceptMut.isLoading}>
              Sync (append) phrases from library
            </Button>
            <Button
              onClick={() =>
                syncFromReportCategoryMut.mutate({
                  reportId,
                  reportCategoryId: concept._id.toHexString(),
                })
              }
              disabled={syncFromReportCategoryMut.isLoading}>
              Update library with new phrases
            </Button>
          </>
        )}
        <Button type="dangerous" onClick={() => deleteReportCategory()} icon={<TrashCan />}>
          Delete
        </Button>
      </ContentSection>
    </PaddedScrollable>
  );
};
