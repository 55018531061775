import { Button } from 'src/PoseidonComponents/Button/Button';
import { Chevron, XMark } from 'src/PoseidonComponents/StrokeIcons';
import styles from '../Images.module.scss';
import { useKey } from 'src/hooks/useKey';

interface Props {
  handleClickOutside: (event: React.MouseEvent<HTMLDivElement>) => void;
  closeLightbox: () => void;
  images: string[];
  selectedImageIndex: number;
  modalRef: any;
  goToPreviousImage: (event: React.MouseEvent<HTMLButtonElement>) => void;
  goToNextImage: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const LittleSliderImage: React.FC<Props> = ({
  handleClickOutside,
  closeLightbox,
  images,
  selectedImageIndex,
  modalRef,
  goToPreviousImage,
  goToNextImage,
}) => {
  useKey('Escape', () => {
    closeLightbox();
  });
  return (
    <div className={styles.modal} onClick={handleClickOutside}>
      <div className={styles.modalContent}>
        <img alt="selected image" src={images[selectedImageIndex]} ref={modalRef} />
        <Button className={styles.closeButton} icon={<XMark />} onClick={closeLightbox} />
        <Button
          icon={<Chevron className={styles.chevronLeft} />}
          className={styles.previousButton}
          onClick={(event) => goToPreviousImage(event)}
        />
        <Button
          icon={<Chevron />}
          className={styles.nextButton}
          onClick={(event) => goToNextImage(event)}
        />
      </div>
    </div>
  );
};
