import React from 'react'

import styles from './scoreTooltip.module.scss'

interface ScoreTooltipProps {
  conceptNames: string[]
}

export const ScoreTooltip: React.FC<ScoreTooltipProps> = ({conceptNames}) => {
  if(conceptNames == null || conceptNames.length === 0) return null;

  let concepts = "";
  if(conceptNames?.length > 1) {
    concepts = conceptNames.slice(0, conceptNames.length - 1).join(', ') + ', and ' + conceptNames[conceptNames.length - 1];
  } else {
    concepts = conceptNames?.join(', ');
  }
  return <div className={styles.container}>
    <div className={styles.header}>Review Score</div>
    <div className={styles.text}>The review score is taken by the average sentiment across the {concepts}.</div>
  </div>
}