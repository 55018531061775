import React from 'react';
import Highlighter from '../../libs/highlighter/highlighter';
import styles from './description.module.scss';

interface DescriptionProps {
  description?: string;
}

export const Description: React.FC<DescriptionProps> = React.memo(({ description }) => {
  if (description == null) return null;

  const highlights = [
    { word: new RegExp('\\$[0-9a-zA-Z\\.,\\/]+'), matchClass: styles.price },
    { word: new RegExp('USD ?[0-9a-zA-Z\\.,\\/]+'), matchClass: styles.price },
    { word: new RegExp('[0-9a-zA-Z\\.,\\/]+ ?USD'), matchClass: styles.price },
  ];

  const matchClass = (search: any) => search?.matchClass;
  const matchStyle = (search: any) => search?.matchStyle;
  const searchTransform = (search: any) => search.word;

  return (
    <div className={styles.description}>
      {description.split('\n').map((d, i) => (
        <p key={i}>
          <Highlighter
            search={highlights}
            matchClass={matchClass}
            matchStyle={matchStyle}
            searchTransform={searchTransform}>
            {d}
          </Highlighter>
        </p>
      ))}
    </div>
  );
});

export default Description;
