import React from 'react';
import styles from './ContentSection.module.scss';
import clsx from 'clsx';
import { InputSize } from '../Input/types';

interface ContentSectionProps extends Omit<React.HTMLProps<HTMLDivElement>, 'size'> {
  size?: InputSize;
}


export const ContentSection = React.forwardRef<HTMLDivElement, ContentSectionProps>(({ children, size, className }, ref) => {
  return (
    <div className={clsx(styles.container, size ? styles[size] : undefined, className)} ref={ref}>
      {children}
    </div>
  );
})