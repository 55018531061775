import React from 'react';
import ConceptsSection from '../../components/conceptsSection/conceptsSection';
import { ExtraColSection } from '../../components/extraColSection/extraColSection';
import FilterSection from '../../components/filterSection/filterSection';
import SentimentRatingSection from '../../components/sentimentRatingSection/sentimentRatingSection';
import StarRatingSection from '../../components/sentimentRatingSection/starRatingSection';
import { useReportUpdateMutation } from '../../trpcHooks/useReportMutation';
import {
  ReportCategory,
  ReviewExtraColFromList,
  ReviewExtraColSectionFromList,
  SvrReport,
  SvrReportApp,
} from '../../utils/trpc';
import { isNotNull } from '../../utils2';
import { ContentDivider } from '../ContentDivider/ContentDivider';
import { ContentSection } from '../ContentSection/ContentSection';
import { PaddedScrollable } from '../PaddedScrollable/PaddedScrollable';
import { Search, Smile, Star } from '../StrokeIcons';
import TopicSection from '../TopicSection';
import styles from './Filters.module.scss';

interface FiltersProps {
  report: SvrReport;
  app: SvrReportApp;
  reportCategories?: ReportCategory[];
  extraCols: ReviewExtraColFromList[] | undefined;
  extraColsSections: ReviewExtraColSectionFromList[] | undefined;
  appIdsFilter: Set<string>;
  onAddConceptClicked?: () => void;
  sentiments?: Record<string, number>;
  ratings?: Record<string, number>;
  categories?: Record<string, { value: number; count: number }>;
}

export const Filters: React.FC<FiltersProps> = React.memo(
  ({
    report,
    app,
    reportCategories,
    extraCols,
    extraColsSections,
    appIdsFilter,
    ratings,
    sentiments,
    onAddConceptClicked,
    categories,
  }) => {
    const updateActiveMutation = useReportUpdateMutation();
    const reportId = report._id.toHexString();

    const [domainConcepts, judgementConcepts] = React.useMemo(() => {
      let domainConcepts: ReportCategory[] = [];
      let judgementConcepts: ReportCategory[] = [];
      reportCategories?.forEach((concept) => {
        if (concept.catType === 'judgement') {
          judgementConcepts.push(concept);
        } else if (concept.catType === 'domain') {
          domainConcepts.push(concept);
        }
      });
      return [domainConcepts, judgementConcepts];
    }, [reportCategories]);

    const updateTopic = (searches?: string[]) => {
      const id = report._id.toHexString();
      updateActiveMutation.mutate({ id, data: { searches } });
    };

    const appProvider = app.appProviderOverride || app.appProvider;

    const showNpsRating = appProvider === 'csvNPS';

    const groupedData = React.useMemo(() => {
      const groupedReviews = new Map<string, ReviewExtraColFromList[]>();

      // filter out the extra cols to only show for the specified apps
      const filteredExtraCols = extraCols?.filter((c) => appIdsFilter.has(c.appId.toHexString()));

      filteredExtraCols?.forEach((col) => {
        if (col.sectionId) {
          if (!groupedReviews.get(col.sectionId.toHexString())) {
            groupedReviews.set(col.sectionId.toHexString(), []);
          }
          groupedReviews.get(col.sectionId.toHexString())?.push(col);
        }
      });

      const sectionsWithCols = extraColsSections?.filter(isNotNull)?.map((sectionCol) => {
        const sections = groupedReviews.get(sectionCol._id.toHexString());
        return {
          section: sectionCol,
          data: sections,
        };
      });
      return sectionsWithCols?.filter((v) => v.data != null && v.data.length > 0);
    }, [extraColsSections, extraCols, appIdsFilter]);

    return (
      <div className={styles.container}>
        <PaddedScrollable size="small">
          <ContentSection size="small">
            <ContentDivider size="small" icon={<Search />}>
              Saved searches
            </ContentDivider>
            <TopicSection topics={report.searches} updateTopic={updateTopic} />
          </ContentSection>

          <ContentSection size="small">
            <ContentDivider size="small" icon={<Star />}>
              Rating
            </ContentDivider>
            {!showNpsRating && <StarRatingSection ratings={ratings} />}
            {/* {showNpsRating && <NpsRatingSection data={ratingData} facetData={facets?.score} />} */}
          </ContentSection>

          <ContentSection size="small">
            <ContentDivider size="small" icon={<Smile />}>
              Sentiment
            </ContentDivider>
            <SentimentRatingSection sentiments={sentiments} />
          </ContentSection>

          <ContentSection size="small">
            <ContentDivider size="small">Domain Concepts</ContentDivider>
            <ConceptsSection
              concepts={domainConcepts}
              reportId={reportId}
              catType="domain"
              onAddConceptClicked={onAddConceptClicked}
              categories={categories}
            />
          </ContentSection>

          <ContentSection size="small">
            <ContentDivider size="small">Valence Concepts</ContentDivider>
            <ConceptsSection
              concepts={judgementConcepts}
              catType="judgement"
              reportId={reportId}
              onAddConceptClicked={onAddConceptClicked}
              categories={categories}
            />
          </ContentSection>

          <ContentSection size="small">
            <ContentDivider size="small">Phrased Concepts</ContentDivider>

            {groupedData?.map((data, i) => {
              if (data == null) return null;

              return (
                <FilterSection name={data.section.name.toUpperCase()} key={i}>
                  {data.data?.map((data, i) => (
                    <ExtraColSection key={i} extraCol={data} />
                  ))}
                </FilterSection>
              );
            })}
          </ContentSection>
        </PaddedScrollable>
      </div>
    );
  },
);

export default Filters;
