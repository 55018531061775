import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { SvrReport } from '../../utils/trpc';
import { AmplitudeContext } from './AmplitudeProvider';
interface LogEvent {
  eventType: string;
  eventProperties?: any;
}
interface ReportLogEvent extends LogEvent {
  eventType: string;
  shared?: boolean;
  report?: SvrReport;
  demoId?: string;
  extraEventProperties?: Record<string, any>;
}

// logs an event normally
export const useLogEffect = ({ eventType, eventProperties }: LogEvent, deps: any) => {
  const { amplitudeInstance } = useContext(AmplitudeContext);

  useEffect(() => {
    // @TODO: FIX
    // amplitudeInstance.logEvent(eventType, eventProperties)
  }, [amplitudeInstance, eventType, eventProperties, ...deps]);
};

// logs an event normally
export const useReportLogEffect = (
  { report, demoId, shared, eventType, extraEventProperties }: ReportLogEvent,
  deps: any,
) => {
  const { amplitudeInstance } = useContext(AmplitudeContext);
  const location = useLocation();
  const reportId = report?._id.toString();
  const href = location.pathname;
  const publicId = report?.publicId;

  useEffect(() => {
    // @TODO: FIX
    // const eventProperties = {
    //   ...(extraEventProperties || {}),
    //   reportId,
    //   href,
    //   ...(shared ? { publicId } : {}),
    //   ...(demoId ? { demoId } : {}),
    // }
    // amplitudeInstance.logEvent(eventType, eventProperties)
  }, [
    amplitudeInstance,
    eventType,
    reportId,
    href,
    demoId,
    publicId,
    extraEventProperties,
    shared,
    ...deps,
  ]);
};

// sets the user id
export const useSetUserId = (userId: string | undefined, user: any) => {
  const { amplitudeInstance } = useContext(AmplitudeContext);
  useEffect(() => {
    // @TODO: FIX
    // if (userId == null) {
    //   return
    // }
    // amplitudeInstance.setUserId(userId);
    // if(user != null && user.email != null) { amplitudeInstance.setUserProperties( { email: user.email } ) }
    // if(userId == null) amplitudeInstance.regenerateDeviceId();
  }, [userId, amplitudeInstance, user]);
};
