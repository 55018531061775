import React from 'react';
import { BrandLogo, IconWithPlaceholder } from '../IconWithPlaceholder/IconWithPlaceholder';
import styles from './DetailHeaderContent.module.scss';
import { ServerBrand } from '../../utils/trpc';

interface DetailHeaderBrandContentProps {
  brand: ServerBrand
}
export const DetailHeaderBrandContent: React.FC<DetailHeaderBrandContentProps> = ({ brand }) => {
  return (
    <div className={styles.container}>
      <BrandLogo size={40} brand={brand} shape="circle" />
      <div className={styles.contactInfo}>
        <div className={styles.name}>{brand.name}</div>
      </div>
    </div>
  );
};

export const DetailHeaderContentLoading = () => {
  return (
    <div className={styles.container}>
      <IconWithPlaceholder urls={[]} size={40} alt="profile" shape="circle" />
      <div className={styles.contactInfo}>
        <div className={styles.name} />
      </div>
    </div>
  );
};
