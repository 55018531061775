import React from 'react';
import { Link } from 'react-router-dom';
import { extractAchronym } from 'src/utils2';
import { UserAndTeam } from '../../Route';
import { IconWithPlaceholder } from '../IconWithPlaceholder/IconWithPlaceholder';
import styles from './UserLogoutRow.module.scss';

export const UserLogoutRow: React.FC<UserAndTeam> = ({ user, team }) => {
  const name = user?.email;
  const teamId = user?.defaultTeamId?.toHexString();

  return (
    <div className={styles.container}>
      <Link className={styles.button} to="/teams/active">
        <IconWithPlaceholder
          size={40}
          alt="profile"
          placeholder={extractAchronym([name])}
          urls={[]}
          shape="circle"
        />
        <div className={styles.content}>
          <div className={styles.name}>{name}</div>
          <div className={styles.teamName}>{team?.team.name}</div>
        </div>
      </Link>
    </div>
  );
};
