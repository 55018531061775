import clsx from 'clsx';
import { FirebaseError } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { AnimatePresence, motion } from 'framer-motion';
import { Controller, useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { LogoIcon } from 'src/toneIcons';
import { SubmitButton } from '../PoseidonComponents/Button/CustomButton';
import { Input } from '../PoseidonComponents/Input/Input';
import { Key, Mail } from '../PoseidonComponents/StrokeIcons';
import styles from './auth.module.scss';

interface FormData {
  email?: string;
  password?: string;
}

export const Login = () => {
  const location = useLocation();

  const { control, handleSubmit, setError, formState } = useForm<FormData>({
    mode: 'all',
  });

  const onSubmit = async (data: FormData) => {
    const email = data.email?.trim();
    const password = data.password?.trim();
    try {
      if (email != null && password != null) {
        await signInWithEmailAndPassword(getAuth(), email, password);
      }
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        if (error.code === 'auth/wrong-password') {
          setError('password', { message: 'Invalid password', type: 'custom' });
        } else if (error.code === 'auth/invalid-email') {
          setError('email', { message: 'Invalid email', type: 'custom' });
        } else if (error.code === 'auth/user-not-found') {
          setError('root', { message: 'Failed to login', type: 'custom' });
        } else if (error.code === 'auth/too-many-requests') {
          setError('root', {
            message: 'Too many failed login attempts, please wait a few minutes',
            type: 'custom',
          });
        } else {
          setError('root', { message: 'Failed to login', type: 'custom' });
        }
      }
    }
  };

  const hasErrors = Object.values(formState.errors).length > 0;
  const className = clsx(styles.content, hasErrors ? styles.errorShake : null);
  const hash = location.hash;
  const search = location.search;

  return (
    <div className={styles.container}>
      <LogoIcon className={styles.logo} />
      <AnimatePresence>
        <motion.form className={className} onSubmit={handleSubmit(onSubmit)}>
          {formState.errors?.root && (
            <div className={styles.error}>{formState.errors.root.message}</div>
          )}
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <Input
                title="Email"
                placeholder="your@email.com"
                error={formState.errors.email}
                icon={<Mail />}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="password"
            render={({ field }) => (
              <Input
                title="Password"
                placeholder="Password"
                type="password"
                error={formState.errors.password}
                icon={<Key />}
                {...field}
              />
            )}
          />
          <SubmitButton loading={formState.isLoading} disabled={formState.isLoading}>
            Log in
          </SubmitButton>
        </motion.form>
      </AnimatePresence>
      <div className={styles.footer}>
        <div className={styles.footerButtons}>
          <Link to="../forgot-password" className={styles.footerButton}>
            Forgot Password?
          </Link>
          <div className={styles.spacer} />
          <Link to={{ pathname: `../signup`, search, hash }} className={styles.footerButton}>
            Sign Up
          </Link>
        </div>
      </div>
    </div>
  );
};
