import clsx from 'clsx'
import React from 'react'
import Popup from '../popup/popup'
import styles from './zoomableImage.module.scss'

interface ZoomableImageProps extends React.HTMLProps<HTMLImageElement> {

}

export const ZoomableImage: React.FC<ZoomableImageProps> = ({src, className}) => {
  const [zoomed, setZoomed] = React.useState(false)
  return <div className={clsx(className, styles.imageContainer)}>
    <img src={src} className={styles.img} onClick={() => setZoomed(true)} />
    {zoomed && <Popup onClose={() => setZoomed(false)}>
      <img src={src} className={styles.zoomedImage} />
    </Popup>}
  </div>
}