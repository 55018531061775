import React from 'react';
import UtfString from 'utfstring';
import { getSearch } from '../../utils2';
import styles from './review.module.scss';


interface ReviewHighlightProps {
  line?: string;
  query?: string | RegExp;
}

export const ReviewHighlight: React.FC<ReviewHighlightProps> = React.memo(({ line, query }) => {
  if (query == null) return null;
  if (line == null) return null;

  const searchRegex = getSearch(query);
  const matchObj = searchRegex ? line.match(searchRegex) : undefined;
  if (matchObj == null) return null;

  const matchStart = matchObj.index ?? 0;
  const matchEnd = matchStart + (matchObj?.[0]?.length || 0);

  const bLine = UtfString.slice(line, 0, matchStart);
  const hLine = UtfString.slice(line, matchStart, matchEnd);
  const eLine = UtfString.slice(line, matchEnd);

  return (
    <div className={styles.highlightOverlay}>
      <span>{bLine}</span>
      <span className={styles.highlight}>{hLine}</span>
      <span>{eLine}</span>
    </div>
  );
});
