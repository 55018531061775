import React, { useState } from 'react';
import useRouterQuery from '../../hooks/useRouterQuery';
import {
  useReportConceptCreate,
  useReportConceptCreateFromConcept,
} from '../../trpcHooks/useConceptMutation';
import { ReportCategoryCreateParams, trpc } from '../../utils/trpc';
import { Badge } from '../Badge/Badge';
import { Button } from '../Button/Button';
import { ContentDivider } from '../ContentDivider/ContentDivider';
import { ContentSection } from '../ContentSection/ContentSection';
import { DetailHeader } from '../DetailHeader/DetailHeader';
import { Flex } from '../Layout/Layout';
import { Plus } from '../StrokeIcons';
import { Input } from '../Input/Input';
import styles from './ConceptDetailAddPage.module.scss';
import { PaddedScrollable } from '../PaddedScrollable/PaddedScrollable';

interface ConceptDetailAddPageProps extends React.HTMLProps<HTMLDivElement> {
  reportId: string;
  catType: 'domain' | 'judgement';
}

export const ConceptDetailAddPage: React.FC<ConceptDetailAddPageProps> = ({
  reportId,
  catType,
}) => {
  const [query, setQuery] = useRouterQuery();
  const libraryConceptRes = trpc.concepts.all.useQuery({ catType, pagination: { perPage: 1000 } });
  const libraryConcepts = libraryConceptRes.data;
  const createReportConceptFromLibMut = useReportConceptCreateFromConcept();
  const createBlankReportConceptMut = useReportConceptCreate();
  const [filterValue, setFilterValue] = useState('');

  const createReportConceptFromLib = async (reportId: string, conceptId: string) => {
    if (createReportConceptFromLibMut.isLoading == true) {
      return;
    }
    const concept = await createReportConceptFromLibMut.mutateAsync({ reportId, conceptId });
    setQuery((o) => ({ ...o, addConcept: undefined, pinnedConcept: concept._id.toHexString() }));
  };
  const createBlankReportConcept = async (reportId: string, name: string) => {
    if (createBlankReportConceptMut.isLoading == true) {
      return;
    }
    const toCreate: ReportCategoryCreateParams = {
      name,
      catType,
    };
    const concept = await createBlankReportConceptMut.mutateAsync({ reportId, toCreate });
    setQuery((o) => ({ ...o, addConcept: undefined, pinnedConcept: concept._id.toHexString() }));
  };

  const filterLibraryConcepts = () => {
    if (!filterValue) {
      return libraryConcepts;
    } else {
      return libraryConcepts?.filter((el) =>
        el.name?.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()),
      );
    }
  };
  return (
    <>
      <DetailHeader queryKey="addConcept">Add Concept</DetailHeader>
      <PaddedScrollable size="small">
        <div className={styles.sectionInput}>
          <Input
            value={filterValue}
            placeholder="Filter concepts"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterValue(e.target.value)}
          />
        </div>
        {filterLibraryConcepts()?.length === 0 && (
          <Button
            size="small"
            icon={<Plus />}
            onClick={() => createBlankReportConcept(reportId, filterValue)}>
            Add to Workspace
          </Button>
        )}
        {filterLibraryConcepts()?.map((concept) => {
          const conceptId = concept._id.toHexString();
          return (
            <ContentSection key={conceptId} size="small">
              <ContentDivider
                end={
                  <Button
                    size="small"
                    icon={<Plus />}
                    onClick={() => createReportConceptFromLib(reportId, conceptId)}>
                    Add to Workspace
                  </Button>
                }>
                {concept.name || '(no name)'}
              </ContentDivider>
              {concept.catType == 'domain' && (
                <Flex>
                  {concept?.words?.map((word, i) => (
                    <Badge
                      key={`${word + i}`}
                      type={query && word === query.q ? 'primary' : undefined}>
                      {word}
                    </Badge>
                  ))}
                </Flex>
              )}
              {concept.catType == 'judgement' && (
                <>
                  <ContentDivider>{concept.positive?.name} (Positive Words)</ContentDivider>
                  <Flex>
                    {concept?.positive?.words?.map((word, i) => (
                      <Badge
                        key={`${word + i}`}
                        type={query && word === query.q ? 'primary' : undefined}>
                        {word}
                      </Badge>
                    ))}
                  </Flex>
                  <ContentDivider>{concept.negative?.name} (Negative Words)</ContentDivider>
                  <Flex>
                    {concept?.negative?.words?.map((word, i) => (
                      <Badge
                        key={`${word + i}`}
                        type={query && word === query.q ? 'primary' : undefined}>
                        {word}
                      </Badge>
                    ))}
                  </Flex>
                </>
              )}
            </ContentSection>
          );
        })}
      </PaddedScrollable>
    </>
  );
};
