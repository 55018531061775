import copy from 'clipboard-copy';
import clsx from 'clsx';
import domtoimage from 'dom-to-image-more';
import download from 'downloadjs';
import { DateTime } from 'luxon';
import React, { RefObject } from 'react';
import { toast } from 'react-hot-toast';
import UtfString from 'utfstring';
import PopupPopover from '../../components/PopupPopover/PopupPopover';
import { ReviewTextOverlay } from '../../components/review/ReviewText';
import { ReviewHighlight } from '../../components/review/reviewHighlight';
import {
  MachineTooltip2,
  MachineTooltipModel2,
} from '../../components/review/reviewMachineTooltip';
import useRouterQuery from '../../hooks/useRouterQuery';
import { sourcesById } from '../../sources';
import { ReportCategory, ReviewComment, SvrReport, SvrReportReview } from '../../utils/trpc';
import { isNotEmpty, isNotNull, strokeIconByName } from '../../utils2';
import { BrandLogo } from '../IconWithPlaceholder/IconWithPlaceholder';
import {
  ReviewCardAddComment,
  ReviewCardComment,
  ReviewCardResponse,
} from '../ReviewCardComment/ReviewCardComment';
import { ClipboardAdd, Comment, Download, InfoCircle, Lightbulb, Link, Star } from '../StrokeIcons';
import { SvgIconAnchor, SvgIconButton } from '../SvgIconButton/SvgIconButton';
import styles from './ReviewCard.module.scss';

type ButtonType = 'comment' | 'copy' | 'download' | 'info' | 'takeaway' | 'link';

interface ReviewCardProps extends React.HTMLProps<HTMLDivElement> {
  report: SvrReport;
  review: SvrReportReview;
  conceptsById: Record<string, ReportCategory>;
  comments?: ReviewComment[];
  hideAppLogo?: boolean;
  shownButtons?: Set<ButtonType>;
  showComments?: boolean;
  showResponse?: boolean;
}

export type QueryMachine =
  | {
      rid: string;
      start: number;
      end: number;
    }
  | {
      rid: string;
      start?: number | undefined;
      end?: number | undefined;
    }
  | undefined;

export const ReviewCard: React.FC<ReviewCardProps> = ({
  report,
  review,
  conceptsById,
  comments,
  hideAppLogo,
  showComments,
  showResponse = true,
  shownButtons = new Set(['comment', 'copy', 'download', 'info', 'takeaway', 'link']),
}) => {
  const [query] = useRouterQuery();
  const reviewId = review._id.toHexString();
  const reportId = report._id.toHexString();
  const showAddComments = query.reviewCommentId === reviewId;
  const isMachineActive = query.machine?.rid != null && query.machine?.rid === reviewId;
  const isNotMachineActive = query.machine?.rid != null && query.machine.rid !== reviewId;

  const classList = clsx(styles.container, isNotMachineActive ? styles.notMachine : undefined);

  const exportableRef = React.useRef<HTMLDivElement>(null);

  return (
    <>
      <div
        aria-label="review"
        className={clsx(
          classList,
          !isMachineActive && query.machine ? styles.editButton : undefined,
        )}
        id={reviewId}>
        <div className={styles.content} ref={exportableRef}>
          <ReviewCardTitle
            report={report}
            review={review}
            conceptsById={conceptsById}
            comments={comments}
            shownButtons={shownButtons}
            hideAppLogo={hideAppLogo}
          />
          <ReviewCardBody
            review={review}
            conceptsById={conceptsById}
            query={query.q}
            category={query.category}
            isMachineActive={isMachineActive}
            queryMachine={query.machine}
            machineTooltip={{
              reviewId: query.machine?.rid,
              startOffset: query.machine?.start,
              endOffset: query.machine?.end,
            }}
          />
          <ReviewCardFooter
            report={report}
            review={review}
            conceptsById={conceptsById}
            comments={comments}
            shownButtons={shownButtons}
          />
        </div>
        {!query.machine?.rid && (
          <Buttons
            review={review}
            downloadRef={exportableRef}
            reportId={reportId}
            shownButtons={shownButtons}
          />
        )}
        {!!query.machine?.rid && (
          <>
            {reviewId === query.machine?.rid && (
              <Buttons
                review={review}
                downloadRef={exportableRef}
                reportId={reportId}
                shownButtons={shownButtons}
              />
            )}
          </>
        )}
      </div>

      {showAddComments && <ReviewCardAddComment report={report} review={review} />}
      {(showComments || showAddComments) && comments != null && (
        <div className={styles.comments}>
          {comments?.map((c) => (
            <div>
              <ReviewCardComment
                review={review}
                report={report}
                comment={c}
                key={c._id.toHexString()}
              />
            </div>
          ))}
        </div>
      )}
      {showResponse && <ReviewCardResponse report={report} review={review} />}
    </>
  );
};

const ReviewCardTitle: React.FC<ReviewCardProps> = ({ report, hideAppLogo, review }) => {
  const date = review.date ? DateTime.fromJSDate(review.date) : undefined;
  const brandId = review.brandId?.toHexString();
  const brand = report.brands?.find((b) => b._id.toHexString() === brandId);
  const title = review.title || review.extras?.title || review.extrasV2?.title;

  return (
    <div className={styles.header}>
      {!hideAppLogo && (
        <div className={styles.logo}>
          <BrandLogo brand={brand} size={20} shape="circle" />
        </div>
      )}
      <div className={styles.headerLeft}>
        <div className={styles.title}>{title}</div>
        <div className={styles.stars}>
          {Array.from(Array(Math.floor(review.score ?? 0)).keys()).map((k) => (
            <div key={k} className={styles.icon}>
              <Star />
            </div>
          ))}
        </div>
      </div>
      <div className={styles.headerRight}>
        <div className={styles.username}>{review.userName}</div>
        <div className={styles.date}>{date?.toRelative()}</div>
      </div>
    </div>
  );
};

const ReviewCardFooter: React.FC<ReviewCardProps> = ({ report, review, conceptsById }) => {
  const appId = review.appId.toHexString();
  const app = report.apps?.find((a) => a.metaId.toHexString() === appId);
  const appProvider = app?.appProviderOverride || app?.appProvider;
  const source = appProvider ? sourcesById?.[appProvider] : undefined;

  const conceptIds = Array.from(
    new Set(review.categories?.map((c) => c.c?.toHexString()).filter(isNotNull)),
  );

  const concepts = conceptIds.map((id) => conceptsById?.[id]).filter(isNotNull);

  return (
    <div className={styles.footer}>
      <div className={styles.footerIcons}>
        {source?.icon && <div className={styles.icon}>{source?.icon}</div>}
        {concepts?.map((concept) => {
          const Icon = concept.name ? strokeIconByName[concept.name] : undefined;
          if (Icon == null) return null;

          return (
            <div key={concept._id.toHexString()} className={styles.icon}>
              <Icon />
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface ReviewCardBodyProps {
  review: SvrReportReview;
  conceptsById: Record<string, ReportCategory>;
  query?: string;
  category?: string;
  isMachineActive?: boolean;
  machineTooltip?: MachineTooltipModel2;
  queryMachine?: QueryMachine;
}
const splitChar = '\n';
const ReviewCardBody: React.FC<ReviewCardBodyProps> = ({
  review,
  query,
  conceptsById,
  category,
  isMachineActive,
  machineTooltip,
  queryMachine,
}) => {
  let offset = review.title != null ? UtfString.length(review.title) + 2 : 0;

  let lines = review.text?.split(splitChar);
  const isYoutube = false; //appProvider === 'csvYoutube';
  if (isYoutube) {
    lines = review.text?.split('___');
  }
  // const concept = category ? conceptsById?.[category] : undefined
  const concept = undefined;
  let precedingLines = 0;
  return (
    <div className={styles.body} aria-label="reviewBody">
      {lines?.filter(isNotNull).map((line, i) => {
        offset += i > 0 ? UtfString.length(lines?.[i - 1] ?? '') + 1 : 0;
        precedingLines = precedingLines + line.length;
        return (
          <div key={i} className={styles.line}>
            <ReviewTextOverlay
              line={line}
              ranges={review.ranges || []}
              offset={offset}
              conceptsById={conceptsById}
              concept={concept}
              queryMachine={queryMachine}
              isMachine={isMachineActive}
            />
            <ReviewHighlight line={line} query={query} />
            <MachineTooltip2
              index={i}
              precedingLines={precedingLines}
              id={review._id.toHexString()}
              line={line}
              linesLen={lines?.length || 0}
              tooltip={machineTooltip}
            />
          </div>
        );
      })}
    </div>
  );
};

interface ButtonsProps {
  review: SvrReportReview;
  reportId: string;
  downloadRef: RefObject<HTMLDivElement>;
  shownButtons: Set<ButtonType>;
}
const Buttons: React.FC<ButtonsProps> = ({ review, downloadRef, reportId, shownButtons }) => {
  const [, setQuery] = useRouterQuery();
  const reviewId = review._id.toHexString();
  const copyVerbatim = () => {
    copy(reviewId);
    toast.success('copied Verbatim id to clipboard');
  };

  const addComment = () => {
    setQuery?.((s) => ({
      ...s,
      reviewCommentId: s.reviewCommentId === reviewId ? undefined : reviewId,
    }));
  };

  const openDetail = () => {
    setQuery?.((s) => ({ ...s, rId: review._id.toHexString() }));
  };

  const downloadAsPng = async () => {
    if (downloadRef?.current) {
      const dataUrl = await domtoimage.toPng(downloadRef?.current, { scale: 2 });
      download(dataUrl, `review_${reviewId}.png`);
    }
  };

  const url = review.extras?.url || review.extrasV2?.url;

  return (
    <div className={styles.buttons}>
      {shownButtons.has('takeaway') && (
        <PopupPopover reviewId={reviewId} reportId={reportId} key="add review">
          {(handleClick) => (
            <SvgIconButton
              size="small"
              icon={<Lightbulb />}
              tooltip="+ Key Takeaway"
              onClick={handleClick}
            />
          )}
        </PopupPopover>
      )}
      {shownButtons.has('comment') && (
        <SvgIconButton size="small" icon={<Comment />} tooltip="Add Comment" onClick={addComment} />
      )}
      {shownButtons.has('copy') && (
        <SvgIconButton
          size="small"
          icon={<ClipboardAdd />}
          tooltip="Copy Verbatim id"
          onClick={copyVerbatim}
        />
      )}
      {shownButtons.has('download') && (
        <SvgIconButton
          size="small"
          icon={<Download />}
          tooltip="Download as .png"
          onClick={downloadAsPng}
        />
      )}
      {shownButtons.has('link') && isNotEmpty(url) && (
        <SvgIconAnchor
          size="small"
          icon={<Link />}
          tooltip="Link to source"
          href={url}
          target="_blank"
        />
      )}
      {shownButtons.has('info') && (
        <SvgIconButton
          size="small"
          icon={<InfoCircle />}
          tooltip="Open Detail Page"
          onClick={openDetail}
        />
      )}
    </div>
  );
};
