import React from 'react';
import apple1 from '../../images/apple1.jpg';
import apple2 from '../../images/apple2.jpg';

import google1 from '../../images/google_1.jpeg';
import google2 from '../../images/google_2.jpeg';
import google3 from '../../images/google_3.jpeg';
import google4 from '../../images/google_4.jpeg';
import google5 from '../../images/google_5.jpeg';
import google6 from '../../images/google_6.jpeg';
import google7 from '../../images/google_7.jpeg';
import google8 from '../../images/google_8.jpeg';
import google9 from '../../images/google_9.jpeg';
import google10 from '../../images/google_10.jpeg';
import google11 from '../../images/google_11.jpeg';
import google12 from '../../images/google_12.jpeg';
import google13 from '../../images/google_13.jpeg';
import google14 from '../../images/google_14.jpeg';
import google15 from '../../images/google_15.jpeg';
import google16 from '../../images/google_16.jpeg';
import google17 from '../../images/google_17.jpeg';
import google18 from '../../images/google_18.jpeg';
import google19 from '../../images/google_19.jpeg';


import { Android, Apple } from '../icons/brands';
import { ZoomableImage } from '../zoomableImage/zoomableImage';

import styles from './integrationsInfo.module.scss'

export const IntegrationsInfo = () => {
  return <div className={styles.containerWrap}>
    <div className={styles.router}>
      <h1>Integrations Instructions</h1>
      <section className={styles.section}>
        <h2><Apple /> App store instructions</h2>
        <p>Visit <a target="_blank" href="https://appstoreconnect.apple.com/">Apple App Store Connect</a> and add <strong>appstore@harmonize.ai</strong> as a user</p>
        
        <ZoomableImage src={apple1} />
        <ZoomableImage src={apple2} />
      </section>

      
      <section className={styles.section}>
        <h2><Android />Android Play store instructions</h2>
        <p>Watch this video</p>
        <video controls autoPlay>
          <source src="https://images.harmonize.ai/google-play-integration-720.mp4" type="video/mp4" />
        </video>
        <p>Or if you prefer photos</p>
        <ZoomableImage src={google1} />
        <ZoomableImage src={google2} />
        <ZoomableImage src={google3} />
        <ZoomableImage src={google4} />
        <ZoomableImage src={google5} />
        <ZoomableImage src={google6} />
        <ZoomableImage src={google7} />
        <ZoomableImage src={google8} />
        <ZoomableImage src={google9} />
        <ZoomableImage src={google10} />
        <ZoomableImage src={google11} />
        <ZoomableImage src={google12} />
        <ZoomableImage src={google13} />
        <ZoomableImage src={google14} />
        <ZoomableImage src={google15} />
        <ZoomableImage src={google16} />
        <ZoomableImage src={google17} />
        <ZoomableImage src={google18} />
        <ZoomableImage src={google19} />
      </section>
    </div>
  </div>
}