import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/PoseidonComponents/Button/Button';
import { ReportUserAndTeam } from '../Route';
import DeletePopup from '../components/deletePopup/deletePopup';
import { useReportsContext } from '../contexts';
import { useReportDeleteMutation } from '../trpcHooks/useReportMutation';
import { PlusCircle, Rocket, TrashCan } from './StrokeIcons';

export const ReportAdminSection: React.FC<ReportUserAndTeam> = ({ report, user }) => {
  const navigate = useNavigate();
  const reportDeleteMutation = useReportDeleteMutation();
  const [showDeletePopup, setShowDeletePopup] = React.useState(false);
  const [, { processReport, analyzeReport }] = useReportsContext();

  const reportId = report._id.toHexString();

  const closeDeletePopup = async (shouldDelete?: boolean) => {
    if (shouldDelete) {
      await reportDeleteMutation.mutateAsync(reportId);
      setShowDeletePopup(false);
      navigate('/reports');
    } else {
      setShowDeletePopup(false);
    }
  };

  return (
    <>
      <Button icon={<PlusCircle />} size="small" onClick={() => processReport(reportId)}>
        Scan Sources
      </Button>
      <Button icon={<Rocket />} size="small" onClick={() => () => analyzeReport(reportId)}>
        Analyze
      </Button>
      <Button icon={<TrashCan />} size="small" onClick={() => setShowDeletePopup(true)}>
        Delete Workspace
      </Button>

      {showDeletePopup && (
        <DeletePopup
          onClose={() => closeDeletePopup()}
          onSubmit={() => closeDeletePopup(showDeletePopup)}
        />
      )}
    </>
  );
};
