import { useDroppable } from '@dnd-kit/core';
import clsx from 'clsx';
import React from 'react';
import EditableLabel from 'src/components/editableLabel/editableLabel';
import { useReportUpdateMutation } from 'src/trpcHooks/useReportMutation';
import { SvrReportBrand, SvrReport } from 'src/utils/trpc';
import { TrashCan } from '../StrokeIcons';
import { SvgIconButton } from '../SvgIconButton/SvgIconButton';
import { SortableItem } from './SortableItem.';
import styles from './draftReportSections.module.scss';

interface Props {
  brands?: SvrReportBrand[];
  name: string;
  report: SvrReport;
  deleteGroup: (name: string) => void;
  deleteBrand: (name: string, groupName?: string) => void;
}

export const CardBrand: React.FC<Props> = ({ brands, report, name, deleteGroup, deleteBrand }) => {
  const { setNodeRef, isOver } = useDroppable({
    id: name,
    data: { groupId: name },
  });
  const updateMutation = useReportUpdateMutation();

  const onChangeLabel = async (newName: string) => {
    const brandGroups = report.brandGroups;
    const newBrandGroups = { ...brandGroups };
    const data = newBrandGroups[name];
    delete newBrandGroups[name];

    const newGroups = { ...newBrandGroups, [newName]: data || [] };
    if (!newGroups) return undefined;
    await updateMutation.mutateAsync({
      id: report._id.toHexString(),
      data: { brandGroups: newGroups },
    });
  };

  return (
    <div ref={setNodeRef} className={clsx(styles.list, isOver ? styles.overlay : undefined)}>
      <div className={styles.conceptName}>
        <span className={styles.groupName}>
          <EditableLabel
            className={styles.labelName}
            value={name}
            onChangeValue={onChangeLabel}
            deleteTooltip="remove this brand from report"
          />
        </span>
        <div className={styles.buttons}>
          <SvgIconButton icon={<TrashCan />} onClick={() => deleteGroup(name)} />
        </div>
      </div>
      <div className={clsx(styles.block)}>
        {brands
          ?.sort((a, b) => ((a?.name || '') > (b?.name || '') ? 1 : -1))
          .map((brand, index) => {
            const idBrand = brand._id.toHexString();
            return (
              <SortableItem
                key={idBrand}
                deleteBrand={deleteBrand}
                id={idBrand}
                brand={brand}
                groupId={name}
              />
            );
          })}
      </div>
    </div>
  );
};
