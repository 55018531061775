import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { Button } from '../../../PoseidonComponents/Button/Button';
import { ContentSection } from '../../../PoseidonComponents/ContentSection/ContentSection';
import { WithLeftNav } from '../../../PoseidonComponents/Layout/Layout';
import { PaddedScrollable } from '../../../PoseidonComponents/PaddedScrollable/PaddedScrollable';
import { ReportUserAndTeam } from '../../../Route';
import { SearchableExtraCol2 } from '../../../components/SearchableExtraCol2';
import { RQUtils, searchableColsForReport } from '../../../utils/reactQuery.utils';
import { SearchableExtraColsRes, trpc } from '../../../utils/trpc';

export const SearchableExtraCols: React.FC<ReportUserAndTeam> = ({ report }) => {
  const queryClient = useQueryClient();

  const reportId = report._id.toHexString();
  const reportSearchableColsReq = trpc.extraCols.searchableForReport.useQuery({ reportId });

  const cols = reportSearchableColsReq.data?.searchableExtraCols;
  const unusedColKeys = reportSearchableColsReq.data?.unusedColKeys;
  const nextAvailableColKey = unusedColKeys?.[0];

  const createSearchableColMut = trpc.extraCols.createSearchableExtraCol.useMutation({
    onSuccess(updated) {
      queryClient.setQueryData<SearchableExtraColsRes>(searchableColsForReport(reportId), (old) => {
        const oldUnusedKeys = old?.unusedColKeys;
        const unusedColKeys =
          oldUnusedKeys == null
            ? []
            : oldUnusedKeys.filter((v) => v != updated.searchableExtraCol.colKey);

        const oldSearchableExtraCols = old?.searchableExtraCols;
        const searchableExtraCols = RQUtils.updateArrQueryV2(
          oldSearchableExtraCols,
          updated.searchableExtraCol,
          '_id',
        );

        const updatesRes: SearchableExtraColsRes = {
          searchableExtraCols,
          unusedColKeys,
        };
        return updatesRes;
      });
    },
  });

  const createSearchableCol = () => {
    if (nextAvailableColKey == null) {
      alert("can't create any more searchable cols");
      return;
    }

    createSearchableColMut.mutate({
      reportId: reportId,
      params: {
        colKey: nextAvailableColKey,
      },
    });
  };

  return (
    <WithLeftNav hasDetail={false}>
      <PaddedScrollable>
        <ContentSection>
          {cols?.map((col) => (
            <SearchableExtraCol2 key={`${col._id.toHexString()}`} searchableCol={col} />
          ))}
          <Button onClick={() => createSearchableCol()}>Create</Button>
        </ContentSection>
      </PaddedScrollable>
    </WithLeftNav>
  );
};
