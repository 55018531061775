import React from 'react';
import { ContentDivider } from '../../../PoseidonComponents/ContentDivider/ContentDivider';
import { ContentSection } from '../../../PoseidonComponents/ContentSection/ContentSection';
import { WithLeftNav } from '../../../PoseidonComponents/Layout/Layout';
import { PaddedScrollable } from '../../../PoseidonComponents/PaddedScrollable/PaddedScrollable';
import { PageHeader } from '../../../PoseidonComponents/PageHeader/PageHeader';
import { ReviewCard } from '../../../PoseidonComponents/ReviewCard/ReviewCard';
import { Comment } from '../../../PoseidonComponents/StrokeIcons';
import { ReportCategory, ReviewComment, SvrReport, trpc } from '../../../utils/trpc';

interface ReportCommentsProps {
  report: SvrReport;
  conceptsById: Record<string, ReportCategory>;
}

const ReportComments: React.FC<ReportCommentsProps> = React.memo(({ report, conceptsById }) => {
  const reportId = report._id.toHexString();
  const reviewCommentsQuery = trpc.reviewComment.all.useQuery(reportId);

  return (
    <WithLeftNav hasDetail={false}>
      <PageHeader icon={<Comment />} title="Comments">
        <ContentDivider />
      </PageHeader>
      <PaddedScrollable>
        <ContentSection>
          {reviewCommentsQuery.data?.map((c) => {
            return (
              <ReviewContainer
                key={c._id.toHexString()}
                report={report}
                reviewId={c.reviewId.toHexString()}
                comments={c.comments}
                conceptsById={conceptsById}
              />
            );
          })}
        </ContentSection>
      </PaddedScrollable>
    </WithLeftNav>
  );
});

export default ReportComments;

interface ReviewContainerProps {
  report: SvrReport;
  reviewId: string;
  comments: ReviewComment[];
  conceptsById: Record<string, ReportCategory>;
}

const ReviewContainer: React.FC<ReviewContainerProps> = ({
  report,
  reviewId,
  comments,
  conceptsById,
}) => {
  const reportId = report._id.toHexString();
  const reviewQuery = trpc.reportReviews.id.useQuery({ reportId, reviewId });
  const review = reviewQuery.data?.review;

  if (review == null) return null;

  return (
    <ReviewCard review={review} report={report} conceptsById={conceptsById} comments={comments} />
  );
};
