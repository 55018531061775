import React from 'react';

const tone1FillStyle = {
  fill: 'var(--tone1, var(--tone1-default))',
  fillOpacity: 'var(--tone1-opacity, var(--tone1-opacity-default, 1))',
} as React.CSSProperties;

const tone1 = '#333';

interface Props extends React.SVGAttributes<SVGElement> {
  tone1?: string;
  tone2?: string;
  tone3?: string;
  tone4?: string;
}

export const Pricing: React.FC<Props> = ({ className }) => {
  const style = { '--tone1-default': tone1 } as React.CSSProperties;

  return (
    <svg fillRule="evenodd" viewBox="0 0 24 24" style={style} className={className}>
      <path
        d="M11.302 21.615C11.5234 21.7442 11.6341 21.8087 11.7903 21.8422C11.9116 21.8682 12.0884 21.8682 12.2097 21.8422C12.3659 21.8087 12.4766 21.7442 12.698 21.615C14.646 20.4785 20 16.9086 20 12.0001V7.21772C20 6.4182 20 6.01845 19.8692 5.67482C19.7537 5.37126 19.566 5.10039 19.3223 4.88564C19.0465 4.64255 18.6722 4.50219 17.9236 4.22146L12.5618 2.21079C12.3539 2.13283 12.25 2.09385 12.143 2.07839C12.0482 2.06469 11.9518 2.06469 11.857 2.07839C11.75 2.09385 11.6461 2.13283 11.4382 2.21079L6.0764 4.22146C5.3278 4.50219 4.9535 4.64255 4.67766 4.88564C4.43398 5.10039 4.24627 5.37126 4.13076 5.67482C4 6.01845 4 6.4182 4 7.21772V12.0001C4 16.9086 9.35396 20.4785 11.302 21.615Z"
        fill={'#fff'}
      />
      <path
        d="M9.21296 14.5C9.52517 15.0945 10.1486 15.5 10.8667 15.5H13C14.1046 15.5 15 14.6046 15 13.5C15 12.3954 14.1046 11.5 13 11.5H11C9.89543 11.5 9 10.6046 9 9.49999C9 8.39542 9.89543 7.49999 11 7.49999H13.1333C13.8514 7.49999 14.4748 7.90549 14.787 8.49999M12 5.99999V7.49999M12 15.5V17M20 12C20 16.9084 14.646 20.4784 12.698 21.6149C12.4766 21.744 12.3659 21.8086 12.2097 21.8421C12.0884 21.8681 11.9116 21.8681 11.7903 21.8421C11.6341 21.8086 11.5234 21.744 11.302 21.6149C9.35396 20.4784 4 16.9084 4 12V7.21759C4 6.41808 4 6.01833 4.13076 5.6747C4.24627 5.37113 4.43398 5.10027 4.67766 4.88552C4.9535 4.64243 5.3278 4.50207 6.0764 4.22134L11.4382 2.21067C11.6461 2.13271 11.75 2.09373 11.857 2.07827C11.9518 2.06457 12.0482 2.06457 12.143 2.07827C12.25 2.09373 12.3539 2.13271 12.5618 2.21067L17.9236 4.22134C18.6722 4.50207 19.0465 4.64243 19.3223 4.88552C19.566 5.10027 19.7537 5.37113 19.8692 5.6747C20 6.01833 20 6.41808 20 7.21759V12Z"
        style={tone1FillStyle}
        stroke="#644592"
        strokeWidth="1"
      />
    </svg>
  );
};

export const Service: React.FC<Props> = ({ className }) => {
  const style = { '--tone1-default': tone1 } as React.CSSProperties;

  return (
    <svg fillRule="evenodd" viewBox="0 0 22 22" style={style} className={className}>
      <path
        d="M5 19.0872H7.61029C7.95063 19.0872 8.28888 19.1277 8.61881 19.2087L11.3769 19.8789C11.9753 20.0247 12.5988 20.0389 13.2035 19.9214L16.253 19.3281C17.0585 19.1712 17.7996 18.7855 18.3803 18.2205L20.5379 16.1217C21.154 15.5234 21.154 14.5524 20.5379 13.9531C19.9832 13.4135 19.1047 13.3527 18.4771 13.8103L15.9626 15.6449C15.6025 15.9081 15.1643 16.0498 14.7137 16.0498H12.2855L13.8311 16.0498C14.7022 16.0498 15.4079 15.3633 15.4079 14.5159V14.2092C15.4079 13.5055 14.9156 12.892 14.2141 12.7219L11.8286 12.1418C11.4404 12.0476 11.0428 12 10.6431 12C9.67833 12 7.93189 12.7988 7.93189 12.7988L5 14.0249M1 13.6L1 19.4C1 19.9601 1 20.2401 1.10899 20.454C1.20487 20.6422 1.35785 20.7951 1.54601 20.891C1.75992 21 2.03995 21 2.6 21H3.4C3.96005 21 4.24008 21 4.45399 20.891C4.64215 20.7952 4.79513 20.6422 4.89101 20.454C5 20.2401 5 19.9601 5 19.4V13.6C5 13.04 5 12.7599 4.89101 12.546C4.79513 12.3579 4.64215 12.2049 4.45399 12.109C4.24008 12 3.96005 12 3.4 12H2.6C2.03995 12 1.75992 12 1.54601 12.109C1.35785 12.2049 1.20487 12.3579 1.10899 12.546C1 12.7599 1 13.04 1 13.6ZM16.1914 2.59227C15.5946 1.34341 14.2186 0.681802 12.8804 1.32039C11.5423 1.95898 10.9722 3.4734 11.5325 4.80284C11.8787 5.62448 12.8707 7.22002 13.5781 8.31905C13.8394 8.72513 13.9701 8.92817 14.161 9.04695C14.3247 9.14883 14.5297 9.20374 14.7224 9.19737C14.9471 9.18994 15.1618 9.07944 15.5911 8.85845C16.7532 8.26033 18.4101 7.37457 19.1208 6.83614C20.2707 5.96494 20.5556 4.36359 19.6947 3.14626C18.8337 1.92892 17.3327 1.80914 16.1914 2.59227Z"
        style={tone1FillStyle}
        stroke="#644592"
        strokeWidth="1"
      />
    </svg>
  );
};

export const Design: React.FC<Props> = ({ className }) => {
  const style = { '--tone1-default': tone1 } as React.CSSProperties;

  return (
    <svg fillRule="evenodd" viewBox="0 0 24 24" style={style} className={className}>
      <path
        d="M20.1314 10.8686C19.7354 11.2646 19.5373 11.4626 19.309 11.5368C19.1082 11.6021 18.8918 11.6021 18.691 11.5368C18.4627 11.4626 18.2646 11.2646 17.8686 10.8686L13.1314 6.13134C12.7354 5.73532 12.5373 5.53732 12.4632 5.30899C12.3979 5.10814 12.3979 4.8918 12.4632 4.69095C12.5373 4.46263 12.7354 4.26462 13.1314 3.8686L13.8686 3.13134C14.2646 2.73533 14.4627 2.53732 14.691 2.46313C14.8918 2.39787 15.1082 2.39787 15.309 2.46313C15.5373 2.53732 15.7354 2.73532 16.1314 3.13134L20.8686 7.8686C21.2646 8.26462 21.4627 8.46263 21.5368 8.69095C21.6021 8.8918 21.6021 9.10814 21.5368 9.30899C21.4627 9.53732 21.2646 9.73532 20.8686 10.1313L20.1314 10.8686Z"
        style={tone1FillStyle}
      />
      <path
        d="M13 13C13 11.8954 12.1046 11 11 11C9.89543 11 9 11.8954 9 13C9 14.1045 9.89543 15 11 15C12.1046 15 13 14.1045 13 13Z"
        style={tone1FillStyle}
      />

      <path
        d="M13 5.99997L6.50182 7.29961C6.13883 7.37221 5.95733 7.40851 5.80952 7.49677C5.67886 7.57481 5.56772 7.68164 5.4846 7.80913C5.39057 7.95334 5.34714 8.13326 5.26028 8.49311L2 22M2 22L15.5069 18.7397C15.8667 18.6528 16.0466 18.6094 16.1908 18.5154C16.3183 18.4322 16.4252 18.3211 16.5032 18.1904C16.5915 18.0426 16.6278 17.8611 16.7004 17.4981L18 11M2 22L9.586 14.414M20.8686 7.8686L16.1314 3.13134C15.7354 2.73532 15.5373 2.53732 15.309 2.46313C15.1082 2.39787 14.8918 2.39787 14.691 2.46313C14.4627 2.53732 14.2646 2.73533 13.8686 3.13134L13.1314 3.8686C12.7354 4.26462 12.5373 4.46263 12.4632 4.69095C12.3979 4.8918 12.3979 5.10814 12.4632 5.30899C12.5373 5.53732 12.7354 5.73532 13.1314 6.13134L17.8686 10.8686C18.2646 11.2646 18.4627 11.4626 18.691 11.5368C18.8918 11.6021 19.1082 11.6021 19.309 11.5368C19.5373 11.4626 19.7354 11.2646 20.1314 10.8686L20.8686 10.1313C21.2646 9.73532 21.4627 9.53732 21.5368 9.30899C21.6021 9.10814 21.6021 8.8918 21.5368 8.69095C21.4627 8.46263 21.2646 8.26462 20.8686 7.8686ZM11 11C12.1046 11 13 11.8954 13 13C13 14.1045 12.1046 15 11 15C9.89543 15 9 14.1045 9 13C9 11.8954 9.89543 11 11 11Z"
        style={tone1FillStyle}
        stroke="#644592"
        strokeWidth="1"
      />
    </svg>
  );
};

export const Quality: React.FC<Props> = ({ className }) => {
  const style = { '--tone1-default': tone1 } as React.CSSProperties;

  return (
    <svg fillRule="evenodd" viewBox="0 0 24 24" style={style} className={className}>
      <path
        d="M5.36363 2.57576C5.36363 2.12429 5.36363 1.89855 5.42942 1.7178C5.53971 1.41478 5.77841 1.17608 6.08143 1.06579C6.26218 1 6.48792 1 6.93939 1H16.8788C17.3303 1 17.556 1 17.7367 1.06579C18.0398 1.17608 18.2785 1.41478 18.3888 1.7178C18.4545 1.89855 18.4545 2.12429 18.4545 2.57576V8.63636C18.4545 12.2513 15.524 15.1818 11.9091 15.1818C8.29413 15.1818 5.36363 12.2513 5.36363 8.63636V2.57576Z"
        style={tone1FillStyle}
      />
      <path
        d="M11.9091 15.1818C8.29414 15.1818 5.36364 12.2513 5.36364 8.63636V2.57576C5.36364 2.12429 5.36364 1.89855 5.42943 1.7178C5.53972 1.41478 5.77841 1.17608 6.08143 1.06579C6.26219 1 6.48792 1 6.93939 1H16.8788C17.3303 1 17.556 1 17.7367 1.06579C18.0398 1.17608 18.2785 1.41478 18.3888 1.7178C18.4545 1.89855 18.4545 2.12429 18.4545 2.57576V8.63636C18.4545 12.2513 15.524 15.1818 11.9091 15.1818ZM11.9091 15.1818V18.4545M18.4545 3.18182H21.1818C21.6901 3.18182 21.9443 3.18182 22.1447 3.26486C22.412 3.37558 22.6244 3.58795 22.7351 3.85525C22.8182 4.05573 22.8182 4.30988 22.8182 4.81818V5.36364C22.8182 6.37815 22.8182 6.88541 22.7067 7.30159C22.404 8.43098 21.5219 9.31314 20.3925 9.61576C19.9763 9.72727 19.4691 9.72727 18.4545 9.72727M5.36364 3.18182H2.63636C2.12806 3.18182 1.87391 3.18182 1.67344 3.26486C1.40613 3.37558 1.19376 3.58795 1.08304 3.85525C1 4.05573 1 4.30988 1 4.81818V5.36364C1 6.37815 1 6.88541 1.11152 7.30159C1.41414 8.43098 2.29629 9.31314 3.42568 9.61576C3.84186 9.72727 4.34912 9.72727 5.36364 9.72727M6.93939 22.8182H16.8788C17.1466 22.8182 17.3636 22.6011 17.3636 22.3333C17.3636 20.1911 15.627 18.4545 13.4848 18.4545H10.3333C8.19114 18.4545 6.45455 20.1911 6.45455 22.3333C6.45455 22.6011 6.67162 22.8182 6.93939 22.8182Z"
        style={tone1FillStyle}
        stroke="#644592"
        strokeWidth="1"
      />
    </svg>
  );
};

export const Features: React.FC<Props> = ({ className }) => {
  const style = { '--tone1-default': tone1 } as React.CSSProperties;

  return (
    <svg fillRule="evenodd" viewBox="0 0 24 24" style={style} className={className}>
      <g opacity="0.05">
        <path
          d="M2.21799 8.09202C2 8.51984 2 9.0799 2 10.2V14H12V7H5.2C4.0799 7 3.51984 7 3.09202 7.21799C2.71569 7.40973 2.40973 7.71569 2.21799 8.09202Z"
          style={tone1FillStyle}
        />
        <path
          d="M21.782 20.908C22 20.4802 22 19.9201 22 18.8V14H12L12 22L18.8 22C19.9201 22 20.4802 22 20.908 21.782C21.2843 21.5903 21.5903 21.2843 21.782 20.908Z"
          style={tone1FillStyle}
        />
      </g>
      <path
        d="M12 7H7.5C6.83696 7 6.20107 6.73661 5.73223 6.26777C5.26339 5.79893 5 5.16304 5 4.5C5 3.83696 5.26339 3.20107 5.73223 2.73223C6.20107 2.26339 6.83696 2 7.5 2C11 2 12 7 12 7ZM12 7H16.5C17.163 7 17.7989 6.73661 18.2678 6.26777C18.7366 5.79893 19 5.16304 19 4.5C19 3.83696 18.7366 3.20107 18.2678 2.73223C17.7989 2.26339 17.163 2 16.5 2C13 2 12 7 12 7ZM12 7L12 22M2 14H22M2 10.2L2 18.8C2 19.9201 2 20.4802 2.21799 20.908C2.40973 21.2843 2.71569 21.5903 3.09202 21.782C3.51984 22 4.07989 22 5.2 22L18.8 22C19.9201 22 20.4802 22 20.908 21.782C21.2843 21.5903 21.5903 21.2843 21.782 20.908C22 20.4802 22 19.9201 22 18.8V10.2C22 9.0799 22 8.51984 21.782 8.09202C21.5903 7.7157 21.2843 7.40974 20.908 7.21799C20.4802 7 19.9201 7 18.8 7L5.2 7C4.0799 7 3.51984 7 3.09202 7.21799C2.7157 7.40973 2.40973 7.71569 2.21799 8.09202C2 8.51984 2 9.07989 2 10.2Z"
        style={tone1FillStyle}
        stroke="#644592"
        strokeWidth="1"
      />
    </svg>
  );
};
