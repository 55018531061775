import React from 'react';
import ReactDOM from 'react-dom/client'
import 'array-flat-polyfill';
import App from './App';

import './index.module.css';
import './fonts/fonts.module.css'

const element = document.getElementById("root")
ReactDOM.createRoot(element).render(<App />)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
