import clsx from 'clsx';
import { FirebaseError } from 'firebase/app';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { Controller, useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { SubmitButton } from '../PoseidonComponents/Button/CustomButton';
import { Input } from '../PoseidonComponents/Input/Input';
import { Mail } from '../PoseidonComponents/StrokeIcons';
import { LogoIcon2 } from '../components/icons/icons';
import styles from './auth.module.scss';

interface FormData {
  email?: string;
}

export const ForgotPassword = () => {
  const location = useLocation();
  const { control, handleSubmit, setError, formState } = useForm<FormData>({
    mode: 'all',
  });

  const onSubmit = async (data: FormData) => {
    const email = data.email?.trim();

    try {
      if (email != null) {
        const auth = getAuth();
        await sendPasswordResetEmail(auth, email);
      }
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        if (error.code === 'auth/invalid-email') {
          setError('email', { message: 'Invalid email', type: 'custom' });
        } else if (error.code === 'auth/user-not-found') {
          setError('root', { message: 'Failed to login', type: 'custom' });
        } else if (error.code === 'auth/too-many-requests') {
          setError('root', {
            message: 'Too many failed login attempts, please wait a few minutes',
            type: 'custom',
          });
        } else {
          setError('root', { message: 'Failed to login', type: 'custom' });
        }
      }
    }
  };

  const hasErrors = Object.values(formState.errors).length > 0;
  const className = clsx(styles.content, hasErrors ? styles.errorShake : null);

  return (
    <div className={styles.container}>
      <LogoIcon2 className={styles.logo} />
      <form className={className} onSubmit={handleSubmit(onSubmit)}>
        {formState.errors?.root && (
          <div className={styles.error}>{formState.errors.root.message}</div>
        )}
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <Input
              title="Email"
              placeholder="your@email.com"
              error={formState.errors.email}
              icon={<Mail />}
              {...field}
            />
          )}
        />

        <SubmitButton loading={formState.isLoading} disabled={formState.isLoading}>
          Send reset link
        </SubmitButton>
      </form>
      <div className={styles.footer}>
        <div className={styles.footerButtons}>
          <div className={styles.spacer} />
          <Link to={`../login${location.search}`} className={styles.footerButton}>
            Login to existing account
          </Link>
        </div>
      </div>
    </div>
  );
};
