import * as d3 from 'd3';
import React from 'react';
import ConceptSummaryTooltip from '../../components/conceptSummaryTooltip/conceptSummaryTooltip';
import { Tooltip } from '../../libs/Tooltip';
import { describeArc, getBubbleColors } from '../../utils2';
import { BubbleData } from './conceptSummaryCard';
import styles from './conceptSummaryCard.module.scss';

interface BubbleChartProps {
  width: number;
  height: number;
  data: BubbleData[];
  padding?: number;
}

export const BubbleChart: React.FC<BubbleChartProps> = ({ width, height, data, padding }) => {
  const circles = React.useMemo(() => {
    const pack = d3
      .pack()
      .size([width, width])
      .padding(padding || 6);

    // Process the data to have a hierarchy structure;
    if (data?.length > 0) {
      const root = d3
        .hierarchy({ children: data })
        .sum((d: any) => d.value)
        .sort((a, b) => (b.value ?? 0) - (a.value ?? 0))
        .each((d: any) => {
          if (d.data.label) {
            d.label = d.data.label;
            d._id = `_${d.data.id}`;
            d.id = d.data.id;
          }
        });
      const packed = pack(root as any);
      return packed.leaves();
    }
  }, [width, padding, data]);

  return (
    <svg width={width} height={height}>
      {circles?.map((circle: any) => {
        const negAngle = 360 * circle.data.negValue;
        const posAngle = 360 * (1 - circle.data.posValue);

        return (
          <Tooltip
            key={circle._id}
            background="#fff"
            boxShadow="0px 2px 4px rgba(0,0,0,0.2)"
            autoPlace={false}
            label={<ConceptSummaryTooltip data={circle.data} />}>
            <g>
              <ArcSvg
                x={circle.x}
                y={circle.y}
                r={circle.r}
                start={0}
                end={negAngle}
                fill={getBubbleColors(0)}
              />
              <ArcSvg
                x={circle.x}
                y={circle.y}
                r={circle.r}
                start={negAngle}
                end={posAngle}
                fill={getBubbleColors(50)}
              />
              <ArcSvg
                x={circle.x}
                y={circle.y}
                r={circle.r}
                start={posAngle}
                end={360}
                fill={getBubbleColors(100)}
              />
              <circle cx={circle.x} cy={circle.y} r={circle.r - 6} className={styles.circle} />
              <foreignObject
                x={circle.x - circle.r}
                y={circle.y - circle.r}
                width={circle.r * 2}
                height={circle.r * 2}>
                <div className={styles.circleTextContainer}>
                  {circle.data.label != null && (
                    <div className={styles.circleTextHeader}>
                      <div>{circle.data.label}</div>
                    </div>
                  )}
                  <div className={styles.circleTextSubHeader}>{Math.round(circle.data.value)}%</div>
                </div>
              </foreignObject>
            </g>
          </Tooltip>
        );
      })}
    </svg>
  );
};

interface ArcSvgProps {
  x: number;
  y: number;
  r: number;
  start: number;
  end: number;
  fill: string;
  stroke?: string;
  strokeWidth?: number;
}

const ArcSvg: React.FC<ArcSvgProps> = ({
  x,
  y,
  r,
  start,
  end,
  fill,
  stroke = '#fff',
  strokeWidth = 2,
}) => {
  if (start === end) return null;
  if (start === 0 && end === 360)
    return <circle cx={x} cy={y} r={r} fill={fill} stroke={stroke} strokeWidth={strokeWidth} />;
  const neutralD = describeArc(x, y, r, start, end);
  return <path d={neutralD} fill={fill} stroke={stroke} strokeWidth={strokeWidth} />;
};
