import clsx from 'clsx';
import domtoimage from 'dom-to-image-more';
import download from 'downloadjs';
import React from 'react';
import { Download } from '../../PoseidonComponents/StrokeIcons';
import { SvgIconButton } from '../../PoseidonComponents/SvgIconButton/SvgIconButton';
import styles from './card.module.scss';

interface CardProps extends React.HTMLProps<HTMLDivElement> {
  header: string;
  description: string;
  filters?: JSX.Element | JSX.Element[];
  full?: boolean;
  onDelete?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  editing?: boolean;
  fileName?: string;
  auto?: boolean;
}

const Card: React.FC<CardProps> = ({
  header,
  fileName,
  description,
  filters,
  children,
  auto,
  full,
  onDelete,
  onClick,
  editing,
}) => {
  const exportableRef = React.useRef(null);
  const classList = clsx(styles.section, full ? styles.full : null, auto ? styles.auto : null);

  const downloadAsPng = async () => {
    if (exportableRef?.current) {
      const dataUrl = await domtoimage.toPng(exportableRef?.current, { scale: 2 });
      download(dataUrl, fileName);
    }
  };

  return (
    <div className={classList} onClick={onClick}>
      <div className={styles.container} ref={exportableRef}>
        {(header != null || filters != null) && (
          <div className={styles.header}>
            <div className={styles.title}>{header}</div>
            <div className={styles.filters}>{filters}</div>
          </div>
        )}
        <div className={styles.content}>
          {description != null && (
            <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
          )}
          <div className={styles.contentData}>{children}</div>
        </div>
        {editing === null && (
          <div className={styles.editingContainer}>
            <button className={styles.delete} onClick={onDelete}>
              Delete
            </button>
          </div>
        )}
      </div>

      <div className={styles.buttons}>
        <SvgIconButton
          size="small"
          icon={<Download />}
          tooltip="Download as .png"
          onClick={downloadAsPng}
        />
      </div>
    </div>
  );
};

export default Card;
