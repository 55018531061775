import React from 'react';
import { getRangeColor, getScore } from '../../utils2';
import styles from './ScoreBox.module.scss';
import { Tooltip } from '../../libs/Tooltip';
import clsx from 'clsx';
import { ScoreTooltip } from '../../components/scoreTooltip/scoreTooltip';
import { ReportCategory } from '../../utils/trpc';

interface ScoreBoxProps {
  score?: number;
  reportCategories?: ReportCategory[];
}
export const ScoreBox: React.FC<ScoreBoxProps> = ({ score, reportCategories }) => {
  const percentScore = getScore(score);
  const style = {
    background: getRangeColor(percentScore),
  };

  const conceptNames = reportCategories
    ?.filter((c) => c.countInScore)
    .map((c) => c.name || c._id.toHexString());

  const isValidScore = score != null && !Number.isNaN(score);
  if (isValidScore) {
    if (conceptNames) {
      return (
        <Tooltip
          label={<ScoreTooltip conceptNames={conceptNames} />}
          background="#fff"
          boxShadow="0px 2px 4px rgba(0,0,0,0.2)">
          <div style={style} className={styles.box}>
            {percentScore}
          </div>
        </Tooltip>
      );
    } else {
      return (
        <div style={style} className={styles.box}>
          {percentScore}
        </div>
      );
    }
  } else {
    return <div className={clsx(styles.box, styles.nan)}>N/A</div>;
  }
};
