import clsx from 'clsx';
import React from 'react';
import { Tooltip } from 'src/libs/Tooltip';
import { Chevron } from '../StrokeIcons';
import { WaveSpinner } from '../WaveSpinner';
import styles from './Table.module.scss';
import { InputSize } from '../Input/types';

type Align = 'center' | 'right';

interface TableProps extends Omit<React.HTMLProps<HTMLDivElement>, 'size'> {
  gridTemplateColumns: string;
  bordered?: boolean;
  size?: InputSize;
}

export const Table: React.FC<TableProps> = ({ bordered, gridTemplateColumns, children, size }) => {
  const [hoverId, setHoverId] = React.useState<React.Key | null>();

  const style = {
    gridTemplateColumns,
  };
  const classList = clsx(
    styles.table,
    bordered ? styles.bordered : undefined,
    size ? styles[size] : undefined,
  );
  return (
    <div className={classList} style={style}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement<CellProps>(child)) {
          return React.cloneElement(child, {
            hover: hoverId === child.key,
            onMouseEnter: () => setHoverId?.(child.key),
            onMouseLeave: () => setHoverId?.(null),
          });
        } else {
          return child;
        }
      })}
    </div>
  );
};

interface CellProps extends React.HTMLProps<HTMLDivElement> {
  active?: boolean;
  index: number;
  sticky?: boolean;
  align?: Align;
  hover?: boolean;
}
export const Cell: React.FC<CellProps> = ({
  index,
  active,
  children,
  align,
  onClick,
  sticky,
  color,
  hover,
  ...props
}) => {
  const classList = clsx(
    styles.cell,
    index === 0 ? styles.first : undefined,
    active ? styles.active : undefined,
    align ? styles[align] : undefined,
    sticky ? styles.sticky : undefined,
    hover ? styles.hover : undefined,
    onClick != null ? styles.hoverable : undefined,
    color ? styles[color] : undefined,
  );
  return (
    <div className={classList} onClick={onClick} {...props}>
      {children}
    </div>
  );
};

interface CellHeaderProps extends React.HTMLProps<HTMLDivElement> {
  align?: Align;
  sticky?: boolean;
}
export const CellHeader: React.FC<CellHeaderProps> = ({ children, align, sticky }) => {
  const classList = clsx(
    styles.cellHeader,
    align ? styles[align] : undefined,
    sticky ? styles.sticky : undefined,
  );
  return <div className={classList}>{children}</div>;
};

interface SortableHeaderProps extends CellHeaderProps {
  onClickCell: (sortKey: string) => void;
  sortOrder?: 'desc' | 'asc';
  name: string;
  tooltip?: string;
}

export const SortableHeader: React.FC<SortableHeaderProps> = ({
  children,
  align,
  onClickCell,
  sortOrder,
  tooltip,
  sticky,
  name,
}) => {
  const classList = clsx(
    styles.cellHeader,
    align ? styles[align] : undefined,
    sticky ? styles.sticky : undefined,
    onClickCell != null ? styles.hoverable : undefined,
  );
  return (
    <Tooltip label={tooltip} autoPlace={false}>
      <div className={classList} onClick={() => onClickCell(name)}>
        {children}
        <div className={clsx(styles.chevron, sortOrder && styles[sortOrder])}>
          <Chevron />
        </div>
      </div>
    </Tooltip>
  );
};

interface CellLoadingProps extends React.HTMLProps<HTMLDivElement> {
  index: number;
}
export const CellLoading: React.FC<CellLoadingProps> = ({ children, index }) => {
  const classList = clsx(styles.cell, styles.cellLoading, index === 0 ? styles.first : undefined);
  return (
    <div className={classList}>
      <div className={styles.icon}>
        <WaveSpinner />
      </div>
      {children}
    </div>
  );
};
