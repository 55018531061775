import React from 'react';
import { ReportCategory, SvrReportReviewRangeCategory } from '../../utils/trpc';
import { getRangeString, getScore, isNotNull } from '../../utils2';
import styles from './sentimentTooltip.module.scss';

interface SentimentTooltipProps {
  cats: SvrReportReviewRangeCategory[],
  conceptsById?: Record<string, ReportCategory>
}

export const SentimentTooltip: React.FC<SentimentTooltipProps> = ({cats, conceptsById}) => {
  const conceptNames = cats.map(cat => cat.c ? conceptsById?.[cat.c.toString()]?.name : undefined).filter(isNotNull);
  const sentiment = Math.max(...cats.map(c => c.s));
  const rangeString = getRangeString(getScore(sentiment));

  if((conceptNames?.length ?? 0) > 0) {
    return <div className={styles.container}>{conceptNames.join(', ')} {conceptNames.length === 1 ? 'score is' : 'scores are'} <strong>{rangeString}</strong></div>
  } else {
    return <div className={styles.container}>Sentence sentiment is <strong>{rangeString}</strong></div>
  }
}

export default SentimentTooltip