import { keyBy } from 'lodash';
import * as Brands from './components/icons/brands';
import { AppProvider } from './utils/trpc';

export interface Source {
  name: string;
  icon: JSX.Element;
  provider: AppProvider;
  items: string;
  searchable?: boolean;
  viaUrl?: boolean;
  premium?: boolean;
  hidden?: boolean;
  style?: Record<string, string>;
}

export const sources: Source[] = [
  {
    name: 'Android',
    icon: <Brands.Android />,
    provider: 'google',
    items: 'android apps',
    searchable: true,
  },
  {
    name: 'Apple',
    icon: <Brands.Apple />,
    provider: 'apple',
    items: 'apple apps',
    searchable: true,
  },
  { name: 'Amazon', icon: <Brands.Amazon />, provider: 'amazon', items: 'products', viaUrl: true },
  {
    name: 'Amazon Questions',
    icon: <Brands.Amazon />,
    provider: 'amazonQuestions',
    items: 'questions',
    viaUrl: true,
  },
  { name: 'CSV', icon: <Brands.CSV />, provider: 'csv', items: 'csv files' },

  {
    name: 'G2',
    icon: <Brands.G2 />,
    provider: 'g2',
    items: 'companies',
    premium: true,
    searchable: true,
    hidden: true,
  },
  {
    name: 'TrustPilot',
    icon: <Brands.TrustPilot />,
    provider: 'trustPilot',
    items: 'companies',
    premium: true,
    searchable: true,
    hidden: true,
  },
  {
    name: 'Trust Radius',
    icon: <Brands.TrustRadius />,
    provider: 'trustRadius',
    items: 'companies',
    premium: true,
    searchable: true,
  },
  { name: 'Slack', icon: <Brands.Slack />, provider: 'slack', items: 'messages', premium: true },
  { name: 'Zapier', icon: <Brands.Zapier />, provider: 'zapier', items: 'zaps', premium: true },
  {
    name: 'Zendesk',
    icon: <Brands.Zendesk />,
    provider: 'zendesk',
    items: 'tickets',
    premium: true,
  },
  {
    name: 'Qualtrics',
    icon: <Brands.Qualtrics />,
    provider: 'qualtrics',
    items: 'companies',
    premium: true,
  },
  {
    name: 'Alexa Skill',
    icon: <Brands.AmazonAlexa />,
    provider: 'amazonAlexa',
    items: 'skills',
    premium: true,
  },
  {
    name: 'Google Sheets',
    icon: <Brands.GoogleSheets />,
    provider: 'googleSheets',
    items: 'sheets',
    premium: true,
  },
  {
    name: 'Google Maps',
    icon: <Brands.GoogleMaps />,
    provider: 'googleMaps',
    items: 'places',
    premium: true,
    viaUrl: true,
  },

  {
    name: 'User Voice',
    icon: <Brands.UserVoice />,
    provider: 'userVoice',
    items: 'companies',
    premium: true,
  },
  {
    name: 'Twitter',
    icon: <Brands.Twitter />,
    provider: 'twitter',
    items: 'tweets',
    premium: true,
  },
  {
    name: 'Delighted',
    icon: <Brands.Delighted />,
    provider: 'delighted',
    items: 'companies',
    premium: true,
    hidden: true,
  },
  {
    name: 'Glassdoor',
    icon: <Brands.Glassdoor />,
    provider: 'glassdoor',
    items: 'companies',
    premium: true,
    hidden: true,
  },
  {
    name: 'TripAdvisor',
    icon: <Brands.Tripadvisor />,
    provider: 'tripadvisor',
    items: 'companies',
    premium: true,
    hidden: true,
  },
  {
    name: 'Yelp',
    icon: <Brands.Yelp />,
    provider: 'yelp',
    items: 'companies',
    premium: true,
    style: { '--icon-scale': '100%' },
    hidden: true,
  },
  {
    name: 'Podcasts',
    icon: <Brands.Podcasts />,
    provider: 'podcasts',
    items: 'podcasts',
    premium: true,
    style: { '--icon-scale': '100%' },
  },

  {
    name: 'Reddit',
    icon: <Brands.Reddit />,
    provider: 'csvReddit',
    items: 'products',
    premium: true,
  },
  {
    name: 'Amazon CSV',
    icon: <Brands.Amazon />,
    provider: 'csvAmazon',
    items: 'products',
    premium: true,
  },
  {
    name: 'TripAdvisor',
    icon: <Brands.Tripadvisor />,
    provider: 'csvTripadvisor',
    items: 'companies',
    premium: true,
  },
  {
    name: 'Yelp',
    icon: <Brands.Yelp />,
    provider: 'csvYelp',
    items: 'companies',
    premium: true,
    style: { '--icon-scale': '100%' },
  },
  {
    name: 'Walmart',
    icon: <Brands.Walmart />,
    provider: 'csvWalmart',
    items: 'companies',
    premium: true,
  },
  {
    name: 'Target',
    icon: <Brands.Target />,
    provider: 'csvTarget',
    items: 'companies',
    premium: true,
  },
  {
    name: 'Youtube',
    icon: <Brands.Youtube />,
    provider: 'csvYoutube',
    items: 'videos',
    premium: true,
  },
  {
    name: 'Influenster',
    icon: <Brands.Influenster />,
    provider: 'csvInfluenster',
    items: 'companies',
    premium: true,
  },
  { name: 'G2', icon: <Brands.G2 />, provider: 'csvG2', items: 'companies', premium: true },
  {
    name: 'TrustPilot',
    icon: <Brands.TrustPilot />,
    provider: 'csvTrustPilot',
    items: 'companies',
    premium: true,
  },
  {
    name: 'Glassdoor',
    icon: <Brands.Glassdoor />,
    provider: 'csvGlassdoor',
    items: 'companies',
    premium: true,
  },
  {
    name: 'Indeed',
    icon: <Brands.Indeed />,
    provider: 'csvIndeed',
    items: 'companies',
    premium: true,
  },
  {
    name: 'Delighted',
    icon: <Brands.Delighted />,
    provider: 'csvDelighted',
    items: 'companies',
    premium: true,
  },
  { name: 'NPS', icon: <Brands.CSV />, provider: 'csvNPS', items: 'reviews', premium: true },
  {
    name: 'Twitter',
    icon: <Brands.Twitter />,
    provider: 'csvTwitter',
    items: 'tweets',
    premium: true,
  },
];


export const sourcesById = keyBy(sources, s => s.provider)