import clsx from 'clsx';
import Color from 'color';
import React, { CSSProperties } from 'react';
import { ReportCategory } from '../../utils/trpc';
import { getRangeColor2 } from '../../utils2';
import styles from './conceptsSection.module.scss';

interface AspectButtonsProps {
  conceptAspect: '-1' | '1' | undefined;
  concept: ReportCategory;
  index: number;
  onToggleAspect: (n: number) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const AspectButtons: React.FC<AspectButtonsProps> = ({
  conceptAspect,
  concept,
  onToggleAspect,
  index,
}) => {
  let negColor;
  let posColor;
  let negHoverColor;
  let posHoverColor;
  if (concept.catType === 'judgement') {
    negColor = Color(getRangeColor2(0)).fade(0.5).toString();
    posColor = Color(getRangeColor2(100)).fade(0.5).toString();
    negHoverColor = getRangeColor2(0);
    posHoverColor = getRangeColor2(100);
  }
  const negativeName = concept?.negative?.name || 'Negative';
  const positiveName = concept?.positive?.name || 'Positive';
  const negativeClassName = clsx(
    styles.aspectRowButton,
    conceptAspect === '-1' ? styles.selected : null,
  );
  const positiveClassName = clsx(
    styles.aspectRowButton,
    conceptAspect === '1' ? styles.selected : null,
  );
  const negativeStyle = { '--color': negColor, '--hover-color': negHoverColor } as CSSProperties;
  const positiveStyle = { '--color': posColor, '--hover-color': posHoverColor } as CSSProperties;

  return (
    <div className={styles.aspectRow} style={{gridRowStart: index+2, gridRowEnd: "span 1"}}>
      <div className={negativeClassName} style={negativeStyle} onClick={onToggleAspect(-1)}>
        {negativeName}
      </div>
      <div className={styles.aspectRowSpacer} />
      <div className={positiveClassName} style={positiveStyle} onClick={onToggleAspect(1)}>
        {positiveName}
      </div>
    </div>
  );
};
