import React from 'react';
import { Button } from '../Button/Button';
import { Chevron, XMark } from '../StrokeIcons';
import styles from './Images.module.scss';
import useRouterQuery from 'src/hooks/useRouterQuery';
import { useKey } from 'src/hooks/useKey';
import { LittleSliderImage } from './LittleSliderImage/LittleSliderImage';

interface ImagesProps {
  images: string[];
}

export const Images: React.FC<ImagesProps> = ({ images }) => {
  const [search, setSearch] = useRouterQuery();
  const defaultIndex = React.useMemo(
    () => images.findIndex((el) => el === search.imgUrl),
    [search?.imgUrl],
  );

  const [isOpen, setIsOpen] = React.useState(defaultIndex !== -1 ? true : false);
  const [selectedImageIndex, setSelectedImageIndex] = React.useState(defaultIndex || 0);
  const modalRef = React.useRef<any>(null);

  const openLightbox = (index: number, image: string) => {
    setSelectedImageIndex(index);
    setIsOpen(true);
    setSearch((o) => ({ ...o, imgUrl: image }));
  };

  const closeLightbox = () => {
    setIsOpen(false);
    setSelectedImageIndex(0);
    setSearch((o) => ({ ...o, imgUrl: '' }));
  };

  const goToPreviousImage = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setSelectedImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const goToNextImage = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    setSelectedImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeLightbox();
    }
  };

  return (
    <div className={styles.container}>
      {images.map((image, i) => (
        <div key={i} className={styles.image}>
          <img alt="image" src={image} onClick={() => openLightbox(i, image)} />
        </div>
      ))}

      {isOpen && (
        <LittleSliderImage
          handleClickOutside={handleClickOutside}
          closeLightbox={closeLightbox}
          images={images}
          selectedImageIndex={selectedImageIndex}
          modalRef={modalRef}
          goToNextImage={goToNextImage}
          goToPreviousImage={goToPreviousImage}
        />
      )}
    </div>
  );
};
