import clsx from 'clsx';
import React, { CSSProperties } from 'react';
import { Path, Point } from '../../models/point.model';
import { ReportCategory, ServerDataCounts, SvrReportBrand } from '../../utils/trpc';
import { conceptIconByName, getColor, getScore, isNotNull } from '../../utils2';
import { Market } from '../icons/icons';
import styles from './insightPolarChart.module.scss';
import { Tooltip } from '../../libs/Tooltip';
import { BrandLogo } from '../../PoseidonComponents/IconWithPlaceholder/IconWithPlaceholder';

const getAngle = (i: number, length: number) => (i * 2 * Math.PI) / length - Math.PI / 2;

const getXY = (radius: number, angle: number, score: number, inset: number) => {
  const x = radius * (1 + (Math.cos(angle) * score) / 100) + inset;
  const y = radius * (1 + (Math.sin(angle) * score) / 100) + inset;
  return { x, y };
};

interface InsightPolarChartProps extends React.HTMLProps<HTMLDivElement> {
  width?: number;
  height?: number;
  concepts: ReportCategory[];
  brands?: (SvrReportBrand | undefined | null)[];
  onClickPoint?: (p: Point) => void;
  dataCounts?: ServerDataCounts;
}

export const InsightPolarChart: React.FC<InsightPolarChartProps> = ({
  width = 500,
  height = 500,
  concepts,
  dataCounts,
  brands,
  className,
  onClickPoint,
}) => {
  const inset = 50;
  const sections = [20, 40, 60, 80, 100];
  const radius = Math.min(width, height) / 2 - inset;

  const paths: Path[] | undefined = brands
    ?.map((brand, i) => {
      if (brand === undefined) return null;

      const color = getColor(i);
      const points: Point[] = concepts.map((cat, pointIndex) => {
        const catId = cat._id.toHexString();

        const brandsByCategory = dataCounts?.brandsByCategory;
        const categoryScore = brandsByCategory?.[catId];

        const angle = getAngle(pointIndex, concepts.length);

        if (brand == null) {
          const categoryScoreValues: any = Object.values(categoryScore || {});
          const categoryScores = categoryScoreValues?.map(
            (v: any) => getScore(v.categoryScore) || 0,
          );
          const score =
            categoryScores?.reduce((a: any, b: any) => a + b, 0) / categoryScores?.length || 0;
          const count =
            categoryScoreValues?.reduce((p: any, c: any) => p + c.counts.processed, 0) || 0;

          return {
            ...getXY(radius, angle, score, inset),
            score,
            color,
            name: 'Market Average',
            imageUrl: Market,
            count,
            conceptName: cat.name || catId,
          };
        } else {
          const brandDataId = brand._id.toHexString();
          const brandScore = categoryScore?.[brandDataId];
          const score = brandScore ? getScore(brandScore?.categoryScore) || 0 : 0;
          const count = brandScore?.counts.processed ?? 0;

          return {
            ...getXY(radius, angle, score, inset),
            score,
            color,
            name: brand.name,
            imageUrl: brand.imageUrl,
            count,
            conceptName: cat.name || catId,
            brandId: brand._id.toHexString(),
            conceptId: catId,
          };
        }
      });
      if (points == null || points.length === 0) return undefined;
      return { points, color };
    })
    .filter(isNotNull);

  return (
    <div className={clsx(styles.polarContent, className)} style={{ width, height }}>
      <div className={styles.concepts}>
        {concepts?.map((c, i) => {
          const catId = c._id.toHexString();
          const angle = getAngle(i, concepts.length);

          const x = (1 + Math.cos(angle)) * radius + inset;
          const y = (1 + Math.sin(angle)) * radius + inset;
          const angleLegibility = angle > 0 && angle < Math.PI ? '-180deg' : '0';
          const icon = conceptIconByName[c.name || ''];
          const transform = [
            `translate(${x}px, ${y}px)`,
            `translate(-50%, -50%)`,
            `rotate(${(angle * 180) / Math.PI}deg)`,
            `rotate(90deg)`,
            `translate(0, -50%)`,
            `translate(0, -14px)`,
            `rotate(${angleLegibility})`,
          ].join(' ');

          return (
            <div key={catId + i} className={styles.label} style={{ transform }}>
              {icon && React.createElement(icon, { className: styles.conceptIcon })}
              <div>
                {c?.name?.split(' ')?.map((l, i) => (
                  <div key={i}>{l}</div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        className={styles.containerChart}>
        <SpiderAxis inset={inset} concepts={concepts} axis={sections} radius={radius} />
        <ScoreAxis
          height={height}
          width={width}
          concepts={concepts}
          radius={radius}
          inset={inset}
        />
        <ScoreAxisLabels
          height={height}
          width={width}
          axis={sections}
          concepts={concepts}
          radius={radius}
        />

        <BrandArea paths={paths} />
        <BrandPoints paths={paths} onClickPoint={onClickPoint} />
      </svg>
    </div>
  );
};

export default InsightPolarChart;

interface ScoreAxisLabelsProps {
  concepts?: ReportCategory[];
  axis?: number[];
  radius: number;
  height: number;
  width: number;
}

const ScoreAxisLabels: React.FC<ScoreAxisLabelsProps> = ({
  concepts,
  axis,
  radius,
  height,
  width,
}) => {
  if (concepts == null || concepts.length === 0) return null;
  if (axis == null || axis.length === 0) return null;

  return (
    <>
      {axis.map((s) => {
        const rectWidth = 20 + (s === 100 ? 8 : 0);
        const rectHeight = 16;
        const x = width / 2 - rectWidth / 2;
        const y = height / 2 - (radius * s) / 100 - rectHeight / 2;
        return (
          <React.Fragment key={s}>
            <rect
              x={x}
              y={y}
              width={rectWidth}
              height={rectHeight}
              rx={4}
              className={styles.labelRect}
            />
            <text
              x={width / 2}
              y={height / 2 - (radius * s) / 100 + 1}
              className={styles.axisText}
              alignmentBaseline="middle"
              textAnchor="middle"
              dominantBaseline="middle">
              {s}
            </text>
          </React.Fragment>
        );
      })}
    </>
  );
};

interface AxisProps extends React.HTMLProps<HTMLDivElement> {
  concepts: ReportCategory[];
  width?: number;
  height?: number;
  radius: number;
  inset: number;
  axis?: number[];
}

const ScoreAxis: React.FC<AxisProps> = ({ concepts, width = 0, height = 0, radius, inset }) => {
  if (concepts == null || concepts.length === 0) return null;

  return (
    <>
      {concepts.map((_, i) => {
        const angle = getAngle(i, concepts.length);
        const x = radius * (1 + Math.cos(angle)) + inset;
        const y = radius * (1 + Math.sin(angle)) + inset;
        const rectSize = 10;

        return (
          <React.Fragment key={i}>
            <line x1={x} y1={y} x2={width / 2} y2={height / 2} className={styles.axis} />
            <rect
              x={x - rectSize / 2}
              y={y - rectSize / 2}
              width={rectSize}
              height={rectSize}
              rx={4}
              className={styles.labelRect}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};

const SpiderAxis: React.FC<AxisProps> = ({ concepts, axis, radius, inset }) => {
  if (concepts == null || concepts.length === 0) return null;
  if (axis == null || axis.length === 0) return null;

  return (
    <>
      {axis.map((s, axisIndex) => {
        const path = concepts
          .map((_, i) => {
            const angle = getAngle(i, concepts.length);
            const r = (radius * (axisIndex + 1)) / axis.length;

            const x = r * Math.cos(angle) + inset + radius;
            const y = r * Math.sin(angle) + inset + radius;
            return `${i === 0 ? 'M' : 'L'} ${x} ${y}`;
          })
          .join(' ');

        if (!path) return null;

        return <path key={s} d={path + 'Z'} className={styles.axis} />;
      })}
    </>
  );
};

interface BrandAreaProps extends React.HTMLProps<HTMLDivElement> {
  paths?: Path[];
  onClickPoint?: (p: Point) => void;
}

const BrandArea: React.FC<BrandAreaProps> = ({ paths }) => {
  if (paths == null) return null;
  return (
    <>
      {paths.map((p, i) => {
        const path = p.points
          ? p.points.map((p, i) => `${i === 0 ? 'M' : 'L'} ${p.x} ${p.y}`).join(' ') + 'Z'
          : undefined;
        const style = { '--color': p.color } as CSSProperties;
        return <path d={path} style={style} key={i} className={styles.path} />;
      })}
    </>
  );
};

const BrandPoints: React.FC<BrandAreaProps> = React.memo(({ paths, onClickPoint }) => {
  return (
    <>
      {paths?.map((pObj) => {
        if (pObj == null) return null;

        return pObj?.points?.map((point, i) => {
          const style = { '--color': pObj.color } as CSSProperties;
          return (
            <Tooltip
              label={<CircleTooltip data={point} />}
              background="#fff"
              key={i}
              boxShadow="0px 2px 4px rgba(0,0,0,0.2)"
              padding="0">
              <circle
                cx={point.x}
                cy={point.y}
                r={5}
                style={style}
                className={styles.point}
                onClick={
                  onClickPoint
                    ? () => {
                        onClickPoint(point);
                      }
                    : undefined
                }
              />
            </Tooltip>
          );
        });
      })}
    </>
  );
});

interface CircleTooltipProps {
  data: Point;
}

const CircleTooltip: React.FC<CircleTooltipProps> = ({ data }) => {
  let isPosString = null;
  if (data.score > 50) isPosString = <strong className={styles.positive}>positive</strong>;
  else isPosString = <strong className={styles.negative}>positive</strong>;

  const style = { '--color': data.color } as CSSProperties;
  return (
    <div className={styles.circleTooltip} style={style}>
      <BrandLogo size={32} brand={data} />
      <div className={styles.tooltipText}>
        <strong>{data.name}</strong>
        <p>
          <strong>{data.count}</strong> reviews contain keywords that likely indicate{' '}
          {data.conceptName}.
        </p>
        <p>
          <strong>{data.score}%</strong> of these are {isPosString}.
        </p>
      </div>
    </div>
  );
};
