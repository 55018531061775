import clsx from 'clsx';
import React from 'react';
import type { SCButtonProps } from '../Button/Button';
import type { InputSize } from '../Input/types';
import styles from './Button.module.scss';

interface SegmentedControlProps<TID> extends Omit<React.HTMLProps<HTMLDivElement>, 'size'> {
  defaultId: TID;
  onChangeSegment?: (id: TID) => void;
  size?: InputSize;
}

export function SegmentedControl<TID>({
  children,
  className,
  defaultId,
  onChangeSegment,
  size,
}: SegmentedControlProps<TID>) {
  const onChangeActiveId = (id: TID) => {
    onChangeSegment?.(id);
  };

  return (
    <div className={clsx(styles.group, size == 'small' ? styles.small : undefined, className)}>
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement<SCButtonProps<TID>>(child)) {
          return (
            <>
              {index != 0 && <div className={styles.groupDivider} />}
              {React.cloneElement(child, { activeUid: defaultId, onChangeActiveId, size })}
            </>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
}
