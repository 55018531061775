import React from 'react';
import UtfString from 'utfstring';
import styles from './review.module.scss';

export interface MachineTooltipModel {
  reviewId?: string;
  startOffset?: string;
  endOffset?: string;
}

interface MachineTooltipProps {
  id: string;
  line: string;
  tooltip?: MachineTooltipModel;
  offset: number;
}

export const MachineTooltip: React.FC<MachineTooltipProps> = React.memo(
  ({ id, line, tooltip, offset }) => {
    if (tooltip == null) return null;
    if (tooltip.reviewId !== id) return null;
    if (line == null) return null;

    if (tooltip.reviewId && tooltip.startOffset != null && tooltip.endOffset != null) {
      const start = Number.parseInt(tooltip.startOffset) - (offset || 0);
      const end = Number.parseInt(tooltip.endOffset) - (offset || 0);
      const maxEnd = UtfString.length(line) + (offset || 0);

      const correctedStart = Math.max(0, start);
      const correctedEnd = Math.max(Math.min(end, maxEnd), 0);

      const bLine = UtfString.slice(line, 0, correctedStart);
      const cLine = UtfString.slice(line, correctedStart, correctedEnd);
      const eLine = UtfString.slice(line, correctedEnd);

      return (
        <div className={styles.machineOverlay}>
          {bLine != null && UtfString.length(bLine) > 0 && <span>{bLine}</span>}
          {cLine != null && UtfString.length(cLine) > 0 && <span>{cLine}</span>}
          {eLine != null && UtfString.length(eLine) > 0 && <span>{eLine}</span>}
        </div>
      );
    } else {
      return <div className={styles.machineOverlay}>{line}</div>;
    }
  },
);

export interface MachineTooltipModel2 {
  reviewId?: string;
  startOffset?: number;
  endOffset?: number;
}

interface MachineTooltip2Props {
  id: string;
  line: string;
  tooltip?: MachineTooltipModel2;
  precedingLines: number;
  index: number;
  linesLen: number;
}

export const MachineTooltip2: React.FC<MachineTooltip2Props> = React.memo(
  ({ id, index, line, tooltip, linesLen, precedingLines }) => {
    if (tooltip == null) return null;
    if (tooltip.reviewId !== id) return null;
    if (line == null) return null;

    if (tooltip.reviewId && tooltip.startOffset != null && tooltip.endOffset != null) {
      let start;
      let end;
      let maxEnd;
      if (index === 0) {
        start = tooltip.startOffset;
        end = tooltip.endOffset;
        maxEnd = precedingLines;
      } else {
        start = tooltip.startOffset - precedingLines + line.length - index;
        end = tooltip.endOffset - precedingLines + line.length - index;
        maxEnd = precedingLines;
      }

      const correctedStart = Math.max(0, start);
      const correctedEnd = Math.max(Math.min(end, maxEnd), 0);

      const bLine = UtfString.slice(line, 0, correctedStart);
      const cLine = UtfString.slice(line, correctedStart, correctedEnd);
      const eLine = UtfString.slice(line, correctedEnd);
      return (
        <div className={styles.machineOverlay}>
          {bLine != null && UtfString.length(bLine) > 0 && <span>{bLine}</span>}
          {cLine != null && UtfString.length(cLine) > 0 && (
            <span className={styles.selected}>{cLine}</span>
          )}
          {eLine != null && UtfString.length(eLine) > 0 && <span>{eLine}</span>}
        </div>
      );
    } else {
      return <div className={styles.machineOverlay}>{line}</div>;
    }
  },
);
