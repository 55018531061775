import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUpdateDefaultTeamId } from 'src/trpcHooks/useUserMutation';
import { SvrTeam, SvrUser } from 'src/utils/trpc';
import { extractAchronym, isNotNull, isStringNotEmpty } from 'src/utils2';
import { UserAndTeam } from '../../Route';
import { Badge } from '../Badge/Badge';
import { IconWithPlaceholder } from '../IconWithPlaceholder/IconWithPlaceholder';
import { Cell, CellHeader, CellLoading, Table } from '../Table/Table';
import { UserIcons } from '../UserIcons/UserIcons';
import { WaveSpinner } from '../WaveSpinner';
import styles from './TeamsTable.module.scss';

interface TeamsTableProps extends UserAndTeam {
  teams?: SvrTeam['team'][];
  isLoading?: boolean;
  userById?: Record<string, { teams: Record<string, SvrUser> }>;
}
export const TeamsTable: React.FC<TeamsTableProps> = ({
  teams,
  team,
  user,
  isLoading,
  userById,
}) => {
  const [isUpdatingId, setIsUpdatingId] = React.useState<string>();
  const meUpdateMutation = useUpdateDefaultTeamId();
  const navigate = useNavigate();

  const onClick = async (defaultTeamId: string) => {
    if (defaultTeamId !== user.defaultTeamId?.toHexString()) {
      setIsUpdatingId(defaultTeamId);
      await meUpdateMutation.mutateAsync({ id: defaultTeamId });
    }
    setIsUpdatingId(undefined);
  };

  const onRedirect = (team: SvrTeam['team']) => {
    const teamWithStringId = { ...team, _id: team._id.toHexString() };
    navigate(`/teams/active`, { state: { user: user, team: teamWithStringId._id } });
  };

  return (
    <Table gridTemplateColumns="1fr min-content min-content" bordered>
      <CellHeader>Name</CellHeader>
      <CellHeader />
      <CellHeader align="right">Users</CellHeader>

      {isLoading && (
        <>
          <TeamRow
            user={user}
            team={team?.team}
            userById={userById}
            index={0}
            isUpdatingId={isUpdatingId}
          />
          <CellLoading index={1}>Loading Teams</CellLoading>
        </>
      )}
      {teams?.map((team, index) => (
        <TeamRow
          key={team._id.toHexString()}
          user={user}
          team={team}
          userById={userById}
          index={index}
          isUpdatingId={isUpdatingId}
          onClick={() => {
            onClick(team._id.toHexString());
            onRedirect(team);
          }}
        />
      ))}
    </Table>
  );
};

interface TeamRowProps extends React.HTMLProps<HTMLDivElement> {
  user: SvrUser;
  team?: SvrTeam['team'];
  userById?: Record<string, { teams: Record<string, SvrUser> }>;
  index: number;
  isUpdatingId?: string;
}
const TeamRow: React.FC<TeamRowProps> = ({
  team,
  user,
  index,
  userById,
  onClick,
  isUpdatingId,
  ...extraProps
}) => {
  const teamId = team?._id.toHexString();

  const active = user.defaultTeamId?.toHexString() === teamId;
  const users = team?.userIds
    ?.map((id) => (teamId ? userById?.[id]?.teams?.[teamId] : undefined))
    .filter(isNotNull);
  const props = { active, index, onClick, ...extraProps };

  const isUpdating = isUpdatingId === teamId;
  return (
    <React.Fragment key={teamId}>
      <Cell {...props}>
        {isUpdating && (
          <div className={styles.loading}>
            <WaveSpinner />
          </div>
        )}
        {!isUpdating && (
          <IconWithPlaceholder
            size={16}
            alt="team"
            placeholder={extractAchronym([team?.name])}
            urls={[].filter(isStringNotEmpty)} //TODO:DELETE
            // urls={[team?.logo?.url].filter(isStringNotEmpty)}
            shape="circle"
          />
        )}
        {team?.name}
      </Cell>
      <Cell {...props} align="right">
        {team?.adminId === user._id && <Badge dot>Admin</Badge>}
      </Cell>
      <Cell {...props} align="right">
        {users && <UserIcons max={5} users={users} />}
      </Cell>
    </React.Fragment>
  );
};
