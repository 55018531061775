import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { createPortal } from 'react-dom';
import { TrashCan } from 'src/PoseidonComponents/StrokeIcons';
import usePortal from 'src/libs/usePortal';
import { Button } from '../Button/Button';

import styles from './Popup.module.scss';

const PopupPortal: React.FC<React.HTMLProps<HTMLDivElement>> = ({ id, children }) => {
  const target = usePortal(id || 'poseidon-modal-portal');
  return createPortal(children, target);
};

export interface PopupProps extends React.HTMLProps<HTMLDivElement> {
  onClose: () => void;
}

export const Popup: React.FC<PopupProps> = ({ onClose, children }) => {
  React.useEffect(() => {
    document.body.classList.add('popupShown');
    return () => {
      document.body.classList.remove('popupShown');
    };
  }, []);

  React.useEffect(() => {
    const closeFn = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    window.addEventListener('keydown', closeFn);
    return () => window.removeEventListener('keydown', closeFn);
  }, [onClose]);
  return (
    <PopupPortal>
      <div className={styles.container}>
        <AnimatePresence>
          <motion.div
            className={styles.background}
            key="background"
            onClick={onClose}
            initial={{ opacity: 0.3 }}
            animate={{ opacity: 1 }}
          />
          <motion.div
            key="content"
            className={styles.content}
            initial={{ scale: 0.99, y: '20px' }}
            animate={{ scale: 1, y: 0 }}>
            {children}
          </motion.div>
        </AnimatePresence>
      </div>
    </PopupPortal>
  );
};

interface DeletePopupProps extends PopupProps {
  onDelete: () => void;
}
export const DeletePopup: React.FC<DeletePopupProps> = ({ onClose, onDelete }) => {
  return (
    <Popup onClose={onClose}>
      <h2>Are you sure?</h2>
      <p>This action can not be undone</p>
      <Button icon={<TrashCan />} type="dangerous" onClick={onDelete}>
        Yes, Delete it
      </Button>
    </Popup>
  );
};
