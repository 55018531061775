import React, { CSSProperties } from 'react';
import { Button } from '../../PoseidonComponents/Button/Button';
import { getFadedHighlightRangeColor, getHighlightRangeColor, getHoverHighlightRangeColor, getRangeColor2 } from '../../utils2';
import styles from './MachineDetailRating.module.scss';

interface RatingProps extends React.HTMLProps<HTMLButtonElement> {
  word?: string;
  shortcut?: string;
  score?: number;
  isSelected?: boolean
}

export const MachineDetailRating: React.FC<RatingProps> = ({ word, score, isSelected, onClick }) => {
  const style = {
    '--background': score != null ? isSelected ? getRangeColor2(score) : getHighlightRangeColor(score) : undefined,
    '--hover-background': score != null ? getHoverHighlightRangeColor(score) : undefined,
    '--color': score != null ? isSelected ? "#fff" : getRangeColor2(score) : undefined,
  } as CSSProperties;

  return (
    <Button style={style} onClick={onClick} className={styles.container}>
      {word}
    </Button>
  );
};
