import React from 'react';
import { Button } from 'src/PoseidonComponents/Button/Button';
import { Select } from 'src/PoseidonComponents/Input/Select';
import { SubmitButton } from '../../PoseidonComponents/Button/CustomButton';
import { ContentSection } from '../../PoseidonComponents/ContentSection/ContentSection';
import { Flex, Left } from '../../PoseidonComponents/Layout/Layout';
import * as Brands from '../../components/icons/brands';
import { useReportsContext } from '../../contexts';
import { useDeleteIntegrationMutation } from '../../trpcHooks/useDeleteIntegrationMutation';
import { SvrReport, SvrReportApp } from '../../utils/trpc';
import { isNotNull } from '../../utils2';
import { IntegrationRow } from '../IntegrationRow/IntegrationRow';
import { ContentDivider } from '../../PoseidonComponents/ContentDivider/ContentDivider';

interface AppleIntegrationProps {
  report: SvrReport;
}
export const AppleIntegration: React.FC<AppleIntegrationProps> = ({ report }) => {
  const apps = report.apps?.filter((app) => app.appProvider === 'apple');
  if (apps == null || apps.length === 0) {
    return <AppleIntegrationNoApps />;
  }

  return <AppleIntegrationWithApps reportId={report._id.toHexString()} apps={apps} />;
};

const AppleIntegrationNoApps = () => {
  return (
    <ContentSection>
      <ContentDivider size='small' icon={<Brands.Apple />}>Apple</ContentDivider>
      <div>Please add Apple apps to your report</div>
    </ContentSection>
  );
};

interface AppleIntegrationNoAppsProps {
  reportId: string;
  apps: SvrReportApp[];
}
const AppleIntegrationWithApps: React.FC<AppleIntegrationNoAppsProps> = ({ reportId, apps }) => {
  const [, { addAppleIntegration }] = useReportsContext();
  const [selectedAppId, setSelectedAppId] = React.useState<string>();
  const selectedAppleApp = apps?.find((app) => app.metaId.toHexString() === selectedAppId);
  const deleteIntegrationMutation = useDeleteIntegrationMutation();

  const submitApple = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectedAppId) {
      await addAppleIntegration(reportId, selectedAppId, {
        addedAccount: true,
        type: 'account',
      });
      setSelectedAppId(undefined);
    }
  };

  const selectFiltes = [
    { value: '', label: `(select Apple app)` },
    ...apps.map((app) => {
      if (app.metaId == null || app.title == null) return undefined;
      return { value: app.metaId.toHexString(), label: app.title, icon: <img src={app.iconUrl} /> };
    }),
  ].filter(isNotNull);

  return (
    <ContentSection size="small">
      <ContentDivider size='small' icon={<Brands.Apple />}>Apple</ContentDivider>
      <p>
        Visit <strong>Apple App Store Connect</strong> and add{' '}
        <strong>appstore@harmonize.ai</strong> as a user
      </p>

      {apps.map((app) => {
        if (app.integration == null) return null;
        const metaId = app.metaId.toHexString();
        return (
          <IntegrationRow
            key={app.metaId.toHexString()}
            app={app}
            deleteIntegration={() => deleteIntegrationMutation.mutate({ reportId, metaId })}
          />
        );
      })}
      <Left>
        <Select
          placeholder="select Apple app"
          value={selectedAppId ?? ''}
          options={selectFiltes}
          onChange={(e) => setSelectedAppId(e.value)}
        />
      </Left>
      {selectedAppleApp != null && (
        <form onSubmit={submitApple}>
          <Flex>
            <Button onClick={() => setSelectedAppId(undefined)}>cancel</Button>
            <SubmitButton>Add Integration</SubmitButton>
          </Flex>
        </form>
      )}
    </ContentSection>
  );
};
