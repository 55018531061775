import 'array.prototype.flatmap';
import escapeStringRegexp from 'escape-string-regexp';
import React, { CSSProperties } from 'react';

const isFunction = (functionToCheck: any) => functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
const isScalar = (children: any) => (/string|number|boolean/).test(typeof children);
const getSearch = (search: any, caseSensitive?: any) => {
  if(search instanceof RegExp) return search;
  if(search == null || search.length === 0) return null;

  let flags = '';
  if (!caseSensitive) flags +='i';

  if (typeof search === 'string') return new RegExp(escapeStringRegexp(search), flags);
  else return new RegExp(search, flags);
}
const convertChild = (child: any, key: any) => {
  if(isScalar(child)) return <span key={key}>{child}</span>;
  else return React.cloneElement(child, {key})
}
const getMatchBoundaries = (subject: any, search: any, useMatchGroup: any) => {
  const matches = search.exec(subject);
  let length = 0;
  for(var i = 1; i < useMatchGroup; i++) {
    length = length + matches[i].length;
  }
  if (matches) return {first: matches.index + length, last: matches.index + length + matches[useMatchGroup].length};
  else return null;
}

export interface Search {
  word: RegExp;
  matchClass: string | undefined;
}

interface HighlighterProps extends React.HTMLProps<HTMLDivElement> {
  search: any[] | any,
  caseSensitive?: boolean,
  matchElement?: (s: any) => string,
  matchClass: string | ( (s: any) => string),
  matchStyle: CSSProperties | ( (s: any) => CSSProperties),
  matchElementProps?: any,
  searchTransform?: any,
  useMatchGroup?: any
}

const Highlighter: React.FC<HighlighterProps> = ({search, children, caseSensitive, matchElement= () => 'strong', matchClass='highlight', matchStyle={}, matchElementProps={}, searchTransform, useMatchGroup}) => {
  
  const matchClassFn = (search: any) => {
    if(typeof matchClass === 'function') return matchClass(search)
    else return matchClass
  }
  const matchStyleFn = (search: any) => {
    if(typeof matchStyle === 'function') return matchStyle(search)
    else return matchStyle
  }
  const searchTransformFn = (search: any) => {
    if(searchTransform) return searchTransform(search);
    else return search;
  }
  const highlightChildrens = (children: any, search: any, caseSensitive: boolean | undefined) =>
    React.Children.toArray(children).flatMap(child => highlightChildren(child, search, caseSensitive))

  const highlightChildren = (subject: any, _search: any, caseSensitive: boolean | undefined) => {
    let children = [];
    let remaining = subject;
    const search = getSearch(searchTransformFn(_search), caseSensitive);
    if(search == null) return subject;

    while (remaining) {
      if(typeof remaining !== 'string') {
        children.push(remaining);
        return children;
      }
      if(useMatchGroup >= 0) {
        const match = remaining.match(search);
        if((match && match[useMatchGroup]) == null) {
          children.push(remaining);
          return children;
        }
      } else {
        if(!search.test(remaining)) {
          children.push(remaining);
          return children;
        }
      }

      const boundaries = getMatchBoundaries(remaining, search, _search.useMatchGroup || useMatchGroup || 0);

      // Capture the string that leads up to a match...
      const nonMatch = remaining.slice(0, boundaries?.first);
      if (nonMatch) {
        children.push(nonMatch);
      }

      // Now, capture the matching string...
      const match = remaining.slice(boundaries?.first, boundaries?.last);
      if (match) {
        const matchClass = matchClassFn(_search);
        const matchStyle = matchStyleFn(_search);

        const Tag = matchElement(_search)
        let _matchElementProps = null;
        if( typeof(matchElementProps) === 'function' ) {
          _matchElementProps = matchElementProps(_search);
        } else {
          _matchElementProps = matchElementProps;
        }
        children.push(<Tag className={matchClass} style={matchStyle} {..._matchElementProps}>{match}</Tag>)
      }

      // And if there's anything left over, recursively run this method again.
      remaining = remaining.slice(boundaries?.last);
    }
    return children;
  }

  if(isScalar(children)) {
    if(Array.isArray(search)) {
      return search.reduce((acc, s) => highlightChildrens(acc, s, caseSensitive), children)
                   .map(convertChild)
    } else {
      return highlightChildrens(children, search, caseSensitive).map(convertChild)
    }
  } else return children
}

export default Highlighter