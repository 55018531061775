import React from 'react';
import { ColumnsGrid } from 'src/PoseidonComponents/Grid/Grid';
import { Tooltip } from 'src/libs/Tooltip';
import { Button } from '../../PoseidonComponents/Button/Button';
import { ContentDivider } from '../../PoseidonComponents/ContentDivider/ContentDivider';
import { ContentSection } from '../../PoseidonComponents/ContentSection/ContentSection';
import { XMarkCircle } from '../../PoseidonComponents/StrokeIcons';
import { Table } from '../../PoseidonComponents/Table/Table';
import { WaveSpinner } from '../../PoseidonComponents/WaveSpinner';
import { Source, sources } from '../../sources';
import { useAddAppMutation } from '../../trpcHooks/useReportAppMutation';
import { SvrReportBrand, ServerSearchApp, trpc } from '../../utils/trpc';
import { SearchAppRow } from '../AppRow/appRow';
import BrandSource from '../BrandSource/BrandSource';
import Search from '../search/search';
import SourceFileUpload from '../sourceFileUpload/sourceFileUpload';
import SourceUrlUpload from '../sourceUrlUpload/sourceUrlUpload';
import styles from './draftReportSections.module.scss';

interface DraftReportsSectionsAddingSourceProps {
  reportId: string;
  brand: SvrReportBrand;
  createSourceFromFile: (brandId: string, sourceName: string, file: File, provider: string) => void;
  onClose: () => void;
}

export const DraftReportsSectionsAddingSource: React.FC<DraftReportsSectionsAddingSourceProps> = ({
  brand,
  reportId,
  onClose,
  createSourceFromFile,
}) => {
  const [activeBrandSource, setActiveBrandSource] = React.useState<Source>();
  const isCsv = activeBrandSource?.provider?.startsWith('csv');
  if (activeBrandSource) {
    if (
      activeBrandSource.searchable &&
      (activeBrandSource.provider === 'apple' || activeBrandSource.provider === 'google')
    ) {
      return (
        <DraftReportsSearchableSource
          reportId={reportId}
          brand={brand}
          provider={activeBrandSource.provider}
          onClose={onClose}
        />
      );
    } else if (isCsv) {
      return (
        <SourceFileUpload
          brandId={brand._id.toHexString()}
          brandName={brand.name}
          createSourceFromFile={createSourceFromFile}
          provider={activeBrandSource.provider}
          onClose={onClose}
          // csvErrors={csvErrors}
        />
      );
    } else if (activeBrandSource?.viaUrl) {
      return (
        <SourceUrlUpload
          reportId={reportId}
          brand={brand}
          provider={activeBrandSource.provider}
          closeFn={() => onClose()}
        />
      );
    } else {
      return null;
    }
  } else {
    return <BrandSourcesSection selectSource={setActiveBrandSource} onClose={onClose} />;
  }
};

interface DraftReportsSearchableSourceProps {
  reportId: string;
  brand: SvrReportBrand;
  provider: 'apple' | 'google';

  onClose: () => void;
}
const DraftReportsSearchableSource: React.FC<DraftReportsSearchableSourceProps> = ({
  reportId,
  brand,
  provider,
  onClose,
}) => {
  const addAppMutation = useAddAppMutation();
  const [text, setText] = React.useState(brand.name);

  const providerSearchQuery = trpc.providerSearch.search.useQuery(
    { text: text || '', provider },
    { enabled: Boolean(text) },
  );

  const onAddApp = async (app: ServerSearchApp) => {
    const data = {
      brandId: brand._id,
      appProvider: app.appProvider,
      appId: app.appId,
      title: app.title,
      iconUrl: app.iconUrl,
      score: app.score,
      locale: app.locale,
      description: app.description,
    };

    await addAppMutation.mutateAsync({ reportId, data });
    onClose();
  };

  const onCloseButton = () => {
    onClose();
  };
  return (
    <div>
      <Search
        placeholder={`+ Add ${provider} source`}
        value={brand.name}
        autoFocus
        className={styles.searchInput}
        onChange={setText}
      />
      {providerSearchQuery.isLoading && (
        <div>
          <WaveSpinner />
        </div>
      )}

      <Table gridTemplateColumns="max-content max-content 1fr max-content" bordered size="small">
        {providerSearchQuery.data?.map((app, index) => {
          return (
            <SearchAppRow
              index={index}
              key={[app.appId, app.appProvider].join('.')}
              app={app}
              addApp={onAddApp}
              provider={provider}
              color="yellow"
            />
          );
        })}
      </Table>
      <div className={styles.navigationBottom}>
        <Button onClick={onCloseButton}>Cancel</Button>
      </div>
    </div>
  );
};

interface BrandSourcesSectionProps {
  selectSource: (source?: Source) => void;
  onClose: () => void;
}

const BrandSourcesSection: React.FC<BrandSourcesSectionProps> = ({ selectSource, onClose }) => {
  return (
    <ContentSection>
      <ContentDivider
        end={
          <Button icon={<XMarkCircle />} size="small" onClick={onClose}>
            Cancel
          </Button>
        }>
        Pick a data source
      </ContentDivider>
      <ColumnsGrid gap={20} columns={3} columnMinWidth={250}>
        {sources.map((b) => {
          const style = b.style as React.CSSProperties;
          if (b.hidden) return null;
          else if (b.premium) {
            return (
              <Tooltip label="Premium feature" key={b?.provider} autoPlace={false}>
                <BrandSource name={b?.name} icon={b.icon} style={style} />
              </Tooltip>
            );
          } else
            return (
              <BrandSource
                name={b.name}
                icon={b.icon}
                style={style}
                key={b.provider}
                onClick={() => selectSource(b)}
              />
            );
        })}
      </ColumnsGrid>
    </ContentSection>
  );
};
