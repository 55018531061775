import React from 'react';
import useRouterQuery from '../hooks/useRouterQuery';
import { isNotEmpty } from '../utils2';
import { AddBadge, Badge } from './Badge/Badge';
import { Flex } from './Layout/Layout';
interface TopicSectionProps {
  topics?: string[];
  updateTopic: (searches?: string[]) => void;
}

export const TopicSection: React.FC<TopicSectionProps> = React.memo(({ topics, updateTopic }) => {
  const [query, setQuery] = useRouterQuery();
  const addReportFilterFn = () => {
    if (query.q) {
      updateTopic([...(topics || []), query.q]);
    }
  };
  const onDelete = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, topic: string) => {
    e.preventDefault();
    e.stopPropagation();
    updateTopic((topics || []).filter((t) => t !== topic));
  };

  const toggleSearch = (v: string) => {
    setQuery((o) => ({ ...o, page: undefined, q: query.q === v ? undefined : v }));
  };

  return (
    <Flex>
      {topics?.map((s) => (
        <Badge
          key={s}
          type={s === query.q ? 'primary' : undefined}
          onClick={() => toggleSearch(s)}
          onDelete={(e) => onDelete(e, s)}>
          {s}
        </Badge>
      ))}
      {isNotEmpty(query.q) && (topics?.indexOf(query.q) ?? -1) < 0 && (
        <AddBadge onClick={addReportFilterFn}>{query.q}</AddBadge>
      )}
    </Flex>
  );
});

export default TopicSection;
