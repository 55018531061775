import { trpc } from '../utils/trpc';

export const useReportDeleteMutation = () => {
  const utils = trpc.useContext();
  return trpc.report.delete.useMutation({
    onSuccess: (_, id) => {
      utils.report.id.invalidate(id);
      utils.report.all.invalidate();
    },
  });
};

export const useReportCreateMutation = () => {
  const utils = trpc.useContext();

  return trpc.report.create.useMutation({
    onSuccess: () => {
      utils.report.all.invalidate();
    },
  });
};



export const useReportSortBrands = () => {
  const utils = trpc.useContext();

  return trpc.report.sortBrands.useMutation({
    onSuccess: (_, reportId) => {
      utils.report.id.invalidate(reportId)
    },
  })
};



export const useReportUpdateMutation = () => {
  const utils = trpc.useContext();

  return trpc.report.update.useMutation({
    onMutate: ({ id, data }) => {
      utils.report.id.setData(id, (oldData) => {
        if (!data) return;
        if (!oldData) return;
        return { ...oldData, brandGroups: data.brandGroups };
      });
    },
    onSuccess: (_, { id }) => {
      utils.report.id.invalidate(id);
      utils.report.all.invalidate();
    },
  });
};
