import clsx from 'clsx';
import { Link, LinkProps } from 'react-router-dom';
import React, { CSSProperties, type ReactNode } from 'react';
import type { InputSize } from '../Input/types';
import { Tooltip } from 'src/libs/Tooltip';
import styles from './Button.module.scss';

type ButtonType = 'primary' | 'dangerous' | 'submit' | 'submitPrimary';

interface ButtonProps extends Omit<React.HTMLProps<HTMLButtonElement>, 'size'> {
  icon?: JSX.Element;
  type?: ButtonType;
  tooltip?: string;
  size?: InputSize;
}

interface LinkButtonProps extends Omit<LinkProps, 'size'> {
  icon?: JSX.Element;
  type?: ButtonType;
  className?: string;
  children?: ReactNode;
  target?: string;
  tooltip?: string;
  size?: InputSize;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ icon, children, type, className, tooltip, onClick, disabled, size, ...restProps }, ref) => {
    const isSubmitButton = type === 'submit' || type === 'submitPrimary';
    const classList = clsx(
      styles.button,
      size == 'small' ? styles.small : undefined,
      type ? styles[type] : undefined,
      type === 'submitPrimary' ? styles.primary : undefined,
      (onClick || isSubmitButton) && !disabled ? styles.hoverable : styles.disabled,
      className,
    );

    if (tooltip) {
      return (
        <Tooltip label={tooltip} background={type === 'dangerous' ? 'hsl(0, 67%, 45%)' : undefined}>
          <button
            className={classList}
            onClick={onClick}
            type={isSubmitButton ? 'submit' : 'button'}
            ref={ref}
            disabled={disabled}
            {...restProps}
          >
            {icon != null && <div className={styles.icon}>{icon}</div>}
            {children != null && <span>{children}</span>}
          </button>
        </Tooltip>
      );
    }

    return (
      <button
        className={classList}
        onClick={onClick}
        type={isSubmitButton ? 'submit' : 'button'}
        ref={ref}
        disabled={disabled}
        {...restProps}
      >
        {icon != null && <div className={styles.icon}>{icon}</div>}
        {children != null && <span>{children}</span>}
      </button>
    );
  },
);
Button.displayName = 'Button';

export const LinkButton = React.forwardRef<any, LinkButtonProps>(
  ({ icon, children, type, className, to, target, size, tooltip }, ref) => {
    const classList = clsx(
      styles.button,
      size == 'small' ? styles.small : undefined,
      type ? styles[type] : undefined,
      styles.hoverable,
      className,
    );

    if (tooltip) {
      return (
        <Tooltip label={tooltip}>
          <Link className={classList} to={to} target={target} ref={ref}>
            {icon != null && <div className={styles.icon}>{icon}</div>}
            {children != null && <span>{children}</span>}
          </Link>
        </Tooltip>
      );
    }

    return (
      <Link className={classList} to={to} target={target} ref={ref}>
        {icon != null && <div className={styles.icon}>{icon}</div>}
        {children != null && <span>{children}</span>}
      </Link>
    );
  },
);
LinkButton.displayName = 'LinkButton';

export interface SCButtonProps<TID> extends Omit<React.HTMLProps<HTMLDivElement>, 'size'> {
  icon?: JSX.Element;
  uid: TID;
  activeUid?: TID;
  onChangeActiveId?: (id: TID) => void;
  children?: ReactNode;
  dot?: 'error' | 'success' | 'neutral' | 'warning';
  size?: InputSize;
}

export function SCButton<TID>({
  icon,
  activeUid,
  uid,
  children,
  className,
  onChangeActiveId,
  size,
  dot,
}: SCButtonProps<TID>) {
  const isActive = uid === activeUid;
  const classList = clsx(
    className,
    size == 'small' ? styles.small : undefined,
    isActive ? styles.active : undefined,
    styles.button,
    styles.hoverable,
  );
  return (
    <div className={classList} onClick={() => onChangeActiveId?.(uid)}>
      {dot != null && <div className={clsx(styles.dot, styles[`${dot}Dot`])} />}
      {icon != null && <span className={styles.icon}>{icon}</span>}
      {children != null && <span>{children}</span>}
    </div>
  );
}

interface ButtonsGroupProps extends Omit<React.HTMLProps<HTMLDivElement>, 'size'> {
  size?: InputSize;
}
export const ButtonsGroup: React.FC<ButtonsGroupProps> = ({ className, size, children }) => {
  return (
    <div className={clsx(styles.group, size == 'small' ? styles.small : undefined, className)}>
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement<ButtonProps>(child)) {
          return (
            <>
              {index != 0 && <div className={styles.groupDivider} />}
              {React.cloneElement(child, { size })}
            </>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};
