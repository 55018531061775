import clsx from 'clsx';
import React, { CSSProperties } from 'react';
import { starColor } from '../../utils2';
import { Star } from '../StrokeIcons';
import styles from './ShortStars.module.scss';

interface ShortStarsProps extends React.HTMLProps<HTMLDivElement> {
  value?: number;
  toFixed?: number;
}

export const ShortStars: React.FC<ShortStarsProps> = ({ value, toFixed = 0, className }) => {
  const showStars = value != null;

  const style = {
    '--star-rating': showStars ? starColor(Number.parseInt(value.toFixed())) : undefined,
  } as CSSProperties;

  return (
    <div className={clsx(className, styles.container)} style={style}>
      {!showStars && 'N/A'}
      {showStars && (
        <>
          <Star />
          {value.toFixed(toFixed)}
        </>
      )}
    </div>
  );
};
