import React from 'react';

type ClientRect = Record<keyof Omit<DOMRect, 'toJSON'>, number>;

function roundValues(_rect: ClientRect) {
  const rect = {
    ..._rect,
  };
  for (const key of Object.keys(rect)) {
    // @ts-ignore
    rect[key] = Math.round(rect[key]);
  }
  return rect;
}

function shallowDiff(prev: any, next: any) {
  if (prev != null && next != null) {
    for (const key of Object.keys(next)) {
      if (prev[key] != next[key]) {
        return true;
      }
    }
  } else if (prev != next) {
    return true;
  }
  return false;
}

type TextSelectionState = {
  clientRect?: ClientRect;
  isCollapsed?: boolean;
  textContent?: string;
  startOffset?: number;
  endOffset?: number;
  selectReviewId?: string;
};

const defaultState: TextSelectionState = {};

/**
 * useTextSelection(ref)
 *
 * @description
 * hook to get information about the current text selection
 *
 */
export function useTextSelection(target?: HTMLElement) {
  const [
    { clientRect, isCollapsed, textContent, startOffset, endOffset, selectReviewId },
    setState,
  ] = React.useState<TextSelectionState>(defaultState);

  const reset = React.useCallback(() => {
    setState(defaultState);
  }, []);

  const onMouseUp = React.useCallback(() => {
    const selection = window.getSelection();
    const selectedText = selection?.toString();
    if (selection == null || !selection.rangeCount || selectedText === null || selectedText?.length === 0) {
      setTimeout(() => {
        setState(defaultState);
      }, 0)
      return;
    }

    const range = selection.getRangeAt(0);
    const startContainer = range.startContainer.parentNode;
    const endContainer = range.endContainer.parentNode;
    const contents = range.cloneContents();
    const rects = range.getClientRects();
    const commonAncestor = range.commonAncestorContainer as HTMLElement;
    const review = findAncestor(commonAncestor, 'review');
    const reviewBody = findAncestor(commonAncestor, 'reviewBody');
    const selectReviewId = review?.id;

    let clientRect: ClientRect | undefined;

    if (rects.length === 0 && range.commonAncestorContainer != null) {
      clientRect = roundValues(commonAncestor.getBoundingClientRect().toJSON());
    } else {
      if (rects.length < 1) return;
      clientRect = roundValues(rects[0]?.toJSON());
    }

    if (review && reviewBody && startContainer && endContainer) {
      const lines = reviewBody.children;
      let offset = 0;
      let startOffset = 0;
      let endOffset = 0;

      for (var i = 0; i < lines.length; i++) {
        const lineEl = lines[i];
        const lineChildren =
          lineEl?.firstChild instanceof HTMLElement ? lineEl?.firstChild.children : [];

        for (var j = 0; j < lineChildren.length; j++) {
          const el = lineChildren[j];
          if (el == null) {
            continue;
          }
          if (el === startContainer) {
            startOffset = offset + range.startOffset;
          }
          if (el === endContainer) {
            endOffset = offset + range.endOffset;
            break;
          }
          offset += el.innerHTML.length;
        }

        offset += 1;
      }

      setState({
        clientRect,
        startOffset,
        endOffset,
        selectReviewId,
        textContent: contents.textContent || undefined,
      });
    } else {
      setState({
        clientRect: undefined,
        startOffset: range.startOffset,
        endOffset: range.endOffset,
        selectReviewId,
        textContent: contents.textContent || undefined,
      });
    }
  }, []);

  React.useLayoutEffect(() => {
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [target]);

  return {
    clientRect,
    isCollapsed,
    textContent,
    startOffset,
    endOffset,
    selectReviewId,
    reset,
  };
}

export const findAncestor = (el: HTMLElement | null, aria: string): HTMLElement | undefined => {
  if (el == null) return el ?? undefined;
  if (el instanceof Text) return findAncestor(el.parentElement, aria);
  if (el.getAttribute?.('aria-label') === aria) return el;

  return findAncestor(el.parentElement, aria);
};
