import React, { useState } from 'react';


type ContextProps = {
  amplitudeUserId?: string,
  amplitudeInstance: {
    options: {
      deviceId: string | undefined
    }
  }
}

export const AmplitudeContext = React.createContext<ContextProps>({
  amplitudeInstance: {
    options: {
      deviceId: undefined
    }
  }
});

interface AmplitudeProviderProps extends React.HTMLProps<HTMLDivElement> {
  amplitudeInstance: any,
  apiKey?: string,
  userId?: string
}

const AmplitudeProvider: React.FC<AmplitudeProviderProps> = ({ apiKey, userId, children }) => {
  const [amplitudeUserId, setAmplitudeUserId] = useState(null)

  // useEffect(() => {
  //   if (isValidAmplitudeInstance(amplitudeInstance)) {
  //     if (apiKey) {
  //       const v = amplitude.init(apiKey)

  //       v.promise.then( v => {
  //         amplitude.getDeviceId()
  //       })

  //       amplitudeInstance.init(apiKey, null, null, (instance: any) => {
  //         setAmplitudeUserId(instance.options.deviceId);
  //       });
  //     }
  //     if (userId) amplitudeInstance.setUserId(userId);
  //   } else {
  //     console.error('AmplitudeProvider was not provided with a valid "amplitudeInstance" prop.');
  //   }
  // }, [amplitudeInstance, apiKey, userId]);

  // if(amplitudeUserId != null) {
  //   return <AmplitudeContext.Provider value={{amplitudeUserId, amplitudeInstance}}>
  //     {children}
  //   </AmplitudeContext.Provider>
  // }
  
  return <AmplitudeContext.Provider value={{
    amplitudeInstance: {
      options: {
        deviceId: undefined
      }
    }
  }}>
    {children}
  </AmplitudeContext.Provider>
}

export default AmplitudeProvider;