import React from 'react';

export const WaveSpinner: React.FC<React.SVGAttributes<SVGElement>> = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg" width="23" height="23">
      <g transform="matrix(1,0,0,1,0,0)">
        <g id="10925854" opacity="1" style={{ mixBlendMode: 'normal' }}>
          <g>
            <defs>
              <clipPath id="10925854_clipPath" x="-50%" y="-50%" width="200%" height="200%">
                <path d="M0,23v-23h23v23z" fill="white" clipRule="nonzero"></path>
              </clipPath>
            </defs>
            <g clipPath="url(#10925854_clipPath)">
              <g transform="matrix(1,0,0,1,3.08,2)">
                <g id="10925855" opacity="1" style={{ mixBlendMode: 'normal' }}>
                  <g transform="matrix(1,0,0,1,0,0)">
                    <g id="I109258555152248321" opacity="1" style={{ mixBlendMode: 'normal' }}>
                      <g transform="matrix(1,0,0,1,-9,19.3275)">
                        <g id="I109258555152248322" opacity="0" style={{ mixBlendMode: 'normal' }}>
                          <g style={{ mixBlendMode: 'normal' }}>
                            <defs>
                              <linearGradient
                                id="lgrajakeyq2ikluvppq"
                                x1="7.867861488838831"
                                y1="-0.02918628659331223"
                                x2="-1.1269128046104644"
                                y2="12.351056612418382"
                                gradientUnits="userSpaceOnUse">
                                <stop
                                  offset="0%"
                                  stopOpacity="1"
                                  stopColor="rgb(49, 158, 244)"></stop>
                                <stop
                                  offset="100%"
                                  stopOpacity="1"
                                  stopColor="rgb(18, 90, 202)"></stop>
                              </linearGradient>
                            </defs>
                            <path
                              id="I109258555152248322_fill_path"
                              d="M7.88255,0l2.61304,4.10325l-4.93109,8.69891c-0.05792,0.10213 -0.1662,0.1654 -0.28364,0.16565l-4.9529,0.00972c-0.25697,0.00056 -0.41396,-0.28207 -0.27775,-0.49998z"
                              fillRule="evenodd"
                              fill="url(#lgrajakeyq2ikluvppq)"></path>
                          </g>
                        </g>
                      </g>
                      <g transform="matrix(1,0,0,1,13.43,17.33)">
                        <g
                          id="I109258555152248323"
                          opacity="0"
                          style={{ mixBlendMode: 'multiply' }}>
                          <g style={{ mixBlendMode: 'multiply' }}>
                            <defs>
                              <linearGradient
                                id="lgrajakguo7iqqbggmr"
                                x1="0.215513103624278"
                                y1="1.6240652230302568"
                                x2="9.257358113450794"
                                y2="14.069076628643925"
                                gradientUnits="userSpaceOnUse">
                                <stop
                                  offset="0%"
                                  stopOpacity="0.8999999761581421"
                                  stopColor="rgb(104, 50, 174)"></stop>
                                <stop
                                  offset="100%"
                                  stopOpacity="0.8999999761581421"
                                  stopColor="rgb(52, 15, 100)"></stop>
                              </linearGradient>
                            </defs>
                            <path
                              id="I109258555152248323_fill_path"
                              d="M2.45246,0l-2.45246,3.95614l4.88728,8.84295c0.05741,0.1039 0.16661,0.16853 0.28536,0.16877l4.98931,0.00964c0.25806,0.00048 0.41487,-0.28432 0.27645,-0.50207z"
                              fillRule="evenodd"
                              fill="url(#lgrajakguo7iqqbggmr)"></path>
                          </g>
                        </g>
                      </g>
                      <g transform="matrix(1,0,0,1,5.01,0)">
                        <g
                          id="I109258555152248324"
                          opacity="1"
                          style={{ mixBlendMode: 'multiply' }}>
                          <g style={{ mixBlendMode: 'multiply' }}>
                            <defs>
                              <linearGradient
                                id="lgrajakickfsg1ldm89"
                                x1="-1.2114125491622416"
                                y1="-1.346403522229509"
                                x2="4.56176567200585"
                                y2="7.592677693659446"
                                gradientUnits="userSpaceOnUse">
                                <stop
                                  offset="0%"
                                  stopOpacity="0.8999999761581421"
                                  stopColor="rgb(165, 115, 230)"></stop>
                                <stop
                                  offset="100%"
                                  stopOpacity="0.8999999761581421"
                                  stopColor="rgb(120, 65, 191)"></stop>
                              </linearGradient>
                            </defs>
                            <path
                              id="I109258555152248324_fill_path"
                              d="M5.86458,2.94427c0,1.62607 -1.31283,2.94427 -2.93229,2.94427c-1.61946,0 -2.93229,-1.31819 -2.93229,-2.94427c0,-1.62607 1.31283,-2.94427 2.93229,-2.94427c1.61946,0 2.93229,1.31819 2.93229,2.94427z"
                              fillRule="nonzero"
                              fill="url(#lgrajakickfsg1ldm89)"></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <animateTransform
        href="#I109258555152248322"
        attributeName="transform"
        type="translate"
        values="0 0;9 -12.977500000000001;9 -12.977500000000001"
        dur="2.19s"
        repeatCount="indefinite"
        calcMode="spline"
        keyTimes="0;0.37;1"
        keySplines="0.5 0.35 0.15 1;0.5 0.35 0.15 1"
        additive="sum"
        fill="freeze"></animateTransform>
      <animate
        href="#I109258555152248322"
        attributeName="opacity"
        values="0;1;1"
        dur="2.19s"
        repeatCount="indefinite"
        calcMode="spline"
        keyTimes="0;0.37;1"
        keySplines="0.5 0.35 0.15 1;0.5 0.35 0.15 1"
        additive="sum"
        fill="freeze"></animate>
      <animateTransform
        href="#I109258555152248323"
        attributeName="transform"
        type="translate"
        values="0 0;0 0;-7.999999999999999 -10.977500000000001;-7.999999999999999 -10.977500000000001"
        dur="2.19s"
        repeatCount="indefinite"
        calcMode="spline"
        keyTimes="0;0.31;0.68;1"
        keySplines="0.5 0.35 0.15 1;0.5 0.35 0.15 1;0.5 0.35 0.15 1"
        additive="sum"
        fill="freeze"></animateTransform>
      <animate
        href="#I109258555152248323"
        attributeName="opacity"
        values="0;0;1;1"
        dur="2.19s"
        repeatCount="indefinite"
        calcMode="spline"
        keyTimes="0;0.31;0.68;1"
        keySplines="0.5 0.35 0.15 1;0.5 0.35 0.15 1;0.5 0.35 0.15 1"
        additive="sum"
        fill="freeze"></animate>
      <animateTransform
        href="#I109258555152248324"
        attributeName="transform"
        type="translate"
        values="0 0;0 0;0 -5.8885000000000005;0 0;0 -1.9432;0 0;0 0"
        dur="2.19s"
        repeatCount="indefinite"
        calcMode="spline"
        keyTimes="0;0.67;0.78;0.89;0.96;1;1"
        keySplines="0.65 0 1 1;0.65 0 0.35 1;0.65 0 1 1;0 0 0.35 1;0.65 0 0.35 1;0 0 0.35 1"
        additive="sum"
        fill="freeze"></animateTransform>
    </svg>
  );
};
