import React from 'react';
import useRouterQuery from '../../hooks/useRouterQuery';
import { getRangeColor2, sentimentWords } from '../../utils2';
import styles from './RatingsSection.module.scss';
import { Row } from './Row';

interface SentimentRatingSectionProps {
  sentiments?: Record<string, number>;
}
export const SentimentRatingSection: React.FC<SentimentRatingSectionProps> = ({ sentiments }) => {
  const [search, setSearch] = useRouterQuery();

  const toggleSearch = (v: '5' | '4' | '3' | '2' | '1') => {
    setSearch((s) => ({ ...s, sRating: s.sRating === v ? undefined : v, page: undefined }));
  };

  // in the incoming data the sentiment buckets are orders lowest
  // to highest, starting at index 0 to index 4
  // const sentiments = [ 5, 4, 3, 2, 1 ] as const
  const counts = [5, 4, 3, 2, 1] as const;

  const dataCount = Object.values(sentiments || {}).reduce((a, v) => a + v, 0);

  return (
    <div className={styles.ratings}>
      {counts?.map((s, i) => {
        const color = getRangeColor2(s * 20);
        const value = sentiments?.[s] ?? 0;

        // what percentage of all the values are in this sentiment bucket
        const percentageOfTotal = dataCount > 0 ? (100 * value) / dataCount : 0;

        const style = {
          gridRowStart: i + 1,
        } as React.CSSProperties;

        return (
          <Row
            key={s}
            index={i}
            name={sentimentWords[s] || ''}
            style={style}
            count={value}
            color={color}
            rating={percentageOfTotal}
            selected={search?.sRating === `${s}`}
            tooltip={<div>Click to see {sentimentWords[s]} Reviews</div>}
            onClick={() => toggleSearch(`${s}`)}
          />
        );
      })}
    </div>
  );
};

export default SentimentRatingSection;
