import React from 'react';
import { BrandLogo, IconWithPlaceholder } from '../IconWithPlaceholder/IconWithPlaceholder';
import styles from './BrandIcons.module.scss';
import { Tooltip } from '../../libs/Tooltip';

interface BrandIconsProps {
  max: number;
  brands: { name?: string; imageUrl?: string | null }[];
}
const SIZE = 24;
const SHAPE = 'circle';

export const BrandIcons: React.FC<BrandIconsProps> = ({ brands, max }) => {
  const remainingLength = brands.length - max;
  return (
    <div className={styles.container}>
      {brands.slice(0, max).map((brand, index) => {
        return (
          <Tooltip label={brand.name} key={index}>
            <BrandLogo size={SIZE} brand={brand} shape={SHAPE} className={styles.icon} />
          </Tooltip>
        );
      })}
      {remainingLength > 0 && (
        <IconWithPlaceholder
          size={SIZE}
          alt="profile"
          placeholder={`+${remainingLength}`}
          shape={SHAPE}
          className={styles.icon}
        />
      )}
    </div>
  );
};
