import React, { createContext } from 'react'
import toast from 'react-hot-toast'
import { AmplitudeContext } from '../libs/react-amplitude'
import { useAuthContext } from './auth.context'
import { ContextPropsState, initialState, reducer } from './reports.reducer'
import { ApiUrlPath, Fetch, HttpError } from './utils'

type ContextPropsActions = {
  createSourceFromFile: (reportId: string, brandId: string, title: string, file: any, appProvider: string) => Promise<any>,
  processReport: (reportId: string) => void,
  analyzeReport: (reportId: string) => void,
  fetchStatus: (reportId: string) => void,
  addAppleIntegration: (reportId: string, metaId: string, body: any) => void,
  addAndroidSvcAccountIntegration: (reportId: string, metaId: string, file: any) => void,
  reset: () => void,
}

const initialActions: ContextPropsActions = {
  createSourceFromFile: () => { throw new Error("createSourceFromFile not implemented") },
  processReport: () => { throw new Error("processReport not implemented") },
  analyzeReport: () => { throw new Error("analyzeReport not implemented") },
  fetchStatus: () => { throw new Error("fetchStatus not implemented") },
  addAppleIntegration: () => { throw new Error("addAppleIntegration not implemented") },
  addAndroidSvcAccountIntegration: () => { throw new Error("addAndroidSvcAccountIntegration not implemented") },
  reset: () => { throw new Error("reset not implemented") },  
}

type ContextProps = [ContextPropsState, ContextPropsActions]

export const ReportsContext = createContext<ContextProps>([initialState, initialActions]);

export const ReportsProvider: React.FC<React.HTMLProps<HTMLDivElement>> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { me, user } = useAuthContext();
  const { amplitudeInstance } = React.useContext(AmplitudeContext);
  const isMeAdmin = me?.isSuperAdmin;
  const amplitudeInstanceDeviceId = amplitudeInstance.options.deviceId

  const createSourceFromFile = React.useCallback( async (reportId: string, brandId: string, title: string, file: any, appProvider: string) => {
    if (reportId == null) return;
    const formData = new FormData();
    formData.append('title', title);
    formData.append('appProvider', appProvider);
    formData.append('reviewsCSV', file);

    const relativeDate = parseDateFromFileName(file.name)
    if(relativeDate) {
      formData.append('relativeDate', "" + relativeDate.valueOf());
    }
    
    try {
      // @TODO: add type, cast to any for now to zip up type checker
      const data: any = await Fetch.Post(
        user,
        brandId ? `/api/v2/reports/${reportId}/brands/${brandId}/csvSource` : `/api/v2/reports/${reportId}/csvSource`,
        formData,
        amplitudeInstanceDeviceId
      )
      dispatch({ type: 'UPDATE_REPORT', ...data })
      return Promise.resolve(data)
    } catch(error: any) {
      return Promise.reject(error)
    }
    
  }, [amplitudeInstanceDeviceId, user])


  const processReport = React.useCallback( async (reportId: string) => {
    if (reportId == null) return;
    try {
      await Fetch.Post(user, `/api/v2/reports/${reportId}/process`, undefined, amplitudeInstanceDeviceId)
      toast.success("started fetching sources")
    } catch (e) { 
      const error = e as HttpError
      const errorBody = error?.body
      toast.error(errorBody.message || "could not scrape and analyze reviews")
     }
  }, [amplitudeInstanceDeviceId, user, isMeAdmin])

  const analyzeReport = React.useCallback( async (reportId: string) => {
    if (reportId == null) return;
    try {
      await Fetch.Post(user, `/api/v2/reports/${reportId}/analyze`, undefined, amplitudeInstanceDeviceId)
      toast.success("started analyzing reviews")
    } catch (e) { 
      const error = e as HttpError
      const errorBody = error?.body
      toast.error(errorBody.message || "could not analyze reviews")
    }
  }, [amplitudeInstanceDeviceId, user])

  const fetchStatus = React.useCallback( async (reportId: string) => {
    try {
      const url: ApiUrlPath = `/api/v2/reports/${reportId}/status`;

      // @TODO: add type, cast to any for now to zip up type checker
      const data: any = await Fetch.Get(user, url, undefined, amplitudeInstanceDeviceId)
      dispatch({ type: 'FETCH_REPORT_STATUS', ...data, reportId })
      return data;
    } catch (error) { console.error('fetchStatus', error) }
  }, [amplitudeInstanceDeviceId, user])

  const addAppleIntegration = React.useCallback( async (reportId: string, metaId: string, body: any) => {
    try {
      const url: ApiUrlPath = `/api/v2/reports/${reportId}/apps/${metaId}/integration`;

      // @TODO: add type, cast to any for now to zip up type checker
      const data: any = await Fetch.Post(user, url, body, amplitudeInstanceDeviceId)
      dispatch({ type: 'UPDATE_REPORT', ...data })
      return data;
    } catch (error: any) { 
      if(error instanceof HttpError) {
        throw new HttpError(error.message, error.body)
      }
      throw new Error(error)
     }
  }, [amplitudeInstanceDeviceId, user])

  const addAndroidSvcAccountIntegration = React.useCallback( async (reportId: string, metaId: string, file: any) => {
    try {
      const url: ApiUrlPath = `/api/v2/reports/${reportId}/apps/${metaId}/integrationSvcAcc`;

      const formData = new FormData();
      formData.append('svcAccount', file);

      // @TODO: add type, cast to any for now to zip up type checker
      const data: any = await Fetch.Post(user, url, formData, amplitudeInstanceDeviceId)
      dispatch({ type: 'UPDATE_REPORT', ...data })
      return data;
    } catch (error: any) {
      if(error instanceof HttpError) {
        throw new HttpError(error.message, error.body)
      }
      throw new Error(error)
    }
  }, [amplitudeInstanceDeviceId, user])

  const reset = React.useCallback( () => dispatch({ type: 'RESET' }), [])

  const value: ContextProps = [
    state,
    {
      createSourceFromFile,
      processReport,
      analyzeReport,
      fetchStatus,
      addAppleIntegration,
      addAndroidSvcAccountIntegration,
      reset,
    }
  ]

  return <ReportsContext.Provider value={value}>
    {children}
  </ReportsContext.Provider>
}

export const useReportsContext = () => React.useContext(ReportsContext)



const parseDateFromFileName = (fileName?: string) => {
  if(fileName == null) return undefined

  const regex = /(\d{4}-\d{2}-\d{2})\.[^\.]+$/;
  const match = fileName.match(regex)
  const dateString = match?.[1]
  if(dateString == null) return undefined
  return new Date(dateString)
}