import React from 'react';
import Description from '../components/description/description';
import { ExtrasSection } from '../components/detail/detail';
import DetailInfoQuadrant from '../components/detailInfoQuadrant/detailInfoQuadrant';
import { ServerDataCounts, trpc } from '../utils/trpc';
import { ContentDivider } from './ContentDivider/ContentDivider';
import { ContentSection } from './ContentSection/ContentSection';
import { DetailHeader } from './DetailHeader/DetailHeader';
import {
  DetailHeaderBrandContent,
  DetailHeaderContentLoading,
} from './DetailHeaderContent/DetailHeaderContent';
import { Images } from './Images/Images';
import { PaddedScrollable } from './PaddedScrollable/PaddedScrollable';
import { AlignLeft, Image } from './StrokeIcons';

interface ReviewDetailPageProps extends React.HTMLProps<HTMLDivElement> {
  reportId: string;
  id: string;
  reportCounts?: ServerDataCounts;
}

export const ReviewDetailPage: React.FC<ReviewDetailPageProps> = ({
  id,
  reportId,
  reportCounts,
}) => {
  const reviewBrandQuery = trpc.reportReviews.idWithBrand.useQuery({ reportId, reviewId: id });
  const brand = reviewBrandQuery.data?.brand;
  const review = reviewBrandQuery.data?.review;
  const brandId = brand?._id.toHexString();
  const brandApps = brand?.apps;
  const firstApp = brandApps?.[0];
  const brandCounts = brandId ? reportCounts?.brands?.[brandId] : undefined;

  return (
    <>
      <DetailHeader queryKey="rId">
        {brand != null && <DetailHeaderBrandContent brand={brand} />}
        {brand == null && <DetailHeaderContentLoading />}
      </DetailHeader>
      <PaddedScrollable size="small">
        {brandCounts && (
          <ContentSection size="small">
            <DetailInfoQuadrant stringType="Brands" counts={brandCounts} />
          </ContentSection>
        )}

        <ContentSection size="small">
          <ContentDivider icon={<Image />}>Images</ContentDivider>
          {firstApp?.screenshotUrls && firstApp.screenshotUrls.length > 0 && (
            <Images images={firstApp.screenshotUrls} />
          )}
        </ContentSection>

        <ContentSection size="small">
          <ContentDivider icon={<AlignLeft />}>Description</ContentDivider>
          {firstApp?.description && <Description description={firstApp.description} />}
        </ContentSection>

        {review?.extras && (
          <ContentSection size="small">
            <ExtrasSection extras={review?.extras} />
          </ContentSection>
        )}
      </PaddedScrollable>
    </>
  );
};
