import React from 'react';
import { AuthProvider } from './auth.context';
import { ReportsProvider } from './reports.context';
export { AuthProvider, useAuthContext } from './auth.context';
export { ReportsProvider, useReportsContext } from './reports.context';

export const Providers: React.FC<React.HTMLProps<HTMLDivElement>> = ({ children }) => {
  return (
    <AuthProvider>
      <ReportsProvider>{children}</ReportsProvider>
    </AuthProvider>
  );
};
