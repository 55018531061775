import React from 'react';
import { WaveSpinner } from '../WaveSpinner';
import styles from './WaveSpinnerContainer.module.scss';

export const FullPageWaveSpinnerContainer = () => {
  return (
    <div className={styles.container}>
      <WaveSpinner className={styles.spinner} />
    </div>
  );
};

export const WaveSpinnerContainer = () => {
  return (
    <div className={styles.centerContainer}>
      <WaveSpinner />
    </div>
  );
};
