import { AspectType, trpc } from 'src/utils/trpc';

export const useUpdateConceptName = () => {
  const utils = trpc.useContext();

  return trpc.reportCategories.update.useMutation({
    onMutate: ({ categoryId, newVals, reportId }) => {
      utils.report.conceptById.setData({ reportId, id: categoryId }, (data) => {
        if (data == null) return undefined;

        return {
          ...data,
          name: newVals.name,
        };
      });
    },
    onSuccess: ({ reportId }) => {
      const id = reportId?.toHexString();

      utils.report.conceptById.invalidate();
      utils.reportCategories.list.invalidate({ reportId: id });
    },
  });
};
