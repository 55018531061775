import clsx from 'clsx';
import React from 'react';
import styles from './Layout.module.scss';

interface WithLeftNav extends React.HTMLProps<HTMLDivElement> {
  hasDetail: boolean;
}
export const WithLeftNav: React.FC<WithLeftNav> = ({ children, hasDetail }) => {
  return (
    <div className={clsx(styles.withLeftNav, hasDetail ? styles.hasDetail : undefined)}>
      {children}
    </div>
  );
};

export const WithFilters: React.FC<React.HTMLProps<HTMLDivElement>> = ({ children }) => {
  return <div className={styles.withFilters}>{children}</div>;
};

export const Right: React.FC<React.HTMLProps<HTMLDivElement>> = ({ children }) => (
  <div className={styles.right}>{children}</div>
);

export const Left: React.FC<React.HTMLProps<HTMLDivElement>> = ({ children }) => (
  <div className={styles.left}>{children}</div>
);

interface FlexProps extends React.HTMLProps<HTMLDivElement> {
  gap?: string;
  grow?: number
  shrink?: number;
}
export const Flex: React.FC<FlexProps> = ({ children, className, gap = 5, grow, shrink=1 }) => (
  <div className={clsx(styles.flex, className)} style={{ gap, flexGrow: grow, flexShrink: shrink }}>
    {children}
  </div>
);

export const FilterContainer: React.FC<React.HTMLProps<HTMLDivElement>> = ({ children }) => (
  <div className={styles.filterContainer}>{children}</div>
);

export const Spacer = () => <div className={styles.spacer} />;
