import { useLocalStorage } from '@rehooks/local-storage';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { LinkProps, NavLink, useLocation, useMatch, useResolvedPath } from 'react-router-dom';
import { Kbd } from 'src/PoseidonComponents/Kbd/Kbd';
import { SvgIconButton } from 'src/PoseidonComponents/SvgIconButton/SvgIconButton';
import { UserLogoutRow } from 'src/PoseidonComponents/UserLogoutRow/UserLogoutRow';
import { useKey } from 'src/hooks/useKey';
import { Tooltip } from 'src/libs/Tooltip';
import { LogoIcon, LogoText } from 'src/toneIcons';
import { isMacLike } from 'src/utils2';
import { BrandLogo } from '../../PoseidonComponents/IconWithPlaceholder/IconWithPlaceholder';
import {
  Atom,
  Charts,
  Comment,
  CompareMatrix1,
  DoubleChevron,
  Intersect,
  KeyAdmin,
  LeftArrow,
  Lightbulb,
  Pencil,
  Workspaces,
} from '../../PoseidonComponents/StrokeIcons';
import { SvrReport, SvrTeam, SvrUser } from '../../utils/trpc';
import styles from './LeftNav.module.scss';
import { UserAndTeam } from '../../Route';

interface RowProps extends LinkProps {
  icon: JSX.Element;
  children?: React.ReactNode;
  className?: string;
  minimized?: boolean;
}

const Row: React.FC<RowProps> = ({ to, icon, className, children, minimized }) => {
  const location = useLocation();
  let resolved = useResolvedPath(to);

  let match = useMatch({ path: resolved.pathname, end: true });

  const classList = clsx(
    className,
    styles.row,
    styles.hoverable,
    location.pathname === match?.pathname ? styles.active : undefined,
    location.pathname.startsWith(to.toString()) ? styles.partialActive : undefined,
  );

  return (
    <Tooltip label={minimized ? children : undefined}>
      <NavLink to={to} className={classList}>
        <span className={styles.rowIcon}>{icon}</span>
        <span className={styles.rowText}>{children}</span>
      </NavLink>
    </Tooltip>
  );
};

export const SpacerRow = () => {
  return <div className={clsx(styles.row, styles.spacerRow)} />;
};

interface ReportsLeftNavProps extends React.HTMLProps<HTMLDivElement>, UserAndTeam {}

export const ReportsLeftNav: React.FC<ReportsLeftNavProps> = ({ team, user }) => {
  const [navMinimized, setNavMinimized] = useLocalStorage('navMinimized', false);
  useKey('b', () => setNavMinimized(!navMinimized), { metaWindowsCtrlKey: true });

  return (
    <AnimatePresence>
      <motion.div
        className={clsx(styles.container, navMinimized ? styles.minimized : styles.maximized)}
        initial={{ opacity: 0.2, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ type: 'spring' }}>
        <div className={styles.header}>
          <LogoRow navMinimized={navMinimized} setNavMinimized={setNavMinimized} />
        </div>

        <div className={styles.links}>
          <Row to="/drafts" icon={<Pencil />} minimized={navMinimized}>
            Drafts
          </Row>
          <Row to="/reports" icon={<Workspaces />} minimized={navMinimized}>
            Workspaces
          </Row>
          {user.isSuperAdmin && (
            <Row icon={<Lightbulb />} minimized={navMinimized} to="/concepts">
              Concepts
            </Row>
          )}
          {user.isSuperAdmin && (
            <Row icon={<Intersect />} minimized={navMinimized} to="/concept-groups">
              Concept Groups
            </Row>
          )}
        </div>
        <div className={styles.footer}>
          <UserLogoutRow user={user} team={team} />
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

interface WorkspaceLeftNavProps extends React.HTMLProps<HTMLDivElement>, UserAndTeam {
  report?: SvrReport;
}

export const ReportLeftNav: React.FC<WorkspaceLeftNavProps> = ({ report, user, team }) => {
  const [navMinimized, setNavMinimized] = useLocalStorage('navMinimized', false);
  useKey('b', () => setNavMinimized(!navMinimized), { metaWindowsCtrlKey: true });

  const showReportNav = report?.status !== 'draft';
  const showDashbaordCharts = (report?.flourishIds?.length ?? 0) > 0;

  return (
    <AnimatePresence>
      <motion.div
        className={clsx(styles.container, navMinimized ? styles.minimized : styles.maximized)}
        initial={{ opacity: 0.2, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ type: 'spring' }}>
        <div className={styles.header}>
          <LogoRow navMinimized={navMinimized} setNavMinimized={setNavMinimized} />
        </div>
        <div className={styles.links}>
          {report && (
            <Row
              icon={<LeftArrow />}
              minimized={navMinimized}
              to={
                report.status === 'draft'
                  ? `/draft/${report._id.toHexString()}`
                  : `/report/${report._id.toHexString()}`
              }>
              Back to Workspaces
            </Row>
          )}
          {showReportNav && report && (
            <>
              <Row icon={<KeyAdmin />} minimized={navMinimized} to="admin">
                Admin
              </Row>
              {showDashbaordCharts && (
                <Row icon={<Charts />} minimized={navMinimized} to="dashboard">
                  Dashboard
                </Row>
              )}
              <Row icon={<Atom />} minimized={navMinimized} to="./">
                Insights
              </Row>
              <Row icon={<Comment />} minimized={navMinimized} to="comments">
                Comments
              </Row>
              <Row icon={<Lightbulb />} minimized={navMinimized} to="key-takeaways">
                Key Takeaways
              </Row>
              <Row icon={<CompareMatrix1 />} minimized={navMinimized} to="brands">
                Compare: Matrix
              </Row>
              {report.brands?.map((brand) => {
                return (
                  <Row
                    icon={<BrandLogo brand={brand} size={24} shape="circle" />}
                    to={`brands/${brand._id.toHexString()}`}
                    key={brand._id.toHexString()}>
                    {brand.name || 'Brand'}
                  </Row>
                );
              })}
            </>
          )}
        </div>
        <div className={styles.footer}>
          <UserLogoutRow user={user} team={team} />
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

interface LogoRowProps {
  navMinimized: boolean;
  setNavMinimized: (newValue: boolean | null) => void;
}
const LogoRow: React.FC<LogoRowProps> = ({ navMinimized, setNavMinimized }) => {
  return (
    <div className={styles.logo}>
      <span className={styles.logoIcon}>
        <LogoIcon />
      </span>
      <span className={styles.logoText}>
        <LogoText />
      </span>
      <SvgIconButton
        icon={<DoubleChevron />}
        className={styles.minimizer}
        onClick={() => setNavMinimized(!navMinimized)}
        tooltip={
          navMinimized ? (
            <span>
              Expand <Kbd>{isMacLike() ? '⌘' : 'ctrl'}+B</Kbd>
            </span>
          ) : (
            <span>
              Minimize <Kbd>{isMacLike() ? '⌘' : 'ctrl'}+B</Kbd>
            </span>
          )
        }
      />
    </div>
  );
};
