import useLocalStorage from '@rehooks/local-storage';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ConceptReviewDetailPage } from 'src/PoseidonComponents/ConceptReviewDetailPage';
import { Breadcrumbs } from '../../PoseidonComponents/Breadcrumbs/Breadcrumbs';
import { ContentDivider } from '../../PoseidonComponents/ContentDivider/ContentDivider';
import Filters from '../../PoseidonComponents/Filters/Filters';
import { BrandLogo } from '../../PoseidonComponents/IconWithPlaceholder/IconWithPlaceholder';
import { Flex, WithFilters, WithLeftNav } from '../../PoseidonComponents/Layout/Layout';
import { PageHeader } from '../../PoseidonComponents/PageHeader/PageHeader';
import { ReportFilters } from '../../PoseidonComponents/ReportFilters';
import { ReportProviderPicker } from '../../PoseidonComponents/ReportProviderPicker/ReportProviderPicker';
import { FullPageWaveSpinnerContainer } from '../../PoseidonComponents/WaveSpinnerContainer/WaveSpinnerContainer';
import Reviews from '../../components/reviews/reviews2';
import { VersionAppHistory } from '../../components/versionHistory/versionHistory';
import useRouterQuery from '../../hooks/useRouterQuery';
import { IIntervalBucket } from '../../models/intervalBucket.entity';
import {
  ReportCategory,
  ReviewExtraColFromList,
  ReviewExtraColSectionFromList,
  ServerBrand,
  ServerDataCounts,
  SvrReport,
  SvrReportApp,
  SvrUser,
  trpc,
} from '../../utils/trpc';
import { DateTypeValue, convertDateRangeToDates } from '../../utils2';
import { UserAndTeam } from '../../Route';
import { DayRange } from '@hassanmojab/react-modern-calendar-datepicker';

interface ReportBrandAppContainerProps extends UserAndTeam {
  report: SvrReport;
  conceptsById: Record<string, ReportCategory>;
  dateType?: string;
  dateValue?: number;
  intervalBucket: IIntervalBucket;
  reportConcepts?: ReportCategory[];
  extraCols: ReviewExtraColFromList[] | undefined;
  extraColsSections: ReviewExtraColSectionFromList[] | undefined;
  dataCounts?: ServerDataCounts;
}

export const ReportBrandAppContainer: React.FC<ReportBrandAppContainerProps> = ({
  report,
  intervalBucket,
  reportConcepts,
  conceptsById,

  extraCols,
  extraColsSections,
  dataCounts,
  user,
}) => {
  const reportId = report._id.toString();
  const { brandId, metaId } = useParams();
  const [query] = useRouterQuery();

  const reportBrandQuery = trpc.reportBrand.id.useQuery(
    { brandId: brandId || '', reportId },
    { enabled: Boolean(brandId) },
  );
  const brand = reportBrandQuery.data;
  const app = report.apps?.find((app) => app.metaId.toString() === metaId);

  const hasDetail =
    query.pinnedConcept != null ||
    query.rId != null ||
    query.category != null ||
    query.addConcept != null;

  if (brand == null || app == null) {
    return (
      <WithLeftNav hasDetail={hasDetail}>
        <PageHeader icon={<BrandLogo brand={brand} shape="circle" size={24} />} title={'???'}>
          <ContentDivider end={<ReportFilters />} />
        </PageHeader>
        <Flex gap="0" grow={1}>
          <FullPageWaveSpinnerContainer />
        </Flex>
      </WithLeftNav>
    );
  }

  return (
    <WithBrandAndApp
      brand={brand}
      app={app}
      report={report}
      conceptsById={conceptsById}
      intervalBucket={intervalBucket}
      reportConcepts={reportConcepts}
      extraCols={extraCols}
      extraColsSections={extraColsSections}
      dataCounts={dataCounts}
      user={user}
    />
  );
};

interface WithBrandAndAppProps {
  report: SvrReport;
  conceptsById: Record<string, ReportCategory>;
  intervalBucket: IIntervalBucket;
  reportConcepts?: ReportCategory[];
  extraCols: ReviewExtraColFromList[] | undefined;
  extraColsSections: ReviewExtraColSectionFromList[] | undefined;
  dataCounts?: ServerDataCounts;
  brand: ServerBrand;
  app: SvrReportApp;
  user: SvrUser;
}
const WithBrandAndApp: React.FC<WithBrandAndAppProps> = ({
  brand,
  app,
  report,
  conceptsById,
  intervalBucket,
  reportConcepts,
  extraCols,
  extraColsSections,
  dataCounts,
  user,
}) => {
  const reportId = report._id.toHexString();
  const appId = app.metaId.toHexString();
  const [query] = useRouterQuery();

  const versionHistoryIntervalType = intervalBucket?.type ?? 'month';

  const [dateTypeValueV2] = useLocalStorage<DayRange>('dateTypeValueV2');

  const dateRange = React.useMemo(
    () =>
      !dateTypeValueV2?.from || !dateTypeValueV2?.to
        ? undefined
        : {
            start: new Date(
              dateTypeValueV2.from?.year,
              dateTypeValueV2.from.month - 1,
              dateTypeValueV2.from.day,
            ),
            end: new Date(
              dateTypeValueV2.to.year,
              dateTypeValueV2.to.month - 1,
              dateTypeValueV2.to.day,
            ),
          },
    [dateTypeValueV2],
  );

  const reportReviewsQuery = trpc.reportReviews.forApp.useInfiniteQuery(
    {
      reportId,
      brandId: brand._id.toHexString(),
      appId,
      query: query.q ? { must: [query.q] } : undefined,
      filters: {
        rating: query.rating ? Number.parseFloat(query.rating) : undefined,
        sRating: query.sRating ? Number.parseFloat(query.sRating) : undefined,
        conceptAspect: query.conceptAspect ? Number.parseFloat(query.conceptAspect) : undefined,
        category: query.category,
      },
      dateRange,
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      initialCursor: query.page,
    },
  );
  const firstPage = reportReviewsQuery.data?.pages?.[0];
  const firstReviewId = firstPage?.reviews?.[0]?._id.toHexString();

  const hasDetail =
    query.pinnedConcept != null ||
    query.rId != null ||
    query.category != null ||
    query.addConcept != null ||
    query.machine != null;

  return (
    <WithLeftNav hasDetail={hasDetail}>
      <PageHeader
        icon={<BrandLogo brand={brand} shape="circle" size={24} />}
        title={brand?.name ?? 'Brand'}>
        <ContentDivider end={<ReportFilters firstReviewId={firstReviewId} />}>
          <Breadcrumbs showStartingChevron>
            <ReportProviderPicker
              reportId={reportId}
              brand={brand}
              appId={app.metaId.toHexString()}
            />
          </Breadcrumbs>
        </ContentDivider>
      </PageHeader>

      <Flex gap="0" grow={1}>
        {app && (
          <Filters
            report={report}
            reportCategories={reportConcepts}
            app={app}
            extraCols={extraCols}
            extraColsSections={extraColsSections}
            appIdsFilter={new Set<string>(appId ? [appId] : [])}
            sentiments={firstPage?.sentiments}
            ratings={firstPage?.ratings}
            categories={firstPage?.categories}
          />
        )}

        <WithFilters>
          {app && query.section == 'versionHistory' && (
            <VersionAppHistory
              intervalType={versionHistoryIntervalType}
              categoryId={query.category}
              reportId={reportId}
              metaId={appId}
            />
          )}
          {query.section != 'versionHistory' && (
            <Reviews
              report={report}
              isLoading={reportReviewsQuery.isLoading}
              reviews={reportReviewsQuery.data?.pages}
              conceptsById={conceptsById}
              fetchNextPage={
                reportReviewsQuery.hasNextPage ? reportReviewsQuery.fetchNextPage : undefined
              }
              fetchPreviousPage={
                reportReviewsQuery.hasPreviousPage
                  ? reportReviewsQuery.fetchPreviousPage
                  : undefined
              }
              isFetchingNextPage={reportReviewsQuery.isFetchingNextPage}
              isFetchingPreviousPage={reportReviewsQuery.isFetchingPreviousPage}
            />
          )}
        </WithFilters>
      </Flex>

      {hasDetail && (
        <ConceptReviewDetailPage
          report={report}
          user={user}
          dataCounts={dataCounts}
          conceptsById={conceptsById}
        />
      )}
    </WithLeftNav>
  );
};
