import React from 'react';
import { InputSize } from '../Input/types';
import { WaveSpinner } from '../WaveSpinner';
import { Button } from './Button';
import styles from './Button.module.scss';

interface CreateButtonProps extends React.HTMLProps<HTMLButtonElement> {
  isLoading: boolean;
  icon?: JSX.Element;
}
export const CreateButton: React.FC<CreateButtonProps> = ({ isLoading, icon, disabled }) => {
  if (isLoading)
    return (
      <div>
        <Button icon={<WaveSpinner />}>Creating...</Button>
      </div>
    );
  else
    return (
      <div>
        <Button icon={icon} disabled={disabled} type="submitPrimary">
          Create
        </Button>
      </div>
    );
};

export const AddButton: React.FC<CreateButtonProps> = ({ isLoading, disabled, icon }) => {
  if (isLoading)
    return (
      <div>
        <Button icon={<WaveSpinner />}>Adding...</Button>
      </div>
    );
  else
    return (
      <div>
        <Button icon={icon} type="submitPrimary" disabled={disabled}>
          Add
        </Button>
      </div>
    );
};

interface UpdateButtonProps extends Omit<React.HTMLProps<HTMLButtonElement>, 'size'> {
  isLoading: boolean;
  size?: InputSize;
}
export const UpdateButton: React.FC<UpdateButtonProps> = ({
  isLoading,
  disabled,
  size,
  onClick,
  children,
}) => {
  if (isLoading)
    return (
      <div>
        <Button onClick={onClick} size={size} icon={<WaveSpinner />}>
          Updating...
        </Button>
      </div>
    );
  else
    return (
      <div>
        <Button onClick={onClick} size={size} type="submit" disabled={disabled}>
          {children}
        </Button>
      </div>
    );
};

interface SubmitButtonProps extends React.HTMLProps<HTMLButtonElement> {
  loading?: boolean;
}

export const SubmitButton: React.FC<SubmitButtonProps> = ({ children, disabled, loading }) => {
  return (
    <Button type="submitPrimary" disabled={disabled}>
      {loading && <WaveSpinner className={styles.submitButtonIcon} />}
      {!loading && <span>{children}</span>}
    </Button>
  );
};
