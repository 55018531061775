import { autoUpdate, flip, offset, useFloating } from '@floating-ui/react-dom';
import { FloatingPortal } from '@floating-ui/react-dom-interactions';
import clsx from 'clsx';
import type { CSSProperties } from 'react';
import React, { useRef } from 'react';
import { Input } from '../Input/Input';
import { ChevronDown } from '../StrokeIcons';
import styles from './Input.module.scss';
import type { InputSize, SelectValue } from './types';
import Icon from 'src/components/icon/icon';

interface SelectProps<T> {
  placeholder?: string;
  options?: T[];
  value?: string;
  onChange: (value: T) => void;
  className?: string;
  optionRow?: (value: T) => React.ReactNode;
  size?: InputSize;
  borderless?: boolean;
  title?: string;
  offsetNum?: number;
}

export function Select<T extends SelectValue>({
  placeholder,
  options,
  onChange,
  value,
  className,
  optionRow,
  size,
  borderless,
  title,
  offsetNum,
}: SelectProps<T>) {
  const [inputValue, setInputValue] = React.useState<string>('');
  const [currentOptionValue, setCurrentOptionValue] = React.useState<string | undefined>(
    options?.[0]?.value,
  );

  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { x, y, strategy, refs } = useFloating<HTMLDivElement>({
    // middleware: [offset(-16), flip()],
    middleware: [offset(offsetNum ?? 4), flip()],
    whileElementsMounted: autoUpdate,
  });

  const optionsDiff = options?.map((v) => v.value + v.label).join(',');
  const selectedOption = React.useMemo(
    () => options?.find((o) => o.value === value),
    //hack to not refresh too often when options isn't memoized. Do not depend on options
    [optionsDiff, value],
  );

  //this is used to determine the width of the input
  const maxLabelLength = React.useMemo(
    () => Math.max(placeholder?.length || 0, ...(options?.map((o) => o.label.length) || [])),
    //hack to not refresh too often when options isn't memoized. Do not depend on options
    [optionsDiff, placeholder],
  );

  const filteredOptions = inputValue
    ? options?.filter((el) => el.label.toLowerCase().includes(inputValue.toLowerCase()))
    : options;

  const selectOption = (el: T) => {
    setInputValue('');
    onChange(el);
    setIsFocused(false);
    inputRef.current?.blur();
  };

  const onInputBlur = () => {
    setIsFocused(false);
    setInputValue('');
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (['Enter', 'ArrowDown', 'ArrowUp'].includes(e.key)) {
      e.preventDefault();
    } else {
      return;
    }

    if (isFocused && e.key === 'Enter') {
      const currentOption =
        currentOptionValue != null
          ? options?.find((o) => o.value === currentOptionValue)
          : undefined;
      if (currentOption != null) {
        selectOption(currentOption);
        return;
      }
    }

    if (!filteredOptions) {
      return;
    }

    const elIndex = filteredOptions?.findIndex((el) => el.value === currentOptionValue) ?? -1;

    let newIndex: number | undefined;
    if (e.key === 'ArrowDown') {
      newIndex = (elIndex + filteredOptions.length + 1) % filteredOptions.length;
    } else if (e.key === 'ArrowUp') {
      newIndex = (elIndex + filteredOptions.length - 1) % filteredOptions.length;
    }
    if (newIndex != null) {
      setCurrentOptionValue(filteredOptions[newIndex]?.value);
      scrollToOption(newIndex);
    }
  };
  const scrollToOption = (index: number) => {
    const scrollValue = (index + 1) * 35 < 195 ? 0 : (index + 1) * 35 - 193;
    if (refs.floating.current) {
      refs.floating.current.scrollTop = scrollValue;
    }
  };

  return (
    <>
      <div
        onKeyDown={onKeyDown}
        ref={refs.setReference}
        style={{ '--max-label-length': `${maxLabelLength}ch` } as CSSProperties}>
        <Input
          ref={inputRef}
          size={size}
          className={clsx(
            styles.select,
            isFocused ? styles.focusedSelect : undefined,
            className ? className : undefined,
            borderless ? styles.borderless : undefined,
          )}
          icon={selectedOption?.icon || <ChevronDown className={styles.chevron} />}
          value={isFocused ? inputValue : selectedOption?.label || ''}
          onChange={(e) => setInputValue(e.currentTarget.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={onInputBlur}
          placeholder={placeholder}
          title={title}
        />
      </div>
      <FloatingPortal>
        <div
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            width: refs.reference.current?.clientWidth,
            display: isFocused ? 'flex' : 'none',
          }}
          ref={refs.setFloating}
          className={clsx(styles.options, styles.variables, styles.small)}>
          {filteredOptions?.map((el) => {
            return (
              <button
                onFocus={() => setIsFocused(true)}
                className={clsx(
                  styles.option,
                  el.icon ? styles.withIcon : undefined,
                  currentOptionValue === el.value ? styles.currentOption : null,
                  value === el.value ? styles.selectedOption : null,
                )}
                key={el.value}
                onMouseDown={() => selectOption(el)}
                onMouseMove={() => setCurrentOptionValue(el.value)}>
                {optionRow ? (
                  optionRow(el)
                ) : (
                  <>
                    {el.icon && <span className={styles.optionIcon}>{el.icon}</span>}
                    <span>{el.label}</span>
                  </>
                )}
              </button>
            );
          })}
        </div>
      </FloatingPortal>
    </>
  );
}
