import { getAuth, signOut } from 'firebase/auth';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { WaveSpinner } from '../PoseidonComponents/WaveSpinner';
import { useReportsContext } from '../contexts';

export const Logout = () => {
  const navigate = useNavigate();

  const [, { reset: reportsActionsReset }] = useReportsContext();

  React.useEffect(() => {
    const fn = async () => {
      reportsActionsReset();
      const auth = getAuth();
      await signOut(auth);
    };
    fn();
  }, [navigate, reportsActionsReset]);
  return <WaveSpinner />;
};
