import { SearchableExtraColModel } from "../utils/trpc"

interface SearchableExtraColParam {
  searchableCol: SearchableExtraColModel
}

export const SearchableExtraCol2: React.FC<SearchableExtraColParam> = ({ searchableCol }) => {

  return <div>
    <div>Name: {searchableCol.name}</div>
    <div>ExtraCols: {searchableCol.extraColIds?.map(v => v.toHexString())}</div>
  </div>

}