import React from 'react';
import { ReportUserAndTeam } from '../../Route';
import { MachineDetailRating } from '../../components/MachineDetailRating/MachineDetailRating';
import useRouterQuery from '../../hooks/useRouterQuery';
import { useReportClassifyAdd } from '../../trpcHooks/useReportClassifyMutation';
import { ReportClassifyAddData, SvrReportReview, trpc } from '../../utils/trpc';
import { sentimentWords } from '../../utils2';
import { CreateButton } from '../Button/CustomButton';
import { ContentDivider } from '../ContentDivider/ContentDivider';
import { ContentSection } from '../ContentSection/ContentSection';
import { DetailHeader } from '../DetailHeader/DetailHeader';
import { Select } from '../Input/Select';
import { Flex, Right } from '../Layout/Layout';
import { PaddedScrollable } from '../PaddedScrollable/PaddedScrollable';
import { Smile } from '../StrokeIcons';
import { WaveSpinnerContainer } from '../WaveSpinnerContainer/WaveSpinnerContainer';

interface MachineDetailPageWithRangeProps
  extends ReportUserAndTeam,
    React.HTMLProps<HTMLDivElement> {
  reviewId: string;
  machineStart: number;
  machineEnd: number;
}

export const MachineDetailPageWithRange: React.FC<MachineDetailPageWithRangeProps> = ({
  report,
  reviewId,
  machineStart,
  machineEnd,
  user,
}) => {
  const reviewBrandQuery = trpc.reportReviews.idWithBrand.useQuery({
    reportId: report._id.toHexString(),
    reviewId,
  });
  const review = reviewBrandQuery.data?.review;

  if (reviewBrandQuery.isLoading) {
    return (
      <>
        <DetailHeader queryKey="machine">Training</DetailHeader>
        <WaveSpinnerContainer />
      </>
    );
  } else if (review == null) {
    return (
      <>
        <DetailHeader queryKey="machine">Training</DetailHeader>
        <WaveSpinnerContainer />
      </>
    );
  }

  return (
    <MachineDetailPageWithRangeWithData
      user={user}
      review={review}
      report={report}
      machineStart={machineStart}
      machineEnd={machineEnd}
    />
  );
};

interface MachineDetailPageWithRangeWithDataProps
  extends ReportUserAndTeam,
    React.HTMLProps<HTMLDivElement> {
  review: SvrReportReview;
  machineStart?: number;
  machineEnd?: number;
}
const MachineDetailPageWithRangeWithData: React.FC<MachineDetailPageWithRangeWithDataProps> = ({
  review,
  machineEnd,
  machineStart,
  report,
}) => {
  const [, setQuery] = useRouterQuery();
  const reportId = report._id.toHexString();
  const reviewId = review._id.toHexString();
  const reportCategoriesRes = trpc.reportCategories.list.useQuery({
    reportId,
  });
  const reportCategories = reportCategoriesRes.data;

  const [data, setData] = React.useState<Partial<ReportClassifyAddData>>({
    b: machineStart,
    e: machineEnd,
  });

  const text = review.text?.slice(machineStart, machineEnd);
  const reportClassifyAddMutation = useReportClassifyAdd();

  const _onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    onSubmit(data);
  };
  const onSubmit = async (data: Partial<ReportClassifyAddData>) => {
    if (data.b != null && data.e != null && data.catIds != null && data.score != null) {
      const classification: ReportClassifyAddData = {
        e: data.e,
        b: data.b,
        score: data.score,
        catIds: data.catIds,
      };

      await reportClassifyAddMutation.mutateAsync({
        reviewId,
        reportId,
        data: classification,
      });
      setQuery((q) => ({
        ...q,
        machine: { rid: reviewId, start: undefined, end: undefined },
      }));
    }
  };

  const options = reportCategories?.map((cat) => ({
    value: cat._id.toHexString(),
    label: cat.name || '',
  }));

  const disabled = data.b == null || data.e == null || data.score == null || data.catIds == null;

  return (
    <>
      <DetailHeader queryKey="machine">Training</DetailHeader>
      <PaddedScrollable size="small">
        <form onSubmit={_onSubmit}>
          <ContentSection>
            <ContentDivider size="small">Selected text</ContentDivider>
            <div>{text}</div>
          </ContentSection>
          <ContentSection>
            <ContentDivider size="small">Select Concept</ContentDivider>
            <Select
              options={options}
              onChange={(v) => setData((d) => ({ ...d, catIds: [v.value] }))}
              value={data.catIds?.[0]}
              placeholder="Select Concept"></Select>
          </ContentSection>
          <ContentSection>
            <ContentDivider size="small" icon={<Smile />}>
              Suggest your sentiment to AI
            </ContentDivider>
            <Ratings
              ratings={[-1, -0.5, 0, 0.5, 1]}
              onSelect={(v) => setData((d) => ({ ...d, ...v }))}
              selectedScore={data.score}
            />
          </ContentSection>
          <ContentSection>
            <Right>
              <CreateButton isLoading={reportClassifyAddMutation.isLoading} disabled={disabled}>
                Create
              </CreateButton>
            </Right>
          </ContentSection>
        </form>
      </PaddedScrollable>
    </>
  );
};

interface RatingsProps {
  ratings: number[];
  selectedScore: number | undefined;
  onSelect: (data: Partial<ReportClassifyAddData>) => void;
}
const Ratings: React.FC<RatingsProps> = ({ ratings, selectedScore, onSelect }) => {
  return (
    <Flex>
      {ratings?.map((r) => {
        const rating = (r + 1) * 2;
        const score = (rating * 100) / (ratings.length - 1);

        const word = sentimentWords[rating + 1];
        return (
          <MachineDetailRating
            word={word}
            score={score}
            key={rating}
            isSelected={selectedScore == r}
            onClick={() => onSelect({ score: r })}
          />
        );
      })}
    </Flex>
  );
};
