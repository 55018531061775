import { trpc } from '../utils/trpc';

export const useConceptGroupsAddProvider = () => {
  const utils = trpc.useContext();

  return trpc.conceptGroups.addProvider.useMutation({
    onSuccess: () => {
      utils.conceptGroups.list.invalidate();
    },
  });
};

export const useConceptGroupsRemoveProvider = () => {
  const utils = trpc.useContext();

  return trpc.conceptGroups.removeProvider.useMutation({
    onMutate: ({ conceptGroupId, provider }) => {
      utils.conceptGroups.list.setData(undefined, (data) => {
        if (data == null) return undefined;

        const conceptGroups = data?.conceptGroups.map((group) => {
          if (group._id.toHexString() === conceptGroupId) {
            return {
              ...group,
              triggerProviders: group.triggerProviders.filter((p) => p !== provider),
            };
          } else {
            return group;
          }
        });
        return { ...data, conceptGroups };
      });
    },
    onSuccess: () => {
      utils.conceptGroups.list.invalidate();
    },
  });
};


export const useConceptGroupsAddConcept = () => {
  const utils = trpc.useContext();

  return trpc.conceptGroups.addConcept.useMutation({
    onSuccess: () => {
      utils.conceptGroups.list.invalidate();
    },
  });
};

export const useConceptGroupsCreate = () => {
  const utils = trpc.useContext();

  return trpc.conceptGroups.create.useMutation({
    onSuccess: () => {
      utils.conceptGroups.list.invalidate();
    },
  });
};

export const useConceptGroupsRemoveConcept = () => {
  const utils = trpc.useContext();

  return trpc.conceptGroups.removeConcept.useMutation({
    onSuccess: () => {
      utils.conceptGroups.list.invalidate();
    },
  });
};


export const useConceptGroupDelete = () => {
  const utils = trpc.useContext();

  return trpc.conceptGroups.delete.useMutation({
    onSuccess: () => {
      utils.conceptGroups.list.invalidate();
    },
  });
};
