import React from 'react';
import styles from './Form.module.scss';
import clsx from 'clsx';

interface FormProps extends React.HTMLProps<HTMLFormElement> {
  width?: 'restricted';
}

export const Form: React.FC<FormProps> = ({ width, className, ...props }) => {
  const classList = clsx(
    styles.container,
    width === 'restricted' ? styles.restricted : undefined,
    className,
  );
  return <form className={classList} {...props} />;
};
