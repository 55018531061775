import clsx from 'clsx';
import React, { forwardRef } from 'react';
import type { FieldError } from 'react-hook-form';
import styles from './Input.module.scss';
import { InputTitle } from './Title';
import type { InputSize } from './types';

export const LoadingInput: React.FC<InputProps> = ({ className, title, icon }) => {
  return (
    <div className={clsx(className, styles.variables, styles.container, styles.loading)}>
      {title != null && <div className={styles.title}>{title}</div>}
      <label className={styles.label}>
        {icon != null && <span className={styles.icon}>{icon}</span>}
        <div className={styles.input} />
      </label>
    </div>
  );
};

interface LoadingInputsProps {
  titles: string[];
}
export const LoadingInputs: React.FC<LoadingInputsProps> = ({ titles }) => {
  return (
    <>
      {titles.map((title) => (
        <LoadingInput title={title} key={title} />
      ))}
    </>
  );
};


interface InputProps
  extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'title' | 'size'> {
  icon?: JSX.Element;
  error?: FieldError;
  subtitle?: string;
  title?: JSX.Element | string;
  size?: InputSize;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, title, icon, value, disabled, subtitle, error, size, ...props }, ref) => {
    const classList = clsx(
      className,
      styles.variables,
      styles.container,
      size == 'small' ? styles.small : undefined,
      error ? styles.hasError : undefined,
      disabled ? styles.disabled : undefined,
    );
    return (
      <div className={classList}>
        <InputTitle>{title}</InputTitle>
        <label className={styles.label}>
          {icon != null && <span className={styles.icon}>{icon}</span>}
          <input
            className={styles.input}
            ref={ref}
            disabled={disabled}
            {...props}
            value={value || ''}
          />
        </label>
        {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        {error != null && <div className={styles.error}>{error.message}</div>}
      </div>
    );
  },
);
Input.displayName = 'Input';
