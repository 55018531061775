
export type ContextPropsState = {
  reportJobsById: Record<string, any | undefined>;
};

export type OrdersAction =
  | { type: 'UPDATE_REPORT'; report: any; jobs?: any; isProcessing?: boolean }
  
  | {
      type: 'FETCH_REPORT_STATUS';
      reportId: string;
      isProcessing: boolean;
      jobs: any;
      appsById: { [key: string]: any };
    }
  | { type: 'RESET' };

export const initialState: ContextPropsState = {
  reportJobsById: {},
};

export const reducer = (state: ContextPropsState, action: OrdersAction): ContextPropsState => {
  switch (action.type) {
    case 'UPDATE_REPORT': {
      const reportId = action.report._id;
      const reportJobsById = {
        ...state.reportJobsById,
        [reportId]: {
          jobs: action.jobs,
          isProcessing: action.isProcessing,
        }
      }
      return { ...state, reportJobsById };
    }
    

    case 'FETCH_REPORT_STATUS': {
      const jobs = { isProcessing: action.isProcessing, jobs: action.jobs };
      const reportJobsById = { ...state.reportJobsById, [action.reportId]: jobs };

      return { ...state, reportJobsById };
    }

    case 'RESET': {
      return initialState;
    }
    default: {
      return state;
    }
  }
};