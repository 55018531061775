import React from 'react';
import styles from './PageHeader.module.scss';
import clsx from 'clsx';

interface PageHeaderProps extends React.HTMLProps<HTMLDivElement> {
  icon: JSX.Element;
  title: string;
  className?: string;
}

export const PageHeader: React.FC<PageHeaderProps> = ({ icon, className, title, children }) => {
  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.icon}>{icon}</div>
      <h1>{title}</h1>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
