import { useLocalStorage } from '@rehooks/local-storage';
import React from 'react';
import { FilePlus, Pencil, Workspaces } from 'src/PoseidonComponents/StrokeIcons';
import { ContentDivider } from '../../PoseidonComponents/ContentDivider/ContentDivider';
import { ContentSection } from '../../PoseidonComponents/ContentSection/ContentSection';
import { FullHeight } from '../../PoseidonComponents/FullHeight/FullHeight';
import { WithLeftNav } from '../../PoseidonComponents/Layout/Layout';
import { PaddedScrollable } from '../../PoseidonComponents/PaddedScrollable/PaddedScrollable';
import { PageFilters } from '../../PoseidonComponents/PageFilters';
import { PageHeader } from '../../PoseidonComponents/PageHeader/PageHeader';
import { WorkspacesTable } from '../../PoseidonComponents/WorkspacesTable';
import { UserAndTeam } from '../../Route';
import { ReportsLeftNav } from '../../components/LeftNav/LeftNav';
import useRouterQuery from '../../hooks/useRouterQuery';
import { trpc } from '../../utils/trpc';

const PER_PAGE = 25;

interface ReportsProps extends UserAndTeam {
  isDraft?: boolean;
}

const Reports: React.FC<ReportsProps> = ({ user, isDraft, team }) => {
  const [navMinimized] = useLocalStorage('navMinimized', false);
  const [search] = useRouterQuery();

  const page = search.page || 0;
  const perPage = search.limit || PER_PAGE;

  const reportsQuery = trpc.report.all.useQuery({
    isDraft: isDraft ?? false,
    teamId: team?.team._id.toHexString(),
    query: search.q,
    pagination: { perPage, page },
  });

  const style = {
    '--nav-width': !navMinimized ? 'var(--nav-opened-width)' : 'var(--nav-closed-width)',
  } as React.CSSProperties;

  return (
    <FullHeight style={style}>
      <ReportsLeftNav user={user} team={team} />
      <WithLeftNav hasDetail={false}>
        <PageHeader
          icon={isDraft ? <Pencil /> : <Workspaces />}
          title={isDraft ? 'Drafts' : 'Workspaces'}>
          <ContentDivider
            end={
              <PageFilters
                addIcon={<FilePlus />}
                addText="Add Workspace"
                length={reportsQuery.data?.length}
              />
            }
          />
        </PageHeader>

        <PaddedScrollable>
          <ContentSection>
            <WorkspacesTable workspaces={reportsQuery.data} isLoading={reportsQuery.isLoading} />
          </ContentSection>
        </PaddedScrollable>
      </WithLeftNav>
    </FullHeight>
  );
};

export default Reports;
