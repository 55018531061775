import { trpc } from '../utils/trpc';

export const useDeleteIntegrationMutation = () => {
  const utils = trpc.useContext();

  return trpc.reportAppIntegration.deleteIntegration.useMutation({
    onSuccess: (_, {reportId}) => {
      utils.report.all.invalidate()
      utils.report.id.invalidate(reportId)
    },
  });
};
