import React from 'react'
import clsx from 'clsx'

import styles from './Scrollable.module.scss'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  innerClassName?: string
  innerId?: string
  onScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void
}

/**
 * Scrollable always takes as much width and height as possible and doesn't respect margins you give it.
 */
export const Scrollable: React.FC<Props> = ({className, innerClassName, children, id, onScroll, innerId}) => {  
  return <div className={clsx(className, styles.container)} id={id} onScroll={onScroll}>
    <div className={clsx(innerClassName, styles.content)} id={innerId}>
      {children}
    </div>
  </div>
}