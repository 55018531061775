import { trpc } from '../utils/trpc';

export const useTeamCreate = () => {
  const utils = trpc.useContext();

  return trpc.team.create.useMutation({
    onSuccess: () => {
      utils.team.all.invalidate();
    },
  });
};

export const useTeamCreateUser = () => {
  const utils = trpc.useContext();

  return trpc.team.addUser.useMutation({
    onSuccess: (_, { id }) => {
      utils.team.id.invalidate(id);
      utils.team.all.invalidate();
    },
  });
};

export const useTeamDelete = () => {
  const utils = trpc.useContext();

  return trpc.team.delete.useMutation({
    onSuccess: (_, id) => {
      utils.team.all.invalidate();
      utils.team.id.invalidate(id);
    },
  });
};

export const useTeamDeleteUser = () => {
  const utils = trpc.useContext();

  return trpc.team.deleteUser.useMutation({
    onSuccess: (_, { id }) => {
      utils.team.id.invalidate(id);
      utils.team.all.invalidate();
    },
  });
};

export const useTeamUpdate = () => {
  const utils = trpc.useContext();

  return trpc.team.update.useMutation({
    onSuccess: (_, { id }) => {
      utils.team.id.invalidate(id);
      utils.team.all.invalidate();
    },
  });
};

export const useTeamMemberInviteMutation = () => {
  const utils = trpc.useContext();

  return trpc.team.addUser.useMutation({
    onSuccess: (_, { id }) => {
      utils.team.id.invalidate(id);
      utils.team.all.invalidate();
    },
  });
};
