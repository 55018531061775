import React from 'react';
import { MachineDetailRating } from 'src/components/MachineDetailRating/MachineDetailRating';
import useRouterQuery from 'src/hooks/useRouterQuery';
import {
  useReportClassifyDelete,
  useReportClassifyUpdate,
} from 'src/trpcHooks/useReportClassifyMutation';
import {
  ReportCategory,
  ReportClassifyAddData,
  SvrReportReview,
  SvrReportReviewClassOverride,
  trpc,
} from 'src/utils/trpc';
import { sentimentWords } from 'src/utils2';
import { ReportUserAndTeam } from '../../Route';
import { Button } from '../Button/Button';
import { UpdateButton } from '../Button/CustomButton';
import { ContentDivider } from '../ContentDivider/ContentDivider';
import { ContentSection } from '../ContentSection/ContentSection';
import { DetailHeader } from '../DetailHeader/DetailHeader';
import { Select } from '../Input/Select';
import { Flex, Right } from '../Layout/Layout';
import { PaddedScrollable } from '../PaddedScrollable/PaddedScrollable';
import { ArrowLeft, Smile } from '../StrokeIcons';
import { WaveSpinnerContainer } from '../WaveSpinnerContainer/WaveSpinnerContainer';
import { ReviewTextOverlay } from '../review/ReviewText';
import styles from './MachineDetailUpdatePageWithRange.module.scss';

interface MachineDetailUpdatePageWithRangeProps
  extends ReportUserAndTeam,
    React.HTMLProps<HTMLDivElement> {
  reviewId: string;
  machineStart: number;
  machineEnd: number;
  conceptsById: Record<string, ReportCategory>;
}

export const MachineDetailUpdatePageWithRange: React.FC<MachineDetailUpdatePageWithRangeProps> = ({
  report,
  reviewId,
  machineStart,
  machineEnd,
  user,
  conceptsById,
}) => {
  const reportId = report._id.toHexString();
  const [query] = useRouterQuery();

  const reviewBrandQuery = trpc.reportReviews.idWithBrand.useQuery({
    reportId,
    reviewId,
  });

  const { data: dataQuery } = trpc.reportClassify.byReviewId.useQuery({
    reportId,
    reviewId,
  });

  const selectOverride = React.useMemo(() => {
    if (!dataQuery) return;
    return dataQuery?.classOverrides.find(
      (o) =>
        o._id.toHexString() === query.machine?.id ||
        (o.e === query.machine?.start && o.b === query.machine?.end),
    );
  }, [dataQuery]);

  const review = reviewBrandQuery.data?.review;

  if (reviewBrandQuery.isLoading) {
    return (
      <>
        <DetailHeader queryKey="machine">Training</DetailHeader>
        <WaveSpinnerContainer />
      </>
    );
  } else if (review == null) {
    return (
      <>
        <DetailHeader queryKey="machine">Training</DetailHeader>
        <WaveSpinnerContainer />
      </>
    );
  }

  return (
    <MachineDetailUpdatePageWithRangeWithData
      user={user}
      review={review}
      report={report}
      selectOverride={selectOverride}
      machineStart={machineStart}
      machineEnd={machineEnd}
      conceptsById={conceptsById}
    />
  );
};

interface MachineDetailUpdatePageWithRangeWithDataProps
  extends ReportUserAndTeam,
    React.HTMLProps<HTMLDivElement> {
  review: SvrReportReview;
  machineStart?: number;
  conceptsById: Record<string, ReportCategory>;

  machineEnd?: number;
  selectOverride: SvrReportReviewClassOverride | undefined;
}
const MachineDetailUpdatePageWithRangeWithData: React.FC<
  MachineDetailUpdatePageWithRangeWithDataProps
> = ({ review, machineEnd, conceptsById, machineStart, selectOverride, report }) => {
  const [query, setQuery] = useRouterQuery();
  const reportId = report._id.toHexString();
  const reviewId = review._id.toHexString();
  const reportCategoriesRes = trpc.reportCategories.list.useQuery({
    reportId,
  });
  const reportCategories = reportCategoriesRes.data;
  const reportClassifyDeleteMutation = useReportClassifyDelete();

  const [data, setData] = React.useState<Partial<ReportClassifyAddData>>({
    b: machineStart,
    e: machineEnd,
    score: selectOverride?.score,
    catIds: selectOverride?.catIds.map((el) => el.toHexString()),
  });
  const reportClassifyUpdateMut = useReportClassifyUpdate();

  const text = review.text?.slice(machineStart, machineEnd);

  const _onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    onSubmit(data);
  };
  const onSubmit = async (data: Partial<ReportClassifyAddData>) => {
    if (data.b != null && data.e != null && data.catIds != null && data.score != null) {
      const classification: ReportClassifyAddData = {
        e: data.e,
        b: data.b,
        score: data.score,
        catIds: data.catIds,
      };
      if (selectOverride) {
        await reportClassifyUpdateMut.mutateAsync({
          reviewId,
          reportId,
          classOverrideId: selectOverride._id.toHexString(),
          data: classification,
        });
      }
      setQuery((q) => ({
        ...q,
        machine: { rid: reviewId, start: undefined, end: undefined },
      }));
    }
  };

  const options = reportCategories?.map((cat) => ({
    value: cat._id.toHexString(),
    label: cat.name || '',
  }));

  const disabled = data.b == null && data.e == null && data.score == null && data.catIds == null;

  const handleBackButton = () => {
    setQuery((q) => ({
      ...q,
      machine: { rid: reviewId, start: undefined, end: undefined },
    }));
  };

  const deleteFn = async (classificationId: string) => {
    await reportClassifyDeleteMutation.mutate({
      reportId: report._id.toHexString(),
      reviewId: review._id.toHexString(),
      classificationId,
    });

    setQuery((q) => ({
      ...q,
      machine: { rid: reviewId, start: undefined, end: undefined },
    }));
  };

  return (
    <>
      <DetailHeader queryKey="machine" className={styles.header}>
        <div className={styles.headerContent}>
          <div onClick={handleBackButton}>
            <ArrowLeft />
          </div>
          Training
        </div>
      </DetailHeader>
      <PaddedScrollable size="small">
        <form onSubmit={_onSubmit}>
          <ContentSection>
            <ContentDivider size="small">Selected text</ContentDivider>
            <div className={styles.body} aria-label="reviewBody">
              <div className={styles.line}>
                <ReviewTextOverlay
                  line={text}
                  newScore={data.score}
                  isMachine={!query.machine?.id}
                />
              </div>
            </div>
          </ContentSection>
          <ContentSection>
            <ContentDivider size="small">Select Concept</ContentDivider>
            <Select
              options={options}
              onChange={(v) => setData((d) => ({ ...d, catIds: [v.value] }))}
              value={data.catIds?.[0]}
              placeholder="Select Concept"></Select>
          </ContentSection>
          <ContentSection>
            <ContentDivider size="small" icon={<Smile />}>
              Suggest your sentiment to AI
            </ContentDivider>
            <Ratings
              ratings={[-1, -0.5, 0, 0.5, 1]}
              onSelect={(v) => setData((d) => ({ ...d, ...v }))}
              selectedScore={data.score}
            />
          </ContentSection>
          <ContentSection>
            <Right>
              <div className={styles.buttons}>
                {selectOverride && (
                  <Button onClick={() => deleteFn(selectOverride?._id.toHexString())}>
                    Delete
                  </Button>
                )}
                <UpdateButton isLoading={reportClassifyUpdateMut.isLoading} disabled={disabled}>
                  Update
                </UpdateButton>
              </div>
            </Right>
          </ContentSection>
        </form>
      </PaddedScrollable>
    </>
  );
};

interface RatingsProps {
  ratings: number[];
  selectedScore: number | undefined;
  onSelect: (data: Partial<ReportClassifyAddData>) => void;
}
const Ratings: React.FC<RatingsProps> = ({ ratings, selectedScore, onSelect }) => {
  return (
    <Flex>
      {ratings?.map((r) => {
        const rating = (r + 1) * 2;
        const score = (rating * 100) / (ratings.length - 1);

        const word = sentimentWords[rating + 1];
        return (
          <MachineDetailRating
            word={word}
            score={score}
            key={rating}
            isSelected={selectedScore == r}
            onClick={() => onSelect({ score: r })}
          />
        );
      })}
    </Flex>
  );
};
