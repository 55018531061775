import React from 'react';

import styles from './LogoUpload.module.scss';
import { Pencil } from 'src/toneIcons';

export const LogoUpload: React.FC<React.HTMLProps<HTMLInputElement>> = ({
  children,
  accept = '.png, .jpg, .jpeg, .webp',
  onChange,
}) => {
  return (
    <div className={styles.container}>
      {children}
      {onChange && <label className={styles.edit}>
        <input type="file" id="imageUpload" accept={accept} onChange={onChange} />
        <Pencil />
      </label>}
    </div>
  );
};
