import {
  ReviewExtraColFromList,
  ReviewExtraColsRes,
  SearchableExtraColModel,
  SearchableExtraColsRes,
  trpc,
} from '../utils/trpc';
import { useQueryClient } from '@tanstack/react-query';
import {
  extraColsForReportAppRQKey,
  RQUtils,
  searchableExtraColsForReportRQKey,
} from '../utils/reactQuery.utils';

interface ExtraColParam {
  reportId: string;
  extraCol: ReviewExtraColFromList;
  searchableCols: SearchableExtraColModel[] | undefined;
  searchableColsById: Map<string, SearchableExtraColModel> | undefined;
}

export const ExtraCol: React.FC<ExtraColParam> = ({
  reportId,
  extraCol,
  searchableCols,
  searchableColsById,
}) => {
  const queryClient = useQueryClient();

  const addColMut = trpc.extraCols.addExtraCol.useMutation({
    onSuccess: (updated) => {
      queryClient.setQueryData<SearchableExtraColsRes>(
        searchableExtraColsForReportRQKey(reportId),
        (old) => {
          const oldSearchableExtraCols = old?.searchableExtraCols;
          const oldUnusedColKeys = old?.unusedColKeys;

          const updatedSearchableExtraCols = RQUtils.updateArrQueryV2(
            oldSearchableExtraCols,
            updated.searchableExtraCol,
            '_id',
          );

          const updatedRes: SearchableExtraColsRes = {
            searchableExtraCols: updatedSearchableExtraCols,
            unusedColKeys: oldUnusedColKeys || [],
          };
          return updatedRes;
        },
      );

      queryClient.setQueryData<ReviewExtraColsRes>(
        extraColsForReportAppRQKey(reportId, extraCol.appId.toHexString()),
        (old) => {
          const oldExtraCols = old?.extraCols;
          const oldSections = old?.sections;

          const updatedExtraCols = RQUtils.updateArrQueryV2(oldExtraCols, updated.extraCol, '_id');

          const updatedRes: ReviewExtraColsRes = {
            extraCols: updatedExtraCols,
            sections: oldSections || [],
          };
          return updatedRes;
        },
      );
    },
  });
  const removeColMut = trpc.extraCols.removeExtraCol.useMutation({
    onSuccess: (updated) => {
      queryClient.setQueryData<SearchableExtraColsRes>(
        searchableExtraColsForReportRQKey(reportId),
        (old) => {
          const oldSearchableExtraCols = old?.searchableExtraCols;
          const oldUnusedColKeys = old?.unusedColKeys;

          const updatedSearchableExtraCols = RQUtils.updateArrQueryV2(
            oldSearchableExtraCols,
            updated.searchableExtraCol,
            '_id',
          );

          const updatedRes: SearchableExtraColsRes = {
            searchableExtraCols: updatedSearchableExtraCols,
            unusedColKeys: oldUnusedColKeys || [],
          };
          return updatedRes;
        },
      );

      queryClient.setQueryData<ReviewExtraColsRes>(
        extraColsForReportAppRQKey(reportId, extraCol.appId.toHexString()),
        (old) => {
          const oldExtraCols = old?.extraCols;
          const oldSections = old?.sections;

          const updatedExtraCols = RQUtils.updateArrQueryV2(oldExtraCols, updated.extraCol, '_id');

          const updatedRes: ReviewExtraColsRes = {
            extraCols: updatedExtraCols,
            sections: oldSections || [],
          };
          return updatedRes;
        },
      );
    },
  });

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const searchableColId = e.target.value;
    if (searchableColId.length > 0) {
      addColMut.mutate({
        reportId,
        reviewExtraColId: extraCol._id.toHexString(),
        searchableExtraColId: searchableColId,
      });
    } else if (extraCol.searchableColId != null) {
      removeColMut.mutate({
        reportId,
        reviewExtraColId: extraCol._id.toHexString(),
        searchableExtraColId: extraCol.searchableColId.toHexString(),
      });
    }
  };

  const searchableColIdStr = extraCol.searchableColId?.toHexString();
  const searchableColName = searchableColIdStr != null ? searchableColsById?.get(searchableColIdStr)?.name : undefined

  return (
    <div>
      <div>Name: {extraCol.name}</div>
      <div>Searchable col: {searchableColName}</div>
      {searchableCols && (
        <select defaultValue={searchableColIdStr} onChange={onChange}>
          <option value={''}>Not selected</option>
          {searchableCols?.map((col) => {
            const idStr = col._id.toHexString();
            return (
              <option value={idStr} key={idStr}>
                {col.name}
              </option>
            );
          })}
        </select>
      )}
      {addColMut.isLoading == true || removeColMut.isLoading == true ? <div>...</div> : null}
    </div>
  );
};
