import { TRPCClientError } from '@trpc/client';
import React from 'react';
import { toast } from 'react-hot-toast';
import { TrashCan } from 'src/PoseidonComponents/StrokeIcons';
import { useTeamDeleteUser } from 'src/trpcHooks/useTeamMutation';
import { SvrTeam, SvrUser } from 'src/utils/trpc';
import { Badge } from './Badge/Badge';
import { DeletePopup } from './Popup2/Popup2';
import { SvgIconButton } from './SvgIconButton/SvgIconButton';
import { Cell, CellHeader, Table } from './Table/Table';

interface UsersTableProps {
  users: { id: string; email?: string }[];
  user: SvrUser;
  team: SvrTeam['team'];
}

export const UsersTable: React.FC<UsersTableProps> = ({ users, team, user }) => {
  const [shouldDeleteTeamMember, setShouldDeleteTeamMember] = React.useState<string>();
  const teamId = team._id as unknown as string;

  const memberDeleteMutation = useTeamDeleteUser();

  const deleteMember = async (id: string) => {
    try {
      await memberDeleteMutation.mutateAsync({ id: teamId, userId: id });
      setShouldDeleteTeamMember(undefined);
    } catch (e: unknown) {
      if (e instanceof TRPCClientError) {
        toast.error(e.message);
      }
    }
  };

  return (
    <>
      <Table gridTemplateColumns="1fr min-content min-content" bordered>
        <CellHeader>Name</CellHeader>
        <CellHeader />
        <CellHeader />
        {users?.map((teamUser, index) => {
          return (
            <React.Fragment key={teamUser.id}>
              <Cell index={index}>
                {teamUser.email}
                {user._id === teamUser.id && <Badge>YOU</Badge>}
              </Cell>
              <Cell index={index} align="right">
                {team.adminId === teamUser.id && <Badge dot>Admin</Badge>}
              </Cell>
              <Cell index={index} align="right">
                {teamUser.id !== user._id && (
                  <SvgIconButton
                    type="dangerous"
                    icon={<TrashCan />}
                    tooltip="Delete"
                    onClick={() => setShouldDeleteTeamMember(teamUser.id)}
                  />
                )}
              </Cell>
            </React.Fragment>
          );
        })}
      </Table>
      {shouldDeleteTeamMember != null && (
        <DeletePopup
          onClose={() => setShouldDeleteTeamMember(undefined)}
          onDelete={() => deleteMember(shouldDeleteTeamMember)}
        />
      )}
    </>
  );
};
