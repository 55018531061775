import React, { CSSProperties } from 'react';
import styles from './icons.module.css';

const withSvg = (WIDTH: number, HEIGHT: number) => (WrappedComponent: any) =>
  class SVGWrappedComponent extends React.PureComponent {
    render() {
      const { tag, className, mimetype, width, height, animated, ...props } = this.props as any;
      const Tag = tag || 'svg';

      const _props = {
        viewBox: Tag === 'svg' ? `0 0 ${WIDTH} ${HEIGHT}` : undefined,
        width: width || WIDTH,
        height: height || HEIGHT,
        fillRule: 'evenodd',
        ...props,
      };

      return (
        <Tag className={[className, styles.svg].join(' ')} {..._props}>
          <WrappedComponent {...props} mimetype={mimetype} animated={animated} />
        </Tag>
      );
    }
  };

export const LogoIcon2: React.FC<React.SVGProps<SVGElement>> = ({ className }) => {
  return (
    <svg fillRule="evenodd" width="24px" height="29px" viewBox="0 0 24 29" className={className}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="LogoIcon2Gradient">
          <stop stopColor="#2B9DF8" offset="0%"></stop>
          <stop stopColor="#1D6FEF" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M15.9166384,15.4716753 L8.43576647,28.6825478 C8.32844095,28.8692769 8.12876098,28.984707 7.91230714,28.9851469 L0.60543375,29.0000051 C0.271745328,29.0006756 0.000683969128,28.732507 -6.12502549e-06,28.4010262 C-0.000232106281,28.2877157 0.0318280099,28.1766541 0.0924738322,28.0806859 C3.6353953,22.4742322 6.29258643,18.2693919 8.06404717,15.4661651 C8.92153762,14.1092395 10.2850184,12.0738512 11.9999939,9.36 L15.9166384,15.4716753 Z"
          fill="url(#LogoIcon2Gradient)"
          opacity="0.524386"
        />
        <path
          d="M11.9999994,9.36 C13.7215079,12.0763189 15.0558841,14.1135581 15.9166384,15.4717176 C17.6922688,18.2734338 20.3557143,22.4760082 23.9069751,28.0794406 C23.9679663,28.1756769 24.0002283,28.2871983 24.0000036,28.4009998 C23.9993304,28.7324834 23.7289593,29.0006644 23.396108,29.0000037 L16.0492605,28.9853067 C15.8303454,28.9848689 15.6288832,28.866246 15.5229895,28.6754334 L8.06404717,15.4717176 L11.9999994,9.36 Z"
          fill="#634592"
          opacity="0.6"
          style={{ mixBlendMode: 'multiply' }}
        />
        <ellipse fill="#715594" opacity="0.6" cx="12" cy="4.3875" rx="4.3902439" ry="4.3875" />
      </g>
    </svg>
  );
};

export const Chevron3Icon = withSvg(
  19,
  10,
)(() => (
  <g>
    <polygon points="1.01471863 0.0502525317 9.5 8.53553391 17.9852814 0.0502525317 18.6923882 0.757359313 9.5 9.94974747 0.307611845 0.757359313" />
  </g>
));

export const PlusIcon = withSvg(
  100,
  100,
)(() => (
  <g>
    <path
      d="M58,42 L96,42 C98.209139,42 100,43.790861 100,46 L100,54 C100,56.209139 98.209139,58 96,58 L58,58 L58,96 C58,98.209139 56.209139,100 54,100 L46,100 C43.790861,100 42,98.209139 42,96 L42,58 L4,58 C1.790861,58 0,56.209139 0,54 L0,46 C0,43.790861 1.790861,42 4,42 L42,42 L42,4 C42,1.790861 43.790861,4.05812251e-16 46,0 L54,0 C56.209139,-4.05812251e-16 58,1.790861 58,4 L58,42 Z"
      className={styles.tone1}
    />
  </g>
));

export const DeleteIcon: React.FC<React.SVGProps<SVGElement>> = ({ className, onClick }) => {
  return (
    <svg
      fillRule="evenodd"
      width="17px"
      height="17px"
      viewBox="0 0 17 17"
      className={className}
      onClick={onClick}>
      <path
        d="M8.50324184,0 C3.80717511,0 0,3.79650841 0,8.50324184 C0,13.2018184 3.80717511,17 8.50324184,17 C13.1993086,17 17,13.2018184 17,8.50324184 C17,3.79650841 13.1993086,0 8.50324184,0 Z M3.91488786,8.33989493 L13.0913867,8.33989493 C13.5031631,8.35460096 13.5358325,9.0425167 13.0913867,9.04577946 L3.91488786,9.04577946 C3.47943554,9.04006043 3.46717929,8.3505198 3.91488786,8.33989493 Z"
        className={styles.tone1}
      />
    </svg>
  );
};

export const CloseIcon = withSvg(
  12,
  12,
)(() => (
  <g>
    <path
      d="M7.30579461,6.00000585 L11.7418379,10.4360491 C12.0925917,10.786803 12.0849778,11.3641132 11.7241929,11.7248981 C11.3640439,12.0850471 10.7867332,12.0926924 10.4359639,11.7419231 L5.99992061,7.30587984 L1.56508489,11.7407156 C1.2131235,12.092677 0.635813246,12.085063 0.275648338,11.7248981 C-0.085120634,11.3641291 -0.0927659767,10.7868184 0.259210899,10.4348416 L4.69404662,6.00000585 L0.259210899,1.56517013 C-0.092750495,1.21320873 -0.0851365304,0.635898482 0.275028378,0.275733574 C0.63579735,-0.0850353981 1.21310802,-0.0926807408 1.56508489,0.259296135 L5.99992061,4.69413186 L10.4359639,0.258088561 C10.7867177,-0.0926652591 11.364028,-0.0850512945 11.7248128,0.275733574 C12.0849619,0.635882585 12.0926072,1.21319325 11.7418379,1.56396255 L7.30579461,6.00000585 Z"
      fill="#B5B5B5"
    />
  </g>
));

export const Rank1Icon = withSvg(
  15,
  15,
)(() => (
  <g>
    <path d="M0.670627424,1.00012326 C0.393110878,1.00457507 0.145649212,1.17628113 0.0458918934,1.4338591 C-0.0545005723,1.69205573 0.013280057,1.98396983 0.215351652,2.17285926 L7.02926436,8.72147101 L7.02990376,8.72147101 C7.29399711,8.97585795 7.71347221,8.97585795 7.97756556,8.72147101 L14.7914783,2.17285926 C15.0625942,1.91274149 15.0696332,1.48283163 14.8081034,1.21318962 C14.5459253,0.944182554 14.1143038,0.936546876 13.8431879,1.19729031 L7.50367082,7.29052909 L1.16415376,1.19729031 C1.03306799,1.06755379 0.855305494,0.996320561 0.67050727,1.00014654 L0.670627424,1.00012326 Z M0.670627424,6.08786205 C0.393110878,6.09231386 0.145649212,6.26401991 0.0458918934,6.52159788 C-0.0545005723,6.77979452 0.013280057,7.07170862 0.215351652,7.26059805 L7.02926436,13.8092098 L7.02990376,13.8092098 C7.29399711,14.0635967 7.71347221,14.0635967 7.97756556,13.8092098 L14.7914783,7.26059805 C14.9219256,7.13594926 14.9967387,6.9642393 14.9999308,6.78425545 C15.0024886,6.6042716 14.9340687,6.43065353 14.8080936,6.30091212 C14.6821234,6.17181055 14.5101148,6.09740257 14.3291471,6.09422782 C14.1481794,6.09104787 13.9729739,6.15973185 13.8431617,6.2850221 L7.50364463,12.3782609 L1.16412757,6.2850221 C1.0330418,6.15528557 0.855279302,6.08405235 0.670481078,6.08787833 L0.670627424,6.08786205 Z" />
  </g>
));

export const Rank2Icon = withSvg(
  15,
  15,
)(() => (
  <g>
    <path d="M0.0458918934,4.43867683 C-0.0545005723,4.69973638 0.013280057,4.99488724 0.215351652,5.18587108 L7.02926436,11.8070943 C7.29399711,12.0643019 7.71347221,12.0643019 7.97756556,11.8070943 L14.7914783,5.18587108 C14.9219256,5.05984018 14.9967387,4.88622629 14.9999308,4.70424677 C15.0024886,4.52226725 14.9340687,4.34672409 14.8080936,4.2155441 C14.6821234,4.08501104 14.5101148,4.00977802 14.3291471,4.00656807 C14.1481794,4.00335285 13.9729739,4.07279841 13.8431617,4.19947788 L7.50364463,10.3602789 L1.16412757,4.19947788 C1.0330418,4.06830283 0.855279302,3.99627976 0.670481078,4.00014817 C0.393110878,4.00463288 0.145649212,4.17824282 0.0458918934,4.43867683 Z" />
  </g>
));

export const Rank3Icon = withSvg(
  15,
  15,
)(() => (
  <g>
    <path d="M11.5,7.5 C11.5,9.70904 9.70904,11.5 7.5,11.5 C5.29096,11.5 3.5,9.70904 3.5,7.5 C3.5,5.29096 5.29096,3.5 7.5,3.5 C9.70904,3.5 11.5,5.29096 11.5,7.5" />
  </g>
));

export const Rank4Icon = withSvg(
  15,
  15,
)(() => (
  <g>
    <path d="M0.0458918934,11.5613232 C-0.0545005723,11.3002636 0.013280057,11.0051128 0.215351652,10.8141289 L7.02926436,4.1929057 C7.29399711,3.9356981 7.71347221,3.9356981 7.97756556,4.1929057 L14.7914783,10.8141289 C14.9219256,10.9401598 14.9967387,11.1137737 14.9999308,11.2957532 C15.0024886,11.4777328 14.9340687,11.6532759 14.8080936,11.7844559 C14.6821234,11.914989 14.5101148,11.990222 14.3291471,11.9934319 C14.1481794,11.9966472 13.9729739,11.9272016 13.8431617,11.8005221 L7.50364463,5.63972108 L1.16412757,11.8005221 C1.0330418,11.9316972 0.855279302,12.0037202 0.670481078,11.9998518 C0.393110878,11.9953671 0.145649212,11.8217572 0.0458918934,11.5613232 Z" />
  </g>
));

export const Rank5Icon = withSvg(
  15,
  15,
)(() => (
  <g>
    <path d="M0.670627424,13.9998767 C0.393110878,13.9954249 0.145649212,13.8237189 0.0458918934,13.5661409 C-0.0545005723,13.3079443 0.013280057,13.0160302 0.215351652,12.8271407 L7.02926436,6.27852899 L7.02990376,6.27852899 C7.29399711,6.02414205 7.71347221,6.02414205 7.97756556,6.27852899 L14.7914783,12.8271407 C15.0625942,13.0872585 15.0696332,13.5171684 14.8081034,13.7868104 C14.5459253,14.0558174 14.1143038,14.0634531 13.8431879,13.8027097 L7.50367082,7.70947091 L1.16415376,13.8027097 C1.03306799,13.9324462 0.855305494,14.0036794 0.67050727,13.9998535 L0.670627424,13.9998767 Z M0.670627424,8.91213795 C0.393110878,8.90768614 0.145649212,8.73598009 0.0458918934,8.47840212 C-0.0545005723,8.22020548 0.013280057,7.92829138 0.215351652,7.73940195 L7.02926436,1.1907902 L7.02990376,1.1907902 C7.29399711,0.936403265 7.71347221,0.936403265 7.97756556,1.1907902 L14.7914783,7.73940195 C14.9219256,7.86405074 14.9967387,8.0357607 14.9999308,8.21574455 C15.0024886,8.3957284 14.9340687,8.56934647 14.8080936,8.69908788 C14.6821234,8.82818945 14.5101148,8.90259743 14.3291471,8.90577218 C14.1481794,8.90895213 13.9729739,8.84026815 13.8431617,8.7149779 L7.50364463,2.62173912 L1.16412757,8.7149779 C1.0330418,8.84471443 0.855279302,8.91594765 0.670481078,8.91212167 L0.670627424,8.91213795 Z" />
  </g>
));

export const Arrow: React.FC<React.SVGProps<SVGElement>> = ({ className }) => {
  return (
    <svg className={className} width="23px" height="23px" viewBox="0 0 23 23" version="1.1">
      <path d="M23,11.5 C23,17.8512436 17.8512436,23 11.5,23 C5.14875641,23 0,17.8512436 0,11.5 C0,5.14875641 5.14875641,0 11.5,0 C17.8512436,0 23,5.14875641 23,11.5 Z M18.6598552,11.9187307 C18.791794,11.7792367 18.8148833,11.6444728 18.8181818,11.5026002 C18.8181818,11.3631062 18.740119,11.1763294 18.6598552,11.0864848 L14.1563413,5.94178533 C13.9463369,5.70771967 13.6010834,5.67580135 13.355898,5.91341383 C13.1371005,6.12504255 13.1359999,6.5435124 13.3383062,6.77403125 L16.9535019,10.8973565 L4.74475741,10.8973565 C4.4336012,10.8973565 4.18181818,11.1680676 4.18181818,11.5026153 C4.18181818,11.837163 4.43359275,12.107874 4.74475741,12.107874 L16.9535019,12.107874 L13.3383062,16.2311993 C13.1359999,16.4617121 13.1458964,16.8600995 13.355898,17.0823444 C13.5780057,17.3164101 13.9496357,17.3010426 14.1563413,17.0634301 L18.6598552,11.9187307 Z" />
    </svg>
  );
};

export const SearchIcon: React.FC<React.SVGProps<SVGElement>> = ({ className }) => {
  return (
    <svg className={className} width="25px" height="25px" viewBox="0 0 25 25" version="1.1">
      <path d="M9.6493685,0 C4.32203308,0 0,4.41766265 0,9.84921509 C0,15.283085 4.32316934,19.6984302 9.6493685,19.6984302 C12.2325893,19.6984302 14.590661,18.6619162 16.3248714,16.9736159 L24.0213003,24.8222815 C24.1267135,24.9343077 24.2720748,24.9988084 24.4241029,24.9999382 C24.5761226,25.0022012 24.7225973,24.9422276 24.831337,24.8324639 C24.9389717,24.7238328 25,24.5755964 25,24.4194342 C24.9988904,24.2644075 24.937861,24.1161652 24.8291185,24.0075342 L17.0883757,16.1226582 C18.476511,14.4173824 19.3165166,12.2356944 19.3165166,9.84928751 C19.3165166,4.41773507 14.97886,7.24206992e-05 9.6495361,7.24206992e-05 L9.6493685,0 Z M9.6493685,1.15873119 C14.3653983,1.15873119 18.1800931,5.04453622 18.1800931,9.84921509 C18.1800931,14.6573701 14.3651142,18.539699 9.6493685,18.539699 C4.93674748,18.539699 1.13625582,14.6573701 1.13625582,9.84921509 C1.13625582,5.04453622 4.93674748,1.15873119 9.6493685,1.15873119 Z" />
    </svg>
  );
};

export const TrashIcon: React.FC<React.SVGProps<SVGElement>> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      width="51px"
      height="61px"
      viewBox="0 0 51 61"
      version="1.1"
      onClick={onClick}>
      <path
        d="M46.82,14 L44.4512517,53.4791383 C44.197776,57.7037326 40.6978046,61 36.4656129,61 L15.5343871,61 C11.3021954,61 7.80222395,57.7037326 7.5487483,53.4791383 L5.18,14 L2,14 C0.8954305,14 1.3527075e-16,13.1045695 0,12 L0,11 C-1.3527075e-16,9.8954305 0.8954305,9 2,9 L14,9 L14,4 C14,1.790861 15.790861,4.05812251e-16 18,0 L33,0 C35.209139,-4.05812251e-16 37,1.790861 37,4 L37,9 L49,9 C50.1045695,9 51,9.8954305 51,11 L51,12 C51,13.1045695 50.1045695,14 49,14 L46.82,14 Z M20.5,4 C19.3954305,4 18.5,4.8954305 18.5,6 L18.5,9 L32.5,9 L32.5,6 C32.5,4.8954305 31.6045695,4 30.5,4 L20.5,4 Z"
        className={styles.tone1}
      />
      <path
        d="M10.0721982,14 L41.9278018,14 C42.4800865,14 42.9278018,14.4477153 42.9278018,15 C42.9278018,15.0232205 42.926993,15.046434 42.9253769,15.0695983 L40.1298235,55.1391965 C40.056707,56.1871991 39.1852234,57 38.1346733,57 L13.8653267,57 C12.8147766,57 11.943293,56.1871991 11.8701765,55.1391965 L9.07462313,15.0695983 C9.03618508,14.5186527 9.45165446,14.040863 10.0026,14.0024249 C10.0257642,14.0008088 10.0489777,14 10.0721982,14 Z"
        className={styles.tone2}
      />
    </svg>
  );
};

export const HaterIcon: React.FC<React.SVGProps<SVGPathElement>> = ({ ...props }) => {
  return (
    <path
      d="M50,0 C22.4221053,0 0,22.4221053 0,50 C0,77.5778947 22.4221053,100 50,100 C77.5778947,100 100,77.5778947 100,50 C100,22.4221053 77.5778947,0 50,0 Z M23.7915789,22.4221053 C24.5276,20.7362105 26.5301053,19.8974737 28.3187368,20.6334737 L43.4745263,27.2658947 C45.1604211,28.0019158 45.9991579,30.0044211 45.2631579,31.7930526 C44.7368421,33.0553684 43.4745263,33.7913684 42.1052632,33.7913684 C41.6858526,33.7913684 41.1595368,33.6844632 40.736,33.4747579 L25.5802105,26.8423368 C23.8943158,26.2091158 23.0514737,24.2107579 23.7915789,22.4221263 L23.7915789,22.4221053 Z M31.7891579,50.42 C27.8952632,50.42 24.7373684,47.2621053 24.7373684,43.3682105 C24.7373684,39.4743158 27.8952632,36.3164211 31.7891579,36.3164211 C35.6830526,36.3164211 38.8409474,39.4743158 38.8409474,43.3682105 C38.8409474,47.2621053 35.6830526,50.42 31.7891579,50.42 Z M50.0007368,81.16 C43.8946316,81.16 38.8407368,76.2134737 38.8407368,70 C38.8407368,63.8938947 43.7872632,58.84 50.0007368,58.84 C56.1068421,58.84 61.1607368,63.7865263 61.1607368,70 C61.1607368,76.2088421 56.1072632,81.16 50.0007368,81.16 Z M68.2123158,50.42 C64.3184211,50.42 61.1605263,47.2621053 61.1605263,43.3682105 C61.1605263,39.4743158 64.3184211,36.3164211 68.2123158,36.3164211 C72.1062105,36.3164211 75.2641053,39.4743158 75.2641053,43.3682105 C75.2641053,47.2621053 72.1062105,50.42 68.2123158,50.42 Z M74.4211579,26.9494737 L59.2653684,33.5818947 C58.8459579,33.7916 58.3196421,33.8985053 57.8961053,33.8985053 C56.6337895,33.8985053 55.3714737,33.1624842 54.7382105,31.9001895 C54.0021895,30.2142947 54.7382105,28.2159789 56.5268421,27.3730316 L71.6826316,20.7406105 C73.4712632,20.0045895 75.4737895,20.7406105 76.2097895,22.5292421 C76.9499158,24.2110316 76.1069926,26.2093474 74.4211579,26.9494526 L74.4211579,26.9494737 Z"
      {...props}
    />
  );
};

export const LoverIcon: React.FC<React.SVGProps<SVGPathElement>> = ({ ...props }) => {
  return (
    <path
      d="M50,0 C77.5778947,0 100,22.4221053 100,50 C100,77.5778947 77.5778947,100 50,100 C22.4221053,100 0,77.5778947 0,50 C0,22.4221053 22.4221053,0 50,0 Z M78.0021053,50 C76.4231579,50 75.0538947,51.2623158 74.8442105,52.8412632 C73.4749474,65.2633684 62.8463158,74.9465263 50,74.9465263 C37.1589474,74.9465263 26.5252632,65.2631579 25.1557895,52.8412632 C24.9460842,51.2623158 23.6837895,50 21.9978947,50 C20.1023158,50 18.7330526,51.6858947 18.9469474,53.4745263 C20.7355789,69.1566316 33.8932632,81.2618947 49.9995789,81.2618947 C66.1058947,81.2618947 79.2637895,69.1566316 81.0522105,53.5766316 C81.2619158,51.6851579 79.7898947,49.9993684 78.0012632,49.9993684 L78.0021053,50 Z M21.9978947,40 C23.2602105,40 24.5225263,39.1570737 24.9461053,37.8947368 C25.7890316,35.4728421 28.104,33.6842105 30.8425263,33.6842105 C33.5810526,33.6842105 35.896,35.4728421 36.7389474,37.8947368 C37.1583579,39.1570526 38.3178947,40 39.6871579,40 C41.8993684,40 43.3713684,37.7877895 42.6353684,35.7894737 C40.9494737,30.9456842 36.3195789,27.4753684 30.8469474,27.4753684 C25.4768421,27.4753684 20.8469474,30.9498947 19.0585263,35.7894737 C18.4211895,37.7877895 19.8932316,40 21.9985263,40 L21.9978947,40 Z M60.3168421,40 C61.5791579,40 62.8414737,39.1570737 63.2650526,37.8947368 C64.1079789,35.4728421 66.4229474,33.6842105 69.1614737,33.6842105 C71.9,33.6842105 74.2149474,35.4728421 75.0578947,37.8947368 C75.4773053,39.1570526 76.6368421,40 78.0061053,40 C80.2183158,40 81.6903158,37.7877895 80.9543158,35.7894737 C79.2684211,30.9456842 74.6385263,27.4753684 69.1658947,27.4753684 C63.7957895,27.4753684 59.1658947,30.9498947 57.3774737,35.7894737 C56.6332316,37.7877895 58.1052737,40 60.3174737,40 L60.3168421,40 Z"
      {...props}
    />
  );
};

export const PassionateIcon: React.FC<React.SVGProps<SVGPathElement>> = ({ ...props }) => {
  return (
    <path
      d="M50,0 C77.5778947,0 100,22.4221053 100,50 C100,77.5778947 77.5778947,100 50,100 C22.4221053,100 0,77.5778947 0,50 C0,22.4221053 22.4221053,0 50,0 Z M42.8410526,30.6336842 C42.8410526,26.2710526 39.3089474,22.7389474 34.9463158,22.7389474 C30.5877895,22.7389474 27.0515789,26.2710526 27.0515789,30.6336842 C27.0515789,34.9922105 30.5877895,38.5284211 34.9463158,38.5284211 C39.3089474,38.5284211 42.8410526,34.9922105 42.8410526,30.6336842 Z M72.9484211,30.6336842 C72.9484211,26.2710526 69.4122105,22.7389474 65.0536842,22.7389474 C60.6910526,22.7389474 57.1589474,26.2710526 57.1589474,30.6336842 C57.1589474,34.9922105 60.6910526,38.5284211 65.0536842,38.5284211 C69.4122105,38.5284211 72.9484211,34.9922105 72.9484211,30.6336842 Z M74.2105263,48.7378947 L25.7894737,48.7378947 C24.3174737,48.7378947 22.8412632,49.3711158 21.8955789,50.5265263 C20.9498526,51.6861053 20.5263158,53.1581053 20.736,54.7370526 C23.0509474,69.1570526 35.3696842,79.6833684 50.0002105,79.6833684 C64.6307368,79.6833684 76.9496842,69.1570526 79.2644211,54.7370526 C79.4741263,53.2650526 79.0547158,51.6861053 78.1048421,50.5265263 C77.1591158,49.3669474 75.6829474,48.7378947 74.2109474,48.7378947 L74.2105263,48.7378947 Z"
      {...props}
    />
  );
};

export const IndifferentIcon: React.FC<React.SVGProps<SVGPathElement>> = ({ ...props }) => {
  return (
    <path
      d="M50,100 C22.43,100 0,77.57 0,50 C0,22.43 22.43,0 50,0 C77.57,0 100,22.43 100,50 C100,77.57 77.57,100 50,100 Z M65.785,45.516 L65.7851,45.516 C71.9222,45.516 77.21129,40.9187 78.0941,34.821 C78.3402,33.1139 77.1566,31.52799 75.4457,31.2819 C73.7464,31.0397 72.15269,32.2233 71.9066,33.9303 C71.4691,36.9732 68.8402,39.2662 65.7855,39.2662 C62.7308,39.2662 60.098,36.9694 59.6605,33.9303 C59.4144,32.2194 57.8323,31.02409 56.1214,31.2819 C54.41441,31.528 53.23081,33.114 53.473,34.821 C54.355,40.9183 59.6444,45.516 65.785,45.516 Z M34.375,45.516 C40.5113,45.516 45.80428,40.9187 46.691,34.821 C46.9371,33.1139 45.7535,31.52799 44.0426,31.2819 C42.3551,31.0553 40.74959,32.2194 40.5035,33.9303 C40.0621,36.9732 37.4332,39.2662 34.3785,39.2662 C31.3238,39.2662 28.69491,36.9694 28.2535,33.9303 C28.0074,32.2194 26.4253,31.05924 24.7144,31.2819 C23.0035,31.528 21.81991,33.114 22.066,34.821 C22.945,40.9183 28.2383,45.516 34.375,45.516 Z M59.375,68.75 C59.375,63.5742 55.1758,59.375 50,59.375 C44.8242,59.375 40.625,63.5742 40.625,68.75 C40.625,73.9258 44.8242,78.125 50,78.125 C55.1758,78.125 59.375,73.9258 59.375,68.75 Z"
      {...props}
    />
  );
};

export const PencilIcon = withSvg(
  31,
  31,
)(() => (
  <g>
    <path d="M26.32379,10.7812173 L20.1191694,4.57659671 L21.5998175,3.09594861 L27.8044381,9.30056922 L26.32379,10.7812173 Z M9.80323283,27.3201616 L3.5994897,21.1146635 L19.0427179,5.67580275 L25.246461,11.8813008 L9.80323283,27.3201616 Z M30.2521526,6.93005252 L29.0555968,8.32603432 L22.7736787,2.04411621 L24.0699475,0.747847394 C24.9673644,-0.249282465 26.6624851,-0.249282465 27.659615,0.747847394 L30.2521526,3.34038503 C31.2492825,4.33751489 31.2492825,5.93292266 30.2521526,6.93005252 Z M0.0391178875,29.8640393 L2.73136851,22.3855653 L8.51472169,28.1689185 L1.03624775,30.8611691 C0.93653476,30.8611691 0.836821774,30.8611691 0.737108789,30.8611691 C0.537682817,30.8611691 0.338256845,30.7614561 0.238543859,30.6617432 C0.0391178875,30.4623172 -0.0605950984,30.1631782 0.0391178875,29.8640393 Z" />
  </g>
));

export const Dragger: React.FC<any> = ({ className, ...props }) => {
  const tone1 = props.tone1 || '#D8D8D8';
  const style = { '--tone1-default': tone1 } as CSSProperties;
  return (
    <svg fillRule="evenodd" viewBox="0 0 3 14" className={className} style={style}>
      <circle cx="1.5" cy="1.5" r="1.5" style={{ fill: 'var(--tone1, var(--tone1-default))' }} />
      <circle cx="1.5" cy="7" r="1.5" style={{ fill: 'var(--tone1, var(--tone1-default))' }} />
      <circle cx="1.5" cy="12.5" r="1.5" style={{ fill: 'var(--tone1, var(--tone1-default))' }} />
    </svg>
  );
};

export const Market: React.FC<React.SVGProps<SVGElement>> = ({ className }) => {
  const tone1 = '#AAAAAA';
  const tone2 = '#444444';

  const style = { '--tone1-default': tone1, '--tone2-default': tone2 } as CSSProperties;
  return (
    <svg fillRule="evenodd" viewBox="0 0 14 14" style={style} className={className}>
      <path
        d="M6.29999962,6.29999962 L0,6.29999962 C0,2.82014983 2.82014983,0 6.29999962,0 L6.29999962,6.29999962 Z"
        style={{ fill: 'var(--tone2, var(--tone2-default))' }}
      />
      <path
        d="M7.46953455,1.01954994 L7.46953455,7.31954956 L1.16953493,7.31954956 C1.16953493,10.7993994 3.98968476,13.6195492 7.46953455,13.6195492 C10.9493843,13.6195492 13.7695342,10.7993994 13.7695342,7.31954956 C13.7695342,3.83969977 10.9499843,1.01954994 7.46953455,1.01954994 Z"
        style={{ fill: 'var(--tone1, var(--tone1-default))' }}
      />
    </svg>
  );
};
