import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import useForm from '../../hooks/useForm';
import { reportCategoriesV2ListRQKey, RQUtils } from '../../utils/reactQuery.utils';
import {
  CompactReportPhrase,
  ReportCategoryV2,
  trpc,
  UpdateReportCategoryV2,
} from '../../utils/trpc';
import Popup from '../popup/popup';
import { Input } from 'src/PoseidonComponents/Input/Input';

interface EditConceptV2PopupPopupProps {
  reportId: string;
  concept: ReportCategoryV2;
  reportPhrasesById: Record<string, CompactReportPhrase> | undefined;
  onClose: (concept: ReportCategoryV2) => void;
}

export const EditConceptV2Popup: React.FC<EditConceptV2PopupPopupProps> = ({
  concept,
  reportPhrasesById,
  reportId,
  onClose,
}) => {
  const queryClient = useQueryClient();

  const updatedConceptMut = trpc.reportCategoriesV2.update.useMutation({
    onSuccess: (updatedConcept) => {
      queryClient.setQueryData<ReportCategoryV2[]>(reportCategoriesV2ListRQKey(reportId), (old) => {
        return RQUtils.updateArrQuery(old, updatedConcept);
      });

      onClose(updatedConcept);
    },
  });
  const updatedConcept = (concept: ReportCategoryV2, newValues: UpdateReportCategoryV2) => {
    if (updatedConceptMut.isLoading == true) {
      return;
    }
    updatedConceptMut.mutate({ reportId, conceptId: concept.id, newValues: newValues });
  };

  const { values, handleChange, registerSubmit } = useForm({ name: concept.name });
  const onSubmit = async ({ values }: any) => {
    const updateValues: UpdateReportCategoryV2 = {
      name: values['name'],
    };
    updatedConcept(concept, updateValues);
  };

  return (
    <Popup onClose={() => onClose(concept)}>
      <h4>Edit concept</h4>

      <form onSubmit={registerSubmit(onSubmit)}>
        <Input placeholder="concept name" value={values.name} name="name" onChange={handleChange} />
      </form>
    </Popup>
  );
};
