import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCorners,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

import { keyBy, mapValues } from 'lodash';
import React from 'react';
import { createPortal } from 'react-dom';
import { SvrReportBrand } from 'src/utils/trpc';
import { ReportUserAndTeam } from '../../Route';
import { useReportUpdateMutation } from '../../trpcHooks/useReportMutation';
import { isNotNull } from '../../utils2';
import { UpdateButton } from '../Button/CustomButton';
import { ContentDivider } from '../ContentDivider/ContentDivider';
import { ContentSection } from '../ContentSection/ContentSection';
import { Form } from '../Form/Form';
import { ColumnsGrid } from '../Grid/Grid';
import { Input } from '../Input/Input';
import { Right } from '../Layout/Layout';
import { CardBrand } from './CardBrand';
import { Container } from './Droppable';
import { SortableItem } from './SortableItem.';
import styles from './draftReportSections.module.scss';

export const DraftBrandGroupsSectionV2: React.FC<ReportUserAndTeam> = ({ report }) => {
  const updateMutation = useReportUpdateMutation();
  const [addingGroupName, setAddingGroupName] = React.useState<any>();

  const [brandsByGroupName, groupNames, brandsWithoutGroup] = React.useMemo(() => {
    const brandById = keyBy(report.brands, (b) => b._id.toHexString());
    const groupedBrandIds = new Set(Object.values(report.brandGroups || {}).flat());
    const brandsByGroupName = mapValues(report.brandGroups, (ids) =>
      ids.map((id) => brandById?.[id]).filter(isNotNull),
    );
    const groupNames = Object.keys(brandsByGroupName || {}).sort();
    const brandsWithoutGroup = report.brands?.filter(
      (brand) => !groupedBrandIds.has(brand._id.toHexString()),
    );
    return [brandsByGroupName, groupNames, brandsWithoutGroup];
  }, [report]);
  const [draggingBrand, setDraggingBrand] = React.useState<SvrReportBrand>();

  const submitGroup = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newBrandGroups = { ...report.brandGroups, [addingGroupName]: [] };
    await updateMutation.mutateAsync({
      id: report._id.toHexString(),
      data: { brandGroups: newBrandGroups },
    });
    setAddingGroupName(null);
  };

  const deleteGroup = async (group: string) => {
    const brandGroups = report.brandGroups;
    const newBrandGroups = { ...brandGroups };
    delete newBrandGroups[group];

    await updateMutation.mutateAsync({
      id: report._id.toHexString(),
      data: { brandGroups: newBrandGroups },
    });
  };

  const brandGroups = report.brandGroups;

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  const onDragStart = (event: DragStartEvent) => {
    if (typeof event.active.id == 'string') {
      const activeId = event.active.id;
      const brand = report.brands?.find((brand) => brand._id.toHexString() === activeId);
      setDraggingBrand(brand);
    }
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    const activeId = event.active.id;
    const overId = event.over?.id;

    if (overId == null) return;
    if (overId == activeId) return;

    const activeContainer = event.active.data?.current?.groupId;
    const overContainer = event.over?.data?.current?.groupId;

    if (overContainer === activeContainer) {
      return;
    }

    let newBrandGroups = {
      ...brandGroups,
    };

    if (
      typeof overContainer === 'string' &&
      typeof activeId === 'string' &&
      overContainer.length > 0
    ) {
      const q = brandGroups?.[overContainer] || [];
      newBrandGroups[overContainer] = [...q, activeId];
    }

    if (activeContainer != null) {
      newBrandGroups[activeContainer] =
        brandGroups?.[activeContainer]?.filter((el) => el !== activeId) || [];
    }

    if (activeContainer === '' && typeof activeId === 'string') {
      const q = brandGroups?.[overContainer] || [];
      newBrandGroups[overContainer] = [...q, activeId];
    }

    await updateMutation.mutateAsync({
      id: report._id.toHexString(),
      data: { brandGroups: newBrandGroups },
    });
  };

  const deleteBrand = async (name: string, groupName?: string) => {
    const brandGroup = report.brandGroups;
    if (!groupName) return undefined;
    const newBrandGroups = {
      ...brandGroups,
      [groupName]: brandGroup?.[groupName]?.filter((el) => el !== name) || [],
    };

    await updateMutation.mutateAsync({
      id: report._id.toHexString(),
      data: { brandGroups: newBrandGroups },
    });
  };

  const brandById = React.useMemo(() => {
    return keyBy(report.brands, (el) => el._id.toHexString());
  }, [report.brands]);

  return (
    <ContentSection>
      <ContentDivider>Group Brands</ContentDivider>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragEnd={handleDragEnd}
        onDragStart={onDragStart}>
        <ColumnsGrid gap={20} columns={3} columnMinWidth={250}>
          {groupNames?.map((name) => {
            const brands = brandGroups?.[name]?.map((el) => brandById[el]).filter(isNotNull);
            return (
              <CardBrand
                brands={brands}
                key={name}
                name={name}
                report={report}
                deleteGroup={deleteGroup}
                deleteBrand={deleteBrand}
              />
            );
          })}
          <Form onSubmit={submitGroup}>
            <ContentSection>
              <Input
                placeholder="+ Add Group"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setAddingGroupName(e.target.value)
                }
                value={addingGroupName || ''}
              />
              <Right>
                <UpdateButton isLoading={updateMutation.isLoading}>Update</UpdateButton>
              </Right>
            </ContentSection>
          </Form>
          <div className={styles.ungroupedBrandsContainer}>
            <ContentDivider>Ungrouped brands</ContentDivider>
            <Container deleteBrand={deleteBrand} brands={brandsWithoutGroup}></Container>
          </div>
          {createPortal(
            <DragOverlay>
              {draggingBrand && (
                <SortableItem
                  id={draggingBrand._id.toHexString()}
                  brand={draggingBrand}
                  groupId={draggingBrand.name}
                />
              )}
            </DragOverlay>,
            document.body,
          )}
        </ColumnsGrid>
      </DndContext>
    </ContentSection>
  );
};
