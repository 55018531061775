import clsx from 'clsx';
import React from 'react';
import styles from './Button.module.scss';
import { ChevronDown } from 'src/PoseidonComponents/StrokeIcons';

interface SelectProps extends React.HTMLProps<HTMLButtonElement> {
  icon?: JSX.Element;
}

export const Select: React.FC<SelectProps> = ({ icon, children, className, onClick }) => {
  const classList = clsx(styles.button, onClick ? styles.hoverable : styles.disabled, className);
  return (
    <button className={classList} onClick={onClick}>
      {icon != null && <div className={styles.icon}>{icon}</div>}
      {children}
      <div className={styles.chevron}>
        <ChevronDown />
      </div>
    </button>
  );
};

export const SmallSelect: React.FC<SelectProps> = ({ className, ...props }) => {
  return <Select {...props} className={clsx(className, styles.small)} />;
};

export const SmallSelectV2: React.FC<SelectPropsV2> = ({ className, ...props }) => {
  return <SelectV2 {...props} className={clsx(className, styles.small)} />;
};

interface SelectPropsV2 extends React.HTMLProps<HTMLSelectElement> {
  icon?: JSX.Element;
}

export const SelectV2: React.FC<SelectPropsV2> = ({ icon, children, className, onChange }) => {
  const classList = clsx(styles.button, onChange ? styles.hoverable : styles.disabled, className);
  return (
    <select className={classList} onChange={onChange}>
      {children}
    </select>
  );
};
