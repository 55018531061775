import { Placement } from '@floating-ui/react-dom-interactions';
import { Popover } from '@mui/material';
import React from 'react';
import styles from './PopupPopover.module.scss';
import { PreviewPopupPickerWithData } from './PreviewPopupPicker/PreviewPopupPicker';

interface Props {
  children: (handleClick: (e: React.MouseEvent<HTMLElement>) => void) => JSX.Element;
  reviewId: string;
  reportId: string;
  className?: string;
  placement?: Placement;
  autoPlace?: boolean;
}

const PopupPopover: React.FC<Props> = ({
  children,
  reviewId,
  reportId,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Popover
        classes={{'paper': styles.popover}}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        >
        <PreviewPopupPickerWithData
          handleClose={handleClose}
          reportId={reportId}
          reviewId={reviewId}
        />
      </Popover>
      {children(handleClick)}
    </React.Fragment>
  );
};

export default PopupPopover;
