import React, { useEffect } from 'react';
import { isMacLike } from 'src/utils2';

const defaultOptions = {
  when: true,
  eventTypes: ['keydown'],
};

export const useKeyOld = (keyList: string[] = [], handler: (any: any) => void, opts?: any) => {
  const options = { ...defaultOptions, ...opts };
  const { when, eventTypes } = options;
  const { target } = options;
  let targetNode: any;
  if (typeof window !== 'undefined') targetNode = window;
  if (target?.current) targetNode = target.current;

  useEffect(() => {
    const handle = (e: any) => {
      if (keyList.includes(e.key) || keyList.includes(e.keyCode)) {
        handler(e);
      }
    };
    if (when) {
      eventTypes.forEach((eventType: any) => {
        targetNode.addEventListener(eventType, handle);
      });
      return () => {
        eventTypes.forEach((eventType: any) => {
          targetNode.removeEventListener(eventType, handle);
        });
      };
    }
  }, [when, eventTypes, keyList, targetNode, handler]);
};

export const useGlobalKey = (key: string, handler: any) => {
  useEffect(() => {
    const handle = (e: any) => {
      if (key === e.key || key === e.keyCode) handler(e);
    };
    ['keydown'].forEach((eventType) => {
      window.addEventListener(eventType, handle);
    });
    return () => {
      ['keydown'].forEach((eventType) => {
        window.removeEventListener(eventType, handle);
      });
    };
  }, [key, handler]);
};

interface Options {
  metaWindowsCtrlKey?: boolean;
  metaKey?: boolean;
  shiftKey?: boolean;
  ctrlKey?: boolean;
}

export const useKey = (key: string, onKeyPress: () => void, options: Options = {}) => {
  const { metaKey, shiftKey, ctrlKey, metaWindowsCtrlKey } = options;

  React.useEffect(() => {
    const keyPressFn = (e: KeyboardEvent) => {
      if (e.target instanceof HTMLElement) {
        if (e.target.tagName === 'TEXTAREA') return;
        if (e.target.tagName === 'INPUT') return;
      }

      if (e.key === key) {
        const extraKey =
          metaKey != null || shiftKey != null || ctrlKey != null || metaWindowsCtrlKey != null;

        if (extraKey === true) {
          if (metaKey != null && metaKey == e.metaKey) {
            onKeyPress();
          } else if (ctrlKey != null && ctrlKey === e.ctrlKey) {
            onKeyPress();
          } else if (shiftKey != null && shiftKey === e.shiftKey) {
            onKeyPress();
          } else if (metaWindowsCtrlKey != null) {
            const isMac = isMacLike();
            if (isMac && e.metaKey) {
              onKeyPress();
            } else if (!isMac && e.ctrlKey) {
              onKeyPress();
            }
          }
        } else {
          onKeyPress();
        }
      }
    };
    window.addEventListener('keydown', keyPressFn);
    return () => window.removeEventListener('keydown', keyPressFn);
  }, [onKeyPress, key, metaKey, shiftKey, ctrlKey, metaWindowsCtrlKey]);
};
