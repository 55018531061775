import { trpc } from '../utils/trpc';

export const useUpdateDefaultTeamId = () => {
  const utils = trpc.useContext();

  return trpc.user.updateDefaultTeamId.useMutation({
    onSuccess: () => {
      utils.user.me.invalidate();
    },
  });
};



export const useMeUpdateMutation = () => {
  const utils = trpc.useContext();

  return trpc.user.meUpdate.useMutation({
    onSuccess: async () => {
      await utils.user.me.invalidate();
    }
  });
}
