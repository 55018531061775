import React from 'react';
import { Star } from '../../PoseidonComponents/StrokeIcons';
import useRouterQuery, { Rating } from '../../hooks/useRouterQuery';
import { getRangeColor2 } from '../../utils2';
import styles from './RatingsSection.module.scss';
import { Row } from './Row';

interface StarRatingSectionProps {
  ratings?: Record<string, number>;
}
export const StarRatingSection: React.FC<StarRatingSectionProps> = ({ ratings }) => {
  const [search, setSearch] = useRouterQuery();

  // defining above because TS is getting confused in the for loop below
  // and forgetting about the null checks we already did

  const toggleSearch = (v: Rating) => {
    setSearch((s) => ({ ...s, rating: search.rating === v ? undefined : v, page: undefined }));
  };

  const stars = ['1', '2', '3', '4', '5'] as const;
  return (
    <div className={styles.ratings}>
      {stars.map((s, i) => {
        const floatRating = parseFloat(s);
        const startCount = Math.floor(floatRating);
        // a rating scaled to range of 0 to 100
        const centiRating = floatRating * 20;
        const color = getRangeColor2(centiRating);
        const starCount = ratings?.[s] ?? 0;
        const total = ratings?.['total'] ?? 0;
        const ratingValue = total > 0 ? 100 * (starCount / total) : 0;

        const style = {
          gridRowStart: i + 1,
        };

        return (
          <Row
            key={s}
            index={i}
            name={
              <>
                {Array.from(Array(startCount).keys()).map((i) => (
                  <Star key={i} className={styles.star} />
                ))}
              </>
            }
            style={style}
            count={starCount}
            color={color}
            rating={ratingValue}
            selected={search?.rating === `${s}`}
            tooltip={<div>Click to see {startCount} Star Reviews</div>}
            onClick={() => toggleSearch(`${s}`)}
          />
        );
      })}
    </div>
  );
};

// interface NpsRatingSectionProps {
//   data: IAggregatedCounts;
//   facetData: ScoreFacets | undefined;
// }

// export const NpsRatingSection: React.FC<NpsRatingSectionProps> = ({ data, facetData }) => {
//   const [search, setSearch] = useRouterQuery();

//   if (data?.bktd == null) return null;
//   if (data?.count == null) return null;

//   const dataCount = data.count;

//   const toggleSearch = (v: Rating) => {
//     setSearch((s) => ({ ...s, rating: search.rating === v ? undefined : v, page: undefined }));
//   };

//   const facetCountsByRating = facetData?.buckets?.reduce<Map<string, number>>((acc, curr) => {
//     if (isString(curr._id)) {
//       return acc;
//     }
//     const key = curr._id.toFixed(1);
//     acc.set(key, curr.count);
//     return acc;
//   }, new Map());

//   const getNpsSmile = (score: number) => {
//     switch (getNPSScore(score)) {
//       case 0:
//         return NpsSmiles.Smile0;
//       case 1:
//         return NpsSmiles.Smile1;
//       case 2:
//         return NpsSmiles.Smile2;
//       case 3:
//         return NpsSmiles.Smile3;
//     }
//   };

//   return (
//     <div className={styles.ratings}>
//       {RatingValues.map((s, i, arr) => {
//         const floatScore = parseFloat(s);
//         const floatScoreAsFixed = floatScore.toFixed(1);

//         const nps = Math.floor(floatScore * 2);
//         // a rating scaled to range of 0 to 100
//         const centiRating = floatScore * 20;

//         const color = getRangeColor2(centiRating);

//         const bktdIdx = nps;
//         // if facet data exists, us it
//         const bucketCount =
//           facetCountsByRating != null
//             ? facetCountsByRating.get(floatScoreAsFixed) || 0
//             : data.bktd?.[bktdIdx] || 0;

//         const percentageOfTotal =
//           bucketCount != null && dataCount > 0 ? (100 * bucketCount) / dataCount : 0;

//         const style = {
//           gridRowStart: arr.length - nps,
//         };
//         const className = clsx(styles.row, search?.rating === s ? styles.selected : null);
//         const outerStyle = { '--color': color } as React.CSSProperties;
//         const scoreStyle = { '--color': getNPSColor(getNPSScore(nps)) } as React.CSSProperties;
//         return (
//           <div
//             key={s}
//             className={className}
//             style={outerStyle}
//             onClick={() => toggleSearch(`${s}`)}>
//             <div className={styles.rowName} style={style}>
//               <div className={styles.delightedScore} style={scoreStyle}>
//                 {React.createElement(getNpsSmile(nps))}
//                 <div className={styles.divider} />
//                 <span>{nps}</span>
//               </div>
//             </div>
//             <div className={styles.ratingBar} style={style}>
//               <RatingBar value={percentageOfTotal} />
//             </div>
//             <div className={styles.rowCount} style={style}>
//               {bucketCount}
//             </div>
//             <Tooltip label={`Click to see reviews with a NPS score of ${nps}`}>
//               <div className={styles.tooltipRow} style={style} />
//             </Tooltip>
//           </div>
//         );
//       })}
//     </div>
//   );
// };

export default StarRatingSection;
