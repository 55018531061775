import clsx from 'clsx';
import React from 'react';
import { useKey } from '../../hooks/useKey';
import { useRouterQuery } from '../../hooks/useRouterQuery';
import { Kbd } from '../Kbd/Kbd';
import { XMark } from '../StrokeIcons';
import { SvgIconButton } from '../SvgIconButton/SvgIconButton';
import styles from './DetailHeader.module.scss';

interface DetailHeaderProps extends React.HTMLProps<HTMLDivElement> {
  buttons?: React.ReactNode;
  footer?: React.ReactNode;
  queryKey?: string;
}

export const DetailHeader: React.FC<DetailHeaderProps> = ({
  children,
  buttons,
  footer,
  queryKey = 'id',
}) => {
  const [, setQuery] = useRouterQuery();
  const onClose = () => setQuery((o) => ({ ...o, [queryKey]: undefined, add: undefined }));
  useKey('Escape', () => onClose());
  return (
    <div className={clsx(styles.container, footer ? styles.hasFooter : undefined)}>
      <div className={styles.header}>
        <div>{children}</div>
        <div className={styles.buttons}>
          {buttons}
          <SvgIconButton
            icon={<XMark />}
            onClick={onClose}
            tooltip={
              <span>
                Close <Kbd>ESC</Kbd>
              </span>
            }
          />
        </div>
      </div>
      {footer}
    </div>
  );
};
