import React from 'react'
import useLocalStorage from 'react-use-localstorage';

import {CloseIcon} from '../icons/icons'
import styles from './weUseCookies.module.scss'

export const WeUseCookies = () => {
  const [weUseCookies, setWeUseCookies] = useLocalStorage('weUseCookies', 'false');
  if(weUseCookies === 'true') return null;

  return <div className={styles.container}>
    <div className={styles.spacer} />
    <div className={styles.content}>
      <div className={styles.header}>This website uses cookies and other tracking tools to provide you with the best experience.</div>
      <div className={styles.subHeader}>By using our site, you acknowledge that you understand this and are willing to comply with the terms in our privacy policy</div>
    </div>
    <div className={styles.spacer} />
    <div className={styles.closeButton} onClick={() => setWeUseCookies('true')}>
      <CloseIcon />
    </div>
  </div>
}

export default WeUseCookies