import React from 'react';
import { trpc } from '../utils/trpc';

export const useUpdateBrandMutation = () => {
  const utils = trpc.useContext();
  return trpc.reportBrand.update.useMutation({
    onSuccess: (_, { reportId }) => {
      utils.report.all.invalidate();
      utils.report.id.invalidate(reportId);
    },
  });
};


export const useReportBrandUpdateMutation = () => {
  const utils = trpc.useContext();

  return trpc.reportBrand.update.useMutation({
    onSuccess: (_, { brandId, reportId }) => {
      utils.reportBrand.id.invalidate({ brandId, reportId });
      utils.report.id.invalidate(reportId);
    },
  });
};



export const useDeleteBrandMutation = () => {
  const utils = trpc.useContext();

  return trpc.reportBrand.delete.useMutation({
    onSuccess: (_, {reportId}) => {
      utils.report.all.invalidate()
      utils.report.id.invalidate(reportId)
    },
  });
};


export const useAddBrandMutation = () => {
  const utils = trpc.useContext();

  return trpc.reportBrand.add.useMutation({
    onSuccess: (_, { reportId }) => {
      utils.report.all.invalidate();
      utils.report.id.invalidate(reportId);
    },
  });
};
