import clsx from 'clsx';
import type { ReactNode } from 'react';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { Tooltip } from 'src/libs/Tooltip';
import { InputSize } from '../Input/types';
import styles from './SvgIconButton.module.scss';

interface SvgIconProps {
  icon: JSX.Element;
  tooltip?: ReactNode;
  size?: InputSize;
}

type ButtonType = 'submit' | 'dangerous';

interface SvgIconLinkProps extends LinkProps, SvgIconProps {}
export const SvgIconLink: React.FC<SvgIconLinkProps> = ({ icon, tooltip, className, size, to }) => {
  const classList = clsx(styles.button, className, size == 'small' ? styles.small : undefined);
  if (tooltip) {
    return (
      <Tooltip label={tooltip}>
        <Link className={classList} to={to}>
          {icon}
        </Link>
      </Tooltip>
    );
  }
  return (
    <Link className={classList} to={to}>
      {icon}
    </Link>
  );
};

interface SvgIconAnchorProps
  extends Omit<React.HTMLProps<HTMLAnchorElement>, 'size'>,
    SvgIconProps {}
export const SvgIconAnchor: React.FC<SvgIconAnchorProps> = ({
  icon,
  tooltip,
  className,
  href,
  target,
  size,
}) => {
  const classList = clsx(styles.button, className, size == 'small' ? styles.small : undefined);
  if (tooltip) {
    return (
      <Tooltip label={tooltip}>
        <a className={classList} href={href} target={target}>
          {icon}
        </a>
      </Tooltip>
    );
  }
  return (
    <a className={classList} href={href} target={target}>
      {icon}
    </a>
  );
};

interface SvgIconButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'>, SvgIconProps {
  type?: ButtonType;
}
export const SvgIconButton: React.FC<SvgIconButtonProps> = ({
  icon,
  tooltip,
  type,
  onClick,
  className,
  size,
  ...props
}) => {
  const classList = clsx(
    styles.button,
    className,
    size == 'small' ? styles.small : undefined,
    type ? styles[type] : undefined,
  );
  if (tooltip) {
    return (
      <Tooltip label={tooltip}>
        <button className={classList} onClick={onClick} type={type === 'submit' ? 'submit' : undefined} {...props}>
          {icon}
        </button>
      </Tooltip>
    );
  }

  return (
    <button className={classList} onClick={onClick} type={type === 'submit' ? 'submit' : undefined} {...props}>
      {icon}
    </button>
  );
};