import { DayRange } from '@hassanmojab/react-modern-calendar-datepicker';
import useLocalStorage from '@rehooks/local-storage';
import { keyBy } from 'lodash';
import React from 'react';
import DatePicker from 'src/components/datePicker/datePicker';
import useRouterQuery from 'src/hooks/useRouterQuery';
import { ContentDivider } from '../../../PoseidonComponents/ContentDivider/ContentDivider';
import { Flex, WithLeftNav } from '../../../PoseidonComponents/Layout/Layout';
import { PaddedScrollable } from '../../../PoseidonComponents/PaddedScrollable/PaddedScrollable';
import { PageHeader } from '../../../PoseidonComponents/PageHeader/PageHeader';
import { Atom } from '../../../PoseidonComponents/StrokeIcons';
import { WaveSpinner } from '../../../PoseidonComponents/WaveSpinner';
import { useReportLogEffect } from '../../../libs/react-amplitude';
import PolarCard from '../../../marksyComponents/PolarCard2/PolarCard2';
import ConceptSummaryCard from '../../../marksyComponents/conceptSummaryCard/conceptSummaryCard';
import HeatmapCard from '../../../marksyComponents/heatmapCard/heatmapCard';
import LoversHatersCard from '../../../marksyComponents/loversHatersCard/loversHatersCard';
import { ReportCategory, ServerDataCounts, SvrReport, trpc } from '../../../utils/trpc';
import { getSortedBrands2, isNotNull } from '../../../utils2';

interface ReportExecSummaryProps {
  report?: SvrReport;
  reportConcepts: ReportCategory[];
  dataCounts?: ServerDataCounts;
}
export const ReportExecSummary: React.FC<ReportExecSummaryProps> = ({
  report,
  reportConcepts,
  dataCounts,
}) => {
  useReportLogEffect({ eventType: 'viewInfo', report }, [report]);
  if (report == null) return <WaveSpinner />;

  return (
    <WithLeftNav hasDetail={false}>
      <PageHeader icon={<Atom />} title="Insights">
        <ContentDivider end={<DatePicker />} />
      </PageHeader>
      <DefinedReportExecSummary
        report={report}
        reportConcepts={reportConcepts}
        dataCounts={dataCounts}
      />
    </WithLeftNav>
  );
};

interface DefinedReportExecSummaryProps {
  report: SvrReport;
  reportConcepts: ReportCategory[];
  dataCounts?: ServerDataCounts;
}
const DefinedReportExecSummary: React.FC<DefinedReportExecSummaryProps> = ({
  report,
  reportConcepts,
  dataCounts,
}) => {
  const [dateTypeValueV2] = useLocalStorage<DayRange>('dateTypeValueV2');

  const reportId = report._id.toHexString();
  const brandGroups = report.brandGroups;
  const [query, setQuery] = useRouterQuery();
  const dateRange = React.useMemo(
    () =>
      !dateTypeValueV2?.from || !dateTypeValueV2?.to
        ? undefined
        : {
            start: new Date(
              dateTypeValueV2.from?.year,
              dateTypeValueV2.from.month - 1,
              dateTypeValueV2.from.day,
            ),
            end: new Date(
              dateTypeValueV2.to.year,
              dateTypeValueV2.to.month - 1,
              dateTypeValueV2.to.day,
            ),
          },
    [dateTypeValueV2],
  );

  const reviewsQuery = trpc.reportReviews.forTopic.useQuery(
    {
      reportId,
      categoryId: query.topicId,
      brandId: query.filterInsights?.split('_')?.[1],
      dateRange,
      limit: 500,
    },
    { enabled: Boolean(query.topicId) },
  );

  const handleChangeFilter = (filterInsights?: string) => {
    setQuery((q) => ({ ...q, filterInsights }));
  };
  const handleChangeTopicId = (topicId?: string) => {
    setQuery((q) => ({ ...q, topicId }));
  };

  const conceptsById: any = React.useMemo(
    () => keyBy(reportConcepts, (r) => r._id),
    [reportConcepts],
  );

  const sortedBrands = React.useMemo(() => {
    return getSortedBrands2(report.brands, dataCounts, 'score', 'asc');
  }, [report, dataCounts]);

  React.useEffect(() => {
    setQuery((q) => ({
      ...q,
      topicId: q.topicId || reportConcepts[0]?._id.toHexString(),
      filterInsights: q.filterInsights,
      polarSentimentInsights: q.polarSentimentInsights
        ? q.polarSentimentInsights
        : sortedBrands
            ?.slice(0, 2)
            .map((d) => d._id.toHexString())
            .join('_'),
    }));
  }, []);

  const filteredBrandIds = React.useMemo(() => {
    if (!query.filterInsights) return;
    if (query.filterInsights?.length > 0) {
      const filterArray = query.filterInsights?.split('_');
      if (!filterArray) return;
      if (filterArray.length === 2) {
        return [filterArray[1]].filter(isNotNull);
      } else if (filterArray.length === 1) {
        const id = filterArray[0];
        // return id ? brandGroups?.[id] : undefined;
      }
      return [];
    }
    return undefined;
  }, [brandGroups, query.filterInsights]);

  return (
    <PaddedScrollable size="small">
      <Flex gap="16px">
        <PolarCard
          report={report}
          concepts={reportConcepts}
          sortedBrands={sortedBrands}
          dataCounts={dataCounts}
        />
        <ConceptSummaryCard
          report={report}
          concepts={reportConcepts}
          filteredBrandIds={filteredBrandIds}
          filter={query.filterInsights}
          handleChangeFilter={handleChangeFilter}
          dataCounts={dataCounts}
        />
        <HeatmapCard
          report={report}
          concepts={reportConcepts}
          filter={query.filterInsights}
          handleChangeFilter={handleChangeFilter}
          filteredBrandIds={filteredBrandIds}
          dataCounts={dataCounts}
        />
        <LoversHatersCard
          report={report}
          concepts={reportConcepts}
          filter={query.filterInsights}
          handleChangeFilter={handleChangeFilter}
          topicId={query.topicId}
          handleChangeTopicId={handleChangeTopicId}
          allReviews={reviewsQuery.data?.reviews || []}
          conceptsById={conceptsById}
        />
      </Flex>
    </PaddedScrollable>
  );
};

export default ReportExecSummary;
