import React from 'react';

export default function useOnScreen<T extends HTMLElement>(
  ref: React.RefObject<T | undefined>,
  rootMargin = '0px',
) {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = React.useState(false);
  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry?.isIntersecting ?? false);
      },
      {
        rootMargin,
      },
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [setIntersecting, ref.current]); // Empty array ensures that effect is only run on mount and unmount
  return isIntersecting;
}
