import { useDroppable } from '@dnd-kit/core';
import clsx from 'clsx';
import React from 'react';

interface Props extends React.HTMLProps<HTMLDivElement> {
  id: string;
  className?: string;
}

export const Droppable: React.FC<Props> = ({ id, children, className }) => {
  const { setNodeRef, isOver } = useDroppable({
    id,
  });

  return (
    <div ref={setNodeRef} className={clsx(className)}>
      {children}
    </div>
  );
};
