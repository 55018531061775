import { DateTime } from 'luxon';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  useReviewCommentAddMutation,
  useReviewCommentDeleteMutation,
} from '../../trpcHooks/useReviewCommentMutation';
import { ReviewComment, SvrReport, SvrReportReview, trpc } from '../../utils/trpc';
import { IconWithPlaceholder } from '../IconWithPlaceholder/IconWithPlaceholder';
import { TextareaWithSubmit } from '../Input/TextArea';
import { TrashCan } from '../StrokeIcons';
import { SvgIconButton } from '../SvgIconButton/SvgIconButton';
import styles from './ReviewCardComment.module.scss';

interface CommentCardProps {
  report: SvrReport;
  review: SvrReportReview;
  comment: ReviewComment;
}
export const ReviewCardComment: React.FC<CommentCardProps> = ({ report, review, comment }) => {
  const reviewCommentDeleteMutation = useReviewCommentDeleteMutation();

  const deleteFn = (commentId: string) => {
    const reportId = report._id.toHexString();
    const reviewId = review._id.toString();

    reviewCommentDeleteMutation.mutateAsync({ reportId, reviewId, commentId });
  };

  return (
    <div className={styles.container}>
      <IconWithPlaceholder
        size={30}
        placeholder={comment.email?.[0] || ''}
        alt="user"
        shape="circle"
      />
      <div className={styles.content}>
        <div className={styles.title}>{comment.email}</div>
        <div className={styles.text}>{comment.text}</div>
      </div>
      <div className={styles.buttons}>
        <SvgIconButton
          size="small"
          icon={<TrashCan />}
          type="dangerous"
          tooltip="Delete Comment"
          onClick={() => deleteFn(comment._id.toHexString())}
        />
      </div>
    </div>
  );
};

interface ReviewResponseProps {
  report: SvrReport;
  review: SvrReportReview;
}
export const ReviewCardResponse: React.FC<ReviewResponseProps> = ({ report, review }) => {
  const reportReviewDeleteResponseMutation = trpc.reportReviews.deleteResponse.useMutation();

  if (review.response == null && review.responseMeta == null) {
    return null;
  }
  const date = review.response?.date ? DateTime.fromJSDate(review.response?.date) : undefined;

  const deleteFn = () => {
    reportReviewDeleteResponseMutation.mutate({
      reportId: report._id.toHexString(),
      reviewId: review._id.toHexString(),
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          RESPONSE: <span>{date?.toRelative()}</span>
        </div>
        <div className={styles.text}>{review.response?.text}</div>
      </div>
      <div className={styles.buttons}>
        <SvgIconButton
          size="small"
          icon={<TrashCan />}
          type="dangerous"
          tooltip="Delete Response"
          onClick={deleteFn}
        />
      </div>
    </div>
  );
};

interface FormData {
  text: string;
}
export const ReviewCardAddComment: React.FC<ReviewResponseProps> = ({ report, review }) => {
  const reviewCommentAddMutation = useReviewCommentAddMutation();
  const { control, handleSubmit } = useForm<FormData>({
    mode: 'all',
  });

  const reportId = report._id.toHexString();
  const reviewId = review._id.toString();

  const onSubmit = (data: FormData) => {
    reviewCommentAddMutation.mutateAsync({ reportId, reviewId, data: data.text });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="text"
        render={({ field }) => <TextareaWithSubmit placeholder="Add Comment" {...field} />}
      />
    </form>
  );
};
