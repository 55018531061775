import React from 'react';
import { ContentDivider } from '../../../PoseidonComponents/ContentDivider/ContentDivider';
import { WithLeftNav } from '../../../PoseidonComponents/Layout/Layout';
import { PaddedScrollable } from '../../../PoseidonComponents/PaddedScrollable/PaddedScrollable';
import { PageHeader } from '../../../PoseidonComponents/PageHeader/PageHeader';
import { ReportAdminSection } from '../../../PoseidonComponents/ReportAdminSection';
import { DraftBrandGroupsSectionV2 } from '../../../PoseidonComponents/ReportAdminsBlocks/DraftBrandGroupsSection';
import { IntegrationsSection } from '../../../PoseidonComponents/ReportAdminsBlocks/IntegrationsSection';
import { ReportBrandsSection } from '../../../PoseidonComponents/ReportAdminsBlocks/ReportBrandsSection';
import { ReportFlourishSection } from '../../../PoseidonComponents/ReportFlourishSection';
import { ReportNameSection } from '../../../PoseidonComponents/ReportNameSection/ReportNameSection';
import { Key } from '../../../PoseidonComponents/StrokeIcons';
import { ReportUserAndTeam } from '../../../Route';

export const ReportAdmin: React.FC<ReportUserAndTeam> = ({ report, user }) => {
  return (
    <WithLeftNav hasDetail={false}>
      <PageHeader icon={<Key />} title="Admin">
        <ContentDivider end={<ReportAdminSection report={report} user={user} />} />
      </PageHeader>

      <PaddedScrollable>
        <ReportNameSection user={user} report={report} />
        <ReportBrandsSection user={user} report={report} />
        {user?.isSuperAdmin && <ReportFlourishSection user={user} report={report} />}

        <DraftBrandGroupsSectionV2 user={user} report={report} />
        <IntegrationsSection user={user} report={report} />
      </PaddedScrollable>
    </WithLeftNav>
  );
};

export default ReportAdmin;
