import clsx from 'clsx';
import React from 'react';
import { InputSize } from '../Input/types';
import styles from './ContentDivider.module.scss';

interface ContentDividerProps extends Omit<React.HTMLProps<HTMLDivElement>, 'size'> {
  icon?: JSX.Element;
  end?: JSX.Element;
  align?: 'left' | 'center' | 'right';
  size?: InputSize;
}

export const ContentDivider = React.forwardRef<HTMLDivElement, ContentDividerProps>(
  ({ children, icon, end, className, size, align = 'left' }, ref) => {
    const classList = clsx(styles.container, className, size ? styles[size] : undefined);
    return (
      <div className={classList} ref={ref}>
        {align != 'left' && <div className={styles.divider} />}
        {icon != null && <div className={styles.icon}>{icon}</div>}
        {children}
        {align != 'right' && <div className={styles.divider} />}
        {end}
      </div>
    );
  },
);
ContentDivider.displayName = 'ContentDivider';