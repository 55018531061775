import React from 'react';
import useRouterQuery from 'src/hooks/useRouterQuery';
import { ReportUserAndTeam } from '../../Route';
import { useReportClassifyDelete } from '../../trpcHooks/useReportClassifyMutation';
import { SvrReportReview, SvrReportReviewClassOverride, trpc } from '../../utils/trpc';
import { DetailHeader } from '../DetailHeader/DetailHeader';
import { PaddedScrollable } from '../PaddedScrollable/PaddedScrollable';
import { Pencil, TrashCan } from '../StrokeIcons';
import { SvgIconButton } from '../SvgIconButton/SvgIconButton';
import { WaveSpinnerContainer } from '../WaveSpinnerContainer/WaveSpinnerContainer';
import { ReviewTextOverlay } from '../review/ReviewText';
import styles from './MachineDetailPage.module.scss';

interface ConceptDetailAddPageProps extends ReportUserAndTeam, React.HTMLProps<HTMLDivElement> {
  reviewId: string;
}

export const MachineDetailPage: React.FC<ConceptDetailAddPageProps> = ({
  report,
  reviewId,
  user,
}) => {
  const reviewBrandQuery = trpc.reportReviews.idWithBrand.useQuery({
    reportId: report._id.toHexString(),
    reviewId,
  });
  const review = reviewBrandQuery.data?.review;
  const classOverrides = reviewBrandQuery.data?.classOverrides;

  if (reviewBrandQuery.isLoading) {
    return (
      <>
        <DetailHeader queryKey="machine">Training</DetailHeader>
        <WaveSpinnerContainer />
      </>
    );
  } else if (review == null) {
    return (
      <>
        <DetailHeader queryKey="machine">Training</DetailHeader>
        <WaveSpinnerContainer />
      </>
    );
  }

  return (
    <MachineDetaileWithData
      review={review}
      report={report}
      user={user}
      classOverrides={classOverrides}
    />
  );
};

interface MachineDetaileWithDataProps extends ReportUserAndTeam, React.HTMLProps<HTMLDivElement> {
  review: SvrReportReview;
  classOverrides?: SvrReportReviewClassOverride[];
}
const MachineDetaileWithData: React.FC<MachineDetaileWithDataProps> = ({
  review,
  report,
  classOverrides,
}) => {
  const reportClassifyDeleteMutation = useReportClassifyDelete();

  const deleteFn = (classificationId: string) => {
    reportClassifyDeleteMutation.mutate({
      reportId: report._id.toHexString(),
      reviewId: review._id.toHexString(),
      classificationId,
    });
  };
  const [query, setQuery] = useRouterQuery();

  const editFn = (rid: string, start: number, end: number, id: string) => {
    setQuery((q) => ({
      ...q,
      machine: rid ? { rid, start, end, id } : undefined,
    }));
  };
  return (
    <>
      <DetailHeader queryKey="machine">Training</DetailHeader>
      <PaddedScrollable size="small" className={styles.linesSection}>
        {classOverrides?.map((o) => {
          const text = review.text?.slice(o.b, o.e);
          return (
            <div key={o._id.toHexString()} className={styles.lineSection}>
              <div aria-label="reviewBody" className={styles.body}>
                <ReviewTextOverlay line={text} newScore={o.score} isMachine={!!query.machine?.id} />
              </div>
              <div className={styles.navigation}>
                <SvgIconButton
                  icon={<TrashCan />}
                  tooltip="Delete"
                  size="small"
                  onClick={() => deleteFn(o._id.toHexString())}
                />
                <SvgIconButton
                  icon={<Pencil />}
                  tooltip="Edit"
                  size="small"
                  onClick={() => editFn(review._id.toHexString(), o.b, o.e, o._id.toHexString())}
                />
              </div>
            </div>
          );
        })}
      </PaddedScrollable>
    </>
  );
};
