import { Controller, useForm } from 'react-hook-form';
import { useTeamUpdate } from 'src/trpcHooks/useTeamMutation';
import { SvrTeam, SvrUser } from 'src/utils/trpc';
import { UpdateButton } from '../Button/CustomButton';
import { ContentSection } from '../ContentSection/ContentSection';
import { TeamLogo } from '../IconWithPlaceholder/IconWithPlaceholder';
import { Input } from '../Input/Input';
import { LogoUpload } from '../LogoUpload/LogoUpload';

interface ProfileSectionProps {
  user: SvrUser;
  team: SvrTeam['team'];
}
interface TeamFormData {
  name: string;
}
export const TeamProfileEditSection: React.FC<ProfileSectionProps> = ({ user, team }) => {
  const teamUpdateMutation = useTeamUpdate();
  const teamId = team._id.toHexString();
  const { control, handleSubmit } = useForm<TeamFormData>({
    mode: 'all',
    defaultValues: {
      name: team.name,
    },
  });

  const isAdmin = user._id === team.adminId;
  const disabled = !isAdmin || teamUpdateMutation.isLoading;

  const onSubmit = (data: TeamFormData) => {
    teamUpdateMutation.mutate({ id: teamId, data });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ContentSection>
        {!disabled && (
          <LogoUpload>
            <TeamLogo team={team} size={150} shape="circle" />
          </LogoUpload>
        )}
        {disabled && <TeamLogo team={team} size={150} shape="circle" />}

        <Controller
          control={control}
          name="name"
          render={({ field }) => (
            <Input
              title="Name"
              placeholder="Name"
              disabled={disabled}
              // length="restricted"
              {...field}
            />
          )}
        />

        {isAdmin && <UpdateButton isLoading={teamUpdateMutation.isLoading}>Update</UpdateButton>}
      </ContentSection>
    </form>
  );
};
