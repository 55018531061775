import React from 'react';
import { Scrollable } from 'src/components/Scrollable/Scrollable';
import clsx from 'clsx';
import { InputSize } from '../Input/types';
import styles from './PaddedScrollable.module.scss';

interface PaddedScrollableProps extends Omit<React.HTMLProps<HTMLDivElement>, 'size'> {
  fullHeight?: boolean;
  size?: InputSize;
  onScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
}

export const PaddedScrollable: React.FC<PaddedScrollableProps> = ({
  children,
  onScroll,
  size,
  className,
  fullHeight,
}) => {
  return (
    <Scrollable
      innerClassName={clsx(
        className,
        styles.content,
        size ? styles[size] : undefined,
        fullHeight ? styles.fullHeight : undefined,
      )}
      onScroll={onScroll}>
      {children}
    </Scrollable>
  );
};

export const Padded: React.FC<PaddedScrollableProps> = ({
  children,
  className,
  size,
  fullHeight,
}) => {
  return (
    <div
      className={clsx(
        styles.content,
        size ? styles[size] : undefined,
        className,
        fullHeight ? styles.fullHeight : undefined,
      )}>
      {children}
    </div>
  );
};
