import clsx from 'clsx';
import { FirebaseError } from 'firebase/app';
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { Controller, useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { SubmitButton } from '../PoseidonComponents/Button/CustomButton';
import { Input } from '../PoseidonComponents/Input/Input';
import { Key, Mail } from '../PoseidonComponents/StrokeIcons';
import { LogoIcon2 } from '../components/icons/icons';
import { trpc } from '../utils/trpc';
import styles from './auth.module.scss';

interface FormData {
  email: string;
  password: string;
  password2: string;
}

export const Signup = () => {
  const location = useLocation();
  const postSignupMutation = trpc.user.postSignUp.useMutation();

  const { control, formState, handleSubmit, setError } = useForm<FormData>({ mode: 'all' });

  const onSubmit = async (data: FormData) => {
    const email = data.email?.trim();
    const password = data.password?.trim();
    const password2 = data.password2?.trim();
    if (password2 != password) {
      setError('password2', { message: "Passwords don't match", type: 'custom' });
      return;
    }

    try {
      if (email != null && password != null) {
        await createUserWithEmailAndPassword(getAuth(), email, password);
        await postSignupMutation.mutateAsync();
      }
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        if (error.code === 'auth/wrong-password') {
          setError('password', { message: 'Invalid password', type: 'custom' });
        } else if (error.code === 'auth/invalid-email') {
          setError('email', { message: 'Invalid email', type: 'custom' });
        } else if (error.code === 'auth/user-not-found') {
          setError('root', { message: 'Failed to login', type: 'custom' });
        } else if (error.code === 'auth/too-many-requests') {
          setError('root', {
            message: 'Too many failed login attempts, please wait a few minutes',
            type: 'custom',
          });
        } else {
          setError('root', { message: 'Failed to login', type: 'custom' });
        }
      }
    }
  };

  const hash = location.hash;
  const search = location.search;
  const hasErrors = Object.values(formState.errors).length > 0;
  const className = clsx(styles.content, hasErrors ? styles.errorShake : null);

  return (
    <div className={clsx(styles.container, styles.signup)}>
      <LogoIcon2 className={styles.logo} />
      <form className={className} onSubmit={handleSubmit(onSubmit)}>
        {formState.errors?.root && (
          <div className={styles.error}>{formState.errors.root.message}</div>
        )}

        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <Input
              title="Email"
              placeholder="your@email.com"
              error={formState.errors.email}
              icon={<Mail />}
              {...field}
            />
          )}
        />

        <Controller
          control={control}
          name="password"
          render={({ field }) => (
            <Input
              title="Password"
              placeholder="Password"
              type="password"
              error={formState.errors.password}
              icon={<Key />}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="password2"
          render={({ field }) => (
            <Input
              title="Confirm Password"
              placeholder="Confirm Password"
              type="password"
              error={formState.errors.password2}
              icon={<Key />}
              {...field}
            />
          )}
        />
        <SubmitButton loading={formState.isLoading} disabled={formState.isLoading}>
          Sign up
        </SubmitButton>
      </form>
      <div className={styles.footer}>
        <div className={styles.footerButtons}>
          <Link to="../forgot-password" className={styles.footerButton}>
            Forgot Password
          </Link>
          <div className={styles.spacer} />
          <Link to={{ pathname: `../login`, search, hash }} className={styles.footerButton}>
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};
