import queryString from 'query-string'
import { User } from 'firebase/auth';
import { isStringNotEmpty } from '../utils2';
export class HttpError extends Error {

  _body: any;
  get body() {
    return this._body
  }

  constructor(message: string, body: any) {
    super(message)
    this._body = body
  }
}

export const getUserToken = async (user?: User) => {
  if (user == null) return null;
  const token = await user.getIdToken(true);
  return token;
}

type MethodType = "GET" | "POST"

export type ApiUrlPath = `/api/${string}` | `https${string}`

export const fetchUrl = async <T extends object>(method: MethodType, url: ApiUrlPath, options: any): Promise<T> => {
  const body = options?.body;
  let newUrl = null;
  if(method === 'GET') {
    newUrl = [url, queryString.stringify(body ?? {})].filter(isStringNotEmpty).join('?');
  } else {
    newUrl = url;
  }
  const contentType = (body instanceof FormData) ? undefined : "application/json"
  let headers: any = {};
  if(options?.headers) headers = { ...options.headers }
  if(contentType) headers['Content-Type'] = contentType;
  
  const res = await fetch(newUrl, {
    method,
    headers,
    body: (body == null || method === 'GET') ? undefined : ( (body instanceof FormData) ? body : JSON.stringify(body))
  });

  const resJson: T = await res.json();
  if (res.ok) {
    return resJson;
  } else {
    throw new HttpError(`${method} with token failed ${url} ${JSON.stringify(body, null, 2)}`, resJson);
  }
}

export const fetchUrlWithUser = async <T extends object>(user: User | null | undefined, method: MethodType, url: ApiUrlPath, body: any | undefined, amplitudeInstanceDeviceId?: string) => {
  const headers = await headersWithXToken2(user, amplitudeInstanceDeviceId)
  return fetchUrl<T>(method, url, {headers, body})
}

export const headersWithXToken2 = async (user: User | null | undefined, deviceId?: string) => {
  const userIdToken = user != null && await user.getIdToken();
  const headers: Record<string, string> = {}
  if(deviceId) {
    headers['X-AMP-DEVICE-ID'] = deviceId
  }
  if(userIdToken) headers['X-TOKEN'] = userIdToken;
  return headers;
}

export class Fetch {
  static Get = <T extends object>(user: User | null | undefined, url: ApiUrlPath, body: any | undefined, amplitudeInstanceDeviceId?: string) => {
    return fetchUrlWithUser<T>(user, "GET", url, body, amplitudeInstanceDeviceId)
  }
  static Post = <T extends object>(user: User | null | undefined, url: ApiUrlPath, body: any | undefined, amplitudeInstanceDeviceId?: string) => {
    return fetchUrlWithUser<T>(user, "POST", url, body, amplitudeInstanceDeviceId)
  }
}