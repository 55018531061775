import React from 'react';
import { TrashCan } from 'src/PoseidonComponents/StrokeIcons';
import { sources, sourcesById } from '../../sources';
import {
  useConceptGroupsAddConcept,
  useConceptGroupsAddProvider,
  useConceptGroupsRemoveConcept,
  useConceptGroupsRemoveProvider,
} from '../../trpcHooks/useConceptGroups';
import { AppProvider, SvrLibraryConcept, ConceptGroup as ConceptGroupModel, trpc } from '../../utils/trpc';
import { isNotEmpty } from '../../utils2';
import { Badge } from '../Badge/Badge';
import { ContentDivider } from '../ContentDivider/ContentDivider';
import { ContentSection } from '../ContentSection/ContentSection';
import { Select } from '../Input/Select';
import { Flex } from '../Layout/Layout';
import { SvgIconButton } from '../SvgIconButton/SvgIconButton';
import styles from './ConceptGroupCard.module.scss';

interface ConceptGroupProps {
  data: ConceptGroupModel;
  deleteConceptGroup: (conceptGroupId: string) => void;
  conceptById?: Record<string, SvrLibraryConcept>;
}

type SelectValue = {
  value: AppProvider;
  label: string;
  icon?: JSX.Element;
};

export const ConceptGroupCard: React.FC<ConceptGroupProps> = ({
  data,
  deleteConceptGroup,
  conceptById,
}) => {
  const addConceptMut = useConceptGroupsAddConcept();
  const removeConceptMut = useConceptGroupsRemoveConcept();
  const addProviderMut = useConceptGroupsAddProvider();
  const removeProviderMut = useConceptGroupsRemoveProvider();

  const concepts = trpc.concepts.all.useQuery({
    pagination: { perPage: 1000 },
  });

  const addConcept = (conceptGroupId: string, conceptId: string) => {
    if (addConceptMut.isLoading) {
      return;
    }
    addConceptMut.mutate({ conceptGroupId, conceptId });
  };
  const removeConcept = (conceptGroupId: string, conceptId: string) => {
    if (removeConceptMut.isLoading) {
      return;
    }
    removeConceptMut.mutate({ conceptGroupId, conceptId });
  };
  const addProvider = (conceptGroupId: string, provider: AppProvider) => {
    if (addProviderMut.isLoading) {
      return;
    }
    addProviderMut.mutate({ conceptGroupId, provider });
  };
  const removeProvider = (conceptGroupId: string, provider: AppProvider) => {
    if (removeProviderMut.isLoading) {
      return;
    }
    removeProviderMut.mutate({ conceptGroupId, provider });
  };

  const providers = sources
    .map((s) => ({ icon: s.icon, value: s.provider, label: s.name }))
    .filter((s) => !data.triggerProviders.includes(s.value));

  const conceptOptions = concepts.data
    ?.map((c) => ({ value: c._id.toHexString(), label: c.name || '' }))
    .filter((s) => !data.conceptIds.includes(s.value))
    .filter((c) => isNotEmpty(c.label));

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <span>{data.name}</span>
        <SvgIconButton
          size="small"
          icon={<TrashCan />}
          onClick={() => deleteConceptGroup(data._id.toHexString())}
          type="dangerous"
        />
      </div>
      <ContentSection size="small">
        <ContentDivider>Tags</ContentDivider>
        <Flex>
          {data.conceptIds?.map((id) => {
            const concept = conceptById?.[id];
            if (concept != null) {
              return (
                <Badge key={id} onDelete={() => removeConcept(data._id.toHexString(), id)}>
                  {concept.name || concept._id.toHexString()}
                </Badge>
              );
            } else {
              return (
                <Badge key={id} onDelete={() => removeConcept(data._id.toHexString(), id)}>
                  {id}
                </Badge>
              );
            }
          })}
        </Flex>
        <Select
          size="small"
          onChange={(v) => addConcept(data._id.toHexString(), v.value)}
          options={conceptOptions}
          placeholder="Add Concept"
        />
      </ContentSection>

      <ContentSection size="small">
        <ContentDivider>Providers</ContentDivider>
        <Flex>
          {data.triggerProviders?.map((p) => (
            <Badge
              key={p}
              onDelete={() => removeProvider(data._id.toHexString(), p)}
              icon={sourcesById?.[p]?.icon}>
              {p}
            </Badge>
          ))}
        </Flex>
        <Select<SelectValue>
          size="small"
          onChange={(v) => addProvider(data._id.toHexString(), v.value)}
          options={providers}
          placeholder="Add Provider"
        />
      </ContentSection>
    </div>
  );
};
