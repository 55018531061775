import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import clsx from 'clsx';
import React from 'react';
import styles from './RowDnd.module.scss';
import { SvgIconButton } from 'src/PoseidonComponents/SvgIconButton/SvgIconButton';
import { DraggerHandle, Pin } from 'src/PoseidonComponents/StrokeIcons';
import RatingBar from 'src/PoseidonComponents/RatingBar2/RatingBar2';
import { Tooltip } from 'src/libs/Tooltip';

export interface RowRowDndProps extends Omit<React.HTMLProps<HTMLDivElement>, 'name'> {
  selected?: boolean;
  name: string | JSX.Element;
  count: number;
  rating?: number;
  color?: string;
  tooltip: JSX.Element;
  onPin?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isPinned?: boolean;
  index: number;
  id: string;
}

export const RowDnd: React.FC<RowRowDndProps> = ({
  selected,
  onClick,
  name,
  id,
  rating,
  color,
  count,
  onPin,
  tooltip,
  isPinned,
  index,
}) => {
  const [hover, setHover] = React.useState(false);
  const className = clsx(
    styles.row,
    hover ? styles.hover : undefined,
    selected ? styles.selected : undefined,
    onPin ? styles.pinnable : undefined,
    isPinned ? styles.pinned : undefined,
  );
  const { attributes, listeners, setNodeRef, transform, isDragging, transition } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Transform.toString({
      x: transform?.x || 0,
      y: transform?.y || 0,
      scaleX: 1,
      scaleY: 1,
    }),
    transition,
    '--color': color,
  };

  return (
    <div
      ref={setNodeRef}
      onClick={onClick}
      style={style}
      className={clsx(className, styles.draggableReview)}>
      <div className={styles.rowDgD} {...attributes} {...listeners}>
        <SvgIconButton size="small" icon={<DraggerHandle />} />
      </div>
      <div className={styles.rowName}>{name}</div>
      <div className={styles.ratingBar}>
        <RatingBar value={rating} />
      </div>
      <div className={styles.rowCount}>{count}</div>
      {onPin && (
        <SvgIconButton className={styles.pin} onClick={onPin} icon={<Pin />} tooltip={'Pin'} />
      )}
      <Tooltip autoPlace={false} label={tooltip}>
        <div
          className={styles.tooltipRow}
          // style={style}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        />
      </Tooltip>
    </div>
  );
};
