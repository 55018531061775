import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { httpBatchLink } from '@trpc/client';
import { ObjectId } from 'bson';
import { getAuth } from 'firebase/auth';
import { Timestamp } from 'firebase/firestore';
import { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import superjson from 'superjson';
import styles from './App.module.scss';
import SettingsTeamsCreate from './PoseidonComponents/TeamsCreate';
import { RequireAuthed, RequireSuperAuthed, RequireUnAuthed } from './Route';
import { IntegrationsInfo } from './components/integrationsInfo/integrationsInfo';
import WeUseCookies from './components/weUseCookies/weUseCookies';
import Report from './containers/Report2/Report2';
import { Providers } from './contexts';
import { AmplitudeProvider } from './libs/react-amplitude';
import ConceptGroups from './pages/concept-groups';
import Concepts from './pages/concepts';
import DraftReport from './pages/drafts/[reportId]';
import { ForgotPassword } from './pages/forgot';
import { Login } from './pages/login';
import { Logout } from './pages/logout';
import Reports from './pages/reports';
import { Signup } from './pages/signup';
import TeamsContainer from './pages/teams';
import SettingsTeamsActive from './pages/teams/active';
import { trpc } from './utils/trpc';
import { namespaceStyle } from './utils2';

// add custom transfromer for ObjectId
superjson.registerCustom<ObjectId, string>(
  {
    isApplicable: (v): v is ObjectId => {
      return v instanceof ObjectId;
    },
    serialize: (v) => {
      return v.toHexString();
    },
    deserialize: (v) => {
      return new ObjectId(v);
    },
  },
  'objectid',
);

superjson.registerCustom<Timestamp, number>(
  {
    isApplicable: (v): v is Timestamp => {
      return v instanceof Timestamp;
    },
    serialize: (v) => {
      return v.toMillis();
    },
    deserialize: (v) => {
      return Timestamp.fromMillis(v);
    },
  },
  'timestamp',
);

const getBaseUrl = () => {
  if (typeof window !== 'undefined') return ''; // browser should use relative url
  if (process.env.VERCEL_URL) return `https://${process.env.VERCEL_URL}`; // SSR should use vercel url
  return `http://localhost:${process.env.PORT ?? 3001}`; // dev SSR should use localhost
};

const App = () => {
  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient] = useState(() => {
    const url = `${getBaseUrl()}/api/trpc`;

    return trpc.createClient({
      transformer: superjson,
      links: [
        httpBatchLink({
          url: url,
          // // optional
          headers: async () => {
            const authToken = await getAuth().currentUser?.getIdToken();
            if (!authToken) return {};
            return {
              authorization: `Bearer ${authToken}`,
            };
          },
        }),
      ],
    });
  });

  const shouldSowDevTools = process.env.REACT_APP_DEV_TOOLS == 'true';

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <AmplitudeProvider
          amplitudeInstance={undefined}
          apiKey={process.env.REACT_APP_AMPLITUDE_KEY}>
          <Providers>
            <div className={styles.container} style={namespaceStyle()}>
              <Router>
                <Routes>
                  <Route path="/integrations-info" element={<IntegrationsInfo />} />
                  <Route
                    path="/logout"
                    element={
                      <RequireAuthed redirectTo="/login" cleanRedirect>
                        <Logout />
                      </RequireAuthed>
                    }
                  />
                  <Route
                    path="/login"
                    element={
                      <RequireUnAuthed redirectTo="/">
                        <Login />
                      </RequireUnAuthed>
                    }
                  />
                  <Route
                    path="/signup"
                    element={
                      <RequireUnAuthed redirectTo="/signup-success">
                        <Signup />
                      </RequireUnAuthed>
                    }
                  />
                  <Route
                    path="/forgot-password"
                    element={
                      <RequireUnAuthed redirectTo="/forgot-password-success">
                        <ForgotPassword />
                      </RequireUnAuthed>
                    }
                  />

                  <Route
                    path="/reports"
                    element={
                      <RequireAuthed redirectTo="/login">
                        {({user, team}) => <Reports user={user} team={team} />}
                      </RequireAuthed>
                    }
                  />
                  <Route
                    path="/reports/:reportId/*"
                    element={
                      <RequireAuthed redirectTo="/login">
                        {({user, team}) => <Report user={user} team={team} />}
                      </RequireAuthed>
                    }
                  />
                  <Route
                    path="/drafts"
                    element={
                      <RequireAuthed redirectTo="/login">
                        {({user, team}) => <Reports user={user} team={team} isDraft />}
                      </RequireAuthed>
                    }
                  />
                  <Route
                    path="/drafts/:reportId/*"
                    element={
                      <RequireAuthed redirectTo="/login">
                        {({user, team}) => <DraftReport user={user} team={team} />}
                      </RequireAuthed>
                    }
                  />
                  <Route
                    path="/teams"
                    element={
                      <RequireAuthed redirectTo="/login">
                        {({user, team}) => <TeamsContainer user={user} team={team} />}
                      </RequireAuthed>
                    }
                  />
                  <Route
                    path="/teams/active"
                    element={
                      <RequireAuthed redirectTo="/login">
                        {({user, team}) => <SettingsTeamsActive user={user} team={team} />}
                      </RequireAuthed>
                    }
                  />
                  <Route
                    path="/teams/create"
                    element={
                      <RequireAuthed redirectTo="/login">
                        {({user, team}) => <SettingsTeamsCreate user={user} team={team} />}
                      </RequireAuthed>
                    }
                  />

                  <Route
                    path="/concepts"
                    element={
                      <RequireSuperAuthed redirectTo="/login">
                        {({user, team}) => <Concepts user={user} team={team} />}
                      </RequireSuperAuthed>
                    }
                  />

                  <Route
                    path="/concept-groups"
                    element={
                      <RequireSuperAuthed redirectTo="/login">
                        {({user, team}) => <ConceptGroups user={user} team={team} />}
                      </RequireSuperAuthed>
                    }
                  />

                  <Route path="/*" element={<Navigate to="/reports" />} />
                </Routes>
              </Router>
              <Toaster
                position="bottom-right"
                reverseOrder={false}
                toastOptions={{ className: styles.toast }}
              />
            </div>
            <WeUseCookies />
          </Providers>
        </AmplitudeProvider>
        {shouldSowDevTools == true ? <ReactQueryDevtools initialIsOpen={false} /> : false}
      </QueryClientProvider>
    </trpc.Provider>
  );
};

export default App;
