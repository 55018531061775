import { useQueryClient } from '@tanstack/react-query';
import { reportCategoriesRQKey, reportConceptByIdRQKey, RQUtils } from '../utils/reactQuery.utils';
import { ReportCategory, trpc } from '../utils/trpc';

export const useReportCategoriesDelete = (reportId: string) => {
  const queryClient = useQueryClient();

  const reportConceptsKey = reportCategoriesRQKey(reportId);

  return trpc.reportCategories.delete.useMutation({
    onSuccess: (deletedReportConcept) => {
      queryClient.setQueryData<ReportCategory[]>(reportConceptsKey, (old) => {
        return RQUtils.delArrQueryV2(old, deletedReportConcept._id, '_id');
      });
    },
  });
};

export const useReportCategoriesCreateFromConcepts = () => {
  const utils = trpc.useContext();
  const queryClient = useQueryClient();

  return trpc.reportCategoriesConcept.createFromConcepts.useMutation({
    onSuccess: (newReportConcepts, { reportId }) => {
      const reportConceptsKey = reportCategoriesRQKey(reportId);
      // update report concepts
      queryClient.setQueryData<ReportCategory[]>(reportConceptsKey, (old) => {
        // cluster element was updated
        return RQUtils.bulkUpdateArrQueryV2(old, newReportConcepts, '_id');
      });
      utils.reportCategories.list.invalidate({ reportId });
    },
  });
};

export const useReportCategoryRemoveWord = () => {
  const queryClient = useQueryClient();
  const utils = trpc.useContext();

  return trpc.reportCategories.removeWord.useMutation({
    onMutate: ({ categoryId, reportId, aspect, word }) => {
      utils.report.conceptById.setData({ reportId, id: categoryId }, (data) => {
        if (data == null) return undefined;
        if (aspect) {
          const updateWords = data?.[aspect]?.words?.filter((el) => el !== word);
          return { ...data, [aspect]: { ...data?.[aspect], words: updateWords } };
        } else {
          const updateWords = data?.words?.filter((el) => el !== word);
          return { ...data, words: updateWords };
        }
      });
    },
    onSuccess: (updatedReportConcept, { reportId, categoryId }) => {
      queryClient.setQueryData<ReportCategory>(
        reportConceptByIdRQKey(reportId, categoryId),
        (old) => {
          return updatedReportConcept;
        },
      );
    },
  });
};

export const useAddWords = () => {
  const queryClient = useQueryClient();
  const utils = trpc.useContext();

  return trpc.reportCategories.addWords.useMutation({
    onMutate: ({ categoryId, reportId, aspect, words }) => {
      utils.report.conceptById.setData({ reportId, id: categoryId }, (data) => {
        if (data == null) return undefined;
        if (aspect && data[aspect]?.words) {
          const checkWords = words.filter((el) => !(data[aspect]?.words || []).includes(el));
          const updateWords = [...(data[aspect]?.words || []), ...checkWords];
          return { ...data, [aspect]: { ...data?.[aspect], words: updateWords } };
        } else {
          const checkWords = words.filter((el) => !(data.words || []).includes(el));
          const updateWords = [...(data.words || []), ...checkWords];
          return { ...data, words: updateWords };
        }
      });
    },
    onSuccess: (updatedReportConcept, { reportId, categoryId }) => {
      queryClient.setQueryData<ReportCategory>(
        reportConceptByIdRQKey(reportId, categoryId),
        (old) => {
          return updatedReportConcept;
        },
      );
    },
  });
};
