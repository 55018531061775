import { useQueryClient } from '@tanstack/react-query';
import { reportReviewsIdWithBrand } from 'src/utils/reactQuery.utils';
import { SvrReportReviewIdWithBrand, trpc } from '../utils/trpc';

export const useReportClassifyDelete = () => {
  const utils = trpc.useContext();
  const queryClient = useQueryClient();

  return trpc.reportClassify.delete.useMutation({
    onMutate: ({ reportId, classificationId, reviewId }) => {
      utils.reportReviews.idWithBrand.setData({ reportId, reviewId }, (data) => {
        if (data == null) return undefined;
        if (!data.classOverrides) return data;

        const updateClassOverrides = data.classOverrides?.filter(
          (el) => el._id.toHexString() !== classificationId,
        );

        return { ...data, classOverrides: updateClassOverrides };
      });
    },
    onSuccess: (_, { reportId, reviewId, classificationId }) => {
      queryClient.setQueryData<SvrReportReviewIdWithBrand>(
        reportReviewsIdWithBrand(reportId, reviewId),
        (old) => {
          if (!old?.classOverrides) return old;
          const newClassOverrides = old.classOverrides?.filter(
            (el) => el._id.toHexString() !== classificationId,
          );
          return { ...old, classOverrides: newClassOverrides };
        },
      );
    },
  });
};



export const useReportClassifyAdd = () => {
  const queryClient = useQueryClient();
  const utils = trpc.useContext();

  return trpc.reportClassify.add.useMutation({
    onMutate: ({ data: newData, reportId, reviewId }) => {
      utils.reportReviews.idWithBrand.setData({ reportId, reviewId }, (data) => {
        if (data == null) return undefined;
        if (!data?.classOverrides) return data;

        //TODO:CHECK_THIS
        return { ...data };
      });
    },
    onSuccess: (updatedValue, { reportId, reviewId }) => {
      queryClient.setQueryData<SvrReportReviewIdWithBrand>(
        reportReviewsIdWithBrand(reportId, reviewId),
        (old) => {
          return old;
        },
      );
    },
  });
};


export const useReportClassifyUpdate = () => {
  const queryClient = useQueryClient();
  const utils = trpc.useContext();

  return trpc.reportClassify.update.useMutation({
    onMutate: ({ classOverrideId, data: newData, reportId, reviewId }) => {
      utils.reportReviews.idWithBrand.setData({ reportId, reviewId }, (data) => {
        if (data == null) return undefined;
        if (!data?.classOverrides) return data;
        const updatedClassOverrides = data?.classOverrides?.map((el) => {
          if (el._id.toHexString() === classOverrideId) {
            return { ...el, score: newData.score };
          }
          return el;
        });

        return { ...data, classOverrides: updatedClassOverrides };
      });
    },
    onSuccess: (updatedValue, { reportId, reviewId }) => {
      queryClient.setQueryData<SvrReportReviewIdWithBrand>(
        reportReviewsIdWithBrand(reportId, reviewId),
        (old) => {
          return old;
        },
      );
    },
  });
};
