import useRouterQuery from '../hooks/useRouterQuery';
import { ReportCategory, ServerDataCounts, SvrReport, SvrUser } from '../utils/trpc';
import { ConceptDetailAddPage } from './ConceptDetailAddPage/ConceptDetailAddPage';
import { ConceptDetailPage, PinnedConceptDetailPage } from './ConceptDetailPage/ConceptDetailPage';
import { DetailPage } from './DetailPage/DetailPage';
import { MachineDetailUpdatePageWithRange } from './MachineDetail/MachineDetailUpdatePageWithRange';
import { MachineDetailPage } from './MachineDetailPage/MachineDetailPage';
import { MachineDetailPageWithRange } from './MachineDetailPage/MachineDetailPageWithRange';
import { ReviewDetailPage } from './ReviewDetailPage';

interface DetailPage2PRops {
  report: SvrReport;
  user: SvrUser;
  dataCounts?: ServerDataCounts;
  conceptsById: Record<string, ReportCategory>;
}
export const ConceptReviewDetailPage: React.FC<DetailPage2PRops> = ({
  report,
  user,
  dataCounts,
  conceptsById,
}) => {
  const [query] = useRouterQuery();

  if (query.machine) {
    if (query.machine.start != null && query.machine.end != null && query.machine.id) {
      return (
        <DetailPage>
          <MachineDetailUpdatePageWithRange
            conceptsById={conceptsById}
            reviewId={query.machine.rid}
            report={report}
            user={user}
            machineStart={query.machine.start}
            machineEnd={query.machine.end}
          />
        </DetailPage>
      );
    } else if (query.machine.start != null && query.machine.end != null) {
      return (
        <DetailPage>
          <MachineDetailPageWithRange
            reviewId={query.machine.rid}
            report={report}
            user={user}
            machineStart={query.machine.start}
            machineEnd={query.machine.end}
          />
        </DetailPage>
      );
    } else {
      return (
        <DetailPage>
          <MachineDetailPage reviewId={query.machine.rid} report={report} user={user} />
        </DetailPage>
      );
    }
  } else if (query.addConcept) {
    return (
      <DetailPage>
        <ConceptDetailAddPage reportId={report._id.toHexString()} catType={query.addConcept} />
      </DetailPage>
    );
  } else if (query.pinnedConcept) {
    return (
      <DetailPage>
        <PinnedConceptDetailPage
          id={query.pinnedConcept}
          reportId={report._id.toHexString()}
          user={user}
        />
      </DetailPage>
    );
  } else if (query.category) {
    return (
      <DetailPage>
        <ConceptDetailPage id={query.category} reportId={report._id.toHexString()} user={user} />
      </DetailPage>
    );
  } else if (query.rId) {
    return (
      <DetailPage>
        <ReviewDetailPage
          id={query.rId}
          reportCounts={dataCounts}
          reportId={report._id.toHexString()}
        />
      </DetailPage>
    );
  } else {
    return null;
  }
};
